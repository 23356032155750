import { makeStyles } from '@material-ui/core/styles';
import { GRAY, VERY_LIGHT_PURPLE3, VERY_LIGHT_PURPLE4, PURPLE } from '../../../../../constants/colors';

export const useCustomListItemStyles = makeStyles((theme) => ({
    
  listItemText : {
    color: GRAY,
    marginLeft: -10,
    textAlign: 'center',
  },

  listItem : props => ({
    paddingRight: 0,
    '&:hover': {
      borderRadius: '0.3rem',
      backgroundColor: VERY_LIGHT_PURPLE4,
    },
    "&.Mui-selected": {
      borderRadius: '0.3rem',
      borderLeft: `0.3rem solid ${PURPLE}`,
      backgroundColor: VERY_LIGHT_PURPLE3,
    },
    paddingLeft: (props.nested) ? ((props.small)? theme.spacing(1) : theme.spacing(2)) : theme.spacing(1),
  }),

}));
