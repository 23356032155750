import React from 'react';
import YearRow from './yearRow';
import Grid from '@material-ui/core/Grid';
import { Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import LockIcon from '@material-ui/icons/Lock';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import DeleteIcon from '@material-ui/icons/Delete';
import { YELLOW } from '../../../constants/colors';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FloatingCard from '../../utils/GenericComponents/floatingCard';
import { CircularProgressWithLabel } from '../../utils/GenericComponents/progressBar';
import ConfirmDeleteAlertDialog from '../../utils/GenericComponents/dialogDeleteConfirm';

import { Accordion, AccordionDetails, AccordionSummary } from './styles';

const GoalsDashboard = (props) => {
    const { employee, firebase, isManager } = props;
    const currentYear = new Date().getFullYear();
    const [snackMsg, setSnackMsg] = React.useState("");
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [expanded, setExpanded] = React.useState(currentYear);
    const [selectedYear, setSelectedYear] = React.useState(currentYear);
    const [openDialogConfirmDelete, setOpenDialogConfirmDelete] = React.useState(false);
  
    const years = Object.keys(employee?.contract?.goals || {}).map(year => {
        let progress = 0;
        let count = Object.values(employee.contract.goals[year]).reduce((count, quarter) => {
            progress += Object.keys(quarter).reduce((acc, goalKey) => acc + quarter[goalKey].progress, 0);
            return count + Object.keys(quarter).length;
        }, 0);
        return { value: year, ...employee.contract.goals[year], progress: Math.round(progress / (count || 1), 1) };
    }).sort((a, b) => b.value - a.value);

    const onCancelDeleteYearGoal = () => setOpenDialogConfirmDelete(false);
    const handleChange = (panel) => (event, newExpanded) => setExpanded(newExpanded ? panel : false);

    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') return;
        setSnackOpen(false);
    };

    const setSnackStatus = (status, msg) => {
        setSnackMsg(msg);
        setSnackOpen(status);
    }
    
    const onDeleteYearGoal = (event, year) => {
        event.stopPropagation();
        setSelectedYear(year);
        setOpenDialogConfirmDelete(true);
    };

    const onConfirmDeleteYearGoal = () => {
        delete employee.contract.goals[selectedYear];
        setOpenDialogConfirmDelete(false);
        saveUserInfos(`Goal deleted successfully for ${selectedYear}`);
    };

    const onCreateGoal = () => {
        let years = Object.keys(employee?.contract?.goals || { [(new Date().getFullYear() - 1)]: ({})}).sort((a, b) => b - a);
        const lastYear = parseInt(years[0]) + 1;
        if (!employee.contract?.goals?.[lastYear]) {
            if (!employee.contract.goals) employee.contract.goals = {};
            employee.contract.goals[lastYear] = {
                Q1: { [`goal${Date.now()+1}`]: { description: "New goal: TBD", progress: 0 }},
                Q2: { [`goal${Date.now()+2}`]: { description: "New goal: TBD", progress: 0 }},
                Q3: { [`goal${Date.now()+3}`]: { description: "New goal: TBD", progress: 0 }},
                Q4: { [`goal${Date.now()+4}`]: { description: "New goal: TBD", progress: 0 }},
            };
            saveUserInfos(`Goal created successfully for ${lastYear + 1}`);
        }
    };

    const saveUserInfos = (msg = null) => {
        const payload = employee.contract.goals;
        firebase.db.ref(`/users/${employee.uid}/contract/goals`).set(payload)
        .then(() => setSnackStatus(true, msg || "User infos saved successfully"))
        .catch(error => setSnackStatus(true, "Error while saving user infos"));
    };

    return (
        <FloatingCard
            title="Goals"
            avatar={<GpsFixedIcon />}
            subheader={`Personal goals for each year & quarter`}
            action={
                <Tooltip arrow title={ isManager ? 'Add a new yearly objective' : 'Locked' }>
                  <span>
                    <IconButton
                      disabled={!isManager}
                      aria-label="add-goals"
                      onClick={onCreateGoal}
                    >
                      {isManager ? <AddIcon style={{ color: YELLOW }} /> : <LockIcon />}
                    </IconButton>
                  </span>
                </Tooltip>
            }    
        >
            <div style={{flexGrow: 1}}>

                {years.map(year => (
                    <Accordion expanded={expanded === year.value} onChange={handleChange(year.value)} key={year.value}>
                        <AccordionSummary
                            id={`${year.value}-header`}
                            expandIcon={<ExpandMoreIcon />}
                            IconButtonProps={{edge: 'start'}}
                            aria-controls={`${year.value}-content`}
                        >
                            <Grid container spacing={0} direction="row" alignItems="center" justifyContent='center' style={{margin: -5, flexGrow: 1}}>
                                <Grid item xs={11} align="left" >
                                    <Typography variant='h5' gutterBottom >{year.value} {year?.description}</Typography>
                                </Grid>
                                <Grid item xs={1} align="right">
                                    <Grid container spacing={0} direction="row" alignItems="center" justifyContent='center'>
                                        <Grid item style={{maxWidth: 45}}>
                                            <CircularProgressWithLabel size={43} value={year.progress} normal={true}/>
                                        </Grid>
                                        {isManager && (<Grid item style={{maxWidth: 45}}>
                                            <Tooltip arrow title="Delete yearly goals">
                                                <IconButton aria-label="delete-year" onClick={(event) => onDeleteYearGoal(event, year.value)}>
                                                    <DeleteIcon style={{fontSize: '1.0rem'}}/>
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>)}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <YearRow {...props} year={year} saveUserInfos={saveUserInfos} setSnackStatus={setSnackStatus} />
                        </AccordionDetails>
                    </Accordion>
                ))}

                {years.length === 0 ? <Typography variant='h5' gutterBottom><center>{`No goals set yet`}</center></Typography> : null}

                <ConfirmDeleteAlertDialog
                    open={openDialogConfirmDelete}
                    title={`Delete goals for ${selectedYear}?`}
                    onCancelDeleteCallback={onCancelDeleteYearGoal}
                    onConfirmDeleteCallback={onConfirmDeleteYearGoal}
                    message={`Are you sure you want to delete all goals for ${selectedYear}?`}
                />

                <Snackbar
                    open={snackOpen}
                    message={snackMsg}
                    autoHideDuration={2000}
                    onClose={handleCloseSnackBar}
                    anchorOrigin={ {vertical: 'top', horizontal: 'center' }}
                    action={
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackBar}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    }
                />

            </div>
        </FloatingCard>
    );
}

export default GoalsDashboard;