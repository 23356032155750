import React from 'react';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import { useStyles } from './styles';

const CreateNewsButton = (props) => {
  
  const classes = useStyles();

  const { onCreateNews } = props;

  return (
        <Fab size="medium" color="secondary" aria-label="add" className={classes.fab} onClick={onCreateNews}>
          <AddIcon />
        </Fab>
  );
}

export default CreateNewsButton;

