import React from 'react';
import { DAY_OPTIONS } from '../dayOptions';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Grid, TextField } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import HalfFullDayOptionsPanel from './halfFullDayOptionsPanel';
import AddInGoogleCandarPanel from './addInGoogleCalendarPanel';

import { useStyles } from '../styles';

const LEAVE_CHOICES = {
    reason1: 'Sick leave',
    reason2: 'Holidays',
    reason3: 'Family event',
    reason4: 'Special event',
    reason5: 'Unpaid leave',
};

const NameEventPanel = (props) => {
    const { eventName, onChangeTextField } = props;
    const [choice, setChoice] = React.useState('reason2');

    const handleChange = (event) => {
        setChoice(event.target.value);
        onChangeTextField({target: {value: LEAVE_CHOICES[event.target.value]}});
    }

    const classes = useStyles();
    return (
        <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
            <Grid item >
                <TextField
                    id={'name'}
                    align="left"
                    name={'name'}
                    margin="dense"
                    value={eventName}
                    variant="outlined"
                    label={'name your event'}
                    onChange={onChangeTextField}
                    className={classes.textField}
                />
            </Grid>
            <Grid item >
                <FormControl className={classes.holidayReason}>
                    <InputLabel id="holiday-select-helper-label">Or choose among list</InputLabel>
                    <Select
                        value={choice}
                        onChange={handleChange}
                        id="holiday-select-helper"
                        labelId="holiday-select-helper-label"
                    >
                        {Object.keys(LEAVE_CHOICES).map(key => (
                            <MenuItem key={key} value={key}>{LEAVE_CHOICES[key]}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
}

const RecapPanel = (props) => {
    const {
        step,
        endDate,
        message,
        startDate,
        eventName,
        endDayOption,
        setEventName,
        availableDays,
        daysOffToTake,
        startDayOption,
        setEndDayOption,
        setStartDayOption,
        addInGoogleCalendar,
        setAddInGoogleCalendar
    } = props;

    const duration = (endDate?.getTime() || 0) - (startDate?.getTime() || 0) < 86400000;
    const remainingDays = Math.round((availableDays - daysOffToTake) * 10) / 10;
    const classes = useStyles({ remainingDays });

    if (startDate === null || endDate === null) return null;

    const onChangeTextField = (event) => setEventName(event.target.value);
    const showDay = (day) => <span className={classes.day}><strong>{day.toLocaleDateString()}</strong></span>

    const showMessage = (oneDayOnly) => {
        let message = '';
        if (oneDayOnly) {
            const label = Object.values(DAY_OPTIONS).find(day => day.option === startDayOption).label;
            message = <span>A single day: {showDay(startDate)} <i>({label})</i></span>
            return <Typography variant="h6" gutterBottom>{message}</Typography>
        } else {
            const labelEnd = (Object.values(DAY_OPTIONS).find(day => day.option === endDayOption).value === 0) ? 'Full day' : 'Morning';
            const labelStart = (Object.values(DAY_OPTIONS).find(day => day.option === startDayOption).value === 0) ? 'Full day' : 'Afternoon';
            return (
                <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
                    <Grid item >
                        <Typography gutterBottom className={classes.recapMessage}>
                            From: {showDay(startDate)} <i>({labelStart})</i>
                        </Typography>
                    </Grid>
                    <Grid item >
                        <Typography gutterBottom className={classes.recapMessage}>
                            To: {showDay(endDate)} <i>({labelEnd})</i>
                        </Typography>
                    </Grid>
                </Grid>
            );
        }
    }

    return (
        <>
            {message && (
                <Typography variant="caption" gutterBottom className={classes.remaining}>
                    {message}
                </Typography>
            )}
            <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center" className={classes.recapPanel}>
                <Grid item xs={12} align="center">
                    {showMessage(duration)}
                </Grid>
                {addInGoogleCalendar && (
                    <Grid item xs={12} align="center">
                        <Typography variant="caption" gutterBottom>
                            <i>You have selected to add the days off to your Google calendar.</i>
                        </Typography>
                    </Grid>
                )}
                {step === 1 && (
                    <div className={classes.step1}>
                        <Grid item xs={12} align="center">
                            <HalfFullDayOptionsPanel
                                endDate={endDate}
                                startDate={startDate}
                                endDayOption={endDayOption}
                                startDayOption={startDayOption}
                                setEndDayOption={setEndDayOption}
                                setStartDayOption={setStartDayOption}
                            />
                        </Grid>
                        <Grid item xs={12} align="center">
                            <NameEventPanel eventName={eventName} onChangeTextField={onChangeTextField} />
                        </Grid>
                    </div>
                )}
                {step === 2 && (
                    <Grid item xs={12} align="center">
                        <AddInGoogleCandarPanel setAddInGoogleCalendar={setAddInGoogleCalendar} />
                    </Grid>
                )}
            </Grid>
        </>
    );
}

export default RecapPanel;