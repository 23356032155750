import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
      
    sectionMobile: {
      display: 'flex',
      flexGrow: 1,
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    
    grow: {
      flexGrow: 1,
      paddingTop: 8,
    },
  
}));
  