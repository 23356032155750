export const schema = [
    {
        width: 20,
        column: 'Last Name',
        value: employee => employee.lastName,
    },
    {
        width: 20,
        column: 'First Name',
        value: employee => employee.firstName,
    },
    {
        width: 12,
        column: 'Date of Birth',
        value: employee => {
            const date = employee?.birthDate?.split('/') || null;
            if (!date) return "";
            return `${date[1]}/${date[0]}/${date[2]}`;
        }
    },
    {
        width: 20,
        column: 'Place of Birth',
        value: employee => employee.birthPlace,
    },
    {
        width: 5,
        column: 'Age',
        value: employee => employee.age,
    },
    {
        width: 12,
        column: 'Hiring date',
        value: employee => {
            const date = employee?.start?.split('/') || null;
            if (!date) return "";
            return `${date[1]}/${date[0]}/${date[2]}`;
        }
    },
    {
        width: 12,
        column: 'End date',
        value: employee => {
            const date = employee?.end?.split('/') || null;
            if (!date) return "";
            return `${date[1]}/${date[0]}/${date[2]}`;
        }
    },
    {
        width: 10,
        column: 'Salary',
        value: employee => employee.salary,
    },
    {
        width: 15,
        column: 'Contract Type',
        value: employee => employee.type,
    },
    {
        width: 30,
        column: 'Contract Role',
        value: employee => employee.role,
    },
    {
        width: 30,
        column: 'Nb BSPCE',
        value: employee => employee.esops,
    },
    {
        width: 30,
        column: 'Perso Phone',
        value: employee => employee.persoPhone,
    },
    {
        width: 30,
        column: 'Perso mail',
        value: employee => employee.persoMail,
    },
    {
        width: 30,
        column: 'Address',
        value: employee => employee.address,
    },
    {
        width: 30,
        column: 'City',
        value: employee => employee.city,
    },
    {
        width: 30,
        column: 'Postal code',
        value: employee => employee.zip,
    },
    {
        width: 30,
        column: 'Country',
        value: employee => employee.country,
    },
    {
        width: 30,
        column: 'Secu #',
        value: employee => employee.id,
    },
  ];