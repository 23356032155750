import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({

    tagIcon: {
        fontSize: theme.spacing(2),
    },

    tagTextField: {
        fontSize: theme.spacing(1.5),
    },

    tagTextFieldLabel: {
        fontSize: theme.spacing(1.5),
    },

    tagList: {
        padding: theme.spacing(0.5),
        backgroundColor: 'rgba(0, 0, 0, 0.01)',
    },

    popup: {
        padding: 12,
        overflowY: 'auto',
        maxHeight: '300px',
    },

    tag: {
        padding: theme.spacing(0.5),
        fontSize: theme.spacing(1.5),
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
        },
    },

}));
