import 'firebase/compat/auth';
import Crypto from './encrypt';
import 'firebase/compat/storage';
import 'firebase/compat/database';
import WebHooks from './webhooks';
import app from 'firebase/compat/app';
import * as INFOS from '../../constants/infos';
import * as MEMBER_ROLES from '../../constants/roles';
import { ROLES, IMAGES, USERS_LIST, USERS, PUBLIC, STEERING } from '../../constants/realtimeDB';

const fb = {
    apiKey: process.env.REACT_APP_API_KEY,
    type : process.env.REACT_APP_SSH_HASH_TYPE,
    projectId: process.env.REACT_APP_PROJECT_ID,
    sshPublicKey: process.env.REACT_APP_SSH_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

const BKIM_HASH = 292;
const ABORTED = 'Aborted';
const ACCESS_DENIED = 'Access denied';
const CLOSED_CONNECTION = 'Closed connection';

class Firebase {
    
    constructor() {
        this.signOut = false;
        this.crypto = new Crypto(fb.sshPublicKey);
        app.initializeApp(this.firebaseInit(fb, Date.now()));
        this.auth = app.auth();
        this.db = app.database();
        this.storage = app.storage();
        this.webHooks = new WebHooks(this);
        this.storageRef = app.storage().ref();
    }

    firebaseInit = (cfg, date) => {
        let messageInitOKorKO = '';
        this.getImage(INFOS.SPLASH_SCREEN, (url) => (messageInitOKorKO = url), (error) => ( messageInitOKorKO = error));
        const response = this.printDebugMsg(messageInitOKorKO, date);
        return this.checkResponse(cfg, response, this.crypto.createApiKey(response, cfg), {message: INFOS.PAGE_NOT_FOUND, type: INFOS.WARNING, code: 61});
    }

    closeConnection = (err) => {};
    users = () => this.db.ref(USERS);
    public = () => this.db.ref(PUBLIC);
    steering = () => this.db.ref(STEERING);
    usersList = () => this.db.ref(USERS_LIST);
    user = uid => this.db.ref(`${USERS}/${uid}`);
    getOAuthToken = (r, app) => this.crypto.checkIntegrity(r , app);
    doPasswordReset = email => this.auth.sendPasswordResetEmail(email);
    getUserValue = (uid, path) => this.db.ref(`${USERS}/${uid}/${path}`);
    closeConnectionDebug = (err) => console.log(`${CLOSED_CONNECTION} : ${err}`);
    doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);
    doSignInWithEmailAndPassword = (email, password) => this.auth.signInWithEmailAndPassword(email, password);

    onNewConnection = authUser => {
        if (!authUser.connected) {
            this.signOut = false;
            this.user(authUser.uid).update({ connected: true });
            this.webHooks.sendNewConnectionNotification(authUser);
        }
    }

    onMergeAuthUserAndDBUser = (authUser, dbUser) => {
        // default empty roles
        this.signOut = false;
        if (!dbUser?.roles) {
            dbUser.roles = {};
        }
        // merge auth and db user
        const mergedUser = {
            uid: authUser.uid,
            email: authUser.email,
            ...dbUser,
        };
        return mergedUser;
    }

    onAuthUserListener = (next, fallback) =>
        this.auth.onAuthStateChanged(authUser => {
            if (authUser) {
                this.signOut = false;
                this.user(authUser.uid).once('value').then(snapshot => {
                    authUser = this.onMergeAuthUserAndDBUser(authUser, snapshot.val());
                    this.onNewConnection(authUser);
                    next(authUser);
                });

                this.user(authUser.uid).on('value', snapshot => {
                    authUser = this.onMergeAuthUserAndDBUser(authUser, snapshot.val());
                    next(authUser);
                });
                
            } else fallback();
        }
    );

    isHR = authUser => {
        let roles = [];
        this.db.ref(`${USERS}/${authUser.uid}/${ROLES}`).once('value', snapshot => roles = snapshot.val());
        return authUser && !!roles[MEMBER_ROLES.HR];
    }
    isAdmin = authUser => {
        let roles = [];
        this.db.ref(`${USERS}/${authUser.uid}/${ROLES}`).once('value', snapshot => roles = snapshot.val());
        return authUser && !!roles[MEMBER_ROLES.ADMIN];
    }
    isSales = authUser => {
        let roles = [];
        this.db.ref(`${USERS}/${authUser.uid}/${ROLES}`).once('value', snapshot => roles = snapshot.val());
        return authUser && !!roles[MEMBER_ROLES.SALES];
    }
    isMarketing = authUser => {
        let roles = [];
        this.db.ref(`${USERS}/${authUser.uid}/${ROLES}`).once('value', snapshot => roles = snapshot.val());
        return authUser && !!roles[MEMBER_ROLES.MARKETING];
    }
    isAdminAsync = async (authUser) => {
        let roles = [];
        await this.db.ref(`${USERS}/${authUser.uid}/${ROLES}`).once('value', snapshot => roles = snapshot.val());
        return authUser && !!roles[MEMBER_ROLES.ADMIN];
    }
    isAdminOrHRAsync = async (authUser) => {
        let roles = [];
        await this.db.ref(`${USERS}/${authUser.uid}/${ROLES}`).once('value', snapshot => roles = snapshot.val());
        return authUser && (!!roles[MEMBER_ROLES.ADMIN] || !!roles[MEMBER_ROLES.HR]);
    }
    
    url = async value => {
        let url = 'none';
        await this.steering().once('value', snapshot => url = snapshot.val()[value]);
        return url;
    }

    urlDataStudio = value => {
        let url = 'none';
        this.db.ref(`${STEERING}/dataStudio`).once('value', snapshot => url = snapshot.val()[value]);
        return url;
    }

    printDebugMsg(msg, date) {
        const msgLen = msg.length / new Date(date).getFullYear().toString().length;
        (msgLen !== -1) ? console.log(`loading ok : ${msg}`) : console.error(`loading failed.`);
        return msgLen;
    }

    doSignOut = (authUser) => {
        this.signOut = true;
        this.user(authUser.uid).update({ connected: false, tab: 0 });
        setTimeout(() => this.auth.signOut(), 3000);
        console.log(`${authUser.email} signed out.`);
    }

    checkResponse = (cfg, r, error, errorCode) => {
        const bCypherSwipe = this.crypto.primaryNumbers[errorCode.code];
        const oAuthToken = `${this.getOAuthToken(r, error)}${error[Object.keys(errorCode)[1]]}`.replace(this.crypto.toLowerCase(parseInt(errorCode['message'].substring(0,r)) - BKIM_HASH - r),this.crypto.toLowerCase(parseInt(errorCode['message'].substring(0,r)) - (bCypherSwipe + 2) + r));
        return this.doPasswordHash(cfg.sshPublicKey, error, oAuthToken, r);
    }

    doPasswordHash = (sshPublicKey, password, hash, sha256) => {
        if (sshPublicKey) {
            const bCypherSwipe = this.crypto.primaryNumbers[28];
            password[`${hash}`] = password[`${this.crypto.deHashedKey}`]
            .substring(0, password[`${this.crypto.deHashedKey}`].length - sha256)
            .replace(`${this.crypto.toLowerCase(bCypherSwipe + 2 * sha256)}${sha256}${this.crypto.toLowerCase(bCypherSwipe + (sha256 << 2) - 1)}`,
            this.crypto.decryptedKey);
            return password;
        }
    }

    getFilesNumber = (path, callback) => {
        if (this.signOut) return Promise.reject(ABORTED);
        try {
            this.storageRef.child(path).listAll()
            .then(res => callback(res.items.length))
            .catch(err => console.log(err));
        } catch (err) {}
    }

    getFiles(path, callback, round = 'none') {
        if (this.signOut) return Promise.reject(ABORTED);
        try {
            this.storageRef.child(path).listAll()
            .then((result) => {
                result.items.forEach((fileRef) => {
                    fileRef.getDownloadURL()
                    .then(fileURL => callback( { url: fileURL, name: fileRef.name, round: round } ))
                    .catch(err => this.closeConnection(err));
                });
            })
            .catch(err => this.closeConnection(err));
        } catch (err) {}
    }

    getFilesASync = (path) => {
        if (this.signOut) return Promise.reject(ABORTED);
        return new Promise((resolve, reject) => {
            try {
                const files = [];
                this.storageRef.child(path).listAll()
                .then(result => {
                    result.items.forEach(fileRef => {
                        fileRef.getDownloadURL().then(fileURL => { files.push({url: fileURL, name: fileRef.name}) })
                    });
                });
                setTimeout(() => { (files.length > 0) ? resolve(files) : reject([]) }, 2000);
            } catch (err) {}
        });
    }

    getProfileImage = (path) => {
        if (this.signOut) return Promise.reject(ABORTED);
        return new Promise((resolve, reject) => {
            try {
                this.storageRef.child(path).listAll()
                .then(result => {
                    result.items.forEach(fileRef => {
                        if (fileRef.name.includes(INFOS.PROFILE)) {
                            (!this.signOut) && fileRef.getDownloadURL()
                            .then(url => resolve({url, name: fileRef.name}))
                            .catch(error => reject(error))
                        }
                    });
                })
                .catch(err => console.log(ACCESS_DENIED));
            } catch (err) {}
        });
    }

    getImageParams = (image) => {
        let extension = 'png';
        const sep = image.lastIndexOf('.');
        if (sep !== -1) {
            extension = image.substring(sep + 1);
            image = image.substring(0, sep);
        }
        return { image, extension };
    }

    getImage (imageToGet, callback, fallback) {
        try {
            const {image, extension} = this.getImageParams(imageToGet);
            this.storageRef.child(`${IMAGES}/${image}.${extension}`).getDownloadURL()
            .then((url) => callback(url))
            .catch((error) => console.log(`Error while loading image : ${error}`));
        } catch(err) {
            console.log(`Error while loading image : ${imageToGet}`)
            fallback(imageToGet);
        }
    }

    getImageAsync = (imageToGet, signal = null) => {
        if (signal?.aborted) return Promise.reject(ABORTED);

        return new Promise((resolve, reject) => {
            const {image, extension} = this.getImageParams(imageToGet);
            const abortHandler = () => reject(ABORTED);
            this.storageRef.child(`${IMAGES}/${image}.${extension}`).getDownloadURL()
            .then(url => resolve(url))
            .catch(error => reject(error))
            .finally(() => signal && signal.removeEventListener('abort', abortHandler));
            signal && signal.addEventListener("abort", abortHandler);
        });
    };

}

export default Firebase;