import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { ListItemText, Typography } from '@material-ui/core';

import { useStyles } from './styles';

const CategorySelector = (props) => {
    const classes = useStyles();
    const { categories, callbackCategorySelected } = props;
    const [category, setCategory] = React.useState(props.category || '');

    const handleChange = React.useCallback((event, reason) => {
        event.stopPropagation();
        if (reason === 'clickaway') return;
        setCategory(event.target.value);
        callbackCategorySelected && callbackCategorySelected(event.target.value);
    }, [callbackCategorySelected]);
    
    return (
        <div>
            {categories && categories.length && (<FormControl className={classes.formControl}>
                <Select
                    value={category}
                    id="trigram-selector"
                    onChange={handleChange}
                    labelId="trigram-selector-label"
                >
                    { categories.sort().map((candidate) => (
                        <MenuItem key={candidate} value={candidate}>
                            <ListItemText className={classes.listItemText}>
                                <Typography className={classes.typoText}>
                                    {candidate}
                                </Typography>
                            </ListItemText>
                        </MenuItem>
                    ))} 
                </Select>
            </FormControl>)}
        </div>
    );
}

export default CategorySelector;