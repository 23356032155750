
import React from 'react';
import { Typography } from '@material-ui/core';

import { useStyles } from './styles';

const Legal = () => {

  const classes = useStyles();

  return (
    <>
      <div className={classes.root} style={{marginTop: 30}}>
        <Typography variant="h4" gutterBottom>
          Legal notice
        </Typography>
        <Typography variant="h5" gutterBottom>
          English
        </Typography>

        <Typography variant="subtitle1" gutterBottom>
          Everything you need to know about CrowdSec legal notices can be found right below.<br/>
          If one of your questions is not covered here, kindly get in touch with the team via email, GitHub or Gitter, we will be happy to help.<br/>
          The website companion.crowdsec.net is operated by the company CrowdSec : CrowdSec – A simplified joint stock company (SAS), with a share capital of 419,912.18 euros, registered in Nanterre, under number 880 140 496, whose registered office is located at 20 rue Maurice Arnoux, 92120, Montrouge, France.<br/>
          Siret number: 880 140 496 00022. EU VAT number: FR 20 880140496<br/>
          Email address: info@crowdsec.net<br/>
          The director of the publication is Mr. Philippe Humeau, in his capacity as CEO of CrowdSec.<br/>
          He can be contacted at the above-mentioned contact details.<br/>
          Please be advised the Site is hosted by Google Firebase 1600 Amphitheatre Parkway, Mountain View, CA 94043, Etats-Unis.<br/>
          There is no cookie on the website.<br/>
          GDPR and French Data Protection Act: CrowdSec is the data controller for the processing of data collected on this site. To find out more about the purposes and methods of processing your personal data, the type of information collected or the technical security we offer to your data, we invite you to read our privacy policy.<br/>
          Intellectual property: This website and the domain name are the property of the CrowdSec company.<br/>
          The content of the website such as data, information, brands, illustrations, logos, videos, 3D animations is protected by copyright and other intellectual property rights.<br/>
          Any copy, translation, adaptation, distribution, representation or reproduction of all or part of the content of the site is prohibited.<br/>
        </Typography>

        <Typography variant="h5" gutterBottom>
          Français
        </Typography>

        <Typography variant="subtitle1" gutterBottom>
          Tout ce que vous devez savoir sur les mentions légales de CrowdSec se trouve ci-dessous.<br/>
          Si l’une de vos questions n’est pas couverte ici, veuillez contacter l’équipe par email, ou via GitHub et Gitter. Nous serons heureux de vous aider.<br/>
          Le site web companion.crowdsec.net est exploité par la société CrowdSec : CrowdSec – Société par actions simplifiée (SAS), au capital social de 419 912.18 euros, immatriculée au registre du commerce et des sociétés de Nanterre sous le numéro 880 140 496, dont le siège social est situé au 20 rue Maurice Arnoux, 92120, Montrouge, France.<br/>
          Numéro de Siret : 880 140 496 00022. Numéro de TVA intracommunautaire : FR 20880140496<br/>
          Adresse électronique : info@crowdsec.net<br/>
          Le directeur de la publication est Monsieur Philippe Humeau, en sa qualité de PDG de CrowdSec.<br/>
          Il peut être contacté aux coordonnées mentionnées ci-dessus<br/>
          Le site est hébergé par : 1600 Amphitheatre Parkway, Mountain View, CA 94043, Etats-Unis.<br/>
          Il n’y a pas de cookie sur le site.<br/>
          Concernant la RGPD et la loi française sur la protection des données, CrowdSec est le responsable du traitement des données collectées sur ce site.<br/>
          Pour en savoir plus sur les finalités et les modalités de traitement de vos données personnelles, le type d’informations collectées ou la sécurité technique que nous offrons à vos données, nous vous invitons à lire notre politique de confidentialité.<br/>
          Propriété intellectuelle : Ce site web et le nom de domaine sont la propriété de la société CrowdSec.<br/>
          Le contenu du site web tel que les données, informations, marques, illustrations, logos, vidéos, animations 3D est protégé par le droit d’auteur et d’autres droits de propriété intellectuelle.<br/>
          Toute copie, traduction, adaptation, diffusion, représentation ou reproduction de tout ou partie du contenu du site est interdite.<br/>
        </Typography>

      </div>
    </>
  );
};

export default Legal;