import { makeStyles } from '@material-ui/core/styles';
import * as COLORS from '../../../../../constants/colors';

export const useStyles = makeStyles( theme => ( {

    gridDaysOff: {
        width: '100%',
    },

    none : {
        color: COLORS.GRAY
    },

    taken: {
        color: COLORS.GRAY
    },

    available: {
        color: COLORS.DARK_GREEN
    },

    overtaken: {
        color: COLORS.DARK_RED
    },

    divider: {
        margin: theme.spacing(4),
    },

    listItemText: {
      fontSize:'0.9em',
    },

    yearDayOff: {
        border: '1px solid #CCC',
    },

    button: {
        margin: theme.spacing(1),
        padding: theme.spacing(3),
    },

    yearDayOffHeader: {
        border: '1px solid #CCC',
        backgroundColor: COLORS.VERY_LIGHT_GRAY
    },

    totalDaysOffPanel: {
        width: '98%',
        borderRadius: '5px',
        border: '1px solid #CCC',
        backgroundColor: '#FDFDFD',
        marginTop: -theme.spacing(4),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(2),
    },

    year: {
        [theme.breakpoints.up('md')]: { fontSize: '1.1vw' },
        [theme.breakpoints.down('sm')]: { fontSize: '1.1rem' },
    },

    warning: {
        color: COLORS.YELLOW,
        marginTop: theme.spacing(-0.5),
        [theme.breakpoints.up('md')]: { fontSize: '1.6vw' },
        [theme.breakpoints.down('sm')]: { fontSize: '1.3rem' },

    },

    remaining: {
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(1),
        [theme.breakpoints.up('md')]: { fontSize: '1.6vw' },
        [theme.breakpoints.down('sm')]: { fontSize: '1.3rem' },
    },

    circularProgress: props => ({
        maxWidth: 43,
        maxHeight: 43,
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
        color: props.value < 50 ? COLORS.YELLOW : props.value < 30 ? 'orange' : COLORS.RED,
    }),

    circularProgressGray: {
        marginTop: 0,
        maxWidth: 43,
        maxHeight: 43,
        color: '#DDD',
    },

    box: {
        left: 0,
        bottom: 0,
        display: "flex",
        position: "absolute",
        alignItems: "center",
        top: theme.spacing(1),
        right: theme.spacing(1),
        justifyContent: "center",
    },

})); 
