import { makeStyles } from '@material-ui/core/styles';
import { YELLOW, DARK_PURPLE } from '../../../../../../constants/colors';

export const chartOptions = {
    //legend: 'none',
    //bars: 'vertical',
    bar: { groupWidth: '50%' },
    legend: { position: 'none' },
    colors: [YELLOW, DARK_PURPLE],
    backgroundColor: { fill: 'transparent' },
    chartArea: { width: '90%', height: '84%' },
    vAxis: {
        minValue: 0,
        legend: { position: 'none' },
        format:'###,### €',
        textStyle: {
            fontSize: 10,
            fontName: 'Arial',
        },            
    },
    hAxis: {
        minValue: 0,
        format: 'short',
        legend: { position: 'none' },
        textStyle: {
            fontSize: 10,
            fontName: 'Arial',
        },
    },
    series: {
        0: { axis: 'forecast' }, // Bind series 0 to an axis named 'distance'.
        1: { axis: 'real' } // Bind series 1 to an axis named 'brightness'.
    },
    axes: {
        x: {
            forecast: {label: 'popolo'},
            real: {label: 'pipili'}
        }
    }   
};

export const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
        maxWidth: 120,
        margin: theme.spacing(1),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    gridItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'right',
    },
    noDataDiv: {
        height: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
}));