import React from 'react';
import List from '@material-ui/core/List';
import DescriptionIcon from '@material-ui/icons/Description';
import FileBox from '../../../utils/GenericComponents/fileBox';
import FloatingCard from '../../../utils/GenericComponents/floatingCard';

import { useStyles } from './styles';

const QuarterReport = (props) => {

    const { width, reports } = props;

    const classes = useStyles({length: reports.length});

    return(
        <FloatingCard title="Quaterly Reports" avatar={<DescriptionIcon />}>        
            <List className={classes.list} dense={true}>
                {reports.sort((a, b) => ( a.name < b.name ? 1 : -1 )).map((file, index) => (
                    <FileBox key={file.name + index} disabled={false} width={width} fileName={file.name} url={file.url}/>
                ))}
            </List>
        </FloatingCard>
    );
};

export default QuarterReport;