import { makeStyles } from '@material-ui/core/styles';
import { YELLOW, PURPLE } from '../../../../../constants/colors';

export const useStyles = makeStyles( theme => ( {
    
    circular: {
        color: PURPLE,
    },

    addIcon: {
        color: YELLOW,
    },
    
    amountFont: {
        [theme.breakpoints.up('md')]: { fontSize: '1.6vw' },
        [theme.breakpoints.down('sm')]: { fontSize: '1.1rem' },
    },

    disclaimerFont: {
        [theme.breakpoints.up('md')]: { fontSize: '0.8vw' },
        [theme.breakpoints.down('sm')]: { fontSize: '0.6rem' },
    },

    recapPanel: {
        flexGrow: 1,
        paddingTop: 10,
        minHeight: '14vh',
    },

    amountPanel: {
        height: '100%',
        padding: '2vh',
        border: '2px solid #CCC',
    },

}));
  