import { LIGHT_GRAY } from '../../../constants';
import { makeStyles } from '@material-ui/core/styles';
import { RED } from '../../../../../../constants/colors';

export const useRowStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
  
    rootOpen: {
      '& > *': {
        borderBottom: 'unset',
        backgroundColor: LIGHT_GRAY,
      },
    },

    historyRowTableBox: {
      marginTop: theme.spacing(-2),
      marginBottom: theme.spacing(1),
    },

    historyRowTable: {
      paddingTop: 0,
      paddingBottom: 0,
    },

    historyRow: {
        backgroundColor: LIGHT_GRAY,
    },
  
    large: {
        width: theme.spacing(5),
        height: theme.spacing(5),
        backgroundColor: RED,
    },      
    xtralarge: {
        width: theme.spacing(9),
        height: theme.spacing(9),
        backgroundColor: RED,
    },
    
    titleTextField: {
      fontSize: theme.spacing(1.75),
    }
}));
