import React from 'react';
import { Chart } from "react-google-charts";
import { Grid, Typography } from '@material-ui/core';

import { useStyles, chartOptions } from './styles';

const TAG_HEADER = ['Tag', 'Forecast', { calc: "stringify", sourceColumn: 1, type: "string", role: "annotation", format:'# €' }, 'Real', { calc: "stringify", sourceColumn: 3, type: "string", role: "annotation" }];
const CATEGORY_HEADER = ['Category', 'Forecast', { calc: "stringify", sourceColumn: 1, type: "string", role: "annotation" }, 'Real', { calc: "stringify", sourceColumn: 3, type: "string", role: "annotation" }];

const BarChart = props => {
    return (
        <Chart
            data={props.data}
            options={chartOptions}
            chartType="ColumnChart"
            //chartType="Bar"
            rootProps={{ 'data-testid': '1' }}
            loader={<div>Loading Chart...</div>}
        />
    )
}

const BarChartExpenses = (props) => {
    const classes = useStyles();
    const { chartData } = props;
    const [chartBarsData, setBarsData] = React.useState([CATEGORY_HEADER]);

    React.useEffect(() => {
        const chartCategories = [CATEGORY_HEADER];
        Object.keys(chartData).forEach(category => {
            if (chartData[category].forecast > 0 || chartData[category].real > 0) {
                const real = parseFloat(chartData[category].real);
                const forecast = parseFloat(chartData[category].forecast);
                chartCategories.push([category, forecast, forecast, real, real]);
            }
        });
        setBarsData(chartCategories);
    }, [chartData]);

    return (
        <div style={{ border: '1px solid gray' }}>
            <Grid container spacing={2} alignItems='center' justifyContent='center'>
                <Grid item xs={12}>
                    { chartBarsData.length > 1
                    ? <BarChart data={chartBarsData} />
                    : <div className={classes.noDataDiv}>
                        <Typography variant="h5" color="textSecondary" component="p" align="center">No data yet</Typography>
                      </div>
                    }
                </Grid>
            </Grid>
        </div>
    );
}

export default BarChartExpenses;