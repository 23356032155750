import { Grid } from '@material-ui/core';
import AddFilesPanel from './addFilesPanel';
import React, { useState, useEffect } from 'react';
import ReceiptIcon from '@material-ui/icons/Receipt';
import CircularProgress from '@material-ui/core/CircularProgress';
import FloatingCard from '../../../utils/GenericComponents/floatingCard';

import { useStyles } from './styles';

const EmployeeNDF = (props) => {
  const classes = useStyles();
  const firebase = props.firebase;
  
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    async function loadInvoicesFiles(employee) {
      try {
        const files = await firebase.getFilesASync(`ndf/${employee.trigram}`)
        if (!employee.accounting) {
          firebase.user(employee.uid).update({ accounting: { ndf : { nbr : files.length || 0 } } });
        } else {
          firebase.user(employee.uid).child("accounting").child("ndf").child("nbr").set(files.length || 0);
        }
        setFiles(files);
        setLoading(false);
      } catch(error) {
        setFiles([]);
        setLoading(false);
      }
    }
    setLoading(true);
    loadInvoicesFiles(props.employee);
  }, [props.employee, firebase]);
  
  let amount = 0;
  const deadline = new Date();
  deadline.setDate(26);
  (deadline < new Date()) && deadline.setMonth(deadline.getMonth() + 1);

  if (files.length !== 0)
      amount = props.employee?.accounting?.ndf?.[deadline.getFullYear()]?.amount || props.employee?.accounting?.ndf?.[deadline.getFullYear()] || 0;
  
  let subHeader = `You have ${props.employee?.accounting?.ndf?.nbr || 0} expense report${files.length > 1 ? 's' : ''} for a total of ${parseFloat(amount).toFixed(2)}€`;
  subHeader += ` and next collection is on ${deadline.toLocaleDateString()}`;

  return (
    <>
      <FloatingCard
        subheader={subHeader}
        title="Expense reports"
        avatar={<ReceiptIcon />}
        buttonAction={loading ? <CircularProgress size={24} className={classes.circular} /> : null}
      >
        <div className={classes.recapPanel}>
          <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
            <Grid item xs={12}>
              <AddFilesPanel
                files={files}
                firebase={firebase}
                employee={props.employee}
                saveUserInfos={props.saveUserInfos}
                currentYear={deadline.getFullYear()}
              />
            </Grid>
          </Grid>
        </div>
      </FloatingCard>
    </>
  );
}

export default EmployeeNDF;