import React from 'react';
import { Button, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import { useStyles } from '../styles';

const AddInGoogleCandarPanel = (props) => {

    const classes = useStyles();
    const { setAddInGoogleCalendar } = props;
    const [yes, setYes] = React.useState(false);

    const handleYes = (e) => {
        setYes(true);
        setAddInGoogleCalendar(true);
    }

    return(
        <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center">
            <Grid item align="center">
                <Typography>Would you like these days to be added to your Google calendar?</Typography>
            </Grid>
            <Grid item align="center">
                <Button
                    disabled={yes}
                    color="secondary"
                    onClick={handleYes}
                    className={classes.button}
                    variant={yes ? "contained" : "outlined"}
                >
                    {"Yes, please"}
                </Button>
            </Grid>
        </Grid>
    );
}

export default AddInGoogleCandarPanel;