import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardInvestTitle from './cardInvestTitle';
import Tooltip from '@material-ui/core/Tooltip';
import { withFirebase } from '../../../Firebase';
import CardInvestContent from './cardInvestContent';
import { getTotalVestedEsops } from '../../../utils';
import IconButton from '@material-ui/core/IconButton';
import CardHeader from '@material-ui/core/CardHeader';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { ESOP_TYPE } from '../../../../constants/infos';
import LinearProgress from '@material-ui/core/LinearProgress';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import PDFDialog from '../../../utils/GenericComponents/pdfDialog';
import StyledBadge from '../../../utils/GenericComponents/styledBadge';
import FloatingFilesMenu  from '../../../utils/GenericComponents/floatingFilesMenus';

import { useStyles } from './styles';

const CardInvest = props => {
  const sm = (props.width === 'md' ) || (props.width === 'sm' ) || (props.width === 'xs' );
  const classesCardInvest = useStyles({sm, type: props.type});
  return <CardInvestComponent {...props} classesCardInvest={classesCardInvest} sm={sm}/>
}

class CardInvestBase extends Component {

  constructor(props) {
    super(props);
    this.state = {
      files: [],
      trigram : "",
      currentUrl: '',
      anchorEl : null,
      currentFileName: '',
      openPDFViewer: false,
    };
    this.unMounted = false;
  }

  componentWillUnmount = () => this.unMounted = true;
  handleClose = (event) => this.setState({anchorEl : null});
  handleCloseDialog = () => this.setState({openPDFViewer : false});
  handleMenu = (event) => this.setState({anchorEl : event.currentTarget});

  componentDidMount() {
    this.setState({ trigram: this.props.partner?.trigram });
    this.loadFiles();
  }

  componentDidUpdate() {
    if (this.props.partner.trigram !== this.state.trigram) {
      this.setState({ trigram: this.props.partner.trigram, files: [] });
      this.loadFiles();
    }
  }

  loadFiles = () => {
    const round = this.props.round;
    const path = `rounds/${round}`;
    this.props.firebase.getFiles(path, this.setFiles);
    this.props.firebase.getFiles(`members/${this.props.partner.uid}/partner/${round}`, this.setFiles);
  }

  setFiles = (fileInfos) => {
    if (!this.unMounted) {
      this.state.files.push(fileInfos)
      this.state.files.sort( (a, b) => (a.name > b.name) ? 1 : -1);
      this.setState( {files: this.state.files} );
    }
  }
  
  handleMenuItemClick = (event, index) => {
    this.setState({
      anchorEl : null,
      openPDFViewer: true,
      currentUrl: this.state.files[index].url,
      currentFileName: this.state.files[index].name,
    });
  };

  render() {

    const { files, anchorEl, currentUrl, openPDFViewer, currentFileName } = this.state;
    const { sm, date, type, price, round, partner, ongoing, quantity, steeringObj, classesCardInvest } = this.props;

    let realDate = date;
    let operationValidated = false;
    if (date && date.split('/').length === 3) {
      realDate = new Date(date.split('/').reverse().join('-'));
      operationValidated = (realDate < new Date());
      realDate = realDate.toLocaleDateString();
    }
      
    const open = Boolean(anchorEl);
    const currentSharePrice = steeringObj.currentSharePrice;
    const percentVested = getTotalVestedEsops(partner)[round] * 100;

    return(
      <Grid item xs={12} sm={12} className={classesCardInvest.mainGrid}>
        <Card className={classesCardInvest.goldBar}> 
          <div className={classesCardInvest.background}>
            <div className={classesCardInvest.content}>

              <CardHeader
                action={
                  operationValidated ?
                  <IconButton aria-label="settings" onClick={this.handleMenu}>
                    <StyledBadge badgeContent={files.length} overlap="rectangular" top={-3} right={-3}>
                      <MoreVertIcon />
                    </StyledBadge>
                  </IconButton>
                  : <QueryBuilderIcon className={classesCardInvest.waitButton}/>
                }
                title={
                  <CardInvestTitle
                    type={type}
                    price={price}
                    round={round}
                    partner={partner}
                    ongoing={ongoing}
                    quantity={quantity}
                    realDate={realDate}
                    currentSharePrice={currentSharePrice}
                    classesCardInvest={classesCardInvest}
                    operationValidated={operationValidated}
                  />
                }
              />

              <CardInvestContent
                sm={sm}
                round={round}
                partner={partner}
                ongoing={ongoing}
                classesCardInvest={classesCardInvest}
              />
    
            </div>
          </div> 

          {type === ESOP_TYPE && (
            <Tooltip title={`${percentVested}% vested`} placement="top" arrow>
              <div style={{ minWidth: 100 }}>
                <LinearProgress variant="determinate" value={percentVested} />
              </div>
            </Tooltip>
          )}

        </Card>


        {operationValidated && (
          <FloatingFilesMenu
            open={open}
            files={files}
            anchorEl={anchorEl}
            handleCloseFileList={this.handleClose}
            handleMenuItemClick={this.handleMenuItemClick}
          />
        )}

        {(currentUrl !== '') &&
          <PDFDialog
            open={openPDFViewer}
            currentUrl={currentUrl}
            fileName={currentFileName}
            handleCloseDialog={this.handleCloseDialog}
          />
        }
      </Grid>
    );
  }
}

const CardInvestComponent = withFirebase(CardInvestBase);

export default CardInvest;