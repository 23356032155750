import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  
    grid : {
        border: '1px solid gray',
        margin: theme.spacing(0.09),
    },

    formControl: {
        minWidth: '100%',
        margin: theme.spacing(1),
    },

    container: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
    },

    currentSalary: {
        border: '1px solid gray',
        padding: theme.spacing(1),
        backgroundColor: '#fafafa'
    },

    newSalary: {
        border: '2px solid gray',
        padding: theme.spacing(1),
        backgroundColor: '#b6f5c9'
    },

})); 
