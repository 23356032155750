import React from 'react';
import RolesPanel from './rolesPanel';
import Grid from '@material-ui/core/Grid';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import DescriptionIcon from '@material-ui/icons/Description';
import EmployeeInteractiveFolderView from '../../../../utils/GenericComponents/employeeInteractiveFolderView';

const HistoryEmployeeCell = ({sm, children, align}) => {
    return(
      <TableCell align={align}>
        <Typography component={'span'} variant={sm ? "caption" : "body2"} >{children}</Typography>
      </TableCell>
    );
};

const HistoryEmployeeRow = (props) => {
  
  const { width, employee, firebase } = props;
  const sm = (width === 'sm') || (width === 'xs');

  return (
    <TableRow >
      <HistoryEmployeeCell sm={sm} align={'left'}>
        <Grid container spacing={2}>

          <Grid item xs={12} sm={12} md={3} style={{maxHeight: 550}}>
            <RolesPanel {...props} />
          </Grid>

          <Grid item xs={12} sm={12} md={9}>
            <EmployeeInteractiveFolderView
              id="files"
              option={""}
              height={400}
              message={``}
              width={width}
              subheader={""}
              employee={employee}
              firebase={firebase}
              icon={<DescriptionIcon />}
              title={"Employee's documents"}
            />
          </Grid>
        </Grid>
      </HistoryEmployeeCell>
    </TableRow>
  );
};
  
export default HistoryEmployeeRow;