import Switch from '@material-ui/core/Switch';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { RED, LIGHT_GRAY, YELLOW } from '../../../../../../../constants/colors';

export const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(1.25),
  },

  pressKitGrid: props => ({
    marginTop: theme.spacing(0.25),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    maxWidth: '97%',
    backgroundColor: props.currentNews.hidden ? LIGHT_GRAY : null
  }),

  uploadButton: {
    width: theme.spacing(3),
    height: theme.spacing(3.1),
    marginTop: -2,
    marginBottom: 0,
  },

  formControlLabel: {
    margin: 0,
  },

  backUpIcon: props => ({
    color: YELLOW,
    fontSize: props.sm ? 12 : 20,
  }),

  urlTextField: {
    fontSize: theme.spacing(1.5),
  }
  
}));

export const AntSwitch = withStyles((theme) => ({
    root: {
        marginTop: -1,
        width: 28,
        height: 14,
        padding: 2,
        display: 'flex',
    },
    switchBase: {
        padding: 2,
        color: theme.palette.common.white,
        '&$checked': {
            transform: 'translateX(12px)',
            color: theme.palette.common.white,
        '& + $track': {
            opacity: 1,
            backgroundColor: RED,
            borderColor: RED,
        },
      },
    },
    thumb: {
        width: 12,
        height: 12,
        boxShadow: 'none',
    },
    track: {
        opacity: 1,
        borderRadius: 16 / 2,
        backgroundColor: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
    },
    checked: {},
  }))(Switch);
