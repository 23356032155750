import axios from 'axios';
import React from 'react';
import fileDownload from 'js-file-download';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { DialogContent, DialogActions } from './styles';

const ImageDialog = (props) => {

  const {open, fileName, currentUrl, handleCloseDialog} = props;

  const openInTab = () => window.open(currentUrl);

  const handleDownload = () => {
    axios.get(currentUrl, { responseType: 'blob' })
    .then(res => fileDownload(res.data, fileName));
  }

  return (
    <Dialog onClose={handleCloseDialog} aria-labelledby="image-dialog" open={open}>

      <DialogContent dividers>
        <img src={currentUrl} alt="receipt" width="100%" height="100%"/>
      </DialogContent>

      <DialogActions>
        <Button autoFocus onClick={handleDownload} color="primary">
          Download
        </Button>
        <Button autoFocus onClick={handleCloseDialog} color="primary">
          Close
        </Button>
        <Button onClick={openInTab} color="primary">
          Open in tab
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ImageDialog;
