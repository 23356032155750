import { makeStyles } from '@material-ui/core/styles';
import * as COLORS from '../../../../../constants/colors';

export const useStyles = makeStyles({

    pnl: props => ({
        color: (props.pnl > 0) ? COLORS.DARK_GREEN : (props.pnl < 0) ? COLORS.DARK_RED : COLORS.GRAY,
        fontSize:'0.9em',
    }),

    list : {
        padding: 0,
        width: '100%',
        height: '100%',
        borderRadius: 5,
    },

    listItemHeader : {
        height: '50%',
        paddingTop: 0,
        borderRadius: 2,
        paddingBottom: 0,
        backgroundColor: COLORS.DARK_PURPLE,
    },

    listItemContent : {
        height: '70%',
        borderRadius: 2,
        paddingBottom: 0,
        backgroundColor: COLORS.VERY_LIGHT_PURPLE3,
    },

    listItemText: {
        color: 'white',
        fontWeight: 800,
        fontSize:'0.8em',
    }
});
