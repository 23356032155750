import * as utils from '../utils/index';
import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import { AuthUserContext } from '../Session';
import Backdrop from '@material-ui/core/Backdrop';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import { STEERING } from '../../constants/realtimeDB';
import CardContent from '@material-ui/core/CardContent';
import BorderLinearProgress from './borderLinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useStyles } from './styles';

const InvestIntentProgress = props => {
  
  const classes = useStyles( { height: props.height } );

  return(
    <AuthUserContext.Consumer>
      { authUser => <InvestIntentProgressBase {...props} classes={classes} /> }
    </AuthUserContext.Consumer>
  );
}

const getMessage = (message, info) => <span>{message} <i>{info}</i></span>
class InvestIntentProgressBase extends Component {

  constructor(props) {
    super(props);
    this.state = {
      users: [],
      loading: false,
      targetValue: 0,
      round: props.round,
    };
    this.classes = props.classes;
  }
  
  componentDidMount() {
    this.setState({ loading: true });
  
    this.props.firebase.users().on('value', snapshot => {
      const usersObject = snapshot.val();
      const usersList = Object.keys(usersObject).map(key => ({
        ...usersObject[key],
        uid: key,
      }));

      this.setState({
        users: usersList,
      });
    });

    this.props.firebase.db.ref(`${STEERING}/SHARES/${this.state.round}`).on('value', snapshot => {
      const value = snapshot.val();
      this.setState({
        loading: false,
        targetValue: (value !== null) ? value.target : 0,
      });
    });

  }

  componentWillUnmount() {
    this.props.firebase.users().off();
    this.props.firebase.db.ref(`${STEERING}/SHARES/${this.state.round}`).off();
  }
  
  render() {
  
    const { users, loading, targetValue, round } = this.state;
  
    const partners = users.filter(user => ('partnerInfo' in user) );
    const totalInvestment = utils.getInvestIntentionsForRound(partners, round) + utils.getCompletedInvestmentsForRound(partners, round);
    const completed = (targetValue > 0) ? (totalInvestment > targetValue) ? 100 : totalInvestment * 100 / targetValue : 0;

    const message = getMessage(`Total invest. intentions ${totalInvestment.toLocaleString()} €`, `(${Math.round((totalInvestment * 100 / targetValue),)}%)`);

    return(
      <div>

        <Backdrop className={this.classes.backdrop} open={loading} >
          <CircularProgress color="inherit" />
        </Backdrop> 
          
        {this.props.border && (
          <Card className={this.classes.card}>
            <CardHeader subheader={message}/>
            <CardContent>
              <BorderLinearProgress variant="determinate" value={completed} target={targetValue}/>
            </CardContent>
          </Card>
        )}

        {!this.props.border && (
          <div>
            <Typography variant="body2" color="textSecondary">{message}</Typography>
            <div style={{paddingTop: 10}}></div>
            <BorderLinearProgress variant="determinate" value={completed} target={targetValue}/>
          </div>
        )}

      </div>
    );
  }
}
  
export default InvestIntentProgress;