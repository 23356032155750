import { card } from '../../utils';
import { PURPLE } from '../../../constants/colors';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({

  signin: {
    padding: '15px',
  },

  signinGrid: {
    paddingLeft: 5,
    paddingRight: 5,
  },

  form_root: {
    '& > *': {
      width: '100%',
    },
  },

  logoPanel: {
    padding: theme.spacing(2),
    backgroundColor: 'inherit',
  },

  root: {
    top: '50%',
    left: '50%',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
  },

  card: {
    ...card(),
    height: '100%',
    backgroundColor: '#FCFCFC',
    borderTop: `5px solid ${PURPLE}`,
    "&:hover": {
      backgroundColor: '#FFF',
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: 300,
      maxWidth: 400,
    },
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
      maxWidth: '30vw',
    },  
  },
  
}));
  