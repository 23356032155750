import React from 'react';
import Button from '@material-ui/core/Button';
import { Grid, TextField } from '@material-ui/core';

const CredentialsForm = (props) => {

    const { classes } = props;
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
  
    const isInvalid = password === '' || email === '';
  
    const onEmailChange = (event) => setEmail(event.target.value);
    const onPasswordChange = (event) => setPassword(event.target.value);
  
    const onSubmit = event => {
      props.onSubmit(email, password);
      event.preventDefault();
    };
  
    return (
      <form className={ classes.form_root } onSubmit={ onSubmit } >
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <TextField
              align="left"
              name="email"
              value={email}
              id="signin-email"
              variant="outlined" 
              label="Email Address"
              onChange={onEmailChange}
              style={{maxWidth: 400, minWidth: 250}}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              align="left"
              name="password"
              type="password"
              value={password}
              label="Password"
              variant="outlined"
              id="signin-password"
              onChange={onPasswordChange}
              style={{maxWidth: 400, minWidth: 250}}
            />
          </Grid>
          <Grid item xs={12}>
            <Button disabled={isInvalid} type="submit" >
              Sign In
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }

  export default CredentialsForm;
  