import React from "react";

export const useIntersectionObserver = (props) => {
  const { target, onIntersect, threshold = 0.3, rootMargin = "0px" } = props;

  React.useEffect(() => {
    let observer = null;
    const current = target.current;
    if (current) {
      observer = new IntersectionObserver(onIntersect, { rootMargin, threshold });
      observer.observe(current);
    }
    return () => {
      if (observer && current) {
        observer.unobserve(current);
      }
    };
  }, [target, onIntersect, threshold, rootMargin]);
};
