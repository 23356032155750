import Logo from '../../Logo';
import NavigationAuthTabs from './tabs';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { Hidden } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import SessionTimeout from './sessionTimeout';
import Toolbar from '@material-ui/core/Toolbar';
import * as ROUTES from '../../../constants/routes';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import NotificationDialog from '../notificationDialog';
import NavigationAuthSectionMobile from './sectionMobile';
import { PURPLE, YELLOW } from '../../../constants/colors';
import NavigationAuthSectionDesktop from './sectionDesktop';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { APP, PROPERTIES, NOTIFICATIONS } from '../../../constants/realtimeDB';

const useStyles = makeStyles((theme) => ({
    button: {
        color: YELLOW,
        margin: theme.spacing(1),
        border: `2px solid ${YELLOW}`,
        "&:hover": {
            color: PURPLE,
            backgroundColor: YELLOW,
        },
    },
}));

const NewVersionPanel = (props) => {

    const classes = useStyles();
    const { loading, refreshPage, currentVersion, parentClasses, userCurrentVersion } = props;

    return(
        <div className={parentClasses.update} hidden={loading || !(currentVersion !== userCurrentVersion)}>
            <div style={{backgroundColor: PURPLE}} align='center'>
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                    <Grid item>
                        <Typography variant='overline' style={{color: YELLOW}} >
                            <b>{`A new version (${currentVersion}) is available =>`}</b>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="outlined"
                            onClick={refreshPage}
                            className={classes.button}
                            endIcon={<CloudDownloadIcon />}
                        >
                            <b>Update</b>
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};
class NavigationAuthBase extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loadCounter: 0,
            notifications: [],
            currentVersion: 'none',
            openNotifDialog: false,
            userCurrentVersion: 'none',
        };
        this.unMounted = false;
        this.classes = props.classes;
        this.firebase = props.firebase;
        this.authUser = props.authUser;
    }

    handleCloseDialog = (value) => this.setState({ openNotifDialog: false });

    markAsRead = (event, key, value) => {
        this.firebase.getUserValue(this.authUser.uid, `${NOTIFICATIONS}/${key}`).update({ read: !value });
    }

    handleNotifClick = (event, choice) => {
        (choice === 1) && this.setState({ openNotifDialog: true });
    }

    doSignOut = () => {
        this.componentWillUnmount();
        this.firebase.doSignOut(this.authUser);
    }
  
    refreshPage = () => {
        this.firebase.getUserValue(this.authUser.uid, APP).update({ version : this.state.currentVersion });
        window.location.reload(false); 
    }

    componentDidMount() {
        this.setState({ loading: true, loadCounter: 3 });  // Set loading to true and initialize loadCounter to 3 because we have 3 Firebase calls
    
        this.firebase.getUserValue(this.authUser.uid, APP).on('value', snapshot => {
            const childData = snapshot.val();
            (childData) && this.setState({ userCurrentVersion : childData.version, currentVersion : childData.version });
            this.setState(state => {
                const loadCounter = state.loadCounter - 1;
                return { loadCounter, loading: loadCounter > 0 };
            });
        });
    
        this.firebase.getUserValue(this.authUser.uid, NOTIFICATIONS).on('value', snapshot => {
            const notifications = snapshot.val();
            if (notifications) {
                const notificationsList = Object.keys(notifications).map(notif => ({ key: notif, ...notifications[notif] }));
                notificationsList.sort((a, b) => (a.key < b.key) ? 1 : -1);
                this.setState({ notifications: notificationsList });
                this.setState(state => {
                    const loadCounter = state.loadCounter - 1;
                    return { loadCounter, loading: loadCounter > 0 };
                });
            }
        });
    
        this.firebase.db.ref(`${APP}/${PROPERTIES}`).on('value', snapshot => {
            setTimeout(() => { this.setState({ currentVersion : snapshot.val().version }) }, 5000);
            this.setState(state => {
                const loadCounter = state.loadCounter - 1;
                return { loadCounter, loading: loadCounter > 0 };
            });
        });
    }
    
    componentWillUnmount() {
        this.unMounted = true;
        this.firebase.db.ref(`${APP}/${PROPERTIES}`).off();
        this.firebase.getUserValue(this.authUser.uid).off();
        this.firebase.getUserValue(this.authUser.uid, APP).off();
        this.firebase.getUserValue(this.authUser.uid, NOTIFICATIONS).off();
    }

    render() {

        const { loading, openNotifDialog, notifications, currentVersion, userCurrentVersion } = this.state;

        return (
            <>
                <AppBar position="static" className={this.classes.appBar}>
                    <Toolbar>

                        <Hidden smDown>
                            <IconButton
                                edge="start"
                                color="inherit"
                                component={Link}
                                to={ROUTES.LANDING}
                                aria-label="nav-to-landing-page"
                                className={this.classes.menuButton}
                            >
                                <Logo height={35}/>
                            </IconButton>

                            <NavigationAuthTabs authUser={this.authUser} firebase={this.firebase} />
                        </Hidden>

                        <NavigationAuthSectionDesktop
                            authUser={this.authUser}
                            firebase={this.firebase}
                            doSignOut={this.doSignOut}
                            notifications={notifications}
                            handleNotifClick={this.handleNotifClick}
                        />
        
                        <NavigationAuthSectionMobile
                            authUser={this.authUser}
                            doSignOut={this.doSignOut}
                            notifications={notifications}
                            handleNotifClick={this.handleNotifClick}
                        />
        
                    </Toolbar>
                </AppBar>
    
                <NotificationDialog
                    openDialog={openNotifDialog}
                    markAsRead={this.markAsRead}
                    notifications={notifications}
                    handleCloseDialog={this.handleCloseDialog}
                />

                <NewVersionPanel
                    loading={loading}
                    parentClasses={this.classes}
                    refreshPage={this.refreshPage}
                    currentVersion={currentVersion}
                    userCurrentVersion={userCurrentVersion}
                />

                {this.authUser && (!this.firebase.isAdmin(this.authUser)) && this.unMounted === false &&  <SessionTimeout doSignOut={this.doSignOut} firebase={this.firebase}/>}

            </>
        );
    }
};

export default NavigationAuthBase;

