export const TYPOLOGY = {
    TAGS: {value: 'TAGS', label: 'Tags'},
    CATEGORIES: {value: 'CATEGORIES', label: 'Categories'},
};

export const PERIODS = {
    Q1: {value: 'Q1', label: 'Q1'},
    Q2: {value: 'Q2', label: 'Q2'},
    Q3: {value: 'Q3', label: 'Q3'},
    Q4: {value: 'Q4', label: 'Q4'},
    YEAR: {value: 'YEAR', label: 'Whole Year'},
};


