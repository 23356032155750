import { lighten, makeStyles } from '@material-ui/core/styles';
import { ORANGE, RED, GREEN, GRAY } from '../../../../../constants/colors';

export const useStyles = makeStyles((theme) => ({

    root: {
        width: '100%',
    },

    unpaid: {
        paddingTop: theme.spacing(1),
        color: RED,
    },

    iconReason: {
        paddingTop: theme.spacing(1),
        color: GRAY,
    },

    noDaysOff: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        textAlign: 'center',
    },
    
    paper: {
        width: '100%',
        marginBottom: theme.spacing(1),
    },

    table: {
        minWidth: 750,
        width: '100%',
    },

    pending: {
        color: ORANGE,
    },

    refused: {
        color: RED,
    },

    validated: {
        color: GREEN,
    },

    visuallyHidden: {
        top: 20,
        width: 1,
        border: 0,
        height: 1,
        margin: -1,
        padding: 0,
        overflow: 'hidden',
        position: 'absolute',
        clip: 'rect(0 0 0 0)',
    },

}));

export const useToolbarStyles = makeStyles((theme) => ({

    title: {
        flex: '1 1 10%',
    },

    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },

    checkIcon: {
        color: GREEN,
    },

    refuseIcon: {
        color: RED,
    },

    highlight: 
        theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
        },
    
}));