import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import AddIcon from '@material-ui/icons/Add';
import Select from '@material-ui/core/Select';
import EditIcon from '@material-ui/icons/Edit';
import RadarChart from 'react-svg-radar-chart';
import LockIcon from '@material-ui/icons/Lock';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import 'react-svg-radar-chart/build/css/index.css';
import Typography from "@material-ui/core/Typography";
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import { dateToPrettyString } from "../../../../utils";
import FormControl from '@material-ui/core/FormControl';
import { YELLOW } from '../../../../../constants/colors';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import EditablePerformanceChartDialog from "./editablePerfChart";
import { COLORS, captions, options } from './performancesDetails';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FloatingCard from '../../../../utils/GenericComponents/floatingCard';

const OVERALL = 'Overall';

const PerformanceChart = (props) => {
  return <PerformanceChartComponent {...props} />
}

const NorthPanel = (props) => {

  const { years, teamChecked, currentYear, handleChangeYear, handleShowTeamPerformance } = props;

  return (
    <>
      {currentYear !== OVERALL && (<FormControlLabel
        label="Global Team Performance"
        control={<Checkbox color="primary" name="teamChecked" checked={teamChecked} onChange={handleShowTeamPerformance}/>}
      />)}
      <FormControl style={{ fontSize: 12, marginRight: 13, marginLeft: 13 }}>
        <InputLabel id="performance-choose-year-label">Years</InputLabel>
          <Select
            value={currentYear}
            onChange={handleChangeYear}
            id="performance-choose-year"
            labelId="performance-choose-year-label"
            style={{ fontSize: 12, paddingLeft: 2, paddingRight: 2 }}
          >
            {years.map(key => <MenuItem key={key} value={key}>{key}</MenuItem>)}
        </Select>
      </FormControl>
    </>
  );
}

const ExistingPreviousEvaluation = (props) => {
  const { isManager, evaluator, evaluationDate, editEvaluation } = props;

  return (
    <Grid container spacing={4} direction="row" justifyContent="center" alignItems="stretch" style={{backgroundColor: '#EDEDED'}}>
      <Grid item>
        <Typography variant="caption" color="textSecondary" gutterBottom>
          <b>Evaluator:</b> {evaluator}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="caption" color="textSecondary" gutterBottom>
          <b>Last update:</b> {dateToPrettyString(new Date(evaluationDate))}
        </Typography>
      </Grid>
      <Grid item>
        <Tooltip title={ isManager ? 'Edit evaluation' : 'You are not allowed to edit this evaluation'} arrow>
          <span>
            <IconButton
              disabled={!isManager}
              style={{ marginTop: -6}}
              onClick={editEvaluation}
              aria-label="edit-evaluation"
            >
              {isManager ? <EditIcon style={{fontSize: 18, color: YELLOW }} /> : <LockIcon style={{fontSize: 18}} />}
            </IconButton>
          </span>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

class PerformanceChartComponent extends Component
{
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      evaluator: '',
      edition: false,
      years: [OVERALL],
      teamChecked: false,
      openSnackbar: false,
      snackbarMessage: '',
      evaluationDate: null,
      currentYear: OVERALL,
      isManager: props.isManager,
      employees: props.employees,
      employee: { ...props.employee },
      editablePerfChartDialogOpen: false,
    };
    this.firebase = props.firebase;
    this.authUser = props.authUser;
  }

  updateEmployeeYears = () => {
    this.setState({ data: [] });
    const evaluator = this.authUser?.civil?.firstName || '';
    const years = Object.keys(this.state.employee?.contract?.performance || {});
    this.setState({ data: this.getAllData(years), currentYear: OVERALL, years: [OVERALL, ...years], evaluationDate: null, evaluator });
  }

  componentDidUpdate() {
    if (this.state.employee.uid !== this.props.employee.uid || JSON.stringify(this.props.employee) !== JSON.stringify(this.state.employee)) {
      this.setState({ employee: { ...this.props.employee }, teamChecked: false, isManager: this.props.isManager });
      setTimeout(() => { this.updateEmployeeYears() }, 1000);
    }
  }

  componentDidMount = () => this.updateEmployeeYears();
  handleCloseSnack = () => this.setState({ openSnackbar: false });
  editEvaluation = () => this.setState({ edition: true, editablePerfChartDialogOpen: true });
  createNewEvaluation = () => this.setState({ edition: false, editablePerfChartDialogOpen: true });
  handleEditablePerfChartDialogClose = () => this.setState({ editablePerfChartDialogOpen: false });
  getAllData = (years) => years.filter(year => year !== OVERALL).map((year, index) => this.getDataFromYear(year, index));

  getDataFromYear = (year, index = 0) => {
    const { employee } = this.state;
    return {
      meta: { color: COLORS[index] },
      data: { ...employee.contract.performance[year].data },
    };
  }

 handleChangeYear = (event) => {
    const currentYear = event.target.value;
    const { years, employee } = this.state;
    if (currentYear === OVERALL) {
      this.setState({ data: this.getAllData(years), currentYear, evaluationDate: null, evaluator: '', teamChecked: false });
    } else {
      const data = [this.getDataFromYear(currentYear)];
      const evaluator = employee.contract.performance[currentYear].evaluator;
      const evaluationDate = employee.contract.performance[currentYear].date;
      this.setState({ data, currentYear, evaluator, evaluationDate });
    }
  };

  handleSavePerfChart = (year, data, evaluator) => {
    const payload = {
      evaluator,
      data: {...data},
      date: new Date().toISOString(),
    };
    this.firebase.db.ref(`/users/${this.state.employee.uid}/contract/performance/${year}`).set(payload)
    .then(() => {
      const snackbarMessage = `Performance chart for ${year} has been saved`;
      this.setState({ editablePerfChartDialogOpen: false, openSnackbar: true, snackbarMessage });
    })
    .catch(error => {
      const snackbarMessage = `Error saving performance chart for ${year}`;
      this.setState({ editablePerfChartDialogOpen: false, openSnackbar: true, snackbarMessage });
    });
  }

  handleShowTeamPerformance = () => {
    const data = [];
    const averageData = {};
    const { teamChecked, employees, currentYear } = this.state;
    data.push(this.getDataFromYear(currentYear));
    if (!teamChecked) {
      const employeesWithEnd = employees.filter(employee => employee.contract.end.length === 0);
      const employeesWithEndAndPerformance = employeesWithEnd.filter(employee => employee.contract.performance);
      const employeesWithEndAndPerformanceAndCurrentYear = employeesWithEndAndPerformance.filter(employee => employee.contract.performance[currentYear]);
      const employeesWithEndAndPerformanceAndCurrentYearAndData = employeesWithEndAndPerformanceAndCurrentYear.filter(employee => employee.contract.performance[currentYear].data);
      employeesWithEndAndPerformanceAndCurrentYearAndData.forEach(employee => {
        const data = { ...employee.contract.performance[currentYear].data };
        Object.keys(data).forEach(key => (averageData[key]) ? averageData[key] += data[key] : averageData[key] = data[key]);
      });
      // now calculate the average
      Object.keys(averageData).forEach(key => { averageData[key] = (averageData?.[key] || 0) / (employeesWithEndAndPerformanceAndCurrentYearAndData?.length || 1) });
      data.push({ data: averageData, meta: { color: COLORS[4] } });
    }
    this.setState({ data });
    this.setState({ teamChecked: !teamChecked });
  }

  render() {
    
    const { data, years, isManager, edition, currentYear, evaluator, openSnackbar, evaluationDate, snackbarMessage } = this.state;

    const chartSize = () => {
      switch(this.props.width) {
        case 'xs': return 300;
        case 'sm': return 400;
        case 'md': return 450;
        case 'lg': return 500;
        case 'xl': return 800;
        default:   return 500;
      }
    }

    return(
      <>
        <FloatingCard
          title="360° Performance"
          avatar={<DonutLargeIcon />}
          subheader={`Breakdown of 360° performance`}
          action={
            <Tooltip arrow title={ isManager ? 'Add a new evaluation' : 'Locked' }>
              <span>
                <IconButton
                  disabled={!isManager}
                  aria-label="add-evaluation"
                  onClick={this.createNewEvaluation}
                >
                  {isManager ? <AddIcon style={{color: YELLOW }} /> : <LockIcon />}
                </IconButton>
              </span>
            </Tooltip>
          }
          buttonAction={<NorthPanel years={years} currentYear={currentYear} handleChangeYear={this.handleChangeYear} teamChecked={this.state.teamChecked} handleShowTeamPerformance={this.handleShowTeamPerformance} />}
        >
          <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" >
            <Grid item xs={12} align="center">
              <RadarChart data={data} size={chartSize()} options={options} captions={captions} />
            </Grid>
            {evaluationDate && evaluator && (
            <Grid item xs={12} align="center">
              <ExistingPreviousEvaluation
                isManager={isManager}
                evaluator={evaluator}
                evaluationDate={evaluationDate}
                editEvaluation={this.editEvaluation}
              />
            </Grid>
            )}
          </Grid>
        </FloatingCard>
        
        <EditablePerformanceChartDialog
          edition={edition}
          width={chartSize()}
          evaluator={evaluator}
          data={data?.[0]?.data || {}}
          employee={this.state.employee}
          handleSave={this.handleSavePerfChart}
          open={this.state.editablePerfChartDialogOpen}
          handleClose={this.handleEditablePerfChartDialogClose}
          year={edition ? currentYear : new Date().getFullYear()}
        />

        <Snackbar
          open={openSnackbar}
          autoHideDuration={3000}
          message={snackbarMessage}
          onClose={this.handleCloseSnack}
          anchorOrigin={ {vertical: 'top', horizontal: 'center' }}
          action={
            <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleCloseSnack}>
                <CloseIcon fontSize="small" />
            </IconButton>
          }
        />   

      </>
    );
  }
}

export default PerformanceChart;