import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import InvestIntentProgress from '../../../InvestIntentProgress';

const RoundSummaryPanel = (props) => {

    const {round, firebase} = props;

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant="h5">
                    {round.round} Round
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <div style={{margin: 10}}>
                    <InvestIntentProgress firebase={firebase} round={round.round} height={120} border={false}/>
                </div>
            </Grid>
        </Grid>
    );

};

export default RoundSummaryPanel;
