import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

const ConfirmDeleteAlertDialog = (props) => {
    const { open, title, message, onCancelDeleteCallback, onConfirmDeleteCallback } = props;

  return (
    <Dialog
        open={open}
        onClose={onCancelDeleteCallback}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {message}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={onCancelDeleteCallback} color="primary">
                Cancel
            </Button>
            <Button onClick={onConfirmDeleteCallback} color="primary" autoFocus>
                Delete
            </Button>
        </DialogActions>
    </Dialog>
  );
}

export default ConfirmDeleteAlertDialog;