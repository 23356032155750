import React from 'react';
import DaysOffTable from './daysOffTable';
import { VALIDATED } from '../holidays_constants';

const DashboardPendingHolidays = (props) => {

    const { width, authUser, firebase, employees, saveUserInfos, daysOffConfirmEmailUrl } = props;

    const employeesWithPendingHoidays = employees.filter(employee => {
        return Object.values(employee.contract.daysOff?.holidays || {}).reduce((value, holiday) => value || (holiday.status !== VALIDATED), false);
    });

    const listOfDaysOff = [];
    employeesWithPendingHoidays.forEach(employee => {
        Object.keys(employee.contract.daysOff.holidays).forEach(holidayKey => {
            const status = employee.contract.daysOff.holidays[holidayKey].status;
            (status !== VALIDATED) && listOfDaysOff.push({ key: holidayKey, employee: {...employee} });
        });
    });

    return (
        <DaysOffTable 
            width={width}
            authUser={authUser}
            firebase={firebase}
            saveUserInfos={saveUserInfos}
            listOfDaysOff={listOfDaysOff}
            daysOffConfirmEmailUrl={daysOffConfirmEmailUrl}
        />
    );
}

export default DashboardPendingHolidays;
