import React from 'react';
import { Card, Avatar } from '@material-ui/core';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import { useStyles } from './styles';

const FloatingCard = (props) => {

    const { title, avatar, action, noflex, avatarBg, avatarSize, subheader, buttonAction } = props;

    const classes = useStyles({noflex, avatarSize, avatarBg});

    return (
        <Card className={classes.card}>
            {title?.length > 0 && (
            <CardHeader
                title={title}
                className={classes.cardHeader}
                classes={{ title: classes.cardTitle }}
                action={buttonAction ? buttonAction : null}
                subheader={<span className={classes.subheader}><i>{subheader}</i></span>}
                avatar={<Avatar aria-label="recipe" className={classes.avatar}>{avatar}</Avatar>}
            />)}
            <CardContent className={classes.cardContent}>
                {props.children ? props.children : <div>{title}</div>}
            </CardContent>
            {/* <div className={classes.spacer} style={{border: '1px solid black'}}/> */}
            {action && (
                <CardActions className={classes.cardActions}>
                    <div>{action}</div>
                </CardActions>
            )}
        </Card>
    );
}

export default FloatingCard;
