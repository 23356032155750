import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { VERY_LIGHT_PURPLE4, PURPLE, YELLOW } from '../../../../../../../constants/colors';

export const DialogContent = withStyles((theme) => ({
    root: {
      margin: 0,
      width: '100%',
      padding: theme.spacing(0),
    },
}))(MuiDialogContent);
  
export const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(0),
    },
}))(MuiDialogActions);

const iOSBoxShadow =
  '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

export const marks = [
    { value: 0 },
    { value: 0.25 },
    { value: 0.5 },
    { value: 0.75 },
    { value: 1 },
];

export const IOSSlider = withStyles({
    root: {
        height: 2,
        color: PURPLE,
        padding: '15px 0',
    },
    thumb: {
        width: 18,
        height: 18,
        marginTop: -9,
        marginLeft: -9,
        backgroundColor: '#fff',
        boxShadow: iOSBoxShadow,
        '&:focus, &:hover, &$active': {
            boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
            width: 25,
            height: 25,
            marginTop: -12,
            marginLeft: -12,
            backgroundColor: YELLOW,
            '@media (hover: none)': {
                boxShadow: iOSBoxShadow,
            },
        },
    },
    active: {},
    valueLabel: {
        top: -22,
        left: 'calc(-50% + 12px)',
        '& *': {
            color: '#000',
            background: 'transparent',
        },
    },
    track: {
        height: 2,
    },
    rail: {
        height: 2,
        opacity: 0.5,
        backgroundColor: '#bfbfbf',
    },
    mark: {
        width: 1,
        height: 8,
        marginTop: -3,
        backgroundColor: '#bfbfbf',
    },
    markActive: {
        opacity: 1,
        backgroundColor: 'currentColor',
    },
})(Slider);

export const useStyles = makeStyles((theme) => ({
    dialog: {
        padding: theme.spacing(2),
    },

    dialogContent: {
        backgroundColor: VERY_LIGHT_PURPLE4,
    },

    dialogTitle: {
        height: theme.spacing(7),
    },

    closeButton: {
        position: 'absolute',
        top: theme.spacing(0.2),
        right: theme.spacing(1),
        color: theme.palette.grey[500],
    },

    sliderPopup: {
        fontSize: 11,
        minWidth: 100,
        textAlign :'center',
        background: "#f0f0f0",
        padding: theme.spacing(1),
        border: '1px solid #f0f0f0',
        borderRadius: theme.spacing(1),
    },

}));
  