export const URL = 'url';
export const DATE = 'date';
export const TYPE = 'type';
export const LANG = 'lang';
export const TITLE = 'title';
export const VIDEO = 'video';
export const IMAGE = 'image';
export const RATIO = 'ratio';
export const MEDIA = 'media';
export const AMOUNT = 'amount';
export const HIDDEN = 'hidden';
export const FEEDBACK = 'feedback';
export const LIGHT_GRAY = '#f6f6f6';
export const TIMESTAMP = 'timestamp';
export const LANG_SELECTION = 'lang_selection';
export const MEDIA_SELECTION = 'media_selection';
export const FILENAME_CHANGED = 'fileName_changed';

export const MEDIA_TYPES = {
    ADS: 'ads',
    POST: 'post',
    TWEET: 'tweet',
    ARTICLE: 'article',
    PODCAST: 'podcast',
    CONFERENCE: 'conference',
    PRESS_RELEASE: 'press_release'
};

export const LANGUAGES = {
    "en": "English",
    "fr": "French",
    "es": "Spanish",
    "ar": "Arabic",
    "cmn": "Mandarin",
    "ru": "Russian",
    "pt": "Portuguese",
    "de": "German",
    "ja": "Japanese",
    "cz": "Czech",
    "gr": "Greek",
    "hi": "Hindi",
    "ms": "Malay",
    "fa": "Persian",
    "sw": "Swahili",
    "ta": "Tamil",
    "it": "Italian",
    "nl": "Dutch",
    "bn": "Bengali",
    "tr": "Turkish",
    "vi": "Vietnamese",
    "pl": "Polish",
    "jv": "Javanese",
    "pa": "Punjabi",
    "th": "Thai",
    "ko": "Korean",
    "az": "Azeri",
    "da": "Danish"
};


