import React from 'react';
import { withFirebase } from '../Firebase';
import * as INFOS from '../../constants/infos';

const Logo = props => {

    const [logo, setLogo] = React.useState('');

    React.useEffect(() => {
        let mounted = true;

        props.firebase.getImageAsync(props?.name || INFOS.LOGO).then(url => {
              if (mounted) {
                setLogo(url);
              }
            })
        return () => mounted = false;
      }, [props.firebase, setLogo, props.name]);
  
    return <img src={logo} alt={INFOS.LOGO_ALT} width={props.height}/>;

}

export default withFirebase(Logo);