import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Backdrop from '@material-ui/core/Backdrop';
import { PUBLIC } from '../../../constants/realtimeDB';
import ClickableMediaCard from './../clickableMediaCard';
import CircularProgress from '@material-ui/core/CircularProgress';
class NewsPanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      news: [],
      loading: true,
    };
    this.classes = props.classes;
  }

  componentDidMount() {
    this.setState({ loading: true });
  
    this.props.firebase.db.ref(`${PUBLIC}/news`).on('value', snapshot => {
      const newsObjectFromDB = snapshot.val();
      const newsList = Object.keys(newsObjectFromDB)
        .map(key => ({
            key,
            ...newsObjectFromDB[key]
        }));
      newsList.sort( (a, b) => (a.timestamp < b.timestamp) ? 1 : -1);
      this.setState({ news: newsList, loading: false });
    });
  }
  
  componentWillUnmount() {
    this.props.firebase.db.ref(`${PUBLIC}/news`).off();
  }

  render() {
    const { classes, editable, firebase, sendToEdit } = this.props;
    const { news, loading } = this.state;

    return (
      <>
        <Backdrop className={classes.backdrop} open={loading} >
          <CircularProgress color="inherit"/>
        </Backdrop> 

        <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="stretch" >
          {news && news.map(currentNews => !currentNews.hidden && (
            <Grid key={currentNews.key} item xs={12} sm={6} md={4} lg={3} xl={3}>
              <ClickableMediaCard
                rating={true}
                editable={editable}
                firebase={firebase}
                sendToEdit={sendToEdit}
                currentNews={currentNews}
              />
            </Grid>
          ))}
        </Grid>
      </>
    );
  }

};

export default NewsPanel;