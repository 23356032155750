import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Select from '@material-ui/core/Select';
import { PLAN } from '../../contractDescription';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormHelperText from '@material-ui/core/FormHelperText';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import { useStyles } from './styles';

const CreateContractDialog = (props) => {

    const classes = useStyles();
    const [plan, setPlan] = React.useState(PLAN.FREE.id);
    const [clientName, setClientName] = React.useState("");
    const [clientProjectDescription, setClientProjectDescription] = React.useState("");
    const { open, submitCreateContractDialog, handleCloseCreateContractDialog } = props;

    const handleChangePlan = (event) => setPlan(event.target.value);
    const updateClientNameValue = (event) => setClientName(event.target.value);
    const updateClientProjectDescriptionValue = (event) => setClientProjectDescription(event.target.value);
    const handleSubmitNewContrat = () => {
        submitCreateContractDialog({
            clientName,
            description : clientProjectDescription,
            plan : Object.values(PLAN).find(p => p.id === plan).name,
        });
    }

    const errorClientName = clientName.length === 0;
    const errorProjectDescription = clientProjectDescription.length === 0;
    const conditionSubmit = clientProjectDescription.length === 0 || clientName.length === 0;

    return (
        <Dialog open={open} onClose={handleCloseCreateContractDialog} aria-labelledby="form-dialog-new-client-title">
            <DialogTitle id="form-dialog-new-client-title">New Client's registration</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please fill the form below to create a new client's registration
                </DialogContentText>
                <TextField
                    autoFocus
                    fullWidth
                    type="text"
                    id="clientName"
                    margin="dense"
                    label="Client Name"
                    error={errorClientName}
                    onChange={updateClientNameValue}
                />
                <TextField
                    fullWidth
                    multiline
                    minRows={3}
                    type="text"
                    margin="dense"
                    label="Project Description"
                    id="clientProjectDescription"
                    error={errorProjectDescription}
                    onChange={updateClientProjectDescriptionValue}
                />
                <FormControl className={classes.formControl}>
                    <InputLabel id="crowdsec-choose-plan-label">Plan</InputLabel>
                        <Select
                            value={plan}
                            id="crowdsec-choose-plan"
                            onChange={handleChangePlan}
                            labelId="crowdsec-choose-plan-label"
                        >
                            <MenuItem value={1}>{PLAN.FREE.name}</MenuItem>
                            <MenuItem value={2}>{PLAN.PREMIUM.name}</MenuItem>
                            <MenuItem value={3}>{PLAN.PRO.name}</MenuItem>
                        </Select>
                    <FormHelperText>Choose appropriate plan</FormHelperText>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseCreateContractDialog} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSubmitNewContrat} color="primary" disabled={conditionSubmit}>
                    Add Client
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default CreateContractDialog;