import React from 'react';
import List from '@material-ui/core/List';
import DescriptionIcon from '@material-ui/icons/Description';
import FileBox from '../../../utils/GenericComponents/fileBox';
import CircularProgress from '@material-ui/core/CircularProgress';
import FloatingCard from '../../../utils/GenericComponents/floatingCard';

import { useStyles } from './styles';

const ChartersAndPolicies = (props) => {
    const classes = useStyles();
    return <ChartersAndPoliciesComponent {...props} classes={classes} />;
};

class ChartersAndPoliciesComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            policies: [],
            loading: true,
            policiesNumber: 0,
        }
        this.unMounted = false;
        this.width = props.width;
        this.classes = props.classes;
    }
      
    componentDidMount() {
        this.setState({ loading: true });
        this.props.firebase.getFilesNumber(`policies`, this.setPoliciesFilesNumber);
    }
    
    componentWillUnmount() {
        this.unMounted = true;
    }
    
    setPoliciesFilesNumber = (number) => {
        this.setState({ policiesNumber: number });
        this.props.firebase.getFiles(`policies`, this.setPoliciesFiles);
    }
    
    setPoliciesFiles = (fileInfos) => {
        if (!this.unMounted) {
          this.state.policies.push(fileInfos)
          if (this.state.policies.length === this.state.policiesNumber)
            this.setState({ policies: this.state.policies, loading: false });
        }
    }
    
    render() {
        const { policies } = this.state;
        return(
            <FloatingCard
                title={"Charters & Policies"}
                avatar={<DescriptionIcon />}
                buttonAction={this.state.loading ? <CircularProgress size={24} className={this.classes.circular} /> : null}
            >
            <div className={this.classes.fileBox}>
                <List dense={true}>
                    {policies.sort((a, b) => ( a.name < b.name ? 1 : -1 )).map((file, index) => (
                        <FileBox key={index} disabled={false} width={this.width} fileName={`${file.name}`} url={file.url}/>
                    ))}
                </List>
            </div>
            </FloatingCard>
        );
    }
};

export default ChartersAndPolicies;