import React from 'react';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import * as ROLES from '../../../../../../constants/roles';
import { VERITAS } from '../../../../../../constants/realtimeDB';
import { YELLOW, GRAY2 } from '../../../../../../constants/colors';
import FloatingCard from '../../../../../utils/GenericComponents/floatingCard';
import WaitUntilConfirmationModal from '../../../../../utils/GenericComponents/modalWaitUntilConfirmation';

import {
  Tooltip,
  Snackbar,
  Checkbox,
  FormGroup,
  IconButton,
  FormControlLabel,
} from '@material-ui/core';

const RolesPanel = (props) => {

  const { authUser, employee, firebase } = props;

  const initialStates = {};
  Object.keys(ROLES).forEach(role => initialStates[role] = !!(employee.roles?.[role] || false));

  const [openSnack, setOpenSnack] = React.useState(false);
  const [snackMessage, setSnackMessage] = React.useState('');
  const [state, setState] = React.useState({...initialStates})
  const [somethingChanged, setSomethingChanged] = React.useState(false)

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') return;
    setOpenSnack(false);
  };

  const handleChange = (event) => {
    const newState = { ...state, [event.target.name]: event.target.checked };
    setSomethingChanged(JSON.stringify(initialStates) !== JSON.stringify(newState));
    setState(newState);
  };

  const updateRolesCallback = (value) => {
    if (value === 'done') {
      setTimeout(() => {
          setSnackMessage('New Roles saved');
          setOpenSnack(true);
          firebase.db.ref(`${VERITAS}/employees`).update({ status: 'none', error: 'none' });
      }, 1000);
    } else if (value === authUser.uid) {
      setTimeout(() => {
        setSnackMessage('Error while saving new Roles');
        setOpenSnack(true);
        firebase.db.ref(`${VERITAS}/employees`).update({ status: 'none', error: 'none' });
      }, 1000);
    }
  }

  const handleSave = () => {
    firebase.db.ref(`${VERITAS}/employees`).update({ status: authUser.uid, employee : { uid: employee.uid, roles: { ...state } }})
    .then(() => true)
    .catch(error => {
      console.error("error with ID [" + authUser.uid + "] : " + error);
      setSnackMessage('Error while saving new Roles');
      setOpenSnack(true);
    });
  }

  return(
    <>
      <FloatingCard
        title={'Roles'}
        avatar={<VpnKeyIcon />}
        buttonAction={
          <Tooltip title="save" arrow>
            <span>
              <IconButton
                aria-label="save"
                onClick={handleSave}
                disabled={!somethingChanged}
              >
                <SaveIcon style={{ color: somethingChanged ? YELLOW : GRAY2 }} />
              </IconButton>
            </span>
          </Tooltip>
        }
      >
        <FormGroup >
          {Object.keys(ROLES).map((role, index) => (
            <FormControlLabel
              key={role}
              label={ROLES[role]}
              style={{height: 30}}
              disabled={role === ROLES.ADMIN}
              control={<Checkbox checked={state[role]} onChange={handleChange} name={role} />}
            />
          ))}
        </FormGroup>
      </FloatingCard>
      
      <WaitUntilConfirmationModal
        type="employees"
        title='Modify roles'
        firebase={firebase}
        callBack={updateRolesCallback}
        authUserUID={authUser?.uid || ""}
        message="Processing, wait a few seconds..."
      />

      <Snackbar
        open={openSnack}
        message={snackMessage}
        autoHideDuration={3000}
        onClose={handleCloseSnack}
        anchorOrigin={ {vertical: 'top', horizontal: 'center' }}
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
              <CloseIcon fontSize="small" />
          </IconButton>
        }
      />   
    </>
  );
}

export default RolesPanel;