import clsx from 'clsx';
import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import Tooltip from '@material-ui/core/Tooltip';
import Toolbar from '@material-ui/core/Toolbar';
import CheckIcon from '@material-ui/icons/Check';
import Checkbox from '@material-ui/core/Checkbox';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import * as REASONS from '../../holidays_constants';
import PanToolIcon from '@material-ui/icons/PanTool';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import StarRateIcon from '@material-ui/icons/StarRate';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import MemberAvatar from '../../../utils/GenericComponents/memberAvatar';

import { useStyles, useToolbarStyles } from './styles';

const TO = 'to';
const FROM = 'from';
const EVENT = 'name';
const STATUS = 'status';
const FIRSTNAME = 'firstName';
const DURATION = "daysOffToTake";


const descendingComparator = (a, b, orderBy) => {
    if (orderBy in b && orderBy in a) {
      if (b[orderBy] < a[orderBy]) return -1;
      if (b[orderBy] > a[orderBy]) return 1;
    }
    return 0;
  }

const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
}
  
function stableSort(array, orderBy, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  if(orderBy === FIRSTNAME) {
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0].employee.civil, b[0].employee.civil);
      return (order !== 0) ? order : a[1] - b[1];
    });
  } else {
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0].employee.contract.daysOff.holidays[a[0].key], b[0].employee.contract.daysOff.holidays[b[0].key]);
      return (order !== 0) ? order : a[1] - b[1];
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: FIRSTNAME, numeric: false, disablePadding: false, label: 'Alpaca', width: '10%' },
  { id: EVENT, numeric: false, disablePadding: false, label: "Event's name", width: '20%' },
  { id: FROM, numeric: false, disablePadding: false, label: 'From', width: '20%' },
  { id: TO, numeric: false, disablePadding: false, label: 'To', width: '20%' },
  { id: DURATION, numeric: true, disablePadding: false, label: 'Duration', width: '10%' },
  { id: STATUS, numeric: false, disablePadding: false, label: 'Status', width: '20%' },
];

const DaysOffTableHead = (props) => {

  const { order, classes, orderBy, rowCount, numSelected, onRequestSort, onSelectAllClick } = props;

  const createSortHandler = (property) => (event) => onRequestSort(event, property);

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            onChange={onSelectAllClick}
            checked={rowCount > 0 && numSelected === rowCount}
            inputProps={{ 'aria-label': 'select all holidays' }}
            indeterminate={numSelected > 0 && numSelected < rowCount}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            width={headCell.width || '20%'}
            align={headCell.numeric ? 'right' : 'center'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              onClick={createSortHandler(headCell.id)}
              direction={orderBy === headCell.id ? order : 'asc'}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

DaysOffTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
};


const DaysOffTableToolbar = (props) => {
  
  const { numSelected, handleClickHoliday } = props;

  const classes = useToolbarStyles();

  return (
    <Toolbar className={clsx(classes.root, { [classes.highlight]: numSelected > 0 })} >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <>
          <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
            List of alpacas waiting to rest
          </Typography>
        </>
      )}

      {numSelected > 0 && (
        <>
            <Tooltip title="Pending" arrow>
                <IconButton aria-label={REASONS.PENDING} onClick={() => handleClickHoliday(REASONS.PENDING)}>
                    <HourglassEmptyIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Validate" arrow>
                <IconButton aria-label={REASONS.VALIDATED} onClick={() => handleClickHoliday(REASONS.VALIDATED)}>
                    <CheckIcon className={classes.checkIcon} />
                </IconButton>
            </Tooltip>
            <Tooltip title="Refuse" arrow>
                <IconButton aria-label={REASONS.REFUSED} onClick={() => handleClickHoliday(REASONS.REFUSED)}>
                    <PanToolIcon className={classes.refuseIcon} />
                </IconButton>
            </Tooltip>
        </>
      )}
    </Toolbar>
  );
};

DaysOffTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


const EventNameTableCell = (props) => {
  const { eventName } = props;
  const classes = useStyles();
  const eventname = eventName.toLowerCase();

  const getIcon = () => {
    if (eventname.includes(REASONS.UNPAID)) return <MoneyOffIcon className={classes.unpaid} />;
    if (eventname.includes(REASONS.SPECIAL)) return <StarRateIcon className={classes.iconReason}/>;
    if (eventname.includes(REASONS.SICK)) return <LocalHospitalIcon className={classes.iconReason}/>;
    if (eventname.includes(REASONS.HOLIDAYS)) return <BeachAccessIcon className={classes.iconReason}/>;
    if (eventname.includes(REASONS.FAMILY)) return <SupervisorAccountIcon className={classes.iconReason}/>;
      //case eventname.includes(REASONS.MATERNITY): <LocalHospitalIcon />; break;
      //case eventname.includes(REASONS.MARRIAGE): <LocalHospitalIcon />; break;
      //case eventname.includes(REASONS.BIRTH): <LocalHospitalIcon />; break;
      //case eventname.includes(REASONS.DEATH): <LocalHospitalIcon />; break;
  }

  return (
    <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" >
      <Grid item >
        {getIcon()}
      </Grid>
      <Grid item >
        <Typography variant="body2" color="textPrimary">
          {eventName}
        </Typography>
      </Grid>
    </Grid>
  );
}

const DaysOffTable = (props) => {

    const classes = useStyles();
    const { authUser, listOfDaysOff, saveUserInfos, daysOffConfirmEmailUrl } = props;
  
    const [page, setPage] = React.useState(0);
    const [order, setOrder] = React.useState('asc');
    const [selected, setSelected] = React.useState([]);
    const [orderBy, setOrderBy] = React.useState('from');
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelecteds = listOfDaysOff.map(n => n.key);
        setSelected(newSelecteds);
        return;
      }
      setSelected([]);
    };

    const handleClickCheckBox = (event, key) => {
      event.stopPropagation();
      const selectedIndex = selected.indexOf(key);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, key);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }

      setSelected(newSelected);
    };

    const sendConfirmationEmail = (employee, key) => {
      const payload = {
        dest: employee.email,
        by: authUser.civil.firstName,
        command: "confirm_days_off_email",
        firstName: employee.civil.firstName,
        to: employee.contract.daysOff.holidays[key].to,
        from: employee.contract.daysOff.holidays[key].from,
        status: employee.contract.daysOff.holidays[key].status,
      }
      axios.post(daysOffConfirmEmailUrl, JSON.stringify(payload))
      .then(res => console.log("Email sent to " + employee.email))
      .catch(e => alert("Error sending email to " + employee.email));
    }

    const handleClickHoliday = (action) => {
        const selection = [...selected];
        selection.forEach((key, index) => {
            const holidayToValidate = listOfDaysOff.find(holiday => holiday.key === key);
            if (holidayToValidate) {
                setSelected([]);
                holidayToValidate.employee.contract.daysOff.holidays[key].status = action;
                setTimeout(() => saveUserInfos(holidayToValidate.employee), 1000);
                if (action === REASONS.VALIDATED || action === REASONS.REFUSED) {
                  setTimeout(() => sendConfirmationEmail(holidayToValidate.employee, key), (index + 1) * 1000);
                }
            }
        });
    }
  
    const isSelected = (key) => selected.indexOf(key) !== -1;
    const handleChangePage = (event, newPage) => setPage(newPage);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <div className={classes.root}>
            {listOfDaysOff.length === 0 && (
                <Typography variant="h6" id="tableTitle" component="div" className={classes.noDaysOff}>
                    No days off planned yet.
                </Typography>
            )}
            {listOfDaysOff.length > 0 && (
            <>
                <Paper className={classes.paper}>

                    <DaysOffTableToolbar
                        numSelected={selected.length}
                        handleClickHoliday={handleClickHoliday}
                    />

                    <TableContainer>
                        <Table
                            //size='small'
                            className={classes.table}
                            aria-label="daysoff table"
                            aria-labelledby="tableTitle"
                        >
                            <DaysOffTableHead
                                classes={classes}
                                order={order}
                                orderBy={orderBy}
                                rowCount={listOfDaysOff.length}
                                numSelected={selected.length}
                                onRequestSort={handleRequestSort}
                                onSelectAllClick={handleSelectAllClick}
                            />
                            <TableBody>
                                {stableSort(listOfDaysOff, orderBy, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((holiday, index) => {

                                const isItemSelected = isSelected(holiday.key);
                                const labelId = `daysoff-table-checkbox-${index}`;
                                const status = holiday.employee.contract.daysOff.holidays[holiday.key].status;
                                const eventName = holiday.employee.contract.daysOff.holidays[holiday.key].name;              

                                return (
                                    <TableRow
                                        hover
                                        tabIndex={-1}
                                        role="checkbox"
                                        key={holiday.key}
                                        selected={isItemSelected}
                                        aria-checked={isItemSelected}
                                        onClick={event => handleClickCheckBox(event, holiday.key)}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={isItemSelected}
                                                inputProps={{ 'aria-labelledby': labelId }}
                                                onClick={event => handleClickCheckBox(event, holiday.key)}
                                            />
                                        </TableCell>
                                        <TableCell component="th" id={labelId} scope="row" align="center" >
                                            <MemberAvatar member={holiday.employee} size={4.5}/>
                                        </TableCell>
                                        <TableCell align="center">
                                            <EventNameTableCell eventName={eventName}/>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography variant="body1">
                                                {new Date(holiday.employee.contract.daysOff.holidays[holiday.key].from).toLocaleDateString()}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography variant="body1">
                                                {new Date(holiday.employee.contract.daysOff.holidays[holiday.key].to).toLocaleDateString()}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography variant="body1">
                                                {holiday.employee.contract.daysOff.holidays[holiday.key].daysOffToTake}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <span className={classes[status]}>
                                                <strong>{status}</strong>
                                            </span>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TablePagination
                    page={page}
                    component="div"
                    rowsPerPage={rowsPerPage}
                    count={listOfDaysOff.length}
                    onPageChange={handleChangePage}
                    rowsPerPageOptions={[5, 10, 25]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    />

                </Paper>
            </>
            )}
        </div>
    );
}

export default DaysOffTable;
