import { makeStyles } from '@material-ui/core/styles';
import { PURPLE, VERY_LIGHT_GRAY } from '../../../../../constants/colors';

export const useStyles = makeStyles((theme) => ({
  name:{
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.6vw',
    },  
  },

  role:{
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.4rem'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.1vw',
    },  
  },

  large: {
    width: theme.spacing(17),
    height: theme.spacing(17),
  },

  avatarInvert: {
    color: PURPLE,
    width: theme.spacing(17),
    height: theme.spacing(17),
  },

  atWorkPanel:{
    padding: 7,
    borderRadius: 4,
    background: VERY_LIGHT_GRAY,
  },

}));
