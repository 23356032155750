import React from 'react';
import QuarterRow from './quarterRow';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionActions from '@material-ui/core/AccordionActions';
import { CircularProgressWithLabel } from '../../../utils/GenericComponents/progressBar';

import { Accordion, AccordionDetails, AccordionSummary } from '../styles';

const YearRow = (props) => {
    const [error, setError] = React.useState(false);
    const [edition, setEdition] = React.useState(false);
    const { year, isManager, employee, saveUserInfos, setSnackStatus } = props;
    const originalGoals = React.useRef(JSON.stringify(employee.contract.goals));
    const [expanded, setExpanded] = React.useState(parseInt(year.value) !== (new Date().getFullYear()) ? `Q1` : `Q${Math.ceil((new Date().getMonth() + 1) / 3)}`);
    
    const quarters = Object.keys(year).map(quarterKey => {
        if (quarterKey.indexOf("Q") > -1) {
            const progress = Object.keys(year[quarterKey]).reduce((acc, goalKey) => acc + year[quarterKey][goalKey].progress, 0);
            return {...year[quarterKey], value: quarterKey, progress: Math.round(progress / (Object.keys(year[quarterKey]).length || 1), 1) };
        }
        return null;
    }).filter(quarter => quarter);

    const handleChange = (panel) => (event, newExpanded) => setExpanded(newExpanded ? panel : false);

    const handleAdd = (quarter) => {
        employee.contract.goals[year.value][quarter][`goal${Date.now()}`] = { description: "New goal", progress: 0 };
        setSnackStatus(true, "New goal added successfully");
    };

    const handleEdition = () => {
        if (JSON.stringify(employee.contract.goals) !== originalGoals.current)
            originalGoals.current = JSON.stringify(employee.contract.goals);
        setEdition(!edition);
    };

    const handleCancel = () => {
        employee.contract.goals = JSON.parse(originalGoals.current);
        setEdition(false);
        setSnackStatus(true, "Changes canceled successfully");
    };

    const handleSave = () => {
        originalGoals.current = JSON.stringify(employee.contract.goals);
        saveUserInfos();
        setEdition(false);
    };

    const handleCopyFromPrevious = (quarter) => {
        const previousQuarter = quarter === "Q1" ? "Q4" : `Q${parseInt(quarter[1]) - 1}`;
        Object.keys(employee.contract.goals[year.value][previousQuarter]).forEach((goal, index) => {
            employee.contract.goals[year.value][quarter][`goal${Date.now() + index}`] = { ...employee.contract.goals[year.value][previousQuarter][goal] };
        });
        setSnackStatus(true, `Goals copied successfully from ${previousQuarter}`);
    };

    return (
        <div style={{width: '100%'}}>
            {quarters.map(quarter => (
                <Accordion expanded={expanded === quarter.value} onChange={handleChange(quarter.value)} key={quarter.value}>
                    <AccordionSummary
                        id={`${quarter.value}-header`}
                        expandIcon={<ExpandMoreIcon />}
                        IconButtonProps={{edge: 'start'}}
                        aria-controls={`${quarter.value}-content`}
                    >
                        <Grid container spacing={1} alignItems="center" justifyContent='center'>
                            <Grid item xs={8}>
                                <Typography variant='h6' gutterBottom>{quarter.value}</Typography>
                            </Grid>
                            <Grid item xs={4} align="right">
                                <CircularProgressWithLabel size={38} value={quarter.progress} normal={true}/>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <QuarterRow {...props} year={year} quarter={quarter} edition={edition} setError={setError} />
                    </AccordionDetails>
                    {isManager && (
                        <>
                            <Divider />
                            <AccordionActions>
                                {!edition 
                                ? (<Button size="small" onClick={handleEdition} color="primary" autoFocus>Edit</Button>)
                                : (
                                <>
                                    <Button size="small" onClick={() => handleCopyFromPrevious(quarter.value)}>Copy from previous</Button>
                                    <Button size="small" onClick={() => handleAdd(quarter.value)}>Add</Button>
                                    <Button size="small" onClick={handleCancel} color="secondary" autoFocus>Cancel</Button>
                                    <Button size="small" onClick={handleSave} color="primary" disabled={error}>Save</Button>
                                </>
                                )}
                            </AccordionActions>
                        </>
                    )}
                </Accordion>
            ))}
        </div>
    );
}

export default YearRow;
