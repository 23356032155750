import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles( (theme) => ({

    root: {
      minWidth: 275,
      maxWidth: 400,
    },
  
    title: {
      fontSize: 17,
    },
  
    pos: {
      marginBottom: 12,
    },
  
    form_root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '35ch',
      },
    },
  
}));
  