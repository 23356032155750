import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';
import TableCell from '@material-ui/core/TableCell';
import React, { useState, useCallback } from 'react';
import HistoryIcon from '@material-ui/icons/History';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import InputAdornment from '@material-ui/core/InputAdornment';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { ITEM, FORECAST, REAL, DESCRIPTION, NEW_ITEM_TBD } from '../../../../../constants';
import FloatingTagList from '../../../../../../../utils/GenericComponents/floatingTagList';
import ConfirmDeleteAlertDialog from '../../../../../../../utils/GenericComponents/dialogDeleteConfirm';

export const TextFieldNumericCell = (props) => {
    
    const HISTORY = "history";
    const { saveBudgetInfos, setBusinessUnit, setTextfield } = props;
    const { classes, isManager, textfield, trigram, editionMode, setEditionMode } = props;
    const { year, quarter, month, isForecast, businessUnit, currentCategory, currentItem } = props;
    const [localValue , setLocalValue] = useState(currentItem[month][isForecast ? FORECAST : REAL] || "");
    
    const id = `cell-C${currentCategory.value}-I${currentItem.value}-${month}-${isForecast ? FORECAST : REAL}}`;

    const onFocus = () => setTextfield(id);

    const save = React.useCallback(() => {
        const now = Date.now();
        const itemToModify = businessUnit[year.value][quarter.value][currentCategory.value][currentItem.value][month];
        if (itemToModify?.data?.history) {
            console.log("itemToModify.data.history", itemToModify.data.history);
            itemToModify.data.history[now] = trigram;
        } else {
            if (itemToModify.data) { itemToModify.data[HISTORY] = { [now]: trigram } }
            else { itemToModify["data"] = { [HISTORY]: { [now]: trigram } } }
        }
        setBusinessUnit({ ...businessUnit });
        saveBudgetInfos();
    }, [setBusinessUnit, businessUnit, saveBudgetInfos]);

    const onBlur = () => {
        setTextfield("");
        editionMode && save();
        setEditionMode(false);
    };

    const onKeyDown = (event) => {
        if (event.keyCode === 13) {
            setTextfield("");
            save();
            setEditionMode(false);
        }
    };

    const onChange = (event) => {
        const value = event.target.value;
        if (value >= 0) {
            const itemToModify = businessUnit[year.value][quarter.value][currentCategory.value][currentItem.value][month];
            itemToModify[isForecast ? FORECAST : REAL] = parseInt(value || 0);
            setEditionMode(true);
            setLocalValue(value);
        }
    };

    const getInvoiceInfoIcon = () => {

        const getInvoiceInfos = () => {
            if (currentItem[month]?.data?.invoice) {
                return (
                    <Tooltip title={currentItem[month]?.data?.invoice} arrow>
                        <InputAdornment position="end"><AssignmentTurnedInIcon fontSize='small' style={{ color: "#C2C2C2"}} /></InputAdornment>
                    </Tooltip>                    
                );
            } else if (currentItem[month]?.data?.history) {
                const history = Object.keys(currentItem[month]?.data?.history).map(key => ({ key, value: currentItem[month]?.data?.history[key] }));
                history.sort((a, b) => b.key - a.key);
                return (
                    <Tooltip title={<Box>{history.map(item => <div key={item.key}>{new Date(parseInt(item.key)).toLocaleString()}: {item.value}</div>)}</Box>} arrow>
                        <InputAdornment position="end"><HistoryIcon fontSize='small' style={{ color: "#C2C2C2"}} /></InputAdornment>
                    </Tooltip>                    
                );
            }
            return null;
        }

        return (
            <>
                {(!isForecast && currentItem[month].real && getInvoiceInfos()) || null}
            </>
        );
    };

    return (
        <TextField
            id={id}
            type="tel"
            size="small"
            onBlur={onBlur}
            onFocus={onFocus}
            value={localValue}
            disabled={!isManager}
            onKeyDown={onKeyDown}
            onChange={event => onChange(event)}
            onClick={event => event.stopPropagation()}
            inputProps={{ style: { textAlign: 'center' } }}
            inputRef={input => textfield === id && input && input.focus()}
            InputProps={{
                disableUnderline: true,
                classes: {
                    root: classes.cellTextFieldGeneric,
                    focused: classes.cellTextFieldGenericFocused,
                },
                endAdornment: (<>{getInvoiceInfoIcon()}</>),
            }}
        />
    )
};

export const TextFieldDescriptionCell = (props) => {

    const [isHovered, setIsHovered] = useState(false);
    const { isManager, saveBudgetInfos, setBusinessUnit } = props;
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const { classes, textfield, setTextfield, editionMode, setEditionMode } = props;
    const { year, quarter, description, businessUnit, currentCategory, currentItem } = props;
    const [localValue , setLocalValue] = useState(description || "");
  
    let id = `cell-C${currentCategory.value}`;
    id += currentItem ? `-I${currentItem.value}`: "";
    id += `-${description}}`;

    React.useEffect(() => {
        setLocalValue(description);
    }, [description]);

    const onFocus = () => setTextfield(id);
    const handleMouseOver = () => setIsHovered(true);
    const handleMouseOut = () => setIsHovered(false);

    const handleDelete = useCallback((event) => {
        event.stopPropagation();
        setOpenConfirmDelete(true);
    }, []);

    const save = React.useCallback(() => {
        setBusinessUnit({ ...businessUnit });
        saveBudgetInfos();
    }, [setBusinessUnit, businessUnit, saveBudgetInfos]);

    const onBlur = () => {
        if (!isHovered) {
            setTextfield("");
            editionMode && save();
            setEditionMode(false);
        }
    };

    const onKeyDown = (event) => {
        if (event.keyCode === 13) {
            setTextfield("");
            save();
            setEditionMode(false);
        }
    };

    const onChange = (event) => {
        setEditionMode(true);
        const value = event.target.value;
        let itemToModify = businessUnit[year.value][quarter.value][currentCategory.value];
        itemToModify = currentItem ? itemToModify[currentItem.value] : itemToModify;
        itemToModify[DESCRIPTION] = value;
        setLocalValue(value);
    };

    const handleDeleteConfirm = useCallback(() => {
        setOpenConfirmDelete(false);
        const quarterToModify = businessUnit[year.value][quarter.value];
        delete quarterToModify[currentCategory.value];
        save();
    }, [businessUnit, year, quarter, currentCategory, saveBudgetInfos, setBusinessUnit]);

    const getDeleteIcon = useCallback(() => {
        return !currentItem && isHovered && textfield === id ? (
            <Tooltip title="Delete category" arrow>
                <IconButton onClick={handleDelete} className={classes.deleteIcon}>
                    <DeleteIcon style={{ fontSize: 14 }} />
                </IconButton>
            </Tooltip>
        ) : null;
    }, [currentItem, isHovered, textfield, id, classes.deleteIcon, handleDelete]);

    return (
        <>
            <div onMouseOver={handleMouseOver} onMouseLeave={handleMouseOut}>
                <TextField
                    id={id}
                    size="small"
                    onBlur={onBlur}
                    onFocus={onFocus}
                    value={localValue}
                    disabled={!isManager}
                    onKeyDown={onKeyDown}
                    onChange={event => onChange(event)}
                    onClick={event => event.stopPropagation()}
                    inputProps={{ style: { textAlign: 'left' } }}
                    inputRef={input => textfield === id && input && input.focus()}
                    InputProps={{
                        disableUnderline: true,
                        endAdornment: (<>{getDeleteIcon()}</>),
                        classes: {
                            root: classes.cellTextFieldGeneric,
                            focused: classes.cellTextFieldGenericFocused,
                        },
                    }}
                />
            </div>
            <ConfirmDeleteAlertDialog
                title="Delete category"
                open={openConfirmDelete}
                onConfirmDeleteCallback={handleDeleteConfirm}
                message="Are you sure you want to delete this category ?"
                onCancelDeleteCallback={() => setOpenConfirmDelete(false)}
            />
        </>
    )
};

export const CategoryTableRowHeader = (props) => {
    const { open, classes } = props;
    const { items, setOpen, currentCategory } = props;
    const { textfield, setTextfield, editionMode, setEditionMode } = props;

    const months = [];

    months.push(items.reduce((acc, item) => acc + (item?.month1?.forecast || 0), 0));
    months.push(items.reduce((acc, item) => acc + (item?.month2?.forecast || 0), 0));
    months.push(items.reduce((acc, item) => acc + (item?.month3?.forecast || 0), 0));
    months.push(items.reduce((acc, item) => acc + (item?.month1?.real || 0), 0));
    months.push(items.reduce((acc, item) => acc + (item?.month2?.real || 0), 0));
    months.push(items.reduce((acc, item) => acc + (item?.month3?.real || 0), 0));

    return (
        <TableRow hover className={open ? classes.rootOpen : classes.root}>

            <TableCell align="left" className={classes.firstTableCell}>
                <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
            </TableCell>

            <TableCell align="left" className={classes.headerLabelTableCell}>
                <TextFieldDescriptionCell
                    {...props}
                    classes={classes}
                    currentItem={null}
                    textfield={textfield}
                    editionMode={editionMode}
                    setTextfield={setTextfield}
                    setEditionMode={setEditionMode}
                    description={currentCategory[DESCRIPTION]}
                />
            </TableCell>

            {months.map((month, index) => (
                <TableCell align="center" key={index} className={classes.headerTableCell}>
                    <strong>{month?.toLocaleString() || 0} €</strong>
                </TableCell>
            ))}          
        </TableRow>
    );
};

export const AddItemRow = (props) => {

    const { classes, isManager } = props;
    const { year, quarter, businessUnit, currentCategory, setBusinessUnit, saveBudgetInfos } = props;

    const onAddItem = () => {
        let categoryToModify = businessUnit[year.value][quarter.value][currentCategory.value];
        const timeStamp = Date.now();
        categoryToModify[`${ITEM}${timeStamp}`] = {
            description: NEW_ITEM_TBD,
            month1: { forecast: 0, real: 0 },
            month2: { forecast: 0, real: 0 },
            month3: { forecast: 0, real: 0 },
        };
        setBusinessUnit({ ...businessUnit });
        saveBudgetInfos();
    };

    return (
        <TableRow className={classes.historyRow} >
            <TableCell align="center" className={classes.firstTableCell} colSpan={8}>
                <Tooltip arrow title="Add item">
                    <span>
                        <IconButton aria-label="add-item" onClick={onAddItem} disabled={!isManager}>
                            <AddCircleIcon className={classes.addCategoryIcon} />
                        </IconButton>
                    </span>
                </Tooltip>
            </TableCell>
        </TableRow>
    );
};


export const TaggableCellWithLabel = (props) => {
    const { isManager } = props
    const { classes, textfield, setTextfield, editionMode, setEditionMode } = props
    const { year, quarter, currentCategory, item, businessUnit, setBusinessUnit, saveBudgetInfos } = props;

    const id = `add-tag-${currentCategory.value}${item.value}`;
    const itemToModify = businessUnit[year.value][quarter.value][currentCategory.value][item.value];

    const save = React.useCallback(() => {
        setBusinessUnit({ ...businessUnit });
        saveBudgetInfos();
    }, [setBusinessUnit, businessUnit, saveBudgetInfos]);

    const handleRemoveTag = () => {
        delete itemToModify.tag;
        save();
    };

    const handleAddTag = (tag) => {
        itemToModify.tag = tag;
        save();
    };

    return (
        <Box display="flex" alignItems="center" spacing={1} >
            <TextFieldDescriptionCell
                {...props}
                classes={classes}
                currentItem={item}
                textfield={textfield}
                editionMode={editionMode}
                setTextfield={setTextfield}
                setEditionMode={setEditionMode}
                description={item[DESCRIPTION]}
            />

            <FloatingTagList
                id={id}
                isManager={isManager}
                tag={item?.tag || null}
                callbackAddTag={handleAddTag}
                callbackRemoveTag={handleRemoveTag}
            />
        </Box>
    );
};
