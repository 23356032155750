import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';

import { useStyles } from './styles';

class DragAndDrop extends Component {

    state = { dragging: false }
  
    dropRef = React.createRef();

    handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    handleDragIn = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.dragCounter++;
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            this.setState({dragging: true});
        }
    }

    handleDragOut = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.dragCounter--;
        if (this.dragCounter === 0) {
            this.setState({dragging: false});
        }
    }

    handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({dragging: false});
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            this.props.handleDrop(e.dataTransfer.files);
            e.dataTransfer.clearData();
            this.dragCounter = 0;
        }
    }

    componentDidMount() {
        let div = this.dropRef.current;
        div.addEventListener('drop', this.handleDrop);
        div.addEventListener('dragover', this.handleDrag);
        div.addEventListener('dragenter', this.handleDragIn);
        div.addEventListener('dragleave', this.handleDragOut);
    }

    componentWillUnmount() {
        let div = this.dropRef.current;
        div.removeEventListener('drop', this.handleDrop);
        div.removeEventListener('dragover', this.handleDrag);
        div.removeEventListener('dragenter', this.handleDragIn);
        div.removeEventListener('dragleave', this.handleDragOut);
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root} ref={this.dropRef}>
                {this.state.dragging && (
                    <div className={classes.area}>
                        <div className={classes.drop}>
                            <div>{`drop here :)`}</div>
                        </div>
                    </div>
                )}
                {this.props.children}
            </div>
        );
    }
}

export default withStyles(useStyles)(DragAndDrop);