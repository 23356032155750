import React from 'react';
import { useStyles } from './styles';
import ContractRow from './contractRow';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import CreateNewContract from './createContract';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';

const DATE = 'id';
const PLAN = 'plan';
const ACTIVE = 'active';
const CLIENT_NAME = 'clientName';

const descendingComparator = (a, b, orderBy) => {

  if (orderBy in b && orderBy in a) {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
  }
  return 0;
}

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(employees, orderBy, comparator) {
  const stabilizedThis = employees.map((employee, index) => [employee, index]);
  if(orderBy === CLIENT_NAME) {
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      return (order !== 0) ? order : a[1] - b[1];
    });
  } else {
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      return (order !== 0) ? order : a[1] - b[1];
    });
  }

  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    { id: CLIENT_NAME, numeric: false, disablePadding: true, label: 'Client Name' },
    { id: PLAN, numeric: false, disablePadding: true, label: 'Plan' },
    { id: ACTIVE, numeric: false, disablePadding: true, label: 'Active' },
    { id: DATE, numeric: false, disablePadding: true, label: 'Date' },
];

const ContractsTableComponent = (props) => {

    const { company, onCreateNewContract, onUpdateContract } = props;

    const classes = useStyles();

    const [page, setPage] = React.useState(0);
    const [order, setOrder] = React.useState('asc');
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [orderBy, setOrderBy] = React.useState(CLIENT_NAME);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const contracts = Object.keys(company?.contracts || {}).map((key) => {
        return {
            ...company.contracts[key],
            id: key
        }
    });

    const handleChangePage = (event, newPage) => setPage(newPage);
    const createSortHandler = (property) => (event) => handleRequestSort(event, property);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <>
            <TableContainer className={classes.container} component={Paper}>

                <Table stickyHeader aria-label="collapsible table" >

                    <TableHead>
                        <TableRow>
                            <TableCell />
                            {headCells.map((headCell) => (              
                                <TableCell
                                    key={headCell.id}
                                    align={headCell.numeric ? 'right' : 'center'}
                                    padding={headCell.disablePadding ? 'none' : 'normal'}
                                    sortDirection={orderBy === headCell.id ? order : false}
                                >
                                    <TableSortLabel
                                        active={orderBy === headCell.id}
                                        onClick={createSortHandler(headCell.id)}
                                        direction={orderBy === headCell.id ? order : 'asc'}
                                    >
                                        <strong>{headCell.label}</strong>
                                        {orderBy === headCell.id ? (
                                            <span className={classes.visuallyHidden}>
                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </span>
                                        ) : null}
                                    </TableSortLabel>
                                </TableCell>
                            ))}            
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {stableSort(contracts, orderBy, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map(contract => (
                            <ContractRow key={contract.id} contract={contract} onUpdateContract={onUpdateContract} />
                        ))}
                    </TableBody>

                </Table>

            </TableContainer>
        
            <TablePagination
                page={page}
                component="div"
                count={contracts.length}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                rowsPerPageOptions={[10, 25, 100]}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <CreateNewContract onCreateNewContract={onCreateNewContract} />

        </>
    );
}

export default ContractsTableComponent;