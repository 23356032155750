import React from 'react';
import Box from '@material-ui/core/Box';
import { LIGHT_PURPLE } from '../../constants/colors';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const BorderLinearProgress = withStyles((theme) => ({
  
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'dark' ? 700 : 200],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: LIGHT_PURPLE,
    },
  
}))(LinearProgressWithLabel);
  
  function LinearProgressWithLabel(props) {
    return (
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box minWidth={props.target.toLocaleString().length * 10}>
          <Typography variant="body2" color="textSecondary">
            {`${props.target.toLocaleString()} €`}
          </Typography>
        </Box>
      </Box>
    );
}

export default BorderLinearProgress;
  