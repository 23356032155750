import React from 'react';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import * as CONST from '../../../../constants';
import { YELLOW, LIGHT_GRAY } from '../../../../../../../constants/colors';

import { useStyles } from './styles';

const initValues = (news) => [
    { key: 0, label: CONST.MEDIA_TYPES.ADS, selected: (news.media.indexOf(CONST.MEDIA_TYPES.ADS) !== - 1) },
    { key: 1, label: CONST.MEDIA_TYPES.POST, selected: (news.media.indexOf(CONST.MEDIA_TYPES.POST) !== - 1) },
    { key: 2, label: CONST.MEDIA_TYPES.TWEET, selected: (news.media.indexOf(CONST.MEDIA_TYPES.TWEET) !== - 1) },
    { key: 3, label: CONST.MEDIA_TYPES.ARTICLE, selected: (news.media.indexOf(CONST.MEDIA_TYPES.ARTICLE) !== - 1) },
    { key: 4, label: CONST.MEDIA_TYPES.PODCAST, selected: (news.media.indexOf(CONST.MEDIA_TYPES.PODCAST) !== - 1) },
    { key: 5, label: CONST.MEDIA_TYPES.CONFERENCE, selected: (news.media.indexOf(CONST.MEDIA_TYPES.CONFERENCE) !== - 1) },
    { key: 6, label: CONST.MEDIA_TYPES.PRESS_RELEASE, selected: (news.media.indexOf(CONST.MEDIA_TYPES.PRESS_RELEASE) !== - 1) },
  ];

const MediasTags = (props) => {

    const { currentNews, onChangeCurrentNews } = props;
    const classes = useStyles();
    const [chipData, setChipData] = React.useState(initValues(currentNews));

    const handleClick = (event, data) => {
      data.selected = !data.selected;
      chipData[data.key] = data;
      setChipData([...chipData]);
      event.target.name = CONST.MEDIA_SELECTION;
      event.target.value = chipData.filter(media => media.selected).map(media => media.label).join(",");
      onChangeCurrentNews(event, currentNews);
      event.preventDefault();
    };

    //console.log(currentNews.media);

  return (
    <Box component="ul" className={classes.root}>
      {chipData.map(chip => {
        return (
          <li key={currentNews.key + chip.key}>
            <Chip
                label={chip.label}
                className={classes.chip}
                id={currentNews.key + chip.key}
                name={currentNews.key + chip.key}
                onClick={event => handleClick(event, chip)}
                style={{ backgroundColor: (chip.selected) ? YELLOW : LIGHT_GRAY }}
            />
          </li>
        );
      })}
    </Box>
  );
}

export default MediasTags;