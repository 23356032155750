import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import Tooltip from '@material-ui/core/Tooltip';
import Toolbar from '@material-ui/core/Toolbar';
import Checkbox from '@material-ui/core/Checkbox';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableContainer from '@material-ui/core/TableContainer';
import ConfirmDeleteHolidaysDialog from './deleteConfirmDialog';
import TablePagination from '@material-ui/core/TablePagination';
import AskForGoogleCalendarAuthDialog from './askForGoogleCalendarAuth';

import { useStyles, useToolbarStyles } from './styles';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Event' },
  { id: 'from', numeric: false, disablePadding: false, label: 'From' },
  { id: 'to', numeric: false, disablePadding: false, label: 'To' },
  { id: 'daysOffToTake', numeric: true, disablePadding: false, label: 'Duration' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
];

const DaysOffTableHead = (props) => {

  const { order, classes, orderBy, rowCount, numSelected, onRequestSort, onSelectAllClick } = props;

  const createSortHandler = (property) => (event) => onRequestSort(event, property);

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            onChange={onSelectAllClick}
            checked={rowCount > 0 && numSelected === rowCount}
            inputProps={{ 'aria-label': 'select all holidays' }}
            indeterminate={numSelected > 0 && numSelected < rowCount}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'center'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              onClick={createSortHandler(headCell.id)}
              direction={orderBy === headCell.id ? order : 'asc'}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

DaysOffTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
};


const DaysOffTableToolbar = (props) => {
  
  //const { onDelete, numSelected, availableDays, gooleCalendarConfirmation } = props;
  const { onDelete, numSelected, availableDays } = props;

  const classes = useToolbarStyles({ availableDays });
  

  return (
    <Toolbar className={clsx(classes.root, { [classes.highlight]: numSelected > 0 })} >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <>
          <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
            Days off
          </Typography>
          <Typography className={classes.available} variant="h6" id="tableTitle" component="div">
            ({availableDays.toFixed(2)} available)
          </Typography>
        </>
      )}

      {numSelected > 0 && (
        <Tooltip title="Delete" arrow>
          <IconButton aria-label="delete" onClick={onDelete}>
            {/* <DeleteIcon style={gooleCalendarConfirmation ? {color: 'green'} : {color: 'black'}} /> */}
            <DeleteIcon  />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

DaysOffTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


const DaysOffTable = (props) => {

  const classes = useStyles();
  let gooleCalendarConfirmation = React.useRef(false);
  let userWantsTodeleteEventWithoutDeletingFromGoogleCalendar = React.useRef(false);
  const { apiCalendar, listOfDaysOff, availableDays, handleDelete, highlightHoliday } = props;
  
  const [page, setPage] = React.useState(0);
  const [order, setOrder] = React.useState('asc');
  const [selected, setSelected] = React.useState([]);
  const [orderBy, setOrderBy] = React.useState('from');
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openAskForGoogleCalendarAuthDialog, setOpenAskForGoogleCalendarAuthDialog] = React.useState(false);
    
  const isSelected = (key) => selected.indexOf(key) !== -1;
  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleClickRow = (event, key) => highlightHoliday([key]);
  const handleCloseDeleteDialog = () => setOpenDeleteDialog(false);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleDeleteConfirm = () => {
    handleDelete(selected, userWantsTodeleteEventWithoutDeletingFromGoogleCalendar.current);
    setOpenDeleteDialog(false);
    setSelected([]);
  }

  const handleCloseAskForGoogleCalendarAuthDialog = () => {
    gooleCalendarConfirmation.current = true;
    userWantsTodeleteEventWithoutDeletingFromGoogleCalendar.current = true;
    setOpenAskForGoogleCalendarAuthDialog(false);
  }

  const onDelete = () => {
    let isGoogleCalendar = listOfDaysOff.filter(dayOff => selected.includes(dayOff.key)).reduce((acc, dayOff) => acc || dayOff.calendarID !== '', false);
    if (isGoogleCalendar) {
      (gooleCalendarConfirmation.current === false) ? setOpenAskForGoogleCalendarAuthDialog(true) : setOpenDeleteDialog(true);
    } else {
      userWantsTodeleteEventWithoutDeletingFromGoogleCalendar.current = null;
      setOpenDeleteDialog(true);
    }
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = listOfDaysOff.map(n => n.key);
      setSelected(newSelecteds);
      highlightHoliday(newSelecteds);
      return;
    }
    setSelected([]);
    highlightHoliday([]);
  };

  const handleConfirmAskForGoogleCalendarAuthDialog = () => {
    setOpenAskForGoogleCalendarAuthDialog(false);

    const signUpdate = (sign) => sign && handleDeleteConfirm();

    gooleCalendarConfirmation.current = true;
    userWantsTodeleteEventWithoutDeletingFromGoogleCalendar.current = false;
    if (!apiCalendar.sign) {
      apiCalendar.listenSign(signUpdate);
      apiCalendar.handleAuthClick();
    } else handleDeleteConfirm();
  }

  const handleClickCheckBox = (event, key) => {
    event.stopPropagation();
    const selectedIndex = selected.indexOf(key);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, key);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
    highlightHoliday(newSelected);
  };

  return (
    <div className={classes.root}>
      {listOfDaysOff.length === 0 && (
        <Typography variant="h6" id="tableTitle" component="div" className={classes.noDaysOff}>
          No days off planned yet.
        </Typography>
      )}
      {listOfDaysOff.length > 0 && (
        <>
          <Paper className={classes.paper}>
            <DaysOffTableToolbar
              onDelete={onDelete}
              availableDays={availableDays}
              numSelected={selected.length}
              //gooleCalendarConfirmation={gooleCalendarConfirmation.current}
            />
            <TableContainer>
              <Table
                size={'small'}
                className={classes.table}
                aria-label="daysoff table"
                aria-labelledby="tableTitle"
              >
                <DaysOffTableHead
                  order={order}
                  classes={classes}
                  orderBy={orderBy}
                  numSelected={selected.length}
                  rowCount={listOfDaysOff.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {stableSort(listOfDaysOff, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.key);
                      const labelId = `daysoff-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={row.key}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                          onClick={event => handleClickRow(event, row.key)}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ 'aria-labelledby': labelId }}
                              onClick={event => handleClickCheckBox(event, row.key)}
                            />
                          </TableCell>
                          <TableCell component="th" id={labelId} scope="row" padding="none" align="center">
                            {row.name || 'holidays'}
                          </TableCell>
                          <TableCell align="center">{new Date(row.from).toLocaleDateString()}</TableCell>
                          <TableCell align="center">{new Date(row.to).toLocaleDateString()}</TableCell>
                          <TableCell align="center" style={{width: 60}}>{row.daysOffToTake}</TableCell>
                          <TableCell align="center">
                            <span className={classes[row.status]}>
                              <strong>{row.status}</strong>
                            </span>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              page={page}
              component="div"
              rowsPerPage={rowsPerPage}
              count={listOfDaysOff.length}
              onPageChange={handleChangePage}
              rowsPerPageOptions={[5, 10, 25]}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <ConfirmDeleteHolidaysDialog
              open={openDeleteDialog}
              onClose={handleCloseDeleteDialog}
              onConfirm={handleDeleteConfirm}
              deleteWithoutGoogleCalendar={userWantsTodeleteEventWithoutDeletingFromGoogleCalendar.current}
            />

            <AskForGoogleCalendarAuthDialog
              open={openAskForGoogleCalendarAuthDialog}
              onClose={handleCloseAskForGoogleCalendarAuthDialog}
              onConfirm={handleConfirmAskForGoogleCalendarAuthDialog}
            />
          </Paper>
        </>
      )}
    </div>
  );
}

export default DaysOffTable;
