import React from 'react';
import * as MENUS from '../menus';
import { Link } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import { Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreIcon from '@material-ui/icons/MoreVert';
import { WHITE } from '../../../../constants/colors';
import * as ROUTES from '../../../../constants/routes';
import IconButton from '@material-ui/core/IconButton';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountCircle from '@material-ui/icons/AccountCircle';
import NotificationsIcon from '@material-ui/icons/Notifications';
import StyledBadge from '../../../utils/GenericComponents/styledBadge';

import { useStyles } from './styles';

const mobileMenuId = 'primary-menu-mobile';
  
const MenuMobile = props => {

  const { isMobileMenuOpen, mobileMenuAnchorEl, handleMobileMenuClose, authUser } = props;
  
  return (
    <Menu
      keepMounted
      id={mobileMenuId}
      open={isMobileMenuOpen}
      anchorEl={mobileMenuAnchorEl}
      onClose={handleMobileMenuClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
    >
      {MENUS.menus.map(item => item.condition(authUser) && (
        <MenuItem
          to={item.to}
          key={item.label}
          component={Link}
          onClick={handleMobileMenuClose}>
          <div style={{paddingTop: 8, paddingBottom: 8}}>{item.label}</div>
        </MenuItem>
      ))}

    </Menu>
  );
}


const MoreMobile = props => {

  const {isMobileNotifOpen, mobileMoreAnchorEl, handleMobileMoreClose, handleNotifClick, doSignOut, unread} = props;
  
  return (
    <Menu
      keepMounted
      id={mobileMenuId}
      open={isMobileNotifOpen}
      anchorEl={mobileMoreAnchorEl}
      onClose={handleMobileMoreClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      {/*
      <MenuItem onClick={(event) => handleNotifClick(event, 2)}>
        <IconButton aria-label="messages" color="inherit">
          <Badge badgeContent={0} color="secondary">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      */}

      <MenuItem onClick={(event) => handleNotifClick(event, 1)}>
        <IconButton aria-label="notifications" color="inherit">
          <StyledBadge badgeContent={unread} bordercolor={WHITE} overlap="rectangular">
            <NotificationsIcon />
          </StyledBadge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>

      <MenuItem to={ROUTES.ACCOUNT} component={Link} onClick={handleMobileMoreClose}>
        <IconButton
          color="inherit"
          aria-haspopup="true"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
        >
          <AccountCircle />
        </IconButton >
        <p>Account</p>
      </MenuItem>

      <MenuItem onClick={doSignOut}>
        <IconButton aria-label="exit" color="inherit">
          <ExitToAppIcon />
        </IconButton>
        <p>Sign out</p>
      </MenuItem>

    </Menu>
  );
}

function getUnreadNotifs(notifications)  {
  let unread = 0;
  notifications.map(notif => !notif.read ? unread++ : unread);
  return unread;
}

const NavigationAuthSectionMobile = props => {

  const { notifications, authUser } = props;

  const classes = useStyles();

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMobileNotifOpen = Boolean(mobileMoreAnchorEl);

  const [mobileMenuAnchorEl, setMobileMenuAnchorEl] = React.useState(null);
  const isMobileMenuOpen = Boolean(mobileMenuAnchorEl);

  const handleMobileMoreClose = () => setMobileMoreAnchorEl(null);
  const handleMobileMoreOpen = (event) => setMobileMoreAnchorEl(event.currentTarget);
  
  const handleMobileMenuClose = () => setMobileMenuAnchorEl(null);
  const handleMobileMenuOpen = (event) => setMobileMenuAnchorEl(event.currentTarget);

  const unread = getUnreadNotifs(notifications);
    
  const handleNotifClick = (event, choice) => {
    handleMobileMoreClose();
    props.handleNotifClick(event, choice);
  }
  
  return (
    <>

      <div className={classes.sectionMobile}>

        <IconButton
            color="inherit"
            aria-haspopup="true"
            aria-label="show more"
            aria-controls={mobileMenuId}
            onClick={handleMobileMenuOpen}
          >
            <MenuIcon />
        </IconButton>

        <div className={classes.grow}>
          <Typography variant="body2" gutterBottom align='center'>
            CrowdSec Companion
          </Typography>
        </div>

        <IconButton
          color="inherit"
          aria-haspopup="true"
          aria-label="show more"
          aria-controls={mobileMenuId}
          onClick={handleMobileMoreOpen}
        >
          <StyledBadge badgeContent={unread} bordercolor={WHITE} overlap="rectangular">
            <MoreIcon />
          </StyledBadge>
        </IconButton>
      </div>

      <MenuMobile
        authUser={authUser}
        isMobileMenuOpen={isMobileMenuOpen}
        mobileMenuAnchorEl={mobileMenuAnchorEl}
        handleMobileMenuClose={handleMobileMenuClose}
      />

      <MoreMobile
        unread={unread}
        doSignOut={props.doSignOut}
        notifications={notifications}
        isMobileNotifOpen={isMobileNotifOpen}
        handleNotifClick={handleNotifClick}
        mobileMoreAnchorEl={mobileMoreAnchorEl}
        handleMobileMoreClose={handleMobileMoreClose}
      />

    </>

  );
}

export default NavigationAuthSectionMobile;