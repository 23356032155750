import { makeStyles } from '@material-ui/core/styles';
import { WHITE, PURPLE } from '../../../constants/colors';
import { divRoot, listPanel, rightPanel } from '../../utils';

export const useStyles = makeStyles((theme) => ({

  listPanel: listPanel({theme}),
  divRoot: divRoot({theme, bar: false}),
  rightPanel: {
    ...rightPanel({theme}),
    zIndex: -3,
    top: theme.spacing(0),
    backgroundColor: 'transparent',
  },

  backdrop: {
    color: PURPLE,
    backgroundColor: WHITE,
    zIndex: theme.zIndex.drawer + 2,
  },

  paper : {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },

  treeInfos: {
    padding: 8,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 17
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.7vw'
    },  
  },
    
}));
