export const useStyles = theme => ({
    root: {
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'inline-block',
    },

    area: {
        top: 0,
        left: 0, 
        right: 0,
        bottom: 0,
        zIndex: 9999,
        position: 'absolute',
        border: 'dashed grey 4px',
        backgroundColor: 'rgba(255,255,255,.8)',
    },

    drop: {
        left: 0,
        right: 0,
        top: '40%',
        fontSize: 30,
        color: 'grey',
        textAlign: 'center',
        position: 'absolute',
    }
});
