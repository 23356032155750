import React from 'react';
import CardInvest from '../cardInvest';
import { Grid } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { ESOP_TYPE, SHARES_TYPE } from '../../../../constants/infos';

const PANEL_NAME = 'panelOngoingInvest';

const MyOngoingInvestments = props => {

    const {classesComponent, partner, investmentIntent, steeringObj } = props;
    
    const partnerESOP = partner.partnerInfo.ESOP;
    const partnerShares = partner.partnerInfo.SHARES;
    const [expanded, setExpanded] = React.useState(PANEL_NAME);
    const sharesCondition = (investmentIntent && partnerShares)

    const handleChange = (panel) => (event, newExpanded) => setExpanded(newExpanded ? panel : false);

    const isRoundFunded = React.useCallback((round) => steeringObj?.SHARES?.[round]?.funded || false, [steeringObj]);
    const hasPartnerSomeEsopForRound = React.useCallback((round) => (partnerESOP?.[round]?.quantity || -1) > 0, [partnerESOP]);
    const hasPartnerInvestIntentforRound = React.useCallback((round) => (partnerShares?.[round]?.investmentIntent || -1) > 0, [partnerShares]);
    
    const esopCondition = React.useMemo(() => {
        if (!partnerESOP) return false;
        return Object.keys(partnerESOP).some(roundInWhichPartnerIs =>
          isRoundFunded(roundInWhichPartnerIs) && hasPartnerSomeEsopForRound(roundInWhichPartnerIs)
        );
    }, [partnerESOP, isRoundFunded, hasPartnerSomeEsopForRound]);
    
    return(
        <>
            { (esopCondition || sharesCondition) && (
            <Accordion
                className={classesComponent.hideBorder}
                expanded={expanded === PANEL_NAME}
                onChange={handleChange(PANEL_NAME)}
            >
                <AccordionSummary
                    id="panelOngoing-header"
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panelOngoing-content"
                >
                    <Typography variant="body1" color="textSecondary">My ongoing Investments</Typography>
                </AccordionSummary>

                <AccordionDetails className={classesComponent.accordionDetails}>
                    
                    <Grid container spacing={0} >
                    
                        { Object.keys(partnerShares).map(round => (hasPartnerInvestIntentforRound(round) && (
                        <CardInvest
                            {...props}
                            key={round}
                            round={round}
                            ongoing={true}
                            type={SHARES_TYPE}
                            price={`${partnerShares?.[round]?.investmentIntent?.toLocaleString() || -1}€`}
                            quantity={Math.floor( (partnerShares?.[round]?.investmentIntent || - 1) / (steeringObj?.SHARES?.[round]?.price || 1))}
                        />
                        )))}

                        { Object.keys(partnerESOP).map(round => (hasPartnerSomeEsopForRound(round) && (!isRoundFunded(round)) && (
                        <CardInvest
                            {...props}
                            key={round}
                            round={round}
                            ongoing={true}
                            type={ESOP_TYPE}
                            quantity={partnerESOP?.[round]?.quantity || -1}
                            date={`${partnerESOP?.[round]?.date || 'TBD'}`}
                            price={`Strike: ${ Math.round((steeringObj?.ESOP?.[round]?.strike || 0 ) * 1000) / 1000}€`}
                        />
                        )))}

                    </Grid>

                </AccordionDetails>

            </Accordion>
            )}
        </>
    );
}

export default MyOngoingInvestments;
