import React from 'react';
import * as UTILS from '../../utils';  
import { useStyles } from './styles';
import EmployeeRow from './employeeRow';
import { Tooltip } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import TableContainer from '@material-ui/core/TableContainer';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';
import PDFDialog from '../../utils/GenericComponents/pdfDialog';
import FloatingFilesMenu  from '../../utils/GenericComponents/floatingFilesMenus';

const ASC = 'asc';
const DESC = 'desc';
const ICON = 'icon';
const ROLE = 'role';
const TYPE = 'type';
const START = 'start';
const SALARY = 'salary';
const NAME = 'lastName';

const descendingComparator = (a, b, orderBy) => {

  if (orderBy in b && orderBy in a) {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
  }
  return 0;
}

const getComparator = (order, orderBy) => {
  return order === DESC
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(employees, orderBy, comparator) {
  const stabilizedThis = employees.map((employee, index) => [employee, index]);

  stabilizedThis.sort((a, b) => {
    let order;
    if (orderBy === NAME) {
      order = comparator(a[0].civil, b[0].civil);
    } else if (orderBy === TYPE || orderBy === ROLE || orderBy === START || orderBy === SALARY) {
      order = comparator(a[0].contract, b[0].contract);
    } else {
      order = comparator(a[0], b[0]);
    }
    return (order !== 0) ? order : a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}


const headCells = [
  { id: ICON, numeric: false, disablePadding: true, label: '' },
  { id: NAME, numeric: false, disablePadding: true, label: 'Name' },
  { id: TYPE, numeric: false, disablePadding: true, label: 'Contract' },
  { id: ROLE, numeric: false, disablePadding: true, label: 'Role' },
  { id: START, numeric: false, disablePadding: true, label: 'Start' },
  { id: SALARY, numeric: true, disablePadding: true, label: 'Salary' },
];

const TableHeader = (props) => {

  const { order, orderBy, classes, hideAmounts, handleHideAmounts, createSortHandler } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell />
        {headCells.map((headCell) => (              
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'center'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              onClick={createSortHandler(headCell.id)}
              direction={orderBy === headCell.id ? order : ASC}
            >
              <strong>{headCell.label}</strong>
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === DESC ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
            {headCell.id === SALARY && (
              <Tooltip title={hideAmounts ? "show" : "hide"}>
                <Switch
                  color="primary"
                  name="hide_salarys"
                  checked={hideAmounts}
                  onChange={handleHideAmounts}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />   
              </Tooltip>                   
            )}
          </TableCell>
        ))}            
      </TableRow>
    </TableHead>
  );
};


const EmployeeTableComponent = (props) => {

  const classes = useStyles();

  const [page, setPage] = React.useState(0);
  const [files, setFiles] = React.useState([]);
  const [order, setOrder] = React.useState(ASC);
  const [orderBy, setOrderBy] = React.useState(NAME);
  const [snackMsg, setSnackMsg] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [hideAmounts, sethideAmounts] = React.useState(true);
  const [currentFileUrl, setCurrentFileUrl] = React.useState('');
  const [openPDFDialog, setOpenPDFDialog] = React.useState(false);
  const { width, firebase, authUser, employees, steeringObj } = props;
  const [openFloatingFilesMenu, setOpenFloatingFilesMenu] = React.useState(false);

  const MemoizedEmployeeRow = React.memo(EmployeeRow);

  const employeesWithShares = React.useMemo(() => 
    employees.map(employee => ({
      ...employee,
      ownEsops: UTILS.getTotalOwnedShares(employee),
      ownShares: UTILS.getTotalOwnedShares(employee),
    })), 
    [employees]
  );  

  const handleClosePDFDialog = () => setOpenPDFDialog(false);
  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleHideAmounts = (event) => sethideAmounts( event.target.checked );
  const createSortHandler = (property) => (event) => handleRequestSort(event, property);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const setSnackStatus = (status, msg) => {
    setSnackMsg(msg);
    setSnackOpen(status);
  }

  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackOpen(false);
  };

  const handleCloseFileList = () => {
    setAnchorEl(null);
    setOpenFloatingFilesMenu(false);
  };

  const handleRequestSort = React.useCallback((event, property) => {
    const isAsc = orderBy === property && order === ASC;
    setOrder(isAsc ? DESC : ASC);
    setOrderBy(property);
  }, [orderBy, order]);  

  const handleDocumentList = (event, files) => {
    setAnchorEl(event.currentTarget);
    setFiles(files);
    setOpenFloatingFilesMenu(true);
  };

  const handleMenuItemClick = (event, index) => {
    setAnchorEl(null);
    setOpenPDFDialog(true);
    setOpenFloatingFilesMenu(false);
    setCurrentFileUrl(files[index]?.url);
  };

  return (
    <>
      <TableContainer className={classes.container} component={Paper}>

        <Table stickyHeader aria-label="employees-collapsible-table" >

          <TableHeader 
            order={order} 
            classes={classes}
            orderBy={orderBy} 
            hideAmounts={hideAmounts}
            handleHideAmounts={handleHideAmounts}
            createSortHandler={createSortHandler}
          />          

          <TableBody>
            {stableSort(employeesWithShares, orderBy, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map(employee => (
              <MemoizedEmployeeRow
                width={width}
                authUser={authUser}
                employee={employee}
                firebase={firebase}
                key={employee.trigram}
                hideAmounts={hideAmounts}
                steeringObj={steeringObj}
                setSnackStatus={setSnackStatus}
                handleDocumentList={handleDocumentList}
              />
            ))}
          </TableBody>

        </Table>

      </TableContainer>
      
      {<TablePagination
        page={page}
        component="div"
        count={employees.length}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[10, 25, 100]}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />}

      <Snackbar
        open={snackOpen}
        message={snackMsg}
        autoHideDuration={3000}
        onClose={handleCloseSnackBar}
        anchorOrigin={ {vertical: 'top', horizontal: 'center' }}
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackBar}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />   

      <FloatingFilesMenu
        files={files}
        anchorEl={anchorEl}
        open={openFloatingFilesMenu}
        handleCloseFileList={handleCloseFileList}
        handleMenuItemClick={handleMenuItemClick}
      />

      {(currentFileUrl && currentFileUrl !== '') && <PDFDialog open={openPDFDialog} handleCloseDialog={handleClosePDFDialog} currentUrl={currentFileUrl}/>}

    </>
  );
}

export default EmployeeTableComponent;