import React from 'react';
import Step from '@material-ui/core/Step';
import Stepper from '@material-ui/core/Stepper';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';

const CardInvestContent = (props) => {

    const { classesCardInvest, ongoing, sm, partner, round } = props;
  
    const getStepIndex = (partner, round) => {
      let index = 0;
      index += partner?.partnerInfo?.SHARES?.[round]?.docs || 0;
      index += partner?.partnerInfo?.SHARES?.[round]?.funds || 0;
      return index;
    };
    
    return(
      <>
        {ongoing && (
          <CardContent style={{padding: sm ? 1 : 3}} align='center'>
            <div style={{marginTop: -10}}/>
            <div>
            <Stepper activeStep={getStepIndex(partner, round)} className={classesCardInvest.stepper}>
                <Step >
                  <StepLabel>                    
                    <Typography variant="caption" color="textSecondary">Intent</Typography>
                  </StepLabel>
                </Step>
                <Step >
                  <StepLabel>
                    <Typography variant="caption" color="textSecondary">Docs signed</Typography>
                  </StepLabel>
                </Step>
                <Step >
                  <StepLabel>
                    <Typography variant="caption" color="textSecondary">Funds received</Typography>
                  </StepLabel>
                </Step>                      
              </Stepper>
            </div>
        </CardContent>
        )}
      </>
    );
}

export default CardInvestContent;