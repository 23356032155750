import React from 'react';
import CalendarView from './calendarView';
import DaysOffTable from './daysOffTable';
import { Button, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

import { useStyles } from './styles';

const PlannedHolidayDialog = (props) => {

    const classes = useStyles();
    const [highlightedHoliday, setHighlightedHoliday] = React.useState(null);
    const { width, employee, apiCalendar, availableDays, saveUserInfos, openPlannedHolidayDialog, setOpenPlannedHolidayDialog } = props;

    const highlightHoliday = (key) => setHighlightedHoliday(key);

    const deleteEventAndSave = (event, employee) => {
        delete employee.contract.daysOff.holidays[event];
        saveUserInfos(employee);
    }

    const handleDelete = (selected, userWantsTodeleteEventWithoutDeletingFromGoogleCalendar) => {
        const _employee = {...employee};
        if (userWantsTodeleteEventWithoutDeletingFromGoogleCalendar) {
            selected.forEach(key => deleteEventAndSave(key, _employee));
        } else {
            selected.forEach(key => {
                if (_employee.contract.daysOff?.holidays?.[key]?.calendarID?.length > 0) {
                    apiCalendar.deleteEvent(_employee.contract.daysOff?.holidays?.[key]?.calendarID, _employee.mail)
                    .then(() => deleteEventAndSave(key, _employee))
                    .catch(err => {
                        alert(`I could not find the event in your Google calendar. Please delete it manually.`);
                        deleteEventAndSave(key, _employee);
                    });
                } else {
                    deleteEventAndSave(key, _employee);
                }
            });
        }
    }
    
    const now = new Date();
    now.setDate(1);
    const listOfDaysOff = [];
    Object.keys(employee.contract.daysOff?.holidays || {}).forEach(key => {
        const holiday = employee.contract.daysOff.holidays[key];
        (new Date(holiday.to) >= now) && listOfDaysOff.push({key, ...holiday});
    });
    listOfDaysOff.sort((a, b) => {
        if (a.from < b.from) return -1;
        if (a.from > b.from) return 1;
        return 0;
    });


    return (
        <center>
            <Dialog
                fullScreen
                className={classes.dialog}
                open={openPlannedHolidayDialog}
                onClose={() => setOpenPlannedHolidayDialog(false)}
            >
                <DialogTitle>
                    See planned holidays <i>Beta</i>
                    <IconButton aria-label="close" onClick={() => props.setOpenPlannedHolidayDialog(false)} className={classes.closeButton}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={1} direction="column" justifyContent="center" alignItems="stretch" >
                        <Grid item xs={12} align="center">
                            <CalendarView
                                width={width}
                                employee={employee}
                                highlightedHoliday={highlightedHoliday}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <DaysOffTable
                                apiCalendar={apiCalendar}
                                handleDelete={handleDelete}
                                listOfDaysOff={listOfDaysOff}
                                availableDays={availableDays}
                                highlightHoliday={highlightHoliday}
                                />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenPlannedHolidayDialog(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </center>
    );
}

export default PlannedHolidayDialog;
