import React from 'react';
import { Tooltip } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { stringToDate } from '../../../utils';
import { withFirebase } from '../../../Firebase';
import * as ROLES from '../../../../constants/roles';
import * as INFOS from '../../../../constants/infos';

import { useStyles } from './styles';

const ACCESS_DENIED = "Acces denied.";

const roleColors = {
  [ROLES.HR]: '#4caf50',
  [ROLES.ORG]: '#4caf50', // if employee's role is ORG or HR, the border of the avatar is green
  [ROLES.TECH]: '#2196f3', // if employee's role is TECH, the border of the avatar is blue
  [ROLES.SALES]: '#9c27b0', // if employee's role is SALES, the border of the avatar is purple
  [ROLES.SUPPORT]: '#f44336', // if employee's role is SUPPORT, the border of the avatar is red
  [ROLES.MARKETING]: '#ff9800', // if employee's role is MARKETING, the border of the avatar is orange
  default: '#000000'
};

const MemberAvatar = ({ size, member, firebase, beautify }) => {

  const classesProfile = useStyles({size});

  const [urlAvatar, setUrlAvatar] = React.useState('');

  const lastName = React.useMemo(() => member?.civil?.lastName?.substring(0,1)?.toUpperCase() || "", [member]);
  const firstName = React.useMemo(() => member?.civil?.firstName?.substring(0,1)?.toUpperCase() || "", [member]);

  React.useEffect(() => {
    let mounted = true;
    if (mounted && member) {
      if(firebase && member.roles[ROLES.EMPLOYEE] && (member?.contract?.end?.length || 0) === 0) {
        firebase.getProfileImage(`members/${member.uid}/employee/profile`)
        .then(file => (mounted && (file.url !== urlAvatar) && setUrlAvatar((file?.name?.includes(INFOS.PROFILE)) ? file.url : '') ))
        .catch(err => console.log(ACCESS_DENIED));
      } else { setUrlAvatar('') }
    }
    return () => mounted = false;
  }, [urlAvatar, firebase, member]);  
  
  const border = React.useMemo(() => {
    let properties = '3px';
    if (!beautify) return "none";
    if (member?.contract?.start)
      properties += stringToDate(member?.contract?.start) > new Date() ? ' dashed' : ' solid';
    properties += ` ${roleColors[Object.keys(roleColors).find(role => member.roles[role]) || 'default']}`;
    return properties;
  }, [beautify, member]);

  return (
    <Tooltip title={`${member?.civil?.firstName || ""} ${member?.civil?.lastName || ""}`} arrow>
      {urlAvatar !== ''
        ? <Avatar src={urlAvatar} className={classesProfile.size} style={{ border }}/>
        : <Avatar className={classesProfile.size}>{`${firstName}${lastName}`}</Avatar>
      }
    </Tooltip>
  );
}

export default withFirebase(MemberAvatar);
  