import FileBox from '../fileBox';
import React, { Component } from 'react';
import DragAndDrop from '../dragAndDrop';
import FloatingCard from '../floatingCard';
import FileUploader from '../fileUploader';
import { Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import WorkIcon from '@material-ui/icons/Work';
import { Typography } from '@material-ui/core';
import Warning from '@material-ui/icons/Warning';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import { Grid, IconButton } from '@material-ui/core';
import LinearProgressWithLabel from '../progressBar';
import { YELLOW, GRAY } from '../../../../constants/colors';
import { List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';

import { useStyles } from './styles';

const UPLOAD_ERROR = "Error uploading file";
const UPLOAD_SUCCESS = "File(s) uploaded successfully";

const WarningMessage = (props) => {
    const { classes, message } = props;
    return (
        <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center" className={classes.gridWarning}>
            <Grid item>
                <ListItem style={{alignItems: 'center', marginLeft: 'auto'}}>
                    <ListItemIcon><Warning className={classes.warning}/></ListItemIcon>
                    <ListItemText style={{marginLeft: -20}}>
                        <Typography variant="h5" color="textSecondary" component="p" align="center">
                            No files
                        </Typography>
                    </ListItemText>
                </ListItem>
                <ListItem style={{alignItems: 'center', marginLeft: 'auto'}}>
                    <ListItemText style={{marginTop: -15}}>
                        <Typography variant="subtitle2" color="textSecondary" component="p" align="center">
                            <i>{message || ""}</i>
                        </Typography>
                    </ListItemText>
                </ListItem>
            </Grid>
        </Grid>
    );
}

const EmployeeInteractiveFolderView = props => {
    const classes = useStyles(props);
    return <InteractiveFolderViewComponent {...props} classes={classes}/>;
};

class InteractiveFolderViewComponent extends Component
{
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            progress: 0,
            snackMsg: "",
            loading: true,
            snackOpen: false,
            isUploading: false,
            option: this.props.option,
        }
        this.unMounted = false;
        this.classes = props.classes;
    };

    componentWillUnmount = () => this.unMounted = true;
    handleProgress = progress => this.setState( { progress });
    getPath = (uid) => `members/${uid}/employee/${this.state.option}`;
    componentDidMount = () => this.reloadFiles(this.props.employee.uid);
    handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });
    buildFileName = (file, emp) => `${file.name.split('.')[0]}_${this.props.employee.trigram}.${file.name.split('.')[1]}`;
    handleUploadError = error => this.setState({ isUploading: false, snackOpen: true, snackMsg: UPLOAD_ERROR, progress: 0 });
    
    handleUploadSuccess = filename => {
        this.reloadFiles(this.props.employee.uid);
        this.setState({ isUploading: false, snackOpen: true, snackMsg: UPLOAD_SUCCESS, progress: 100 });
    };

    reloadFiles = (employeeID) => {
        this.setState({ files: [] });
        this.props.firebase.getFiles(this.getPath(employeeID), this.setFiles);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.employee.uid !== this.props.employee.uid) {
            this.reloadFiles(this.props.employee.uid);
        }
    }
    
    handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') return;
        this.setState({ snackOpen: false });
    };

    setFiles = (fileInfos) => {
        if (!this.unMounted) {
            this.state.files.push(fileInfos)
            this.setState( {files: this.state.files} );
        }
    }

    handleDrop = async (files) => {
        let success = 0;
        this.setState({ isUploading: true, progress: 0 })
        const storageRef = this.props.firebase.storage.ref(this.getPath(this.props.employee.uid));
        for (var i = 0; i < files.length; i++) {
          const file = files[i];
          if (!file.name) return
          try {
            await storageRef.child(this.buildFileName(file)).put(file);
            this.handleProgress(files.length > 1 ? (i + 1) * 100 / files.length : 100);
            success++;
          } catch (error) {
            console.log("error", error);
            this.handleUploadError(error);
          }
        }
        (success === files.length) && this.handleUploadSuccess();
    }

    render() {
        const { files, progress, snackMsg, snackOpen, isUploading } = this.state;
        const isCardDisabled = this.props?.disabled || false;

        const UploadButton = () => {
            return (
                <>
                    {!isUploading && (
                        <Tooltip title="Add document" arrow>
                            <span>
                                <FileUploader
                                    multiple={true}
                                    firebase={this.props.firebase}
                                    accept="application/pdf,image/*"
                                    onProgress={this.handleProgress}
                                    onUploadStart={this.handleUploadStart}
                                    onUploadError={this.handleUploadError}
                                    onUploadSuccess={this.handleUploadSuccess}
                                    path={this.getPath(this.props.employee.uid)}
                                    filename={file => this.buildFileName(file, this.props.employee)}
                                >
                                    <IconButton aria-label={`upload-document-${this.state.option}`} component="span">
                                        <AddIcon style={{color: isCardDisabled ?  GRAY : YELLOW }} />
                                    </IconButton>
                                </FileUploader>
                            </span>
                        </Tooltip>)}
                    {isUploading && <LinearProgressWithLabel value={progress} />}
                </>
            );
        };

        return (
            <>
                <FloatingCard
                    title={this.props.title}
                    buttonAction={<UploadButton />}
                    subheader={this.props.subheader}
                    avatar={this.props?.icon || <WorkIcon />}
                >
                    <DragAndDrop handleDrop={this.handleDrop} >
                        <div className={this.classes.dragAndDropDiv}>
                            {(files.length === 0)
                                ? <WarningMessage {...this.props} classes={this.classes} />
                                : <List dense={true}>
                                    {files.map((file, index) => <FileBox key={index} url={file.url} fileName={`${file.name}`} width={this.props.width} />)}
                                </List>
                            }
                        </div>
                    </DragAndDrop>
                </FloatingCard>

                <Snackbar
                    open={snackOpen}
                    message={snackMsg}
                    autoHideDuration={3000}
                    onClose={this.handleCloseSnackBar}
                    anchorOrigin={ {vertical: 'top', horizontal: 'center'} }
                    action={
                        <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleCloseSnackBar}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    }
                />
            </>
        );
    }
}

export default EmployeeInteractiveFolderView;