import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    grid: {
        padding: theme.spacing(2),
        //marginTop: theme.spacing(2),
    },

    gridItemFifo: {
        flexGrow: 1,
        height: '100%',
        backgroundColor: '#fcfcfc',
        borderRadius: theme.spacing(1),
    },

    gridItemTransit: {
        flexGrow: 1,
        height: '100%',
        backgroundColor: '#fcfcfc',
        borderRadius: theme.spacing(1),
    },
  
}));
  