import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({

    disclaimerText: {
        textAlign: "center",
        paddingTop: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
          fontSize: '0.7rem'
        },
        [theme.breakpoints.up('md')]: {
          fontSize: '0.8vw'
        },  
    },    

}));
