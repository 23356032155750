import React from 'react';
import * as UTILS from '../../utils';
import { useStyles } from './styles';
import PartnerRow from './partnerRow';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import CSSBaseline from '@material-ui/core/CssBaseline';
import TableContainer from '@material-ui/core/TableContainer';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import PDFDialog from '../../utils/GenericComponents/pdfDialog';
import TablePagination from '@material-ui/core/TablePagination';
import FloatingFilesMenu  from '../../utils/GenericComponents/floatingFilesMenus';

const ASC = 'asc';
const OWN = 'own';
const PNL = 'pnl';
const DESC = 'desc';
const NAME = 'lastName';
const ROUNDS = 'rounds';
const MULTIPLE = 'multiple';
const INVESTMENT = 'totalInvestment';

const descendingComparator = (a, b, orderBy) => {
  if(orderBy === ROUNDS) {
    if (Object.keys(a).length < Object.keys(b).length) return -1;
    if (Object.keys(a).length > Object.keys(b).length) return 1;
  }else if(orderBy === MULTIPLE) {
    const aMult = a.totalInvestment ? Math.round((a.pnl + a.totalInvestment) / a.totalInvestment) : 0;
    const bMult = b.totalInvestment ? Math.round((b.pnl + b.totalInvestment) / b.totalInvestment) : 0;
    if (aMult < bMult) return -1;
    if (aMult > bMult) return 1;
  } else {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
  }
  return 0;
}

const getComparator = (order, orderBy) => {
  return order === DESC
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(partners, orderBy, comparator) {
  const stabilizedThis = partners.map((partner, index) => [partner, index]);

  stabilizedThis.sort((a, b) => {
    let order;
    if (orderBy === NAME) {
      order = comparator(a[0].civil, b[0].civil);
    } else if (orderBy === ROUNDS) {
      order = comparator(a[0].partnerInfo.SHARES, b[0].partnerInfo.SHARES);
    } else {
      order = comparator(a[0], b[0]);
    }
    return (order !== 0) ? order : a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: NAME, numeric: false, disablePadding: true, label: 'Name' },
  { id: OWN, numeric: true, disablePadding: true, label: 'own (%)' },
  { id: ROUNDS, numeric: true, disablePadding: false, label: 'Rounds' },
  { id: INVESTMENT, numeric: true, disablePadding: false, label: 'Completed Investment' },
  { id: MULTIPLE, numeric: false, disablePadding: false, label: 'multiple' },
  { id: PNL, numeric: true, disablePadding: false, label: 'PnL (€ | %)' },
];

const TableHeader = (props) => {

  const { classes, order, orderBy, createSortHandler } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell />
        {headCells.map((headCell) => (              
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              onClick={createSortHandler(headCell.id)}
              direction={orderBy === headCell.id ? order : ASC}
            >
              <strong>{headCell.label}</strong>
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === DESC ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}            
      </TableRow>
    </TableHead>
  );
}

const getTotalShares = (steeringObj) => Object.values(steeringObj.SHARES).reduce((total, round) => (round.funded) ? total + round.quantity : total, 0);

const PartnerTableComponent = (props) => {

  const { partners, rounds, steeringObj, firebase, width, onSaveUserWithPartnerInfos } = props;
  const classes = useStyles();

  const sm = (width === 'sm') || (width === 'xs');

  const [page, setPage] = React.useState(0);
  const [files, setFiles] = React.useState([]);
  const [order, setOrder] = React.useState(ASC);
  const [orderBy, setOrderBy] = React.useState(NAME);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [snackMsg, setSnackMsg] = React.useState('');
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [currentFileUrl, setCurrentFileUrl] = React.useState('');
  const [openPDFDialog, setOpenPDFDialog] = React.useState(false);
  const [currentFileName, setCurrentFileName] = React.useState('');
  const [openFloatingFilesMenu, setOpenFloatingFilesMenu] = React.useState(false);

  const totalShares = getTotalShares(steeringObj);

  const MemoizedPartnerRow = React.memo(PartnerRow);

  const partnersWithComputedInfos = React.useMemo(() => 
    partners.map(partner => ({
      ...partner,
      pnl: UTILS.computePnl(partner, steeringObj),
      totalInvestment: UTILS.getCompletedInvestments(partner),
      own: (UTILS.getTotalOwnedShares(partner) / totalShares)
    })), 
    [partners, steeringObj, totalShares]
  );  

  const handleRequestSort = React.useCallback((event, property) => {
    const isAsc = orderBy === property && order === ASC;
    setOrder(isAsc ? DESC : ASC);
    setOrderBy(property);
  }, [orderBy, order]);   
  
  const handleClosePDFDialog = () => setOpenPDFDialog(false);
  const handleChangePage = (event, newPage) => setPage(newPage);
  const createSortHandler = (property) => (event) => handleRequestSort(event, property);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const setSnackStatus = (status, msg) => {
    setSnackMsg(msg);
    setSnackOpen(status);
  }

  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') return;
      setSnackOpen(false);
  };

  const handleCloseFileList = () => {
    setAnchorEl(null);
    setOpenFloatingFilesMenu(false);
  };

  const handleDocumentList = (event, files) => {
    setFiles(files);
    setAnchorEl(event?.currentTarget);
    setOpenFloatingFilesMenu(true);
  };

  const handleMenuItemClick = (event, index) => {
    setAnchorEl(null);
    setOpenPDFDialog(true);
    setOpenFloatingFilesMenu(false);
    setCurrentFileUrl(files[index]?.url);
    setCurrentFileName(files[index]?.name);
  };

  return (
    <>
      <CSSBaseline />
      <TableContainer className={classes.container} component={Paper}>

        <Table stickyHeader aria-label="collapsible table" size={sm ? "small" : "medium"}>

          <TableHeader
            order={order}
            classes={classes}
            orderBy={orderBy}
            createSortHandler={createSortHandler}
          />

          <TableBody>
            {stableSort(partnersWithComputedInfos, orderBy, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((partner) => (
              <MemoizedPartnerRow
                width={width}
                rounds={rounds}
                partner={partner}
                firebase={firebase}
                key={partner.trigram}
                steeringObj={steeringObj}
                setSnackStatus={setSnackStatus}
                handleDocumentList={handleDocumentList}
                onSaveUserWithPartnerInfos={onSaveUserWithPartnerInfos}
              />
            ))}
          </TableBody>

        </Table>

      </TableContainer>

      <TablePagination
        page={page}
        component="div"
        count={partners.length}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[10, 25, 100]}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Snackbar
        open={snackOpen}
        message={snackMsg}
        autoHideDuration={3000}
        onClose={handleCloseSnackBar}
        anchorOrigin={ {vertical: 'top', horizontal: 'center'} }
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackBar}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />

      <FloatingFilesMenu
        files={files}
        anchorEl={anchorEl}
        open={openFloatingFilesMenu}
        handleCloseFileList={handleCloseFileList}
        handleMenuItemClick={handleMenuItemClick}
      />

      {(currentFileUrl && currentFileUrl !== '') &&
        <PDFDialog
          open={openPDFDialog}
          fileName={currentFileName}
          currentUrl={currentFileUrl}
          handleCloseDialog={handleClosePDFDialog}
        />
      }
    </>
  );
}

export default PartnerTableComponent;
