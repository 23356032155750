import { makeStyles } from '@material-ui/core/styles';

export const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
    customBadge: (props) => ({
      backgroundColor: props.connected ? "green" : "red",
      color: "white"
    })
});
