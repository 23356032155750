import { makeStyles } from '@material-ui/core/styles';

const PATH = "https://firebasestorage.googleapis.com/v0/b/react-compta-automation.appspot.com/o/images";

const HEADER_INVEST = `url(${PATH}%2Finvest_stepper_step1.png?alt=media&token=${process.env.HEADER_INVEST})`;
const HEADER_AGREEMENT = `url(${PATH}%2Finvest_stepper_step2.png?alt=media&token=${process.env.HEADER_AGREEMENT})`;
const HEADER_THANK_YOU = `url(${PATH}%2Finvest_stepper_step3.png?alt=media&token=${process.env.HEADER_THANK_YOU})`;

export const useStyles = makeStyles((theme) => ({
          
    stepperButton: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    
    stepperActionsContainer: {
        marginBottom: theme.spacing(2),
    },
    
    stepperResetContainer: {
        padding: theme.spacing(3),
    },
    
    headerInvest: {
        marginRight: 10,
        backgroundSize: 'contain',
        backgroundPosition: 'right',
        backgroundRepeat: 'no-repeat',
        backgroundImage: HEADER_INVEST,
        
    },
  
    contentInvest: {
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
    },
  
    headerAgreement: {
        marginTop: 0,
        marginBottom: 10,
        marginRight: 30,
        backgroundSize: 'contain',
        backgroundPosition: 'right',
        backgroundRepeat: 'no-repeat',
        backgroundImage: HEADER_AGREEMENT,
        
    },
  
    contentAgreement: {
        width: '100%',
        height: '100%',
        backgroundColor: `rgba(255, 255, 255, 0.85)`,
    },
  
    headerThankyou: {
        marginTop: 0,
        marginBottom: 0,
        marginRight: 10,
        backgroundSize: 'contain',
        backgroundPosition: 'right',
        backgroundRepeat: 'no-repeat',
        backgroundImage: HEADER_THANK_YOU,
    },
  
    contentThankyou: {
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.85)',
    }
  
}));
