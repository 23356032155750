import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const AddUserDialog = (props) => {

    const [email, setEmail] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [firstName, setFirstName] = React.useState("");

    const updateEmailValue = (event) => setEmail(event.target.value);
    const updateLastNameValue = (event) => setLastName(event.target.value);
    const updateFirstNameValue = (event) => setFirstName(event.target.value);

    const { addUserInList, openAddUserDialog, handleCloseAddUserDialog } = props;

    const error = email.length > 0 ? !email.match(/^\S+@\S+\.\S+$/) : false;
    const errorLastName = lastName.length > 0 ? !lastName.match(/[a-z]/gi) : false;
    const errorFirstName = firstName.length > 0 ? !firstName.match(/[a-z]/gi) : false;

    const conditionSubmit = email.length === 0 || firstName.length === 0 || lastName.length === 0 || error || errorFirstName || errorLastName;

    return (
        <Dialog open={openAddUserDialog} onClose={handleCloseAddUserDialog} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Add user</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    To allow a new user to this dashboard, please enter his/her email address here.
                </DialogContentText>
                <TextField
                    autoFocus
                    fullWidth
                    type="text"
                    id="firstname"
                    margin="dense"
                    label="First Name"
                    error={errorFirstName}
                    onChange={updateFirstNameValue}
                />
                <TextField
                    fullWidth
                    type="text"
                    id="lastname"
                    margin="dense"
                    label="Last Name"
                    error={errorLastName}
                    onChange={updateLastNameValue}
                />
                <TextField
                    id="name"
                    fullWidth
                    type="email"
                    error={error}
                    margin="dense"
                    label="Email Address"
                    onChange={updateEmailValue}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseAddUserDialog} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => addUserInList(firstName, lastName, email)} color="primary" disabled={conditionSubmit}>
                    Add user
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AddUserDialog;