import { makeStyles } from '@material-ui/core/styles';
import { VERY_LIGHT_GRAY } from '../../../../../../../../constants/colors';

export const useStyles = makeStyles((theme) => ({
    root: {
        margin: 0,
        display: 'flex',
        flexWrap: 'wrap',
        listStyle: 'none',
        justifyContent: 'left',
        padding: theme.spacing(0.5),
      },
    chip: {
        "&:hover": {
            color: VERY_LIGHT_GRAY,
        },
        margin: theme.spacing(0.5),
    },
}));
