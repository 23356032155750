import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';

export const AntSwitch = withStyles((theme) => ({
    root: {
        width: 28,
        height: 16,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        padding: 2,
        color: theme.palette.grey[500],
        '&$checked': {
            transform: 'translateX(12px)',
            color: theme.palette.common.white,
        '& + $track': {
            opacity: 1,
            backgroundColor: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
        width: 12,
        height: 12,
        boxShadow: 'none',
    },
    track: {
        opacity: 1,
        borderRadius: 16 / 2,
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.palette.grey[500]}`,
    },
    checked: {},
}))(Switch);
