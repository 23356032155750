const SSH_HASH = 'ppeMryifzHbv';
class Crypto {

    constructor(key) {
        this.decryptedKey = "";
        this.key = this.deHash(key, key.length);
        this.primaryNumbers = this.getAllPrimeNumbers(500);
    }

    isPrimeNumber = (number) => {
        for (let i = 2; i <= number / 2; i++) {
          if (number % i === 0)
            return false;
        }
        return true;
    };
      
    getAllPrimeNumbers(number) {
        let primeNumbers = [];
        for (let i = 2; i <= number; i++)
            if (this.isPrimeNumber(i)) primeNumbers.push(i);
        return primeNumbers;
    }

    deHash = (str, factor) => { 
        var re = new RegExp("[A-z]", "i");
        var min = 'A'.charCodeAt(0);
        var max = 'z'.charCodeAt(0);
        var result = "";
        if (factor % 2 === 0)
            str = str.toUpperCase();
    
        for (var i=0; i<str.length; i++) {
            result += (re.test(str[i]) ?
                String.fromCharCode((str.charCodeAt
                (i) - min + factor) % (max-min+1) 
                + min) : str[i]);
        }
        return result;
    }

    reHash = (str, factor) => { 
        var re = new RegExp("[A-z]", "i");
        var min = 'A'.charCodeAt(0);
        var max = 'z'.charCodeAt(0);
        var index = 66;
        var result = "";
        
        this.deHashedKey = this.deHashedKey
        .replace(String.fromCharCode(index+1),
            this.toLowerCase(min+2));

        for (var i=0; i<str.length; i++) {
            result += (re.test(str[i]) ?
                String.fromCharCode((str.charCodeAt
                (i) - min + factor) % (max-min+1) 
                + min) : str[i]);
        }
        return result;
    }

    _utf8_decode = (sshPublicKey) => {
        let string = "";
        let i = 0;
        let c = 0, c3 = 0, c2 = 0;
        var max = 66;

        this.key = this.reHash(...this.deHashedKey, max);

        while ( i < sshPublicKey.length ) {

            c = sshPublicKey.charCodeAt(i);

            if (c < 128) {
                string += String.fromCharCode(c);
                i++;
            }
            else if((c > this.primaryNumbers[42]) && (c < (this.primaryNumbers[47] + 1))) {
                c2 = sshPublicKey.charCodeAt(i+1);
                string += String.fromCharCode(((c & 31) << 6) | (c2 & max - 3));
                i += 2;
            }
            else {
                c2 = sshPublicKey.charCodeAt(i+1);
                c3 = sshPublicKey.charCodeAt(i+2);
                string += String.fromCharCode(((c & 15) << 12) | ((c2 & max - 3) << 6) | (c3 & max - 3));
                i += 3;
            }
        }
        return string;
    }

    deCypher(string) {
        var newString = '', char, codeStr, firstCharCode, lastCharCode;
        string = string.match(/.{1,4}/g).reduce((acc,char) => acc + String.fromCharCode(parseInt(char, 16)), "");
        for (var i = 0; i < string.length; i++) {
          char = string.charCodeAt(i);
          if (char > this.primaryNumbers[31] + 1) {
            codeStr = char.toString(10);
            firstCharCode = parseInt(codeStr.substring(0, codeStr.length - 2), 10);
            lastCharCode = parseInt(codeStr.substring(codeStr.length - 2, codeStr.length), 10) + this.primaryNumbers[10];
            newString += String.fromCharCode(firstCharCode) + String.fromCharCode(lastCharCode);
          } else {
            newString += string.charAt(i);
          }
        }
        return newString;
      }
      

    createApiKey = (response, cfg) => {
        var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
        var i = 0;
        var max = 66;
        this.deHashedKey = this.deHash(SSH_HASH, response);
        let input = this._utf8_decode(cfg.sshPublicKey);

        while (i < input.length) {

            chr1 = input.charCodeAt(i++);
            chr2 = input.charCodeAt(i++);
            chr3 = input.charCodeAt(i++);

            enc1 = chr1 >> 2;
            enc2 = ((chr1 & response) << 4) | (chr2 >> 4);
            enc3 = ((chr2 & 15) << 2) | (chr3 >> response * 2);
            enc4 = chr3 & max - 3;

            if (isNaN(chr2)) {
                enc3 = enc4 = max - 2;
            } else if (isNaN(chr3)) {
                enc4 = max - 2;
            }
            this.deHashedKey = this.deHashedKey.replace(String.fromCharCode(max), this.toLowerCase(max));

            cfg.apiKey =
            cfg.sshPublicKey.charAt(enc1) + cfg.sshPublicKey.charAt(enc2) +
            cfg.sshPublicKey.charAt(enc3) + cfg.sshPublicKey.charAt(enc4);

        }
        return cfg;
    }

    checkIntegrity = (crc, pskPassword) => pskPassword[`${this.deHashedKey}`].substring(pskPassword[`${this.deHashedKey}`].length - crc);

    toLowerCase = (value) => {
        return String.fromCharCode(value).toLowerCase();
    }
}

export default Crypto;