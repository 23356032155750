import { boxShadow } from '../../../utils';
import { makeStyles } from '@material-ui/core/styles';
import * as COLORS from '../../../../constants/colors';

export const useStyles = makeStyles((theme) => ({

    spacer: {
        flexGrow: 1,
    },

    expandOpen: {
        transform: 'rotate(180deg)',
    },
    
    cardHeader: {
        height: '70px'
    },

    avatar: {
        fontSize: '1rem',
        backgroundColor: COLORS.PURPLE,
    },

    card: {
        height: '100%',
        boxShadow: 'none',
        "&:hover": {
            backgroundColor: COLORS.VERY_LIGHT_GRAY3
        },
    },

    media: props => ({
        height: 0,
        borderRadius: '6px',
        paddingTop: `${props.ratio}%`,
    }),

    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },    

    cardActions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    
    refDiv: {
        height: '100%',
        display: 'flex',
        overflow: 'hidden',
        borderRadius: '5px',
        boxShadow: boxShadow,
        flexDirection: 'column',
    },

    videoContent: {
        padding: '0px',
        display: 'flex',
        flex: '1 0 auto',
        borderRadius: '6px',
        justifyContent: 'center',
        border: `2px solid black`,
        [theme.breakpoints.up('xs')]: {
            height: '50%',
        },
        [theme.breakpoints.up('sm')]: {
            height: '53%',
        },
        [theme.breakpoints.up('md')]: {
            height: '47%',
        },
        [theme.breakpoints.up('lg')]: {
            height: '47%',
        },
        [theme.breakpoints.up('xl')]: {
            height: '64%',
        },
    },

}));
  