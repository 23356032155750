import React from 'react';
import { Grid } from '@material-ui/core';
import FileCard from '../../utils/GenericComponents/filesCard';

const ChartersAndPoliciesComponent = (props) => {

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <FileCard
                    {...props}
                    tags={false}
                    path={"policies"}
                    title={"Charters & Policies"}
                    description="Will appear in employee's area"
                />
            </Grid>
        </Grid>
    );
}

export default ChartersAndPoliciesComponent;
