import React from 'react';
import Logo from '../../Logo';
import * as UTILS from '../../utils';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import * as COLORS from '../../../constants/colors';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { styles, DialogContent, DialogActions, DialogTitle } from './styles';

const testIfLink = (content) => (content.includes('https://') || content.includes('http://'));

const enrichWithLink = (content) => {
  const urlStart = content.indexOf('http');
  const urlEnd = (content.indexOf(" ", urlStart + 5) === -1) ? content.length : content.indexOf(" ", urlStart + 5);
  const url = content.substr(urlStart, urlEnd - urlStart);
  content = content.replace(url, `|£|${url}|£|`);
  return (
    <>
      {  content.split('|£|').map((element, index) => element.includes('http')
        ? <a key={index} href={element} target='_blank' rel='noopener noreferrer'>{element}</a>
        : <span key={index}>{element}</span>
      )}
    </>
  );
}

const NotificationDialog = withStyles(styles)((props) => {

  const { classes, openDialog, handleCloseDialog, notifications, markAsRead } = props;

  const printDate = (notif) => {
    return (
      <Typography style={{fontSize: 9, display: 'inline-block', paddingLeft: 5, color: COLORS.GRAY2}} gutterBottom >
        ({UTILS.dateToPrettyString(new Date(parseInt(notif.key.substr(notif.key.indexOf('f') + 1))))})
      </Typography>
    );
  }

  return (
    <div>
      <Dialog onClose={handleCloseDialog} aria-labelledby="customized-dialog-title" open={openDialog && notifications.length > 0} style={{maxHeight: '90vh'}}>
        
        <DialogTitle id="customized-dialog-title" onClose={handleCloseDialog}>
          <Grid container spacing={1} direction="row" justifyContent="flex-start">
            <Grid item>
              <Logo name={"crowdsec_yellow"} height={30} firebase={props.firebase}/>
            </Grid>
            <Grid item>
              &nbsp;Notifications
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent dividers>
          <div style={{overflowY: 'true'}}>
          {notifications.map(notif => {

            if ('subject' in notif) notif.title = notif.subject;
            
            return (
              <div key={notif.key} style={{backgroundColor: !notif.read ? COLORS.VERY_LIGHT_PURPLE3 : COLORS.WHITE}}>
                <div className={classes.notification}>
                  <Typography variant='subtitle2' gutterBottom style={{display: 'inline-block'}}><b>{notif.title}</b></Typography>
                    {printDate(notif)}
                  <Typography variant='body2'>
                    {testIfLink(notif.content) ? enrichWithLink(notif.content) : notif.content }
                  </Typography>
                  <div align="right">
                      <Button onClick={(event) => markAsRead(event, notif.key, notif.read)}>
                          <Typography style={{fontSize: 9}} gutterBottom>mark as {!notif.read ? "read" : "unread"}</Typography>
                      </Button>
                  </div>
                </div>
              </div>
            );
          })}    
          </div>
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={handleCloseDialog} color="primary">
            close
          </Button>
        </DialogActions>

      </Dialog>
    </div>
  );
});


export default NotificationDialog;