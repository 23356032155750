import React from 'react';
import { Tooltip } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import withWidth from '@material-ui/core/withWidth';
import Typography from '@material-ui/core/Typography';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import { useCustomListItemStyles } from './styles';

const CustomIcon = ({headerIcon, title}) => {
    
  return (
    <>
      {headerIcon}
      <Typography style={{fontSize: 7}}>{title}</Typography>
    </>
  );
}

const CustomListItem = props => {
      
  const { headerIcon, title, nestable, nested, selectedIndex, index, handleListItemClick, disabled } = props;

  const small = (props.width === 'sm' ) || (props.width === 'xs' )
  
  const classes = useCustomListItemStyles({nestable, nested, small});

  return (
    <ListItem
      button
      disabled={disabled}
      className={classes.listItem}
      selected={selectedIndex === index}
      onClick={(event) => handleListItemClick(event, index)}
    >
      {!small && headerIcon && <ListItemIcon >{headerIcon}</ListItemIcon> }
      {!small && <ListItemText primary={title} style={{marginLeft: -15}}/>}
      {small && (
        <Tooltip title={title} arrow placement='right'>
          {(nestable !== undefined)
          ? <ListItemText primary={nestable ? <ExpandLess /> : <ExpandMore />} className={classes.listItemText}/>
          : <ListItemText primary={<CustomIcon headerIcon={headerIcon} title={title}/>} className={classes.listItemText}/>
          }
        </Tooltip>
      )}
      {!small && ((nestable === undefined) ? null : nestable ? <ExpandLess /> : <ExpandMore />)}
    </ListItem>
  );
}

export default withWidth()(CustomListItem);
  
  
  