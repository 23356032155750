import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Select from '@material-ui/core/Select';
import React, { useRef, useState } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { computeRatioFromStartDate } from '../../../../utils';
import { Grid, TextField, Typography } from '@material-ui/core';

import { useStyles } from './styles';

const SALARY_INCREASE = {
    "0.00%": 0.00,
    "1.00%": 0.01,
    "2.00%": 0.02,
    "3.00%": 0.03,
    "4.00%": 0.04,
    "5.00%": 0.05,
};
  
const SalaryIncreaseDialog = (props) => {
    const classes = useStyles();
    const [hasError, setHasError] = useState(false);
    const ratio = computeRatioFromStartDate(props.employee);
    const {open, employee, handleSave, handleClose} = props;
    const [hasSelectFocus, setHasSelectFocus] = useState(false);
    const customPercent = useRef(employee.contract?.salaryIncrease || null);
    const lastSelectedPercent = useRef(employee.contract?.salaryIncrease || 0.01);
    const [salaryIncreasePercent, setSalaryIncreasePercent] = useState(lastSelectedPercent.current);
    const [salaryIncrease, setSalaryIncrease] = useState(Math.round(lastSelectedPercent.current * employee.contract?.salary * ratio));

    const onSelectFocus = (event) => setHasSelectFocus(true);

    const onTextfieldFocus = (event) => {
        event.target.select();
        setHasSelectFocus(false);
    };

    const handleChange = (event) => {
        setSalaryIncreasePercent(event.target.value);
        setSalaryIncrease(Math.round(event.target.value * employee.contract.salary * ratio));
        lastSelectedPercent.current = event.target.value;
        setHasError(event.target.value < 0);
    };

    const handleTextFieldValueChanged = (event) => {
        customPercent.current = 0;
        const value = event?.target?.value || 0;
        setHasError(parseInt(value) < 0);
        if (value) {
            const percent = (value / employee.contract.salary);
            customPercent.current = percent;
            lastSelectedPercent.current = percent;
        }
        setSalaryIncreasePercent(customPercent.current);
        setSalaryIncrease(value);
    };

    return (
        <Dialog open={open} onClose={handleClose}>
        
        <DialogTitle>
            <Typography variant="h5" color="primary" component='div'>
                {`Helper for ${employee.civil.firstName}'s salary increase`}
            </Typography>
        </DialogTitle>

        <DialogContent>
            <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
                <Grid item xs={12} align="center">
                    <Typography variant="h6" className={classes.currentSalary} component='div'>
                        Current gross salary: <strong>{parseInt(employee.contract.salary).toLocaleString()} €</strong>
                    </Typography>
                </Grid>
                <Grid item xs={12} align="center">
                    <form className={classes.container}>
                        <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="center" className={classes.grid}>
                            <Grid item xs={5} align="center">
                                <FormControl className={classes.formControl}>
                                    <InputLabel htmlFor="salary-increase-dialog">Increase (%)</InputLabel>
                                    <Select
                                        error={hasError}
                                        onFocus={onSelectFocus}
                                        onChange={handleChange}
                                        value={salaryIncreasePercent}
                                        id="salary-increase-dialog-select"
                                        labelId="salary-increase-dialog-select-label"
                                    >
                                        {Object.keys(SALARY_INCREASE).map((key, index) => (
                                            <MenuItem key={index} value={SALARY_INCREASE[key]}>{key}</MenuItem>
                                        ))}
                                        {customPercent.current && <MenuItem value={customPercent.current}><strong>{`${(customPercent.current * 100).toFixed(2)}%`}</strong> <em>(custom)</em></MenuItem>}
                                        </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={7} align="center">
                                <TextField
                                    type="number"
                                    error={hasError}
                                    label="Increase (value)"
                                    onFocus={onTextfieldFocus}
                                    id="salary-increase-textfield"
                                    onChange={handleTextFieldValueChanged}
                                    value={hasSelectFocus ? Math.round(employee?.contract?.salary * salaryIncreasePercent * ratio) : salaryIncrease}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
                <Grid item xs={12} align="center">
                    <Typography variant="h6" className={classes.newSalary} component='div'>
                        New gross salary: <strong>{(parseInt(employee.contract.salary) + parseInt(salaryIncrease)).toLocaleString()} €</strong>
                    </Typography>
                </Grid>
            </Grid>
        </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={e => handleSave(lastSelectedPercent.current)} color="primary" disabled={hasError} >
                    Save
                </Button>
            </DialogActions>

        </Dialog>
    );
}

export default SalaryIncreaseDialog;