import React from 'react';
import * as UTILS from '../../../utils';
import { useRowStyles } from './styles';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import { Tooltip } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import Collapse from '@material-ui/core/Collapse';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import HistoryEmployeeRow from './historyEmployeeRow';
import Typography from '@material-ui/core/Typography';
import * as COLORS from '../../../../constants/colors';
import CardTravelIcon from '@material-ui/icons/CardTravel';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import MemberAvatar from '../../../utils/GenericComponents/memberAvatar';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';


const PartnerInfo = ({ employee }) => {
  if (!employee.partnerInfo) return <>&nbsp;</>;

  const esops = UTILS.getTotalOwnedEsops(employee);
  const shares = UTILS.getTotalOwnedShares(employee);

  const renderTooltip = (amount, title, IconComponent) => (
    amount > 0 && (
      <Tooltip title={`${amount.toLocaleString()} ${title}`}>
        <Chip
          size="small"
          align="center"
          variant="outlined"
          label={<IconComponent style={{paddingTop: 4, fontSize: 15, color: COLORS.GRAY2}}/>}
        />
      </Tooltip>
    )
  );

  return (
    <>
      {renderTooltip(shares, 'Shares', CardTravelIcon)}
      {renderTooltip(esops, 'BSPCE', CardGiftcardIcon)}
      &nbsp;
    </>
  );
}
    
const EmployeeRow = (props) => {

  const { width, authUser, employee, firebase, hideAmounts, handleDocumentList } = props;
  
  const classes = useRowStyles({employee});
  const [open, setOpen] = React.useState(false);

  const handleOpen = React.useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);


  return (
    <>
      <TableRow hover className={classes.root}>

        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={handleOpen}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        
        <TableCell align="right" >
          <MemberAvatar member={employee} size={open ? 10 : 4.5}/>
        </TableCell>
        
        <TableCell align="left" >
          <span className={classes.rowText}>{employee.civil.lastName} {employee.civil.firstName}</span>
        </TableCell>
        
        <TableCell align="center">
          <span className={classes.rowText}>{employee.contract.type}&nbsp;</span>
        </TableCell>
        
        <TableCell align="center">
          <span className={classes.rowText}>{employee.contract.role}</span>
        </TableCell>
        
        <TableCell align="center">
          <span className={classes.rowText}>{employee.contract.start}</span>
        </TableCell>
                  
        <TableCell align="right">
          <span className={classes.rowText}>
            <PartnerInfo employee={employee} />
            {hideAmounts ? " XX XXX" : `${employee.contract.salary.toLocaleString()} €`}
          </span>
        </TableCell>
      </TableRow>

      <TableRow style={{backgroundColor: COLORS.VERY_LIGHT_PURPLE3}}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1} >
              <Typography variant="h6" gutterBottom component="div">Details</Typography>
              <Table size="small" aria-label="employee-details" rowkey="id">
                <TableBody>
                  <HistoryEmployeeRow
                    width={width}
                    authUser={authUser}
                    employee={employee}
                    firebase={firebase}
                    setSnackStatus={props.setSnackStatus}
                    handleDocumentList={handleDocumentList}
                  />
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default EmployeeRow;
  