import * as UTILS from '../utils';
import React, { Component } from 'react';
import { withFirebase } from '../Firebase';
import PasswordChangeComponent from './form';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';

const INITIAL_STATE = {
  error: null,
  open: false,
};
class PasswordChangeForm extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.callBackClose = props.callback;
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ open: false });
  };


  onSubmit = passwordOne => {
    this.props.firebase
      .doPasswordUpdate(passwordOne)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.firebase.user(this.props.authUser.uid).update({ newlyCreated: false });
        if (this.callBackClose)
          this.callBackClose();
        UTILS.sendNotification(this.props.firebase, this.props.authUser, "Password changed", "Your password has been successfully changed.");
        this.setState({ open: true });
      })
      .catch(error => {
        this.setState({ error });
      });
  };

  render() {

    const { error, open } = this.state;
    
    return (
      <div>
        <PasswordChangeComponent classes={this.props.classes} onSubmit={this.onSubmit} />

      {error && <p>{error.message}</p>}

      <Snackbar
          anchorOrigin={ { vertical: 'top', horizontal: 'center' } }
          open={open}
          autoHideDuration={3000}
          onClose={this.handleClose}
          message="Password Changed sucessfully."
          action={
            <>
              <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          }
        />

      </div>
    );
  }
}


export default withFirebase(PasswordChangeForm);