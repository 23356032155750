import { divRoot} from '../../utils';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  
  root: {
    ...divRoot({theme, bar: false}),
    overflowY: 'auto',
    padding: theme.spacing(2),
  },
    
}));
  