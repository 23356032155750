import React from "react";
import Grid from "@material-ui/core/Grid";
import PerformanceChart from "./perfChart";
import { Typography } from "@material-ui/core";
import DescriptionIcon from '@material-ui/icons/Description';
import EmployeeInteractiveFolderView from "../../../utils/GenericComponents/employeeInteractiveFolderView";

const LABOR_CODE = "https://www.editions-tissot.fr/code-du-travail/code-du-travail-gratuit.aspx?1=1&codeCategory=PME&codeSpace=MCG";
const ARTICLE_CODE = "https://www.editions-tissot.fr/code-du-travail/article.aspx?idSGML=53a89257-7745-4001-972a-324b82b34af3&codeCategory=PME&codeSpace=MCG&source=gratuit&op=1&chapitre=MCP1L2T2&pageNumber=2&detail=y&section=MCAL.1222-2";

const PersonalPerformance = props => {

    return (
        <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="stretch">
            <Grid item xs={12} sm={12} md={8} lg={8}>
                <PerformanceChart {...props} />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
                <EmployeeInteractiveFolderView
                    {...props}
                    option={"interviews"}
                    icon={<DescriptionIcon />}
                    title={"Personnal interviews"}
                    subheader={"interviews with your manager"}
                />
            </Grid>
            <Grid item xs={12} >
                <div style={{width: '100%', textAlign: 'center'}}>
                    <Typography variant="caption" color="textSecondary">
                        <i>Personal data on employees are only collected for the purpose of assessing their professional suitability (<a href={LABOR_CODE}>Code du travail</a>, art. <a href={ARTICLE_CODE}>L. 1222–2</a>).</i>
                    </Typography>
                </div>
            </Grid>
      </Grid>
    );
};

export default PersonalPerformance;