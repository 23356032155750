import Slider from '@material-ui/core/Slider';
import * as COLORS from '../../../../../../constants/colors';
import { withStyles, makeStyles } from '@material-ui/core/styles';


export const PrettoSlider = withStyles({
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 10,
        borderRadius: 5,
    },
    rail: {
        height: 10,
        borderRadius: 5,
    },
    root: {
        height: 10,
        color: "#f5c96e",
    },
    thumb: {
        width: 24,
        height: 24,
        marginTop: -8,
        marginLeft: -12,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
  })(Slider);

export const useStyles = makeStyles( theme => ( {
    
    root: {
        width: '100%',
    },

    slider: {
        width: '95%',
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
    },

    noedition: {
        flexGrow: 1,
        minHeight: theme.spacing(5),
        paddingLeft: theme.spacing(1),
        backgroundColor: COLORS.WHITE,
        paddingRight: theme.spacing(0.5),
    },
    
    edition: {
        flexGrow: 1,
        '&:hover': {
            backgroundColor: COLORS.VERY_LIGHT_PURPLE3,
        },
        minHeight: theme.spacing(5),
        paddingLeft: theme.spacing(2),
        borderRadius: theme.spacing(1),
        paddingRight: theme.spacing(0.5),
        backgroundColor: COLORS.VERY_LIGHT_PURPLE4,
    },

})); 

