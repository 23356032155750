import React from 'react';
import Box from '@material-ui/core/Box';
import Rating from '@material-ui/lab/Rating';
import { makeStyles } from '@material-ui/core/styles';

const labels = {
  0.5: 'Useless',
  1: 'Useless+',
  1.5: 'Poor',
  2: 'Poor+',
  2.5: 'Ok',
  3: 'Ok+',
  3.5: 'Good',
  4: 'Good+',
  4.5: 'Excellent',
  5: 'Excellent+',
};

const useStyles = makeStyles({
  root: {
    width: 200,
    display: 'flex',
    alignItems: 'center',
  },
});

const NewsRating = (props) => {

    const {currentNews, onChangeCurrentNews, onSaveCurrentNews} = props;

    const classes = useStyles();

    const [hover, setHover] = React.useState(-1);
    const [value, setValue] = React.useState(currentNews.feedback);

    return (
        <div className={classes.root}>
        <Rating
            value={value}
            precision={0.5}
            id={`${currentNews.key}-feedback`}
            name={`${currentNews.key}-feedback`}
            onChange={(event, newValue) => {
                setValue(newValue);
                setHover(-1);
                onChangeCurrentNews(event, currentNews);
                onSaveCurrentNews(event, currentNews);
            }}
            onChangeActive={(event, newHover) => setHover(newHover)}
        />
        {value !== null && hover !== - 1 && <Box ml={2}>{labels[hover !== -1 ? hover : value]}</Box>}
    </div>
  );
}

export default NewsRating;
