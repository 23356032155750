import React from 'react';
import Step from '@material-ui/core/Step';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Stepper from '@material-ui/core/Stepper';
import CloseIcon from '@material-ui/icons/Close';
import StepLabel from '@material-ui/core/StepLabel';
import { TextField, Grid } from '@material-ui/core';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import StepContent from '@material-ui/core/StepContent';
import MemberAvatar from '../../utils/GenericComponents/memberAvatar';

import { useStyles} from './styles';

const checkPermission = (partner, round) => (round in partner.partnerInfo.SHARES);

const checkUserIntent = (partner, round, minimum) => {
    if (checkPermission(partner, round))
        return checkIsValidUserIntent(partner, round) || checkMinimumUserIntent(partner, round, minimum);
    return false;
}

const checkIsValidUserIntent = (partner, round) => {
    if (checkPermission(partner, round))
        return isNaN(partner.partnerInfo.SHARES[round].investmentIntent);
    return false;
}

const checkMinimumUserIntent = (partner, round, minimum) => {
    if (checkPermission(partner, round))
        return partner.partnerInfo.SHARES[round].investmentIntent < minimum;
    return false;
}

const InvestStepper = (props) => {

    const classesComponent = useStyles();

    const { classes, round, partner, stepperHidden, subheader, minimum } = props;

    const [activeStep, setActiveStep] = React.useState(0);
    const error = checkUserIntent(partner, round, minimum);
    const steps = ['Select investment amount', 'Agreement to be contacted', 'Thank you for your trust and support'];

    const getButtonLabel = (activeStep) => {
        if (activeStep === steps.length - 1)
          return 'Finish';
        else if (activeStep === 0)
          return 'Confirm';
        else if (activeStep === 1)
          return 'Agree';
        return "";
    }

    const onChange = (event) => {
        partner.partnerInfo.SHARES[round].investmentIntent = (!isNaN(event.target.value)) ? parseInt(event.target.value) : 0;
        props.updateUser(partner);
    };
    
    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
        if (activeStep === 0)
            props.sendFeedbackInvest(round);
        if (activeStep === steps.length - 1) {
            props.saveInvestIntent(round);
            
        }
    };

    const handleBack  = () => setActiveStep(prevActiveStep => prevActiveStep - 1);
    const handleReset = () => setActiveStep(0);
        
    return(
        <div hidden={stepperHidden}>

            { checkPermission(partner, round) && (
            <Stepper activeStep={activeStep} orientation="vertical" >

            {steps.map((label, index) => (
            <Step key={label}>
                <StepLabel>{label}</StepLabel>
                <StepContent>
                    {activeStep === 0 && (
                        <Card className={classes.card}> 
                        <CardHeader
                            avatar={
                                <MemberAvatar member={partner} />
                            }
                            action={
                                <IconButton aria-label="settings" onClick={props.onCloseStepper}>
                                    <CloseIcon />
                                </IconButton>
                            }
                            title="Investment intention"
                            titleTypographyProps={{variant:'h6' }}
                            subheader={subheader}
                        />
                        <CardContent >

                            <div className={classesComponent.headerInvest}>
                                <div className={classesComponent.contentInvest}>
                                    <Grid container spacing={1}>
                                    
                                        <Grid item xs>
                                            <TextField
                                            align="left"
                                            type="number"
                                            label="Amount"
                                            margin="dense"
                                            disabled={false}
                                            variant="outlined"
                                            id="outlined-basic"
                                            onChange={onChange}
                                            inputRef={input => input && input.focus()}
                                            helperText={checkMinimumUserIntent(partner, round, minimum) ? `Minimum ${minimum} €` : ""}
                                            value={checkIsValidUserIntent(partner, round) ? "" : (round in partner.partnerInfo.SHARES) ? partner.partnerInfo.SHARES[round].investmentIntent : ""}
                                            error={checkIsValidUserIntent(partner, round) || checkMinimumUserIntent(partner, round, 0)}
                                            />                            
                                        </Grid>
                                                                
                                    </Grid>              

                                    <Typography align="center" className={classes.disclaimer} variant="body2" color="textSecondary" component="p">
                                        <i>The amount indicated does not commit you.</i>
                                    </Typography>
                                </div>
                            </div>

                        </CardContent>
                    </Card>                
                    )}

                    {activeStep === 1 && (
                    <Card>
                        <CardHeader
                            classes={{
                                title: classes.title,
                                subheader: classes.subheader,
                            }}              
                            titleTypographyProps={{variant:'h6' }}
                            title="Gentlemen's agreement between us"
                            subheader={`You planned to invest ${partner.partnerInfo.SHARES[round].investmentIntent.toLocaleString()} € in our project.`}
                            />
                        <div className={classesComponent.headerAgreement}>
                            <div className={classesComponent.contentAgreement}>
                                <CardContent style={{maxWidth: 400}}>
                                    <Typography variant="body1" style={{textAlign: 'justify'}}>
                                        The amount of {partner.partnerInfo.SHARES[round].investmentIntent.toLocaleString()} € you indicated does not commit you, however it will allow us to contact you at the close of the operation.
                                    </Typography>
                                </CardContent>
                            </div>
                        </div>
                    </Card>
                    )}

                    {activeStep === 2 && (
                    <Card>
                        <div className={classesComponent.headerThankyou}>
                            <div className={classesComponent.contentThankyou}>
                                <CardHeader
                                    title="Thank you"
                                    titleTypographyProps={{variant:'h2' }}
                                    classes={{ subheader: classes.subheader}}              
                                    subheader={`You planned to invest ${partner.partnerInfo.SHARES[round].investmentIntent.toLocaleString()} € in our project.`}
                                />
                                <CardContent style={{maxWidth: 300}}>
                                    <Typography variant="h6" style={{textAlign: 'justify'}}>
                                        Two simple words, but they have our entire team behind them.
                                    </Typography>
                                </CardContent>
                            </div>
                        </div>
                    </Card>
                    )}

                    <div className={classesComponent.stepperActionsContainer}>
                        <div>

                        <Button
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            className={classesComponent.stepperButton}
                        >
                            Back
                        </Button>

                        <Button
                            disabled={error}
                            color="primary"
                            variant="contained"
                            onClick={handleNext}
                            className={classesComponent.stepperButton}
                        >
                            { getButtonLabel(activeStep) }
                        </Button>

                        </div>
                    </div>
                </StepContent>
            </Step>
        ))}
        </Stepper>
        )}

        {activeStep === steps.length && (
            <Paper square elevation={0} className={classesComponent.stepperResetContainer}>
                <Typography>All steps completed - you&apos;re finished.</Typography>
                <Button onClick={handleReset} className={classesComponent.stepperButton}>
                    Modify Amount
                </Button>
            </Paper>
        )}

        </div>

    );
}

export default InvestStepper;