import { makeStyles } from '@material-ui/core/styles';
import { YELLOW, GRAY, GREEN } from '../../../../../../../constants/colors';

export const useStyles = makeStyles(theme => ({
  
  popoverTypo: {
    padding: theme.spacing(2),
  },

  setPrimaryIcon: props => ({
    marginTop: theme.spacing(0.5),
    fontSize: theme.typography.pxToRem(20),
    color: props.contact?.authorized ? GREEN : GRAY,
    cursor: props.contact?.authorized ? 'normal' : 'pointer',
    "&:hover": { color: props.contact?.authorized ? GREEN : YELLOW },
  }),

  descriptionIcon: props => ({
    color: GRAY,
    marginTop: theme.spacing(0.5),
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(22),
    },
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(22),
    },
    [theme.breakpoints.only('md')]: {
      fontSize: theme.typography.pxToRem(15),
    }
  }),

  listItemText: {
    "&:hover": {
      color: YELLOW,
      textDecoration: 'underline',
    },      
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(-3),
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(-3),
    },
    [theme.breakpoints.only('md')]: {
      marginLeft: theme.spacing(-4),
    },
  },
    
  contactBox: {
    paddingLeft: 2,
    paddingRight: 0,
    [theme.breakpoints.down('md')]: {
      height: theme.typography.pxToRem(35),
    },
    [theme.breakpoints.up('md')]: {
      height: theme.typography.pxToRem(35),
    },
    [theme.breakpoints.only('md')]: {
      height: theme.typography.pxToRem(25),
    }
  }
}));
  