import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    
    card: {
        width: '100%',
      },
  
    dashboardIframe: {
      top: 0,
      left: 0,
      border: 0,
      width: '100%',
      height: '100%',
      position: 'absolute',
    },
    
  }));
  