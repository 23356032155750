export const RED = '#A00';
export const GRAY = '#777';
export const GRAY2 = '#999';
export const WHITE = '#FFF';
export const GREEN = '#0A0';
export const PURPLE = '#5145ae';
export const YELLOW = '#FFAD01';
export const ORANGE = '#eead20';
export const LIGHT_GRAY = '#BBB';
export const DARK_RED = '#880000';
export const DARK_GREEN = '#008800';
export const PASTEL_RED = '#eebbbb';
export const LIGHT_GREEN = '#99CC00';
export const DARK_PURPLE = '#5e5f93';
export const LIGHT_PURPLE = '#8488ce';
export const VERY_LIGHT_GRAY = '#EEE';
export const PASTEL_YELLOW = '#f8ce76';
export const VERY_LIGHT_GRAY2 = '#FCFCFC';
export const VERY_LIGHT_GRAY3 = '#FAFAFA';
export const VERY_LIGHT_PURPLE = '#dfe2ec';
export const VERY_LIGHT_PURPLE2 = '#bfc7e9';
export const VERY_LIGHT_PURPLE3 = '#e7e9f3';
export const VERY_LIGHT_PURPLE4 = '#f5f7fa';