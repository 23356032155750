import { makeStyles } from '@material-ui/core/styles';
import { PURPLE, GRAY } from '../../../../../constants/colors';

export const useStyles = makeStyles((theme) => ({

    metrics : {
        color: PURPLE,
        textAlign : 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.1em'
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.2vw',
        },  
    },

    metricsSmall : {
        color: GRAY,
        textAlign : 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.7em'
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '0.7vw',
        },  
    },

}));
