import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

const ConfirmAuthorizationDialog = (props) => {

    const { openConfirmAuthorizationDialog, handleCloseConfirmAuthorizationDialog, handleConfirmAuthorization } = props;

  return (
    <div>
      <Dialog
        open={openConfirmAuthorizationDialog}
        onClose={handleCloseConfirmAuthorizationDialog}
        aria-labelledby="confirm-authorization-dialog-title"
        aria-describedby="confirm-authorization-dialog-description"
      >
        <DialogTitle id="confirm-authorization-dialog-title">{"Are you sure to authorize this user?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-authorization-dialog-description">
            This user will be created in the user database then be able to access this dashboard.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmAuthorizationDialog} color="primary">
            Disagree
          </Button>
          <Button onClick={handleConfirmAuthorization} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ConfirmAuthorizationDialog;