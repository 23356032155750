import TabPanel from '../TabPanel';
import NewsPanel from './newsPanel';
import ListFooter from '../ListFooter';
import React, { Component } from 'react';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
import SoftwarePanel from './softwarePanel';
import Dialog from '@material-ui/core/Dialog';
import MarketingPanel from './marketingPanel';
import ChangeLogPanel from './changeLogPanel';
import { CssBaseline } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import PasswordChangeForm from '../PasswordChange';
import TimelineIcon from '@material-ui/icons/Timeline';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import SecurityIcon from '@material-ui/icons/Security';
import DialogContent from '@material-ui/core/DialogContent';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import DialogContentText from '@material-ui/core/DialogContentText';
import CustomListItem from '../utils/GenericComponents/customListItem';

const MENUS = {
  NEWS: { name: "News", index: 0 },
  SOFTWARE: { name: "Software", index: 1 },
  CHANGELOG: { name: "Changelog", index: 2 },
  MARKETING: { name: "Marketing", index: 3 },
  MARKETING_ACTIONS: { name: "Marketing Actions", index: 4 },
};

const PasswordDialog = ({authUser, classes, closeDialog, isNewUser}) => {
  return (
    <Dialog open={isNewUser(authUser)} aria-labelledby="change-password-form-dialog-title">
      <DialogContent>
        <DialogContentText>
            <span className={classes.passwordDialogText}>Please change your password.</span>
        </DialogContentText>
      </DialogContent>
      <div className={classes.passwordDialogPanel}>
        <center>
            <PasswordChangeForm classes={classes} authUser={authUser} callback={closeDialog}/>
        </center>
      </div>
    </Dialog>
  );
}

class SimpleUserBase extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      expanded : true,
      selectedIndex: 0,
      closeDialog: false,
      onboardingProgress: 0,
      currentNewsToEdit: null,
      authUser: props.authUser,
    }
    this.classes = props.classes;
    this.conditionToAccessMarketing = props.conditionToAccessMarketing;
  }
  
  closeDialog = () => this.setState( { closeDialog: true });
  handleMarketing = () => this.setState({expanded: !this.state.expanded});
  handleListItemClick = (event, index) => this.setState({ selectedIndex : index });
  updateOnBoardingProgress = (progress) => this.setState({onboardingProgress: progress});
  sendToEdit = (currentNews) => this.setState({currentNewsToEdit: currentNews, selectedIndex: MENUS.MARKETING_ACTIONS.index});

  isNewUser = authUser => {
    if (this.state.closeDialog) return false;
    return authUser?.newlyCreated || false;
  }

  render() {

    const { authUser, expanded, selectedIndex, currentNewsToEdit } = this.state;
    
    const sm = (this.props.width === 'sm' );

    return(
      <div>

        <PasswordDialog authUser={authUser} classes={this.classes} closeDialog={this.closeDialog} isNewUser={this.isNewUser}/>

        <div className={this.classes.divRoot}>

          <CssBaseline />

          <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center" style={{backgroundColor: 'transparent',}}>
            <Grid item xs={1} sm={sm ? 1 : 2}>

              <List
                dense={sm}
                component="nav"
                aria-label="simple-user-info-list"
                className={this.classes.listPanel}
              >
                <CustomListItem
                  title={MENUS.NEWS.name}
                  index={MENUS.NEWS.index}
                  selectedIndex={selectedIndex}
                  headerIcon={<FiberNewIcon />}
                  handleListItemClick={this.handleListItemClick}
                />
                <CustomListItem
                  title={MENUS.SOFTWARE.name}
                  index={MENUS.SOFTWARE.index}
                  selectedIndex={selectedIndex}
                  headerIcon={<SecurityIcon />}
                  handleListItemClick={this.handleListItemClick}
                />
                <CustomListItem
                  title={MENUS.CHANGELOG.name}
                  index={MENUS.CHANGELOG.index}
                  selectedIndex={selectedIndex}
                  headerIcon={<TimelineIcon />}
                  handleListItemClick={this.handleListItemClick}
                />

                {this.conditionToAccessMarketing(authUser) &&
                  <>
                    <CustomListItem
                      title={MENUS.MARKETING.name}
                      index={MENUS.MARKETING.index}
                      nestable={expanded}
                      selectedIndex={selectedIndex}
                      headerIcon={<TrackChangesIcon />}
                      handleListItemClick={this.handleMarketing}
                    />
                    <Collapse in={expanded} timeout="auto" unmountOnExit>  
                      <CustomListItem
                        nested={true}
                        selectedIndex={selectedIndex}
                        headerIcon={<SpeakerNotesIcon />}
                        title={MENUS.MARKETING_ACTIONS.name}
                        index={MENUS.MARKETING_ACTIONS.index}
                        handleListItemClick={this.handleListItemClick}
                      />
                    </Collapse>
                  </>
                }

                <ListFooter sm={sm} firebase={this.props.firebase} />
              </List>

            </Grid>
            
            <Grid item xs={11} sm={sm ? 11 : 10}>
              <div className={this.classes.rightPanel}>
                <TabPanel value={selectedIndex} index={MENUS.NEWS.index}>
                  <NewsPanel classes={this.classes} firebase={this.props.firebase} sendToEdit={this.sendToEdit} editable={this.conditionToAccessMarketing(authUser)}/>
                </TabPanel>

                <TabPanel value={selectedIndex} index={MENUS.SOFTWARE.index}>
                  <SoftwarePanel classes={this.classes} firebase={this.props.firebase} />
                </TabPanel>                    

                <TabPanel value={selectedIndex} index={MENUS.CHANGELOG.index}>
                  <ChangeLogPanel classes={this.classes} firebase={this.props.firebase} />
                </TabPanel>

                <TabPanel value={selectedIndex} index={MENUS.MARKETING_ACTIONS.index}>
                  <MarketingPanel {...this.props} classes={this.classes} firebase={this.props.firebase} currentNewsToEdit={currentNewsToEdit} sendToEdit={this.sendToEdit}/>
                </TabPanel>

              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default SimpleUserBase;