import React from 'react';
import { withFirebase } from '../Firebase';
import AdminPageBase from './AdminPageBase';
import * as ROLES from '../../constants/roles';
import withWidth from '@material-ui/core/withWidth';
import { AuthUserContext, withAuthorization } from '../Session';

import { useStyles } from './styles'; // <-- keep it here because CSS class defines last within the document

const AdminPage = props => {

  const classes = useStyles();

  return(
    <AuthUserContext.Consumer>
      { authUser => <AdminPageComponent {...props} authUser={authUser} classes={classes} /> }
    </AuthUserContext.Consumer>
  );
}

const condition = authUser => authUser && !!authUser.roles[ROLES.ADMIN];

const AdminPageComponent = withFirebase(withAuthorization(condition)(AdminPageBase));

export default withWidth()(AdminPage);