import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import * as CONST from './../../constants';
import { LIGHT_GRAY, YELLOW } from '../../../../../constants/colors';

const FilterPanel = ({onFilterCategoryChange}) => {

    const getInitValues = () => Object.values(CONST.MEDIA_TYPES).map(type => (type === CONST.MEDIA_TYPES.TWEET ? false : true));
  
    const [filters, setFilters] = useState(getInitValues());
  
    const getColor = (index) => (filters[index]) ? YELLOW : LIGHT_GRAY;

    const handleFilterChange = (event, index) => {
      const newFilters = [...filters];
      newFilters[index] = !newFilters[index];
      onFilterCategoryChange(Object.keys(CONST.MEDIA_TYPES).map((type, index) => newFilters[index] ? CONST.MEDIA_TYPES[type] : null));
      setFilters(newFilters);
    };      
  
    return (
      <>
        <Grid container spacing={2} justifyContent="center">
          {Object.keys(CONST.MEDIA_TYPES).map((type, index) => (
            <Grid item key={index}>
              <Chip size="small" label={CONST.MEDIA_TYPES[type]} style={{ backgroundColor: getColor(index) }} onClick={event => handleFilterChange(event, index)}/>
            </Grid>
          ))}
        </Grid>
      </>
    );
  }
  
  export default FilterPanel;