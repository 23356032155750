import { makeStyles } from '@material-ui/core/styles';
import { PURPLE } from '../../../../../constants/colors';

export const useStyles = makeStyles(theme => ({

    icons: {
        fontSize: 45,
    },

    buttonPanel: {
        color: '#fff',
        padding: '5px',
    },

    dialogContent: {
        paddingTop: '10px'
    },

    textZone: {
        fontSize: 17,
        //textJustify: 'center',
    },

    textZoneTitle: {
        fontSize: 23,
        //textJustify: 'center',
    },

    logoPanel: {
        backgroundColor: PURPLE,
        padding: '15px',
        color: '#fff'
    },

    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));
