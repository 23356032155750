import React from 'react';
import Chart from "react-google-charts";
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import CloseIcon from '@material-ui/icons/Close';
import MenuItem from '@material-ui/core/MenuItem';
import PublicIcon from '@material-ui/icons/Public';
import { DialogTitle, Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import { DialogContent, DialogActions } from './styles';
import { useLayoutEffect, useState, useRef } from "react";
import * as COLORS from '../../../../../constants/colors';
import CircularProgress from '@material-ui/core/CircularProgress';
import FloatingCard from '../../../../utils/GenericComponents/floatingCard';
import { Loader } from "@googlemaps/js-api-loader"


export const WorldMap = props => {

    const [region, setRegion] = useState('world');
    const { open, employees, employeeTeamCard, handleCloseDialog } = props;

    const emplacements = {};
    const geoChartData = [];
    geoChartData.push(["City", "Name", { type: 'string', role: 'tooltip' }]);
    employees.forEach(employee => {
        const zip = employee?.civil?.zip;
        const country = employee?.civil?.country?.toLowerCase() || 'none';
        const employeeName = `${employee.civil.firstName} ${employee.civil.lastName}`;
        const city = employee?.civil?.city?.toLowerCase()?.replace(/\b\w/g, l => l.toUpperCase()) || 'none';
        const key = `${zip},${country}`;
        if (emplacements[key]) {
            emplacements[key].employeeName += `, ${employeeName}`; // more than one employee in the same city ...
        } else emplacements[key] = { zip, city, employeeName };
    });
    Object.keys(emplacements).forEach(place => { geoChartData.push([ place, emplacements[place].employeeName, `(${emplacements[place].city})`]) });

    const RegionSelector = () => {
        const handleChange = (event) => {
            event.preventDefault();
            setRegion(event.target.value);
        };
        return (
            <FormControl >
                    <Select
                        value={region}
                        onChange={handleChange}
                        id="alpacas-worldmap-region-selector"
                        labelId="alpacas-worldmap-region-selector-label"
                    >
                        <MenuItem value="world">World</MenuItem>
                        <MenuItem value="150">Europe</MenuItem>
                        <MenuItem value="FR">France</MenuItem>
                        <MenuItem value="015">Nothern Africa</MenuItem>
                        <MenuItem value="019">Americas</MenuItem>
                        <MenuItem value="US">United States</MenuItem>
                        <MenuItem value="142">Asia</MenuItem>
                        <MenuItem value="009">Oceania</MenuItem>
                    </Select>
            </FormControl>            
        );
    };

    return (
      <Dialog fullScreen onClose={handleCloseDialog} aria-labelledby="team-dialog" open={open} className={employeeTeamCard.dialog}>

        <script defer type="text/javascript" src={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places&callback=Function.prototype`}></script>

        <DialogTitle className={employeeTeamCard.dialogTitle}>
            Alpacas worldmap (beta version)
            <IconButton aria-label="close" onClick={handleCloseDialog} className={employeeTeamCard.closeButton}>
                <CloseIcon />
            </IconButton>
        </DialogTitle>
  
        <DialogContent dividers className={employeeTeamCard.dialogContent}>
            <div className={employeeTeamCard.container}>
                <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="stretch">
                        <Grid item xs={12} >
                            <GeoChart geoChartData={geoChartData} region={region} />
                        </Grid>
                </Grid>
            </div>
        </DialogContent>
  
        <DialogActions>
            <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
                <Grid item xs={11} >
                    <div className={employeeTeamCard.dialogChipContainer}>
                        <RegionSelector />
                    </div>
                </Grid>
                <Grid item xs={1} >
                    <Button onClick={handleCloseDialog} color="primary" >
                        Close
                    </Button>
                </Grid>
            </Grid>
        </DialogActions>
      </Dialog>
    );
}

const GeoChart = (props) => {

    let movement_timer = null;
    const RESET_TIMEOUT = 100;
    const targetRef = useRef();
    const { region, geoChartData } = props;
    const [loaded, setLoaded] = useState(false);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    const test_dimensions = () => {
        if (targetRef.current) {
            setDimensions({
                width: targetRef.current.offsetWidth,
                height: targetRef.current.offsetHeight
            });
        }
    };

    const loader = new Loader({
        version: "weekly",
        apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    });
    
    //loader.importLibrary().then(() => console.log("Google Maps API loaded"));
    loader.load().then(async () => {
        console.log("Google Maps API loaded");
        setLoaded(true);
    });

    useLayoutEffect(() => { test_dimensions() }, []);

    window.addEventListener("resize", () => {
        clearInterval(movement_timer);
        movement_timer = setTimeout(test_dimensions, RESET_TIMEOUT);
    });

    const filterRegion = (region) => {
        switch (region) {
            case 'world': return null;
            case '150': return null; // europe
            case 'FR': return 'provinces'; // france
            case '015': return null; // northern africa
            case '019': return null; // americas
            case 'US': return 'provinces'; // united states
            case '142': return null; // asia
            case '009': return null; // oceania
            default: return null; // world
        }
    };

    const filterPeople = (region) => {
        switch (region) {
            case 'world': return geoChartData;
            case '150': return geoChartData; // Europe
            case 'FR': return geoChartData.filter(place => place[0].toUpperCase().indexOf('FRANCE') !== -1 || place[0] === "City"); // France
            case '015': return geoChartData; // Northern Africa
            case '019': return geoChartData; // Americas
            case 'US': return geoChartData.filter(place => place[0].toUpperCase().indexOf('UNITED STATES') !== -1 || place[0] === "City"); // United States
            case '142': return geoChartData; // Asia
            case '009': return geoChartData; // Oceania
            default: return geoChartData; // world
        }
    };

    const chartEvents=[
        {
          eventName : 'select',
          callback  : function(Chart) {
              console.log("Selected ");
          }
        }
    ];

    return(
        <div ref={targetRef} >
        <FloatingCard
            title="Alpacas in the world"
            subheader={`by countries`}
            avatar={<PublicIcon />}
            buttonAction={region.toLowerCase().indexOf('fr') !== -1 ? <CircularProgress size={24}/> : null}
        >
            {loaded ?
            <Chart
                //data={geoChartData}
                data={filterPeople(region)}
                chartType="GeoChart"
                width={ dimensions.width * 2}
                height={ dimensions.height * 5}
                loader={<div>Loading Chart...</div>}
                rootProps={{ 'data-testid': '1' }}
                options={{
                    region,
                    legend: false,
                    markerOpacity: 0.66,
                    displayMode: 'markers',
                    tooltip: { isHtml: true },
                    backgroundColor: '#eaf9ff',
                    defaultColor: COLORS.YELLOW,
                    resolution: filterRegion(region),
                    datalessRegionColor: COLORS.VERY_LIGHT_PURPLE2,
                }}
                //mapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                //chartEvents={chartEvents}
                
            />
            : <div> Loading ... </div>
            }
            </FloatingCard>
        </div>
    );
};


