import React from 'react';
import MediasTags from './media'
import LanguagesTags from './languages';
import * as CONST from '../../../constants';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import TableRow from '@material-ui/core/TableRow';
import BackupIcon from '@material-ui/icons/Backup';
import FormGroup from '@material-ui/core/FormGroup';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Grid, TextField, Tooltip } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DialogContentText from '@material-ui/core/DialogContentText';
import FileUploader from '../../../../../utils/GenericComponents/fileUploader';

import { useStyles, AntSwitch } from './styles';


const HistoryNewsCell = ({sm, children, align}) => {
    return(
      <TableCell align={align}>
        <Typography component={'span'} variant={sm ? "caption" : "body2"} >{children}</Typography>
      </TableCell>
    );
};

const DeleteNewsDialog = (props) => {
    const { openDialog, handleCancel, handleConfirm } = props;
    return(
        <Dialog
            open={openDialog}
            onClose={handleCancel}
            aria-labelledby="alert-dialog-delete-news-title"
            aria-describedby="alert-dialog-delete-news"
        >
            <DialogTitle id="alert-dialog-delete-news-title">{"Really delete news?"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-delete-news">Are you sure to delete this news ?</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="primary">Cancel</Button>
                <Button onClick={handleConfirm} color="primary" autoFocus>Confirm</Button>
            </DialogActions>
        </Dialog>
    );
}
  
const HistoryNewsRow = (props) => {

    const { width, firebase, currentNews, onSaveCurrentNews, onDeleteCurrentNews, onChangeCurrentNews } = props;

    const sm = (width === 'sm') || (width === 'xs');
    const classes = useStyles({ currentNews, sm });
    const [openDialog, setOpenDialog] = React.useState(false);
    const [initialNews, setInitialNews] = React.useState({...props.currentNews});

    const handleUploadSuccess = filename => {
        const fakeEvent = {
            target: {
                value: filename,
                name: CONST.FILENAME_CHANGED,
            },
            preventDefault: () => {}
        }
        onChangeCurrentNews(fakeEvent, currentNews);
        onSaveCurrentNews(fakeEvent, currentNews);
    }

    const buildFileName = (newFile, news) => {
        const oldFileName = news.image;

        if (oldFileName !== CONST.IMAGE) {
            const storageRef = firebase.storage.ref('images');
            const desertRef = storageRef.child(oldFileName);
            desertRef.delete()
            .then(() => console.log(`file ${oldFileName} deleted successfully`))
            .catch(error => console.log(`ERROR deleting <${oldFileName}> : ${JSON.stringify(error)}`));
        }

        return `${news.key.toLowerCase()}_${newFile.name}`;
    }

    const onDelete = () => setOpenDialog(true);
    const handleCancel = () => setOpenDialog(false);
    const checkModification = () => JSON.stringify(initialNews) === JSON.stringify(currentNews);

    const onSubmit = (event) => {
        onSaveCurrentNews(event, currentNews);
        setInitialNews( {...currentNews} );
    };

    const handleConfirm = (event) => {
        onDeleteCurrentNews(event, currentNews);
        setOpenDialog(false);
    }

    return (
      <>
        <DeleteNewsDialog openDialog={openDialog} handleCancel={handleCancel} handleConfirm={handleConfirm} />

        <TableRow >
          
          <HistoryNewsCell sm={sm} align={'left'}>

            <Divider className={classes.divider}/>

                <Grid container spacing={2}>

                    <Grid item xs={12} sm={12} md={2}>
                        <TextField
                            align="left"
                            type='number'
                            margin="dense"
                            fullWidth={true}
                            variant="outlined"
                            name={CONST.AMOUNT}
                            label="Invested money"
                            value={currentNews?.amount || ''}
                            onChange={event => onChangeCurrentNews(event, currentNews)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={10}>
                        <div style={{paddingTop:10}}/>
                        <MediasTags key={currentNews.key} currentNews={currentNews} onChangeCurrentNews={onChangeCurrentNews}/>
                    </Grid>
                
                </Grid>

                <Divider className={classes.divider}/>

                <LanguagesTags key={currentNews.key} currentNews={currentNews} onChangeCurrentNews={onChangeCurrentNews}/>
                
                <Divider className={classes.divider}/>
                
                <Grid container spacing={2} justifyContent="center" alignItems="center" className={classes.pressKitGrid}>

                    <Grid item xs={12} sm={12} md={!currentNews.hidden ? 1 : 2} >
                        <FormGroup row >
                            <FormControlLabel
                                control={
                                    <Switch
                                        size="small"
                                        name={CONST.HIDDEN}
                                        checked={!currentNews.hidden}
                                        onChange={event => onChangeCurrentNews(event, currentNews)}
                                    />}
                                label={currentNews.hidden ? "Show in press Kit" : "Hide"}
                                className={classes.formControlLabel}
                            />
                        </FormGroup>
                    </Grid>

                    {!currentNews.hidden && (
                    <>
                        <Grid item xs={12} sm={12} md={currentNews.type === CONST.IMAGE ? 6 : 7}>
                            <TextField
                                label="Url"
                                align="left"
                                margin="dense"
                                name={CONST.URL}
                                fullWidth={true}
                                variant="outlined"
                                value={currentNews.url}
                                disabled={currentNews.hidden}
                                InputProps={{ classes: { input: classes.urlTextField } }}
                                onChange={event => onChangeCurrentNews(event, currentNews)}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={2}>
                            <TextField
                                align="left"
                                label="Ratio"
                                type='number'
                                margin="dense"
                                fullWidth={true}
                                name={CONST.RATIO}
                                variant="outlined"
                                value={currentNews.ratio}
                                disabled={currentNews.hidden}
                                InputProps={{ classes: { input: classes.urlTextField } }}
                                onChange={event => onChangeCurrentNews(event, currentNews)}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={2} >
                            <Grid component="div" container alignItems="center" spacing={1} justifyContent="center">
                                <Grid item><Typography variant={"caption"}>{CONST.VIDEO}</Typography></Grid>
                                <Grid item>
                                    <AntSwitch
                                        name={CONST.TYPE}
                                        disabled={currentNews.hidden}
                                        checked={currentNews.type === CONST.IMAGE}
                                        onChange={event => onChangeCurrentNews(event, currentNews)}
                                    />
                                </Grid>
                                <Grid item><Typography variant={"caption"}>{CONST.IMAGE}</Typography></Grid>
                            </Grid>
                        </Grid>
                        
                        {currentNews.type === CONST.IMAGE &&
                        <Grid item xs={12} sm={12} md={1} >
                            <Tooltip title="Upload a thumbnail image" arrow>
                                <span>
                                    <FileUploader
                                        path={`images`}
                                        accept="image/*"
                                        multiple={false}
                                        firebase={firebase}
                                        onUploadSuccess={handleUploadSuccess}
                                        filename={file => buildFileName(file, currentNews)}
                                    >
                                        <IconButton aria-label={`upload-image-news`} component="span">
                                            <BackupIcon className={classes.backUpIcon}/>
                                        </IconButton>
                                    </FileUploader>
                                </span>
                            </Tooltip>
                        </Grid>
                        }
                    
                    </>
                    )}

                </Grid>
                
                <Divider className={classes.divider}/>

                <div style={{ float: 'right'}}>
                    <Grid component="div" container alignItems="center" spacing={1}>
                        <Grid item>
                            <Typography variant="caption" color="textSecondary"><i>{`unique ID = {${currentNews.key}}`}</i></Typography>
                        </Grid>
                        <Grid item>
                            <Button size="small" variant="contained" color="secondary" onClick={onDelete}>delete</Button>
                        </Grid>
                        <Grid item>
                            <Button disabled={checkModification()} size="small" variant="contained" color="primary" onClick={onSubmit}>save</Button>
                        </Grid>
                    </Grid>
                </div>

            </HistoryNewsCell>

        </TableRow>
      </>
    );
}
  
export default HistoryNewsRow;