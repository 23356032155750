import React from 'react';
import { useStyles } from './styles';
import * as ROLES from '../../constants/roles';
import AccountPageBase from './AccountPageBase';
import withWidth from '@material-ui/core/withWidth';
import { AuthUserContext, withAuthorization } from '../Session';

const AccountPage = props => {

  const classes = useStyles();

  return(
    <div>
      <AuthUserContext.Consumer>
      { authUser => <AccountPageComponent {...props} authUser={authUser} classes={classes} highCondition={highCondition}/>}        
      </AuthUserContext.Consumer>
    </div>
  );
}

const condition = authUser => authUser != null;
const highCondition = authUser => authUser && (!!authUser.roles[ROLES.ADMIN] || !!authUser.roles[ROLES.PARTNER] || !!authUser.roles[ROLES.EMPLOYEE]);

const AccountPageComponent = withAuthorization(condition)(AccountPageBase);

export default withWidth()(AccountPage);