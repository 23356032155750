import Chart from "react-google-charts";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { TYPE, COUNTRIES } from "../../partnersType";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import React, { useLayoutEffect, useState, useRef } from "react";
import FloatingCard from '../../../utils/GenericComponents/floatingCard';

const OPTIONS = {
    Categories: "Categories",
    Countries: "Countries",
}

const BreakdownChart = (props) => {

    const { partners, slicesColors } = props;
    let movement_timer = null;
    const RESET_TIMEOUT = 100;
    const targetRef = useRef();
    const [option, setOption] = useState(OPTIONS.Categories);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  
    const test_dimensions = () => {
      if (targetRef.current) {
        setDimensions({
          width: targetRef.current.offsetWidth,
          height: targetRef.current.offsetHeight
        });
      }
    };
  
    useLayoutEffect(() => { test_dimensions() }, []);

    const handleChangeOption = (event) => setOption(event.target.value);
  
    window.addEventListener("resize", () => {
      clearInterval(movement_timer);
      movement_timer = setTimeout(test_dimensions, RESET_TIMEOUT);
    });


    let breakdownData = [];
    breakdownData.push(["Partners", "Number of partners"]);
    if (option === OPTIONS.Categories) {
        Object.keys(TYPE).forEach(key => {
            partners.filter(partner => partner.type === key).length > 0 && breakdownData.push([key, partners.filter(partner => partner.type === key).length]);
        });
    } else {
        Object.keys(COUNTRIES).forEach(key => {
            partners.filter(partner => partner.country === key).length > 0 && breakdownData.push([COUNTRIES[key], partners.filter(partner => partner.country === key).length]);
        });
    }

    return(
        <div ref={targetRef} >
            <FloatingCard
                title="Partnerships breakdown"
                subheader={`Breakdown of partnerships by ${option}`}
                avatar={<DonutLargeIcon />}
                buttonAction={
                    <FormControl style={{ fontSize: 12 }}>
                        <InputLabel id="breakdown-choose-option-label">Options</InputLabel>
                            <Select
                                value={option}
                                style={{ fontSize: 12 }}
                                id="breakdown-choose-option"
                                onChange={handleChangeOption}
                                labelId="breakdown-choose-option-label"
                            >
                                <MenuItem value={OPTIONS.Countries} >{OPTIONS.Countries}</MenuItem>
                                <MenuItem value={OPTIONS.Categories} >{OPTIONS.Categories}</MenuItem>
                        </Select>
                    </FormControl>
                }
            >
                <Chart
                    data={breakdownData}
                    chartType="PieChart"
                    width={dimensions.width * 1.6}
                    height={dimensions.height * 2}
                    rootProps={{ 'data-testid': '1' }}
                    loader={<div>Loading Chart...</div>}
                    style={{ marginTop: -(dimensions.height / 8), marginBottom: -(dimensions.height / 3)}}
                    options={{
                        title: '',
                        fontSize: 14,
                        pieHole: 0.34,
                        slices: slicesColors, 
                        backgroundColor: { fill:'transparent' },
                        tooltip: { text: 'value', showColorCode: true },
                        legend: {
                            textStyle: {fontSize: 12}
                        }
                    }}
                />
            </FloatingCard>
        </div>
    );
}

export default BreakdownChart;