import React from 'react';
import Fab from '@material-ui/core/Fab';
import { Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CreateContractDialog from './createContractDialog';

import { useStyles } from './styles';

const CreateNewContract = (props) => {
  
  const classes = useStyles();
  const { onCreateNewContract } = props;
  const [open, setOpen] = React.useState(false);

  const handleCloseCreateContractDialog = () => setOpen(false);
  const submitCreateContractDialog = (contract) => {
    onCreateNewContract(contract);
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Create new client's registration">
        <Fab size="medium" color="secondary" aria-label="add" className={classes.fab} onClick={() => setOpen(true)}>
          <AddIcon />
        </Fab>
      </Tooltip>
        <CreateContractDialog submitCreateContractDialog={submitCreateContractDialog} open={open} handleCloseCreateContractDialog={handleCloseCreateContractDialog}/>
    </>
  );
}

export default CreateNewContract;

