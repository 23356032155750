import { makeStyles } from '@material-ui/core/styles';
import { YELLOW, GRAY } from '../../../../../constants/colors';

export const useStyles = makeStyles(theme => ({
    
  downloadIcon: {
    color: GRAY,
    cursor: 'pointer',
    "&:hover": { color: YELLOW },
    marginTop: theme.spacing(1),
    //marginLeft: theme.spacing(0.5),
    [theme.breakpoints.up('md')]: {
      fontSize: '1.7vh'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.7vh'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.7vh'
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '1.7vh'
    }
  },

  descriptionIcon: {
    color: GRAY,
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      fontSize: '1.7vh'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.7vh'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.7vh'
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '1.7vh'
    }
  },

  box: {
    width: "99%",
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    //justifyContent: "flex-start",
    justifyContent: "space-between",
    //border: '1px solid #ccc',
  },

  listItemText: {
    "&:hover": {
      color: YELLOW,
      textDecoration: 'underline',
    },      
    cursor: 'pointer',
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(1),
      fontSize: '1.6vh'
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(1),
      fontSize: '1.6vh'
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(1),
      fontSize: '1.6vh'
    },
    [theme.breakpoints.only('md')]: {
      marginLeft: theme.spacing(1),
      fontSize: '1.6vh'
    }
  },
    
    
  fileBox: {
    paddingLeft: 2,
    paddingRight: 0,
    [theme.breakpoints.down('md')]: {
      height: theme.typography.pxToRem(30),
    },
    [theme.breakpoints.up('md')]: {
      height: theme.typography.pxToRem(35),
    },
    [theme.breakpoints.up('xl')]: {
      height: theme.typography.pxToRem(45),
    },
    [theme.breakpoints.only('md')]: {
      height: theme.typography.pxToRem(35),
    }
  }
}));
  