import React from 'react';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Popover from '@material-ui/core/Popover';
import Tooltip from '@material-ui/core/Tooltip';
import { withFirebase } from '../../../Firebase';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';

import { useStyles } from './styles';

const PATH = 'budgets/tags';

const FloatingTagList = (props) => {

    const tagClasses = useStyles();
    const { id, tag, firebase } = props;
    const [newTag, setNewTag] = React.useState('');
    const [tagList, setTagList] = React.useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { isManager, callbackAddTag, callbackRemoveTag } = props;

    const openAnchor = Boolean(anchorEl);

    const handleCloseAnchor = () => setAnchorEl(null);
    const saveTagListInDB = () => firebase.db.ref(PATH).set(tagList);
    const onChangeTagInput = (event) => setNewTag(event.target.value);
    const handleAddTagIcon = (event) => setAnchorEl(event.currentTarget);

    React.useEffect(() => {
        let mounted = true;
        mounted && firebase.db && firebase.db.ref(PATH).on('value', snapshot => setTagList(snapshot.val().sort()));

        return () => {
            mounted = false;
            firebase.db && firebase.db.ref(PATH).off();
        };
    }, [firebase.db]);

    const handleRemoveTag = () => {
        handleCloseAnchor();
        callbackRemoveTag();
    };

    const handleAddTag = (value) => {
        handleCloseAnchor();
        callbackAddTag(value);
    };

    const handleDeleteTagFromList = (tag) => {
        tagList.splice(tagList.indexOf(tag), 1);
        saveTagListInDB();
    };

    const onKeyDown = (event) => {
        if (event.keyCode === 13) {
            handleCloseAnchor();
            const newTagToAdd = newTag.trim();
            setNewTag('');
            if (newTagToAdd.length > 0) {
                if(!tagList.includes(newTagToAdd)) {
                    tagList.push(newTagToAdd);
                    saveTagListInDB();
                }
                callbackAddTag(newTagToAdd);
            }
        }
    };

    const createTagList = () => {
        return (
            <Box display="flex" flexDirection="column" alignItems="left" spacing={0} className={tagClasses.tagList}>
                <TextField
                    id={id}
                    autoFocus
                    size="small"
                    value={newTag}
                    label="Add tag"
                    variant="outlined"
                    onKeyDown={onKeyDown}
                    onChange={onChangeTagInput}
                    InputProps={{ classes: { root: tagClasses.tagTextField } }}
                    InputLabelProps={{ classes: { root: tagClasses.tagTextFieldLabel } }}
                />
                {tagList.filter(tag => tag.substring(0, newTag.length) === newTag).map((value, index) => (
                    <span onClick={event => handleAddTag(value)} key={index} className={tagClasses.tag}>
                        <Chip size="small" label={value} onDelete={event => handleDeleteTagFromList(value)} color="primary" />
                    </span>
                ))}
            </Box>
        );
    };

    return (
        <>
            {tag && tag.length > 0
            ? <Chip size="small" label={tag} onDelete={handleRemoveTag} color="primary" disabled={!isManager} />
            : (<Tooltip arrow title="Add tag">
                <span>
                    <IconButton aria-label={id} aria-describedby={id} onClick={handleAddTagIcon} disabled={!isManager}>
                        <LocalOfferIcon className={tagClasses.tagIcon} />
                    </IconButton>
                </span>
            </Tooltip>)}

            <Popover
                id={id}
                open={openAnchor}
                anchorEl={anchorEl}
                onClose={handleCloseAnchor}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <div className={tagClasses.popup}>{createTagList()}</div>
            </Popover>
        </>
    );

};

export default withFirebase(FloatingTagList);