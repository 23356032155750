import { makeStyles } from '@material-ui/core/styles';
import { YELLOW } from '../../../../../../constants/colors';

export const useStyles = makeStyles((theme) => ({

    uploadButton: {
        fontSize: '0.9rem',
    },

    backupIcon : {
        color: YELLOW,
        paddingTop: 10,
        paddinBottom: 10,      
        fontSize: '1.7rem',
    },

    drag_drop: {
        margin: 10,
        paddingTop: 10,
        paddingLeft: 10,
        paddingBottom: 10,
        overflowY: 'auto',
        fontSize: '1.1rem',
        border: '1px solid #AAA',
        backgroundColor: '#f7f7f7',
    },

    center: {
        color: '#444',
        maxWidth: 300,
        margin: 'auto',
        padding: '10px',
        cursor: 'pointer',
        borderRadius: '9px',
        border: '1px solid #DDD',
        backgroundColor: '#fafafa11',
        "&:hover" : { backgroundColor: '#eee' },
    },

}));
  