import React from 'react';
import CategoryRow from './categoryRow';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import { CATEGORY} from '../../../constants';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';

import { useStyles, StyledTableCell } from './styles';

const ITEM_TYPES = {
    ITEM: 'item',
    MONTH1_FORECAST: 'month1Forecast',
    MONTH2_FORECAST: 'month2Forecast',
    MONTH3_FORECAST: 'month3Forecast',
    MONTH1_REAL: 'month1Real',
    MONTH2_REAL: 'month2Real',
    MONTH3_REAL: 'month3Real',
};

const MONTHS = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];

const headCells = [
    { id: ITEM_TYPES.ITEM, numeric: false, disablePadding: true, label: 'Type', width: "26%" },
    { id: ITEM_TYPES.MONTH1_FORECAST, numeric: true, disablePadding: false, label: '', width: "10%" },
    { id: ITEM_TYPES.MONTH2_FORECAST, numeric: true, disablePadding: false, label: '', width: "10%" },
    { id: ITEM_TYPES.MONTH3_FORECAST, numeric: true, disablePadding: false, label: '', width: "10%" },
    { id: ITEM_TYPES.MONTH1_REAL, numeric: true, disablePadding: false, label: '', width: "10%" },
    { id: ITEM_TYPES.MONTH2_REAL, numeric: true, disablePadding: false, label: '', width: "10%" },
    { id: ITEM_TYPES.MONTH3_REAL, numeric: true, disablePadding: false, label: '', width: "10%" },
];
  
const QuarterRow = (props) => {

    const { quarter } = props;
    const classes = useStyles();
    const categories = Object.keys(quarter)
    .map(categoryKey => ({...quarter[categoryKey], value: categoryKey}))
    .filter(category => category.value.indexOf(CATEGORY) > -1);

    headCells[1].label = MONTHS[quarter.value.split('Q')[1] * 3 - 3] + ' Forecast';
    headCells[2].label = MONTHS[quarter.value.split('Q')[1] * 3 - 2] + ' Forecast';
    headCells[3].label = MONTHS[quarter.value.split('Q')[1] * 3 - 1] + ' Forecast';
    headCells[4].label = MONTHS[quarter.value.split('Q')[1] * 3 - 3] + ' Real';
    headCells[5].label = MONTHS[quarter.value.split('Q')[1] * 3 - 2] + ' Real';
    headCells[6].label = MONTHS[quarter.value.split('Q')[1] * 3 - 1] + ' Real';
    
    return (
        <TableContainer className={classes.container} component={Paper} >
    
            <Table stickyHeader aria-label="collapsible news table" >
    
                <TableHead >
                    <TableRow >
                        <StyledTableCell style={{ width: "4%" }} />
                        {headCells.map(headCell => (              
                            <StyledTableCell
                                key={headCell.id}
                                align={headCell.numeric ? 'center' : 'left'}
                                style={{ width: headCell.width }}
                            >
                                <strong>{headCell.label}</strong>
                            </StyledTableCell>
                        ))}            
                        </TableRow>
                </TableHead>
    
                <TableBody>
                    {categories.map((currentCategory, i) => <CategoryRow key={i} {...props} currentCategory={currentCategory} opened={false} />)}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default QuarterRow
