import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

const PayoutTextField = (props) => {
    const { id, label, value, helper, onChange } = props;
    return (
        <TextField
            id={id}
            fullWidth
            type="text"
            margin="dense"
            label={label}
            value={value}
            helperText={helper}
            onChange={onChange}
        />
    )
};

const CreatePayoutDetailsDialog = (props) => {

    const [id, setId] = React.useState("");
    const [fee, setFee] = React.useState("");
    const [net, setNet] = React.useState("");
    const [amount, setAmount] = React.useState("");
    const [invoice, setInvoice] = React.useState("");
    const [customer_id, setCustomer_id] = React.useState("");
    const [invoice_name, setInvoice_name] = React.useState("");
    const [exchange_rate, setExchange_rate] = React.useState("");
    const [customer_email, setCustomer_email] = React.useState("");
    const [created_at, setCreated_at] = React.useState(new Date().toISOString());
    const { open, submitCreatePayoutDetailsDialog, handleCloseCreatePayoutDetailsDialog } = props;

    const handleSubmitNewPayout = () => {
        submitCreatePayoutDetailsDialog({
            id,
            fee,
            net,
            amount,
            invoice,
            created_at,
            customer_id,
            invoice_name,
            exchange_rate,
            customer_email,
        });
    }

    const updateIdValue = (event) => setId(event.target.value);
    const updateFeeValue = (event) => setFee(event.target.value);
    const updateNetValue = (event) => setNet(event.target.value);
    const updateAmountValue = (event) => setAmount(event.target.value);
    const updateInvoiceValue = (event) => setInvoice(event.target.value);
    const updateCreated_atValue = (event) => setCreated_at(event.target.value);
    const updateCustomer_idValue = (event) => setCustomer_id(event.target.value);
    const updateInvoice_nameValue = (event) => setInvoice_name(event.target.value);
    const updateExchange_rateValue = (event) => setExchange_rate(event.target.value);
    const updateCustomer_emailValue = (event) => setCustomer_email(event.target.value);

    const conditionSubmit = false;

    return (
        <Dialog open={open} onClose={handleCloseCreatePayoutDetailsDialog} aria-labelledby="form-dialog-new-client-title">
            <DialogTitle id="form-dialog-new-client-title">New Client's registration</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please fill the form below to create a new client's registration
                </DialogContentText>
                <PayoutTextField id="created_at" label="created_at" value={created_at} onChange={updateCreated_atValue} />
                <PayoutTextField id="fee" label="fee" value={fee} onChange={updateFeeValue} helper="fee of the payout : 2.92" />
                <PayoutTextField id="net" label="net" value={net} onChange={updateNetValue} helper="net of the payout : 89.15" />
                <PayoutTextField id="amount" label="amount" value={amount} onChange={updateAmountValue} helper="amount of the payout : 99" />
                <PayoutTextField id="id" label="id" value={id} onChange={updateIdValue} helper="id of the payout : SgJqYTTp7ok2zszkFHXaoVmbtQS74glY" />
                <PayoutTextField id="customer_email" label="customer_email" value={customer_email} onChange={updateCustomer_emailValue} helper="user@domain.com" />
                <PayoutTextField id="invoice" label="invoice" value={invoice} onChange={updateInvoiceValue} helper="invoice of the payout : in_1MBHQgErEz2tEelvKESv" />  
                <PayoutTextField id="customer_id" label="customer_id" value={customer_id} onChange={updateCustomer_idValue} helper="customer_id of the payout : cus_LuYwxqvza7jAAy" />
                <PayoutTextField id="invoice_name" label="invoice_name" value={invoice_name} onChange={updateInvoice_nameValue} helper="invoice_name of the payout : CROWDSEC-0235" />
                <PayoutTextField id="exchange_rate" label="exchange_rate" value={exchange_rate} onChange={updateExchange_rateValue} helper="exchange_rate of the payout : 0.930012" />
            </DialogContent>

            <DialogActions>
                <Button onClick={handleCloseCreatePayoutDetailsDialog} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSubmitNewPayout} color="primary" disabled={conditionSubmit}>
                    Add Payout
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default CreatePayoutDetailsDialog;