import React from 'react';
import Deal from './deal';
import Company from './company';
import { Avatar, Grid } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import Typography from '@material-ui/core/Typography';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import { useStyles } from './styles';

const CompanyInfosPanel = (props) => {

    const { company } = props;

    const [expanded, setExpanded] = React.useState('deal0');

    const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };

    const classes = useStyles();

    return (
        <>
            <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center" className={classes.grid}>

                <Grid item xs={12}>
                    <Company {...props} />
                </Grid>

                <Grid item xs={12}>
                    {company?.deals && Object.keys(company.deals).map((deal, index) => (
                        <Accordion key={index} expanded={expanded === `deal${index}`} onChange={handleChange(`deal${index}`)}>
                            <AccordionSummary
                                id={`"deal-header"${index}`}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`"deal-content"${index}`}
                            >
                                <Avatar className={classes.avatar}>
                                    <DoneAllIcon />
                                </Avatar>
                                <Typography className={classes.heading}>{company.deals[deal].dealName} </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Deal {...props} deal={company.deals[deal]} dealID={deal}/>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Grid>

            </Grid>
        </>
    );
};

export default CompanyInfosPanel;