import { makeStyles } from '@material-ui/core/styles';
import { YELLOW } from '../../../../../constants/colors';

export const useStyles = makeStyles((theme) => ({

  listItemText: {
    "&:hover": {
      color: YELLOW,
      textDecoration: 'underline',
    },
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.1em'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '0.9vw',
    },  
  }

}));
