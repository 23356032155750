import { card } from '../../utils';
import * as COLORS from '../../../constants/colors';
import { makeStyles } from '@material-ui/core/styles';
import { divRoot, listPanel, rightPanel } from '../../utils';

export const useStyles = makeStyles((theme) => ({

  listPanel: listPanel({theme}),
  rightPanel: rightPanel({theme}),
  divRoot: divRoot({theme, bar: false}),

  employeePanel: {
    overflowY: 'auto',
    overflowX: 'hidden',
    position: 'absolute',
    left: theme.spacing(1.2),
    right: theme.spacing(1.2),
    [theme.breakpoints.down('sm')]: {
      top: theme.spacing(7),
      bottom: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      top: theme.spacing(8),
      bottom: theme.spacing(8.5),
    },  
    backgroundColor: COLORS.VERY_LIGHT_PURPLE4
  },
  
  title: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.2vw'
    },  
  },

  subheader: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7rem'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '0.8vw'
    },  
  },

  backdrop: {
    color: COLORS.PURPLE,
    backgroundColor: COLORS.WHITE,
    zIndex: theme.zIndex.drawer + 1,
  },

  card: card,

  avatar: {
    backgroundColor: COLORS.PURPLE,
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },

  impersonationPanel:{
    zIndex: 1000,
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(1.3),
    marginRight: theme.spacing(2.5),
    marginBottom: theme.spacing(0.5),
    background: COLORS.PASTEL_YELLOW,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${COLORS.VERY_LIGHT_PURPLE2}`,
  },

  impersonationGrid:{
    margin: -10,
    border: '1px solid transparent'
  },

  impersonationTypo:{
    margin: 0,
    border: '1px solid transparent'
  },

  listItemText: {
    marginLeft: theme.spacing(1),
    marginBottom: 0,
  },

  listItemIcon: {
    marginLeft: theme.spacing(1),
    marginBottom: 0,
  },

  menuItem: {
    '&:hover': {
      borderRadius: '0.5rem',
    },
  }
  
}));
  