export const LEGAL = '/legal';
export const ADMIN = '/admin';
export const PARTNER = '/partner';
export const SIGN_UP = '/sign-up';
export const SIGN_IN = '/sign-in';
export const LANDING = '/landing';
export const ACCOUNT = '/account';
export const SIMPLE_USER = '/user';
export const EMPLOYEE = '/employee';
export const INDICATORS = '/indicators';
export const PARTNERSHIPS = '/partnerships';
export const PASSWORD_FORGET = '/password-forget';