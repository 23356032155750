import React from 'react';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import { ITEM } from '../../../../constants';
import Tooltip from '@material-ui/core/Tooltip';
import TableRow from '@material-ui/core/TableRow';
import Collapse from '@material-ui/core/Collapse';
import DeleteIcon from '@material-ui/icons/Delete';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import { TextFieldNumericCell, CategoryTableRowHeader, AddItemRow, TaggableCellWithLabel } from './components';

import { useRowStyles } from './styles';

const CategoryRow = (props) => {

    const { opened, isManager } = props;
    const [open, setOpen] = React.useState(false);
    const [textfield, setTextfield] = React.useState("");
    const [editionMode, setEditionMode] = React.useState(false);
    const { year, quarter, businessUnit, currentCategory, setBusinessUnit, saveBudgetInfos } = props;
    
    const classes = useRowStyles({editionMode});

    React.useEffect(() => {
      setOpen(opened);
    }, [opened]);

    const items = Object.keys(currentCategory).map(itemKey => ({ value: itemKey, ...currentCategory[itemKey] })).filter(item => item.value.indexOf(ITEM) !== -1);

    const onDeleteItem = React.useCallback((event, item) => {
        event.stopPropagation();
        let categoryToModify = businessUnit[year.value][quarter.value][currentCategory.value];
        delete categoryToModify[item.value];
        setBusinessUnit({ ...businessUnit });
        saveBudgetInfos();
    }, [businessUnit, currentCategory, quarter, setBusinessUnit, year, saveBudgetInfos]);

    const createTaggableCellWithLabel = (item) => {
        return <TaggableCellWithLabel
            {...props}
            item={item}
            classes={classes}
            textfield={textfield}
            editionMode={editionMode}
            setTextfield={setTextfield}
            setEditionMode={setEditionMode}
        />
    };

    const createEditableCell = (item, month, isForecast) => {
        return <TextFieldNumericCell
            {...props}
            month={month}
            classes={classes}
            currentItem={item}
            disabled={!isManager}
            textfield={textfield}
            isForecast={isForecast}
            editionMode={editionMode}
            setTextfield={setTextfield}
            setEditionMode={setEditionMode}
        />
    };

    return (
        <>
            <CategoryTableRowHeader
                {...props}
                open={open}
                items={items}
                classes={classes}
                setOpen={setOpen}
                textfield={textfield}
                editionMode={editionMode}
                setTextfield={setTextfield}
                setEditionMode={setEditionMode}
            />

            <TableRow className={classes.historyRow}>
                <TableCell className={classes.historyRowTable} colSpan={8}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={0} className={classes.historyRowTableBox}>
                            <Table size="small" aria-label="news-details" rowkey="id">
                                <TableBody>
                                    {items.map((item, i) => (
                                        <TableRow key={item.value} style={{ backgroundColor: i % 2 === 0 ? 'white' : '#f5f5f5'}}>
                                            <TableCell align="left"  className={classes.firstTableCell}>
                                                <Tooltip arrow title="Delete item">
                                                    <span>
                                                        <IconButton aria-label="delete-item" onClick={(event) => onDeleteItem(event, item)} disabled={!isManager}>
                                                            <DeleteIcon style={{fontSize: '1.0rem'}}/>
                                                        </IconButton>
                                                    </span>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell align="left" className={classes.labelTableCell} >
                                                {createTaggableCellWithLabel(item)}
                                            </TableCell>
                                            <TableCell align="right" className={classes.tableCell}>
                                                {createEditableCell(item, "month1", true)}
                                            </TableCell>
                                            <TableCell align="right" className={classes.tableCell}>
                                                {createEditableCell(item, "month2", true)}
                                            </TableCell>
                                            <TableCell align="right" className={classes.tableCellMiddle}>
                                                {createEditableCell(item, "month3", true)}
                                            </TableCell>
                                            <TableCell align="right" className={classes.tableCell}>
                                                {createEditableCell(item, "month1", false)}
                                            </TableCell>
                                            <TableCell align="right" className={classes.tableCell}>
                                                {createEditableCell(item, "month2", false)}
                                            </TableCell>
                                            <TableCell align="right" className={classes.tableCell}>
                                                {createEditableCell(item, "month3", false)}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    { isManager ? <AddItemRow {...props} classes={classes} /> : <TableRow style={{height: 5}}/> }
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

export default CategoryRow;
  