import TabPanel from '../TabPanel';
import BudgetPanel from './budget';
import ListFooter from '../ListFooter';
import React, { Component } from 'react';
import DropInvoice from './dropInvoices';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
import * as ROLES from '../../constants/roles';
import { CssBaseline } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import ReceiptIcon from '@material-ui/icons/Receipt';
import DonutSmallIcon from '@material-ui/icons/DonutSmall';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import CircularProgress from '@material-ui/core/CircularProgress';
import CustomListItem from '../utils/GenericComponents/customListItem';
import MoveToInboxIcon from '@material-ui/icons/MoveToInbox';
//const Budgets = React.lazy(() => import('./budgets/index') );
const BusinessPlanIndicators = React.lazy(() => import('./bp/index') );
//const RealTimeIndicators = React.lazy(() => import('./realTimeIndicators/index') );

const MENUS = {
  BUDGET: { index: 1, title: 'Budgets (Beta)' },
  BP: { index: 2, title: 'Projections (BP)' },
  DROP_INVOICE: { index: 0, title: 'Drop Invoice' },
  REAL_TIME: { index: 3, title: 'Real Time indicators' },
};

class IndicatorsPageBase extends Component {

    constructor(props) {
      super(props);
      this.state = {
        lastUpdate: '',
        loading: false,
        selectedIndex: 0,
      };
      this.classes = props.classes;
      this.authUser = props.authUser;
    }
  
    componentDidMount() {
      this.setState({ loading: true });
      this.props.firebase.steering().on('value', snapshot => {
        this.setState({
          loading: false,
          lastUpdate: snapshot.val().lastUpdate,
        });
      });
    }
  
    componentWillUnmount() {
      this.props.firebase.steering().off();
    }
  
    getUrlDataStudio = (id) => this.props.firebase.urlDataStudio(id);
    handleChange = (event, newValue) => this.setState({tabIndex: newValue});
    handleListItemClick = (event, index) => this.setState({ selectedIndex: index });
    
    render() {
  
      const { lastUpdate, loading, selectedIndex } = this.state;

      const sm = (this.props.width === 'sm' || this.props.width === 'xs');

      return (
        <div className={this.classes.divRoot}>

          <CssBaseline />
  
          <Backdrop className={this.classes.backdrop} open={loading} >
            <CircularProgress color="inherit" />
          </Backdrop> 
  
          <Grid container spacing={0} alignItems="stretch">

            <Grid item xs={1} sm={sm ? 1 : 2}>
              <List
                dense={sm}
                component="nav"
                aria-label="partner-info-list"
                className={this.classes.listPanel}
              >
                {(!!this.authUser.roles[ROLES.EMPLOYEE]) && (
                  <CustomListItem
                    selectedIndex={selectedIndex}
                    headerIcon={<MoveToInboxIcon />}
                    index={MENUS.DROP_INVOICE.index}
                    title={MENUS.DROP_INVOICE.title}
                    handleListItemClick={this.handleListItemClick}
                  />
                )}
                {(!!this.authUser.roles[ROLES.EMPLOYEE]) && (
                  <CustomListItem
                    index={MENUS.BUDGET.index}
                    title={MENUS.BUDGET.title}
                    selectedIndex={selectedIndex}
                    headerIcon={<DonutSmallIcon />}
                    handleListItemClick={this.handleListItemClick}
                  />
                )}
                <CustomListItem
                  index={MENUS.BP.index}
                  title={MENUS.BP.title}
                  selectedIndex={selectedIndex}
                  headerIcon={<TrendingUpIcon />}
                  handleListItemClick={this.handleListItemClick}
                />

                <ListFooter sm={sm} firebase={this.props.firebase} />

              </List>
            </Grid>

            
            <Grid item xs={11} sm={sm ? 11 : 10} >

                <div className={this.classes.rightPanel}>

                    {selectedIndex === MENUS.DROP_INVOICE.index && (
                      <TabPanel value={selectedIndex} index={MENUS.DROP_INVOICE.index} >
                        <DropInvoice {...this.props} />
                      </TabPanel>
                    )}

                    {selectedIndex === MENUS.BUDGET.index && (
                      <TabPanel value={selectedIndex} index={MENUS.BUDGET.index}>
                        <BudgetPanel
                          trigram={this.authUser.trigram}
                          isManager={!!this.authUser.roles[ROLES.ADMIN] || !!this.authUser.roles[ROLES.MANAGER]} />
                      </TabPanel>
                    )}

                    <React.Suspense fallback={<div>Loading...</div>}>
                      <TabPanel value={selectedIndex} index={MENUS.BP.index}>
                        <BusinessPlanIndicators
                          lastUpdate={lastUpdate}
                          getUrlDataStudio={this.getUrlDataStudio}
                        />
                      </TabPanel>
                    </React.Suspense>

                    {/* <TabPanel value={selectedIndex} index={2}>
                      <RealTimeIndicators
                        lastUpdate={lastUpdate}
                        getUrlDataStudio={this.getUrlDataStudio}
                      />
                    </TabPanel>*/}
  
                </div>

              </Grid>
            </Grid>
  
        </div>
      );
    }
}

export default IndicatorsPageBase;
  