import React from 'react';
import Metrics from './metrics';
import * as UTILS from '../../utils';
import Disclaimer from './disclaimer';
import { Grid } from '@material-ui/core';
import CapitalChart from './capitalChart';
import QuarterReport from './quarterReport';
import StockPriceChart from './stockPriceChart';
import InvestRecapList from './investRecapList';
import BalanceSheetComponent from './balanceSheet';
import MyOngoingInvestments from './myOngoingInvestments';
import MyCompletedInvestments from './myCompletedInvestments';
import MemberAvatar from '../../utils/GenericComponents/memberAvatar';
import FloatingCard from '../../utils/GenericComponents/floatingCard';

import { useStyles } from './styles';

const MyInvestments = (props) => {

    const { width, reports, classes, partner, lastUpdate, steeringObj, balanceSheets } = props;

    const classesComponent = useStyles();

    const pnl = UTILS.computePnl(partner, steeringObj);
    const hasPartnerSomeESOP = UTILS.hasSomeESOP(partner);
    const currentSharePrice = steeringObj.currentSharePrice;
    const numberOfESOPs = UTILS.getTotalOwnedEsops(partner);
    const numberOfShares = UTILS.getTotalOwnedShares(partner);
    const hasPartnerSomeShares = UTILS.hasSomeShares(partner);
    const userInvestment = UTILS.getCompletedInvestments(partner);
    const investIntent = (UTILS.getOngoingInvestments(partner) > 0);
    //const roundedCurrentSharePrice = Math.round(10000 * (currentSharePrice)) / 10000;
    const percentPnl = Math.round(10000 * ((userInvestment) ? pnl / userInvestment : (pnl > 0) ? 1 : 0)) / 100;
    
    return(
        <div style={{backgroundColor: 'inherit'}}>

            <Grid container spacing={3} direction="column" justifyContent="flex-start" alignItems="stretch">

                <Grid item xs={12} sm={12} >
                    <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="stretch">
                        <Grid item xs={12} sm={12} md={7}>
                            <Metrics steeringObj={steeringObj} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={5}>
                            <InvestRecapList
                                pnl={pnl}
                                width={width}
                                percentPnl={percentPnl}
                                steeringObj={steeringObj}
                                investment={userInvestment}
                                numberOfESOPs={numberOfESOPs}
                                numberOfShares={numberOfShares}
                                currentSharePrice={currentSharePrice}
                                investIntent={UTILS.getOngoingInvestments(partner)}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={12} className={classesComponent.southPart}>
                    <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="stretch">
                        <Grid item xs={12} sm={12} md={7}>
                            <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="stretch">
                                <Grid item xs={12} sm={12} md={7}>
                                    <StockPriceChart steeringObj={steeringObj} currentSharePrice={currentSharePrice} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={5}>
                                    <CapitalChart steeringObj={steeringObj} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={5}>
                                    <QuarterReport reports={reports} width={width} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={7}>
                                    <BalanceSheetComponent balanceSheets={balanceSheets} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={5}>
                            <FloatingCard
                                noflex={true}
                                title="Investment Portfolio"
                                subheader={`(Last update : ${lastUpdate})`}
                                avatar={ <MemberAvatar member={partner} /> }
                            >
                                <>
                                    <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="stretch">
                                        <Grid item xs={12}>
                                            {(!hasPartnerSomeESOP && !hasPartnerSomeShares && !investIntent) &&(
                                                <div className={classesComponent.disclaimerPtf}>
                                                    <Disclaimer message={"You have nothing in your portfolio yet"} />
                                                </div>
                                            )}
                                        </Grid>
                                        { investIntent && (
                                            <Grid item xs={12}>
                                                <MyOngoingInvestments
                                                    width={width}
                                                    classes={classes}
                                                    partner={partner}
                                                    steeringObj={steeringObj}
                                                    investmentIntent={investIntent}
                                                    classesComponent={classesComponent}
                                                    currentSharePrice={currentSharePrice}
                                                />
                                            </Grid>
                                        )}
                                        <Grid item xs={12}>
                                            <MyCompletedInvestments
                                                width={width}
                                                classes={classes}
                                                partner={partner}
                                                steeringObj={steeringObj}
                                                classesComponent={classesComponent}
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                            </FloatingCard>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>          
    );
}

export default MyInvestments;