export const APP = 'app';
export const USERS = 'users';
export const ROLES = 'roles';
export const IMAGES = 'images';
export const PUBLIC = 'public';
export const VERITAS = 'veritas';
export const STEERING = 'steering';
export const USERS_LIST = 'users_list';
export const PROPERTIES = 'properties';
export const ONBOARDING = 'onboarding';
export const PARTNER_INFO = 'partnerInfo';
export const PARTNERSHIPS = 'partnerships';
export const NOTIFICATIONS = 'notifications';

