import React from 'react';
import * as UTILS from '../../../utils';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import Collapse from '@material-ui/core/Collapse';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import HistoryContractRow from './historyContractRow';
import Typography from '@material-ui/core/Typography';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { useRowStyles } from './styles';

const ContractRow = (props) => {

    const { contract, onUpdateContract } = props;
    
    //const sm = (width === 'sm') || (width === 'xs');
  
    const classes = useRowStyles({ contract });
    const [open, setOpen] = React.useState(false);

    const date = new Date();
    date.setTime(contract.id);
  
    return (
      <>
        <TableRow hover className={classes.root}>

          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
                    
          <TableCell align="left" >
            <span className={classes.rowText}>{contract.clientName}</span>
          </TableCell>
          
          <TableCell align="center">
            <span className={classes.rowText}>{contract.plan}</span>
          </TableCell>
          
          <TableCell align="center">
            <FiberManualRecordIcon className={classes.activeIcon} />
          </TableCell>
          
          <TableCell align="center">
            <span className={classes.rowText}>{UTILS.dateToPrettyString(date)}</span>
          </TableCell>
          
        </TableRow>
  
        <TableRow className={classes.tableRow}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1} >
                <Typography variant="h6" gutterBottom component="div">Details</Typography>
                <Table size="small" aria-label="employee-details" rowkey="id">
                  <TableBody>
                    <HistoryContractRow contract={contract} onUpdateContract={onUpdateContract} />
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
}

export default ContractRow;
  