import React, { useState } from 'react';
import * as CONST from './../constants';
import FilterPanel from './filterPanel';
import { Grid } from '@material-ui/core';
import NewsTableRow from './newsTableRow';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import SearchBar from "material-ui-search-bar";
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import { YELLOW } from '../../../../constants/colors';
import TableContainer from '@material-ui/core/TableContainer';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';

import { useStyles, StyledTableCell } from './styles';

const headCells = [
    { id: CONST.TYPE, numeric: false, disablePadding: false, label: 'Type' },
    { id: CONST.TITLE, numeric: false, disablePadding: false, label: 'Title' },
    { id: CONST.TIMESTAMP, numeric: false, disablePadding: false, label: 'Date' },
    { id: CONST.FEEDBACK, numeric: false, disablePadding: false, label: 'Feedback' },
    { id: CONST.LANG, numeric: false, disablePadding: false, label: 'Languages' },
];
  
const descendingComparator = (a, b, orderBy) => {
  if (orderBy === CONST.LANG) {
    if (a.lang.length < b.lang.length) return -1;
    if (a.lang.length > b.lang.length) return 1;
  } else {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
  }
  return 0;
}

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const stableSort = (news, orderBy, comparator) => {
    const stabilizedThis = news.map((news, index) => [news, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      return (order !== 0) ? order : a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
}

const NewsTable = (props) => {

  const classes = useStyles();

  const { news, width, firebase, sendToEdit, currentNewsToEdit, onSaveCurrentNews, onChangeCurrentNews, onDeleteCurrentNews } = props;

  const defaultCategorization = (news) => news.filter(n => n.media.indexOf(CONST.MEDIA_TYPES.TWEET) === -1);

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [searched, setSearched] = useState('');
  const [orderBy, setOrderBy] = useState(CONST.DATE);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [filteredNews, setFilteredNews] = useState(null);
  const [categorizedNews, setCategorizedNews] = useState(null);

  const handleChangePage = (event, newPage) => setPage(newPage);
  const createSortHandler = (property) => (event) => handleRequestSort(event, property);

  const requestSearch = React.useCallback((searchedVal, specCategorizedNews = null) => {
    if (searchedVal.length > 0) {
      if (categorizedNews === null) {
        const categorizedNews = defaultCategorization(news);
        setFilteredNews(categorizedNews.filter(n => n.title.toLowerCase().indexOf(searchedVal.toLowerCase()) !== -1));
        setCategorizedNews(categorizedNews);
      } else {
        if (specCategorizedNews) {
          setFilteredNews(specCategorizedNews.filter(n => n.title.toLowerCase().indexOf(searchedVal.toLowerCase()) !== -1));
        } else {
          setFilteredNews(categorizedNews.filter(news => news.title.toLowerCase().indexOf(searchedVal.toLowerCase()) !== -1));
        }
      }
    }
    setSearched(searchedVal);
  }, [categorizedNews, news]);

  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
    sendToEdit(null);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const getCategorizedNews = () => categorizedNews?.length > 0 ? categorizedNews : defaultCategorization(news);
  const getNumberOfNews = () => categorizedNews?.length > 0 ? categorizedNews.length : defaultCategorization(news).length;

  const onFilterCategoryChange = (pattern)  => {
    const categorizedNews = news.filter(n => pattern.reduce((toKeep, p) => (toKeep || n.media.indexOf(p) !== -1), false));
    requestSearch(searched, categorizedNews);
    setCategorizedNews(categorizedNews);
  };

  React.useEffect(() => {
    if (currentNewsToEdit) {
      requestSearch(currentNewsToEdit.title, defaultCategorization(news));
    }
  }, [currentNewsToEdit, requestSearch, news]);

  return (
    <>
      <div style={{ marginTop: 20 }}/>

      <Paper >
      <Grid container spacing={1} alignItems="center" justifyContent="center" >
        <Grid item xs={6}>
          <FilterPanel onFilterCategoryChange={onFilterCategoryChange}/>
        </Grid>
        <Grid item xs={6}>
          <SearchBar
            value={searched}
            className={classes.searchBar}
            onCancelSearch={() => cancelSearch()}
            onChange={(searchVal) => requestSearch(searchVal)}
          />
        </Grid>
      </Grid>
      </Paper>

      <div style={{ marginTop: 10 }}/>
      <TableContainer className={classes.container} component={Paper}>

          <Table stickyHeader aria-label="collapsible news table" >

              <TableHead >
                  <TableRow >
                      <StyledTableCell />
                      {headCells.map(headCell => (              
                          <StyledTableCell
                              key={headCell.id}
                              align={headCell.numeric ? 'center' : 'left'}
                              padding={headCell.disablePadding ? 'none' : 'normal'}
                              sortDirection={orderBy === headCell.id ? order : false}
                          >
                              <TableSortLabel
                                  active={orderBy === headCell.id}
                                  style={{color: YELLOW}}
                                  onClick={createSortHandler(headCell.id)}
                                  direction={orderBy === headCell.id ? order : 'asc'}
                              >
                              <strong>{headCell.label}</strong>
                              {orderBy === headCell.id ? (
                                  <span className={classes.visuallyHidden}>
                                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                  </span>
                              ) : null}
                              </TableSortLabel>
                          </StyledTableCell>
                      ))}            
                  </TableRow>
              </TableHead>

              <TableBody>
                  {stableSort(searched.length === 0 ? getCategorizedNews() : filteredNews, orderBy, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(currentNews => (
                      <NewsTableRow
                          width={width}
                          firebase={firebase}
                          key={currentNews.key}
                          currentNews={currentNews}
                          onSaveCurrentNews={onSaveCurrentNews}
                          onDeleteCurrentNews={onDeleteCurrentNews}
                          onChangeCurrentNews={onChangeCurrentNews}
                          opened={currentNewsToEdit !== null && currentNewsToEdit.key === currentNews.key}
                      />
                  ))}
              </TableBody>

          </Table>

      </TableContainer>

      {<TablePagination
            page={page}
            component="div"
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            rowsPerPageOptions={[10, 25, 100]}
            className={classes.paginationPanel}
            onRowsPerPageChange={handleChangeRowsPerPage}
            count={ searched.length === 0 ? getNumberOfNews() : filteredNews.length }
        />
      }

    </>
  );
}

export default NewsTable;
