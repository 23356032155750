import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({

    southPart : {
        [theme.breakpoints.up('md')]: {
            marginTop: theme.spacing(-2),
        },
    },

    hideBorder: {
        boxShadow: "none",
        backgroundColor: 'inherit'
    },

    disclaimerPtf : {
        width: '100%',
        border: '1px dashed #AAA',
        marginTop: theme.spacing(10),
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(10),
    },

    accordionDetails: (props) => ({
        marginTop: theme.spacing(-3),
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1)
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4)
        },
    }),

}));
