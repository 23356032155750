import React from 'react';
import { Grid } from '@material-ui/core';
import DatePicker from 'react-datepicker';
import "../../calendarConstants/date_picker.css";
import "react-datepicker/dist/react-datepicker.css";
import { addDays, subDays, parseISO } from 'date-fns';
import { DAYS_OFF } from '../../calendarConstants/daysoff';
import { PENDING, REFUSED } from '../../../../holidays_constants';

const isDayOff = (date) => {
    const day = date.getDay();
    const dday = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    const daysoff = DAYS_OFF[year];
    if (daysoff) {
        const key  = `${year}-${month + 1 < 10 ? '0' + (month + 1) : month + 1}-${dday < 10 ? '0' + dday : dday}`;
        if (daysoff[key]) return false;
    }
    return day !== 0 && day !== 6;
};

const fillPreviousRequestedDaysOff = (employee, highlightedHoliday = []) => {
    const requests = [];
    Object.keys(employee.contract.daysOff?.holidays || {}).forEach(key => {
        const holiday = employee.contract.daysOff.holidays[key];
        requests.push({key, ...holiday});
    });

    const highlightWithRanges = [
        { "react-datepicker__day--highlighted-custom-1": [] },
        { "react-datepicker__day--highlighted-custom-2": [] },
        { "react-datepicker__day--highlighted-custom-3": [] },
        { "react-datepicker__day--highlighted-custom-4": [] },
    ];
    requests.forEach(request => {
        const to = parseISO(request.to);
        const from = parseISO(request.from);
        let key = request.status === PENDING ? 2 : request.status === REFUSED ? 3 : 1;
        if (highlightedHoliday?.length > 0 && highlightedHoliday.includes(request.key)) key = 4;
        for (let i = from; i <= to; i = addDays(i, 1)) {
            isDayOff(i) && highlightWithRanges[key-1][`react-datepicker__day--highlighted-custom-${key}`].push(i);
        }
    });
    return highlightWithRanges;
}     

const CalendarView = (props) => {

    const { width, employee, highlightedHoliday } = props;

    const monthsToShow = () => {
        switch(width) {
            case 'xs': return 3;
            case 'sm': return 3;
            case 'md': return 3;
            case 'lg': return 4;
            case 'xl': return 6;
            default: return 3;
        }
    };

    return (
        <Grid container spacing={2} justifyContent="flex-start" alignItems="center" >
            <Grid item xs={12} >
                <DatePicker
                    inline
                    filterDate={isDayOff}
                    monthsShown={monthsToShow()}
                    minDate={subDays(new Date(), 30)}
                    highlightDates={fillPreviousRequestedDaysOff(employee, highlightedHoliday)}
                />
            </Grid>
        </Grid>
    );
}

export default CalendarView;