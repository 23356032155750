import { makeStyles } from '@material-ui/core/styles';
import { PURPLE, YELLOW } from '../../../../../constants/colors';

export const useStyles = makeStyles((theme) => ({
    margin: {
      margin: theme.spacing(1),
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    fab: {
        backgroundColor: PURPLE,
        position: 'absolute',
        bottom: theme.spacing(1),
        right: theme.spacing(1),
        '&:hover' : {
          backgroundColor: YELLOW,
        },
    },  
  }));
  