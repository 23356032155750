export const DAYS_OFF = {
    2022 : {
        "2022-01-01": "1er janvier",
        "2022-04-18": "Lundi de Pâques",
        "2022-05-01": "1er mai",
        "2022-05-08": "8 mai",
        "2022-05-26": "Ascension",
        "2022-07-14": "14 juillet",
        "2022-08-15": "Assomption",
        "2022-11-01": "Toussaint",
        "2022-11-11": "11 novembre",
        "2022-12-25": "Jour de Noël"
    },
    2023 : {
        "2023-01-01": "1er janvier",
        "2023-04-10": "Lundi de Pâques",
        "2023-05-01": "1er mai",
        "2023-05-08": "8 mai",
        "2023-05-18": "Ascension",
        "2023-07-14": "14 juillet",
        "2023-08-15": "Assomption",
        "2023-11-01": "Toussaint",
        "2023-11-11": "11 novembre",
        "2023-12-25": "Jour de Noël"
    },
    2024 : {
        "2024-01-01": "1er janvier",
        "2024-04-01": "Lundi de Pâques",
        "2024-05-01": "1er mai",
        "2024-05-08": "8 mai",
        "2024-05-09": "Ascension",
        "2024-07-14": "14 juillet",
        "2024-08-15": "Assomption",
        "2024-11-01": "Toussaint",
        "2024-11-11": "11 novembre",
        "2024-12-25": "Jour de Noël"
    },
    2025 : {
        "2025-01-01": "1er janvier",
        "2025-04-21": "Lundi de Pâques",
        "2025-05-01": "1er mai",
        "2025-05-08": "8 mai",
        "2025-05-29": "Ascension",
        "2025-07-14": "14 juillet",
        "2025-08-15": "Assomption",
        "2025-11-01": "Toussaint",
        "2025-11-11": "11 novembre",
        "2025-12-25": "Jour de Noël"
    }
}

