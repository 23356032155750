import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({

    stockPriceChart : (props) => ({
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1)
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
        },
    }),
}));
