import { makeStyles } from '@material-ui/core/styles';
import * as COLORS from '../../../../../constants/colors';

export const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
  
    chip: {
      margin: 1,
      backgroundColor: COLORS.VERY_LIGHT_PURPLE2,
    },
  
    pnl: props => ({
      color: (props.pnl > 0) ? COLORS.DARK_GREEN : (props.pnl < 0) ? COLORS.DARK_RED : COLORS.GRAY,
    }),
      
});
