import { makeStyles } from '@material-ui/core/styles';
import { PURPLE, VERY_LIGHT_PURPLE4 } from '../../../../../constants/colors';

export const useStyles = makeStyles((theme) => ({

    circular: {
        color: PURPLE,
    },

    dialog: {
        padding: theme.spacing(2),
    },

    container: {
        padding: theme.spacing(2),
    },

    dialogContent: {
        backgroundColor: VERY_LIGHT_PURPLE4,
    },

    dialogTitle: {
        height: theme.spacing(2),
    },

    dialogChipContainer: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    smallTeamMembersList: {
        width: '100%',
        borderRadius: 4,
        cursor: 'pointer',
        padding: theme.spacing(2),
        backgroundColor: '#f5f5f5',
        border: '1px solid #e0e0e0',
        marginTop: theme.spacing(-1),
    },

    cardContent: props => ({
        height: '100%',
        overflowY: 'auto',
        marginBottom: theme.spacing(1),
        "&:hover": { cursor: 'pointer' },
    }),

    closeButton: {
        position: 'absolute',
        top: theme.spacing(0.2),
        right: theme.spacing(1),
        color: theme.palette.grey[500],
    },

}));
  