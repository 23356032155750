import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({

    grid: {
        marginTop: theme.spacing(2),
    },

    list: props => ({
        width: '100%',
        paddingLeft: theme.spacing(0),
        paddingBottom: theme.spacing(1),
        height: 'calc(100% - 10px)'
    }),

}));
