import InfoBox from '../../infoBox';
import React, { useState } from 'react';
import List from '@material-ui/core/List';
import Select from '@material-ui/core/Select';
import LockIcon from '@material-ui/icons/Lock';
import Tooltip from '@material-ui/core/Tooltip';
import { Button, Grid } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import CreateIcon from '@material-ui/icons/Create';
import SalaryIncreaseDialog from './salaryIncrease';
import * as ROLES from '../../../../constants/roles';
import IconButton from '@material-ui/core/IconButton';
import BusinessIcon from '@material-ui/icons/Business';
import Visibility from '@material-ui/icons/Visibility';
import FormControl from '@material-ui/core/FormControl';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import { CONTRACT_TYPE } from '../../contract_type_constants';
import MemberAvatar from '../../../utils/GenericComponents/memberAvatar';
import FloatingCard from '../../../utils/GenericComponents/floatingCard';
//import { getTotalOwnedEsops, getTotalVestedEsops, getTotalOwnedShares } from '../../../utils';
import { getTotalOwnedEsops, getTotalVestedEsops, getTotalOwnedShares, computeRatioFromStartDate } from '../../../utils';

import { useStyles } from './styles';

const CONTRACT = {
  JEI: 'contract.jei',
  ROLE: 'contract.role',
  TYPE: 'contract.type',
  SALARY: 'contract.salary',
  START_DATE: 'contract.start',
};

const CompanyInfosDetails = props => {

  const { isAdmin, reload, employee, employees, updateEmployee, saveUserInfos } = props;

  const classes = useStyles();
  const [edit, setEdit] = useState(false);
  const [error, setError] = useState(false);
  const ratio = computeRatioFromStartDate(employee);
  const [openSalaryIncreaseDialog, setOpenSalaryIncreaseDialog] = React.useState(false);

  const handleClickOpenSalaryIncreaseDialog = () => setOpenSalaryIncreaseDialog(true);
  const handleClickCloseSalaryIncreaseDialog = () => setOpenSalaryIncreaseDialog(false);
  const handleChangeJEI = (event) => changeUserValue(CONTRACT.JEI, event.target.checked);
  const handleChangeContractType = (event) => changeUserValue(CONTRACT.TYPE, event.target.value);

  const handleSaveSalaryIncrease = (increasePercent) => {
    setOpenSalaryIncreaseDialog(false);
    employee.contract.salaryIncrease = increasePercent;
    saveUserInfos(employee);
  };

  const handleEdit = (event) => {
    event.preventDefault();
    edit && reload();
    setEdit(!edit);
  };
    
  const handleSave = (event) => {
    event.preventDefault();
    setEdit(false);
    saveUserInfos(employee);
  };


  const computeVestedESOPs = () => {
    const totalvestedEsops = getTotalVestedEsops(employee);
    return(
      <Grid container spacing={1}>
        {Object.keys(totalvestedEsops).map((key, index) => (
          <Grid item xs={12} key={index} align="center">
            BSPCE <strong>{key}</strong> : {totalvestedEsops[key] * 100}% vested
          </Grid>
        ))}
      </Grid>
    );
  };
    
  const changeUserValue = (key, value) => {
    const sepIndex = key.indexOf(".");
    if ( sepIndex !== -1) {
      const key1 = key.substring(0, sepIndex);
      const key2 = key.substring(sepIndex + 1);
      employee[ key1 ][ key2 ] = value;
    }
    else employee[ key ] = value;
    updateEmployee(employee);
    setError(value.length === 0);
  };

  const team = () => {
    if (employee?.roles[ROLES.ORG]) return "Ops";
    if (employee?.roles[ROLES.TECH]) return "Tech";
    if (employee?.roles[ROLES.SALES]) return "Sales";
    if (employee?.roles[ROLES.SUPPORT]) return "Support";
    if (employee?.roles[ROLES.MARKETING]) return "Marketing";
    return "None";
  };

  const EditButton = () => {
    return (
      <Tooltip title={ isAdmin ? "edit" : "locked" } arrow>
      <span>
        <IconButton
          aria-label="edit"
          onClick={handleEdit}
          disabled={!isAdmin || error}
        >
          { isAdmin ? <CreateIcon /> : <LockIcon/> }
        </IconButton>
      </span>
    </Tooltip>
    );
  };

  const employeeSalaryIncrease = Math.round((employee?.contract?.salaryIncrease || 0) * employee.contract.salary * ratio).toLocaleString();

  return (
    <>
      <FloatingCard
        noflex={true}
        title="Company details"
        avatar={<BusinessIcon />}
        buttonAction={<EditButton />}
        subheader={<i>role, salary, etc.</i>}
      >
        <List style={{width:'100%'}}>
          <InfoBox
            value={null}
            name={"jei"}
            disabled={!edit}
            title={"JEI status"}
          >
            <Checkbox
              disabled={!edit}
              onChange={handleChangeJEI}
              checked={employee?.contract?.jei || false}
              inputProps={{ 'aria-label': 'jei-checkbox' }}
            />
          </InfoBox>
          <InfoBox
            title={"Role"}
            disabled={!edit}
            copyClipboard={true}
            name={CONTRACT.ROLE}
            changeUserValue={changeUserValue}
            value={employee?.contract?.role || ""}
          />
          <InfoBox
            disabled={!edit}
            title={"Salary"}
            name={CONTRACT.SALARY}
            icon={<EuroSymbolIcon />}
            changeUserValue={changeUserValue}
            value={edit ? parseInt(employee?.contract?.salary) : `${parseInt(employee?.contract?.salary).toLocaleString() || ""}`}
          />
          <InfoBox
            disabled={!edit}
            title={"Contract"}
            copyClipboard={true}
            name={CONTRACT.TYPE}
            changeUserValue={changeUserValue}
            value={edit ? null : employee?.contract?.type}
          >
            <FormControl className={classes.formControl} disabled={!edit}>
              <Select
                id="subcontractor-type-select"
                labelId="subcontractor-type-select-label"
                onChange={handleChangeContractType}
                value={employee?.contract?.type || ""}
              >
                {Object.keys(CONTRACT_TYPE).map((key, index) => (
                  <MenuItem key={index} value={key}>{CONTRACT_TYPE[key]}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </InfoBox>
          <InfoBox
            disabled={!edit}
            title={"Start Date"}
            copyClipboard={true}
            name={CONTRACT.START_DATE}
            changeUserValue={changeUserValue}
            value={employee?.contract?.start || ""}
          />
          <InfoBox
            name={"email"}
            title={"Email"}
            disabled={!edit}
            copyClipboard={true}
            value={employee?.email || ""}
            changeUserValue={changeUserValue}
          />
          <InfoBox
            name={"team"}
            title={"Team"}
            value={team()}
            disabled={true}
            copyClipboard={true}
            />
          <InfoBox
            disabled={true}
            name={"trigram"}
            title={"Trigram"}
            copyClipboard={true}
            value={employee?.trigram || ""}
          />
          <div className={classes.divider} />
          {employee?.partnerInfo &&
            <InfoBox
              name={"BSPCE"}
              title={"ESOPs"}
              disabled={true}
              icon={<Visibility />}
              copyClipboard={true}
              tooltipInfos={computeVestedESOPs()}
              value={parseInt(getTotalOwnedEsops(employee)).toLocaleString() || "0"}
            />
          }
          {employee?.partnerInfo && getTotalOwnedShares(employee) > 0 &&
            <InfoBox
              name={"Shares"}
              disabled={true}
              title={"Shares"}
              copyClipboard={true}
              value={parseInt(getTotalOwnedShares(employee)).toLocaleString() || "0"}
            />
          }

          { isAdmin && (<InfoBox
              value={null}
              disabled={true}
              name={"salaryIncrease"}
              title={"Salary Increase"}
            >
              <Button onClick={handleClickOpenSalaryIncreaseDialog} style={{marginTop: 8}}>
                <Typography variant="body2" color="textSecondary" component="p">
                  {employeeSalaryIncrease} €&nbsp;&nbsp;&nbsp;<em>({((employee?.contract?.salaryIncrease || 0) * 100).toFixed(2)}%</em>)
                </Typography>
              </Button>
            </InfoBox>
          )}

          {employee.contract?.isManagerOf?.length > 0 && (
            <InfoBox
              value={null}
              disabled={true}
              name={"managerOf"}
              title={"Manager of"}
            >
              <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="center" style={{paddingTop: 9}}>
                {employees.filter(emp => employee.contract.isManagerOf.includes(emp.trigram)).map(employee => (
                  <Grid item key={employee.uid}>
                    <MemberAvatar key={employee.trigram} member={employee} size={3.5} />
                  </Grid>
                ))}
              </Grid>
            </InfoBox>
          )}

          {edit && (
            <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" style={{marginTop: 20}}>
              <Grid item>
                <Button
                  align="center"
                  color="primary"
                  disabled={error}
                  variant="contained"
                  onClick={handleSave}
                >
                  save
                </Button>
              </Grid>
              <Grid item>
                <Button
                  align="center"
                  color="primary"
                  disabled={error}
                  variant="outlined"
                  onClick={handleEdit}
                >
                  cancel
                </Button>
              </Grid>
            </Grid>
          )}
        </List>
      </FloatingCard>

      {openSalaryIncreaseDialog && (
        <SalaryIncreaseDialog
          employee={employee}
          open={openSalaryIncreaseDialog}
          handleSave={handleSaveSalaryIncrease}
          handleClose={handleClickCloseSalaryIncreaseDialog}
        />
      )}

    </>
  );
};

export default CompanyInfosDetails;