import * as UTILS from '../utils';
import TabPanel from '../TabPanel';
import ListFooter from '../ListFooter';
import GoalsDashboard from './goals';
import React, { Component } from 'react';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
import { Divider } from '@material-ui/core';
import EmployeeInfos from './employeeInfos';
import EmployeeWages from './employeeWages';
import InfoIcon from '@material-ui/icons/Info';
import * as ROLES from '../../constants/roles';
import { YELLOW } from '../../constants/colors';
import EmployeeDaysOff from './employeeDaysOff';
import CloseIcon from '@material-ui/icons/Close';
import { VALIDATED } from './holidays_constants';
import Backdrop from '@material-ui/core/Backdrop';
import Snackbar from '@material-ui/core/Snackbar';
import EmployeeExpenses from './employeeExpenses';
import Collapse from '@material-ui/core/Collapse';
import PeopleIcon from '@material-ui/icons/People';
import EmployeeInCompany from './employeeInCompany';
import WarningIcon from '@material-ui/icons/Warning';
import ReceiptIcon from '@material-ui/icons/Receipt';
import IconButton from '@material-ui/core/IconButton';
import ImpersonationPanel from './ImpersonationPanel';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import CssBaseline from '@material-ui/core/CssBaseline';
import PersonalPerformance from './performance/personal';
import { CONTRACT_TYPE } from './contract_type_constants';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import { PUBLIC, STEERING } from '../../constants/realtimeDB';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import StyledBadge from '../utils/GenericComponents/styledBadge';
import DashboardPendingHolidays from './dashboardPendingHolidays';
import CircularProgress from '@material-ui/core/CircularProgress';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import CustomListItem from '../utils/GenericComponents/customListItem';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';


const Cockpit = React.lazy(() => import('./performance/cockpits/index'));

const ITEMS = {
  ME: 0,
  WAGES: 1,
  DAYS_OFF: 2,
  EXPENSES: 3,
  INFOS: 4,
  PERFS: 5,
  RADAR: 6,
  GOALS: 7,
  COCKPIT_MARKETING: 8,
  COCKPIT_SALES: 9,
  PENDING_HOLIDAYS: 10,
};
class EmployeePageBase extends Component {

  constructor(props) {
    super(props);
    this.state = {
      employees: [],
      isAdmin: false,
      employeeID: '',
      loading: false,
      expanded : true,
      isManagerOf: [],
      openSnack: false,
      snackMessage: '',
      salesCockpitUrl: '',
      inspectionObject: {},
      marketingCockpitUrl: '',
      selectedIndex: ITEMS.ME,
      employee: props.employee,
      daysOffConfirmEmailUrl: '',
    };
    this.unMounted = false;
    this.classes = props.classes;
    this.firebase = props.firebase;
    this.authUser = props.employee;
  }

  loadFullEmployeesForAdmin() {
    const isManagerOf = [];
    this.firebase.users().on('value', snapshot => {
      const usersObjectFromDB = snapshot.val();
      const usersList = Object.keys(usersObjectFromDB)
      .filter(key => usersObjectFromDB[key]?.roles?.[ROLES.EMPLOYEE] || false)
      .map(key => ({ uid: key, ...usersObjectFromDB[key] }));
      usersList.map(user => delete user.notifications);
      usersList.map(user => isManagerOf.push(user.trigram));
      this.setState( { dashboardHolidaysTitle: this.createDashboardHolidaysTitle(this.getPendingHolidays(usersList)) });
      this.setState({ employees: usersList.sort((a, b) => a?.contract?.end?.localeCompare(b?.contract?.end)), isAdmin: true, isManagerOf});
    });
  }

  addFullMemberInUserList(userID, usersList) {
    let userFromDB = {};
    this.firebase.user(userID).on('value', snapshot => {
      userFromDB = snapshot.val();
      delete userFromDB.notifications;
      usersList.push({ uid: userID, ...userFromDB });
      this.setState({ employees: usersList.sort((a, b) => a?.contract?.end?.localeCompare(b?.contract?.end)) });
    });
  }

  addPartialMemberInUserList(userID, usersList, roles, trigram) {
    const user = this.createEmptyUser(userID, roles);
    user.trigram = trigram;
    this.firebase.getUserValue(userID, 'civil/city').on('value', snapshot => user.civil.city = snapshot.val());
    this.firebase.getUserValue(userID, 'contract/end').on('value', snapshot => user.contract.end = snapshot.val());
    this.firebase.getUserValue(userID, 'civil/country').on('value', snapshot => user.civil.country = snapshot.val());
    this.firebase.getUserValue(userID, 'contract/type').on('value', snapshot => user.contract.type = snapshot.val());
    this.firebase.getUserValue(userID, 'contract/role').on('value', snapshot => user.contract.role = snapshot.val());
    this.firebase.getUserValue(userID, 'contract/start').on('value', snapshot => user.contract.start = snapshot.val());
    this.firebase.getUserValue(userID, 'civil/lastName').on('value', snapshot => user.civil.lastName = snapshot.val());
    this.firebase.getUserValue(userID, 'civil/firstName').on('value', snapshot => {
      user.civil.firstName = snapshot.val()
      usersList.push(user);
      this.setState({ employees: usersList.sort((a, b) => a?.contract?.end?.localeCompare(b?.contract?.end)) });
    });
  }


  componentDidMount() {
    
    this.setState({ loading: true });
    this.firebase.isAdminOrHRAsync(this.authUser).then(isAdmin => {
      if (isAdmin) {
        this.loadFullEmployeesForAdmin();
      }  else {
        let isManagerOf = [];
        // authUser is not an admin
        this.firebase.getUserValue(this.authUser.uid, 'contract/isManagerOf').on('value', snapshot => isManagerOf = snapshot.val());
        this.firebase.usersList().on('value', snapshot => {
          const usersList = [];
          const usersListObjectFromDB = snapshot.val();
          Object.values(usersListObjectFromDB).forEach(cuserID => {
            const userID = this.firebase.crypto.deCypher(cuserID);
            this.firebase.getUserValue(userID, 'roles').on('value', snapshot => {
              const roles = snapshot.val();
              if (roles?.[ROLES.EMPLOYEE]) {
                this.firebase.getUserValue(userID, 'trigram').on('value', snapshot => {
                  const trigram = snapshot.val();
                  if (this.authUser.uid === userID && isManagerOf) isManagerOf.push(trigram);
                  (isManagerOf?.includes(trigram)) ? this.addFullMemberInUserList(userID, usersList) : this.addPartialMemberInUserList(userID, usersList, roles, trigram);
                });
              }
            })
          });
        });
        this.setState({ isManagerOf });
      }
    });

    this.firebase.db.ref(`${PUBLIC}/inspection`).on('value', snapshot => this.setState({ inspectionObject : snapshot.val() }));
    this.firebase.db.ref(`${STEERING}/daysOffConfirmEmail`).once('value', snapshot => this.setState({ daysOffConfirmEmailUrl: snapshot.val() }));
    this.firebase.db.ref(`${STEERING}/dataStudio/salesCockpitUrl`).once('value', snapshot => this.setState({ salesCockpitUrl: snapshot.val() }));
    this.firebase.db.ref(`${STEERING}/dataStudio/marketingCockpitUrl`).once('value', snapshot => this.setState({ marketingCockpitUrl : snapshot.val() }));

    this.listenToEmployeeChanges(this.state.employee.uid);

    this.setState({
      dashboardHolidaysTitle: this.createDashboardHolidaysTitle()
    });      

    this.setState({ loading: false });
  }

  sanitizeUser = (user) => {
    delete user.uid;
    delete user.roles;
    delete user.trigram;
    delete user.partnerInfo;
    //delete user.contract.wages;
    return user;
  }

  createEmptyUser = (uid, roles) => {
    return {
      uid, roles, trigram: "",
      contract: { start: "", end: "", type: "", role: "" },
      civil: { city: "", country: "", firstName: "", lastName: "" },
    };
  }

  updateEmployee = (employee) => this.setState( employee );
  reload = () => this.listenToEmployeeChanges(this.state.employee.uid);
  handleListExpand = () => this.setState({ expanded: !this.state.expanded });
  handleListItemClick = (event, index) => this.setState({ selectedIndex: index });
  ifContratTypeIsDifferent = (list) => list.reduce((value, item) => value && !(CONTRACT_TYPE[item] === this.state.employee.contract.type), true);
  isAdminOrManager = () => (this.state.isAdmin || this.state.isManagerOf?.filter(trigram => this.authUser?.trigram !== trigram)?.includes(this.state.employee.trigram));

  handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') return;
    this.setState({ openSnack: false });
  };

  handleSelectEmployee = (event) => {
    this.setState({ loading: true });
    this.listenToEmployeeChanges(event.target.value);
    this.setState({ loading: false });
  }

  getPendingHolidays = (usersList) => {
    return usersList.reduce((value, employee) => {
      Object.values(employee.contract.daysOff?.holidays || {}).forEach(holiday => (holiday.status !== VALIDATED) && value++ );
      return value;
    }, 0);
  };

  saveUserInfos = (user, notif = true) => {
    this.firebase.user(user.uid).update(this.sanitizeUser({...user}))
    .then(r => notif && this.setState( { openSnack: true, snackMessage: "User infos saved" } ))
    .catch(error => {
      this.setState( { openSnack: true, snackMessage: "Error while saving user infos" } );
      console.err(error);
    });
  }

  componentWillUnmount() {
    this.unMounted = true;
    this.firebase.users().off();
    this.firebase.user(this.state.employee.uid).off();
    this.firebase.db.ref(`${PUBLIC}/inspection`).off();
    this.firebase.db.ref(`${STEERING}/calendar`).off();
    this.firebase.db.ref(`${STEERING}/daysOffConfirmEmail`).off();
    this.firebase.db.ref(`${STEERING}/dataStudio/salesCockpitUrl`).off();
    this.firebase.db.ref(`${STEERING}/dataStudio/marketingCockpitUrl`).off();
  }

  listenToEmployeeChanges = (employeeID) => {
    if (employeeID === undefined || this.unMounted) return;
    this.firebase.user(this.state.employee.uid).off();
    const path = `/members/${employeeID}/employee/remote`;
    this.firebase.user(employeeID).on('value', snapshot => {
      const newEmployee = snapshot.val();
      delete newEmployee.notifications;
      this.firebase.storage.ref(path).listAll().then(r => (!this.unMounted) && this.setState({ infosTitle: this.createInfosTitle(r.items.length > 0) }));
      this.setState( { employeeID, employee: { ...newEmployee, uid: snapshot.key }, openSnack: true, snackMessage: "Alpaca's infos updated" });
    });
  }

  createInfosTitle = (hasFilesForRemoteWork) => {
    return (
      <StyledBadge
        top={13}
        right={-23}
        overlap="rectangular"
        bordercolor="inherit"
        backgroundcolor="inherit"
        badgeContent={ hasFilesForRemoteWork ? null :  <WarningIcon fontSize="small" style={{ color: YELLOW }}/>}
      >
        Infos
      </StyledBadge>
    );
  };

  createDashboardHolidaysTitle = (nbrPendingHolidays) => {
    return (
      <StyledBadge
        top={13}
        right={-23}
        overlap="rectangular"
        badgeContent={ nbrPendingHolidays }
      >
        Pending holidays
      </StyledBadge>
    );
  }


  render() {

    const {
      loading,
      isAdmin,
      employee,
      expanded,
      openSnack,
      employees,
      employeeID,
      isManagerOf,
      snackMessage,
      selectedIndex,
      salesCockpitUrl,
      inspectionObject,
      marketingCockpitUrl,
      daysOffConfirmEmailUrl,
    } = this.state;

    const sm = (this.props.width === 'sm' || this.props.width === 'xs');

    return(
      <>
        <ImpersonationPanel
          isAdmin={isAdmin}
          employee={employee}
          employees={employees}
          classes={this.classes}
          employeeID={employeeID}
          firebase={this.firebase}
          width={this.props.width}
          isManagerOf={isManagerOf}
          handleSelectEmployee={this.handleSelectEmployee}
        />

        <Backdrop className={this.classes.backdrop} open={loading} >
          <CircularProgress color="inherit" />
        </Backdrop> 

        <div className={this.classes.divRoot} style={{marginTop: (isAdmin || isManagerOf?.length > 0) ? 60 : 0}}>

          <CssBaseline />

          <Grid container spacing={0} alignItems="stretch">

            <Grid item xs={1} sm={sm ? 1 : 2}>
              <List
                dense={sm}
                component="nav"
                aria-label="employee-info-list"
                className={this.classes.listPanel}
              >
                <CustomListItem
                  index={ITEMS.ME}
                  title="Me in CrowdSec"
                  selectedIndex={selectedIndex}
                  headerIcon={<AssignmentIndIcon />}
                  handleListItemClick={this.handleListItemClick}
                />

                <CustomListItem
                  nested={true}
                  index={ITEMS.WAGES}
                  title={"Pay slip & Docs"}
                  selectedIndex={selectedIndex}
                  headerIcon={<BusinessCenterIcon />}
                  handleListItemClick={this.handleListItemClick}
                />

                {this.ifContratTypeIsDifferent(['OYSTER', 'SELF EMPLOYED']) && (
                  <CustomListItem
                    nested={true}
                    title={"Expenses"}
                    index={ITEMS.EXPENSES}
                    selectedIndex={selectedIndex}
                    headerIcon={<ReceiptIcon />}
                    handleListItemClick={this.handleListItemClick}
                  />
                )}

                {this.ifContratTypeIsDifferent(['OYSTER']) && (
                  <CustomListItem
                    nested={true}
                    title={"Days off"}
                    index={ITEMS.DAYS_OFF}
                    selectedIndex={selectedIndex}
                    headerIcon={<BeachAccessIcon />}
                    handleListItemClick={this.handleListItemClick}
                  />
                )}

                {this.ifContratTypeIsDifferent(['OYSTER', 'SELF EMPLOYED']) && (
                  <CustomListItem
                    nested={true}
                    index={ITEMS.INFOS}
                    //title={createInfosTitle()}
                    title={this.state.infosTitle}
                    headerIcon={<InfoIcon />}
                    selectedIndex={selectedIndex}
                    handleListItemClick={this.handleListItemClick}
                  />
                )}

                <CustomListItem
                  index={ITEMS.PERFS}
                  nestable={expanded}
                  title={"Performance"}
                  selectedIndex={selectedIndex}
                  headerIcon={<TrendingUpIcon />}
                  handleListItemClick={this.handleListExpand}
                />

                <Collapse in={expanded} timeout="auto" unmountOnExit>  
                  <CustomListItem
                    nested={true}
                    index={ITEMS.RADAR}
                    title={"360° radar"}
                    selectedIndex={selectedIndex}
                    headerIcon={<TrackChangesIcon />}
                    handleListItemClick={this.handleListItemClick}
                  />

                  {(this.isAdminOrManager() || employee?.contract?.goals)
                  && <CustomListItem
                    nested={true}
                    index={ITEMS.GOALS}
                    title={"Objectives"}
                    headerIcon={<GpsFixedIcon />}
                    selectedIndex={selectedIndex}
                    handleListItemClick={this.handleListItemClick}
                  />}

                  {(this.firebase.isMarketing(employee) || isAdmin) && (
                  <CustomListItem
                    nested={true}
                    title={"Cockpit Marketing"}
                    headerIcon={<PeopleIcon />}
                    selectedIndex={selectedIndex}
                    index={ITEMS.COCKPIT_MARKETING}
                    handleListItemClick={this.handleListItemClick}
                  />
                  )}

                  {(this.firebase.isSales(employee) || isAdmin) && (
                  <CustomListItem
                    nested={true}
                    title={"Cockpit Sales"}
                    headerIcon={<PeopleIcon />}
                    index={ITEMS.COCKPIT_SALES}
                    selectedIndex={selectedIndex}
                    handleListItemClick={this.handleListItemClick}
                  />
                  )}

                </Collapse>

                {isAdmin && (
                  <>
                    <Divider />
                    <CustomListItem
                      selectedIndex={selectedIndex}
                      index={ITEMS.PENDING_HOLIDAYS}
                      //title={createDashboardHolidaysTitle()}
                      title={this.state.dashboardHolidaysTitle}
                      handleListItemClick={this.handleListItemClick}
                      headerIcon={<PlaylistAddCheckIcon style={{ color : 'purple' }} />}
                    />
                  </>
                )}

                <ListFooter sm={sm} firebase={this.firebase} />

                </List>
            </Grid>
            
            <Grid item xs={11} sm={sm ? 11 : 10}>

              <div className={this.classes.rightPanel} >

                {selectedIndex === ITEMS.ME && 
                  <TabPanel value={selectedIndex} index={ITEMS.ME}>
                    <EmployeeInCompany
                      employee={employee}
                      reload={this.reload}
                      employees={employees}
                      firebase={this.firebase}
                      width={this.props.width}
                      isAdmin={this.isAdminOrManager()}
                      saveUserInfos={this.saveUserInfos}
                      updateEmployee={this.updateEmployee}
                    />
                  </TabPanel>
                }

                {selectedIndex === ITEMS.WAGES && 
                  <TabPanel value={selectedIndex} index={ITEMS.WAGES}>
                    <EmployeeWages
                      isAdmin={isAdmin}
                      employee={employee}
                      firebase={this.firebase}
                      width={this.props.width}
                      saveUserInfos={this.saveUserInfos}
                    />
                  </TabPanel>
                }

                {selectedIndex === ITEMS.EXPENSES && 
                  <TabPanel value={selectedIndex} index={ITEMS.EXPENSES}>
                    <EmployeeExpenses
                      isAdmin={isAdmin}
                      employee={employee}
                      firebase={this.firebase}
                      width={this.props.width}
                      saveUserInfos={this.saveUserInfos}
                    />
                  </TabPanel>
                }

                {selectedIndex === ITEMS.DAYS_OFF && 
                  <TabPanel value={selectedIndex} index={ITEMS.DAYS_OFF}>
                    <EmployeeDaysOff
                      employee={employee}
                      firebase={this.firebase}
                      authUser={this.authUser}
                      width={this.props.width}
                      saveUserInfos={this.saveUserInfos}
                      isManager={this.isAdminOrManager()}
                    />
                  </TabPanel>
                }

                {selectedIndex === ITEMS.INFOS && 
                  <TabPanel value={selectedIndex} index={ITEMS.INFOS}>
                    <EmployeeInfos
                      employee={employee}
                      firebase={this.firebase}
                      width={this.props.width}
                      inspectionObject={inspectionObject}
                    />
                  </TabPanel>
                }

                <React.Suspense fallback={<div>Loading...</div>}>
                  { selectedIndex === ITEMS.RADAR && 
                    <TabPanel value={selectedIndex} index={ITEMS.RADAR}>
                      <PersonalPerformance
                        employee={employee}
                        authUser={this.authUser}
                        firebase={this.firebase}
                        width={this.props.width}
                        employees={this.state.employees}
                        isManager={this.isAdminOrManager()}
                      />
                    </TabPanel>
                  }

                  { selectedIndex === ITEMS.GOALS &&
                    <TabPanel value={selectedIndex} index={ITEMS.GOALS}>
                      <GoalsDashboard
                        employee={employee}
                        authUser={this.authUser}
                        firebase={this.firebase}
                        width={this.props.width}
                        isManager={this.isAdminOrManager()}
                      />
                    </TabPanel>
                  }

                  { selectedIndex === ITEMS.COCKPIT_MARKETING && (this.firebase.isMarketing(employee) || isAdmin) &&
                    <TabPanel value={selectedIndex} index={ITEMS.COCKPIT_MARKETING}>
                      <Cockpit
                        ratio={500/1000}
                        url={marketingCockpitUrl}
                        title={"Cockpit for Marketing"}
                        lastUpdate={UTILS.dateToPrettyString(new Date())}
                      />
                    </TabPanel>
                  }

                  { selectedIndex === ITEMS.COCKPIT_SALES && (this.firebase.isSales(employee) || isAdmin) &&
                    <TabPanel value={selectedIndex} index={ITEMS.COCKPIT_SALES}>
                      <Cockpit
                        ratio={500/1000}
                        url={salesCockpitUrl}
                        title={"Cockpit for Sales"}
                        lastUpdate={UTILS.dateToPrettyString(new Date())}
                      />
                    </TabPanel>
                  }
                </React.Suspense>

                {selectedIndex === ITEMS.PENDING_HOLIDAYS && 
                  <TabPanel value={selectedIndex} index={ITEMS.PENDING_HOLIDAYS}>
                    <DashboardPendingHolidays
                      firebase={this.firebase}
                      width={this.props.width}
                      authUser={this.authUser}
                      employees={this.state.employees}
                      saveUserInfos={this.saveUserInfos}
                      daysOffConfirmEmailUrl={daysOffConfirmEmailUrl}
                    />
                  </TabPanel>
                }

              </div>
            </Grid>
          </Grid>
        </div>

        <Snackbar
          open={openSnack}
          message={snackMessage}
          autoHideDuration={3000}
          onClose={this.handleCloseSnackBar}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          action={
            <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleCloseSnackBar}>
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />   

      </>
    );
  }
}

export default EmployeePageBase;