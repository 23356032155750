import React from "react";
import EmployeeNDF from "./employeeNDF";
import { Grid } from '@material-ui/core';

const EmployeeExpenses = props => {

    return (
        <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="stretch">
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid container spacing={2} direction="column" justifyContent="flex-start" alignItems="stretch" >
                    <Grid item xs={12}>
                        <EmployeeNDF {...props} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default EmployeeExpenses;