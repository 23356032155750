import React from 'react';
import EuroIcon from '@material-ui/icons/Euro';
import LinkIcon from '@material-ui/icons/Link';
import ListItem from '@material-ui/core/ListItem';
import SchoolIcon from '@material-ui/icons/School';
import HealingIcon from '@material-ui/icons/Healing';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AssignmentIcon from '@material-ui/icons/Assignment';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import FloatingCard from '../../../utils/GenericComponents/floatingCard';

import { useStyles } from './styles';

const LinkBox = props => {
  
    const {linkName, url, disabled, icon} = props;
    const linkBoxClasses = useStyles();
    const handleClick = () => window.open(url);

    return (
      <ListItem button onClick={handleClick} style={{paddingLeft: 3, paddingRight: 0, backgroundColor: 'inherit'}} disabled={disabled}>
        <ListItemIcon >{icon}</ListItemIcon>
        <ListItemText primary={linkName} style={{marginLeft: -15}}  classes={{primary: linkBoxClasses.listItemText}}/>
      </ListItem>                  
    );
}

const EmployeeLinks = props => {

  return (
    <FloatingCard
        avatar={<LinkIcon />}
        title="Links"
        subheader={<i>utility links</i>}
      >

      <div style={{overflowY: 'auto', height: '100%', maxHeight: 300, marginBottom: 10, width: '100%'}}>
        <LinkBox linkName={<>Alan <i>(mutuelle & prevoyance)</i></>} url="https://alan.com/" icon={<HealingIcon/>}/>
        <LinkBox linkName={<>Compte CPF <i>(training)</i></>} url="https://www.moncompteformation.gouv.fr/espace-prive/html/#/" icon={<SchoolIcon/>}/>
        <LinkBox linkName={<>Taxes <i>(simulator)</i></>} url="https://www.boursorama.com/patrimoine/impots/simulateur/impot-revenu" icon={<EuroIcon/>}/>
        <LinkBox linkName={<>Ameli <i>(sickness absence)</i></>} url="https://www.ameli.fr/hauts-de-seine/assure/droits-demarches/maladie-accident-hospitalisation/arret-travail-maladie" icon={<LocalHospitalIcon/>}/>
        <LinkBox linkName={<>Collective agreement <i>(Syntec)</i></>} url="https://www.syntec.fr/convention-collective/" icon={<AssignmentIcon/>}/>
      </div>

    </FloatingCard>
  );
}

export default EmployeeLinks;