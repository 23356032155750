export const CONTRACT_TYPE = {
    'TNS': 'TNS',
    'CDI': 'CDI',
    'CDD': 'CDD',
    'STAGE': 'STAGE',
    'INTERIM': 'INTERIM',
    'CONTRAT PRO': 'CONTRAT PRO',
    'APPRENTISSAGE': 'APPRENTISSAGE',
    'OYSTER': 'OYSTER',
    'SELF EMPLOYED': 'SELF EMPLOYED',
};
