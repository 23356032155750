import React from 'react';
import Logo from '../Logo';
import { Grid } from '@material-ui/core';
import * as INFOS from '../../constants/infos';

const ListFooter = (props) => {
    const { sm, firebase } = props;
    return (
        <Grid container spacing={0} alignItems="center" justifyContent="center" style={{position: 'absolute', bottom: 20}}>
            <Grid item >
              <Logo name={ sm ? INFOS.LOGO_ORIGINAL : INFOS.SPLASH_SCREEN} height={sm ? 30 : 80} firebase={firebase}/>
            </Grid>
        </Grid>
    );
}

export default ListFooter;