
import React from 'react';
import Card from '@material-ui/core/Card';
import { withFirebase } from '../Firebase';
import * as INFOS from '../../constants/infos';
import Typography from '@material-ui/core/Typography';
import { CardMedia, CardContent, CardActions } from '@material-ui/core';

import { useStyles } from './styles';

const Landing = props => {

  const [source, setSource] = React.useState('');

  const classes = useStyles();

  props.firebase.getImage(INFOS.SPLASH_SCREEN, setSource);

  return (
    <>
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent>
          <CardMedia
            component="p"
            image={source}
            className={classes.media}
            alt={"Companion's Splashscreen"}
            title={"Splashscreen for crowdsec"}
        />
        </CardContent>
        <CardActions >
          <Typography className={classes.title} color="textSecondary" component="label">
            <center><i>We are a community, our individual fates are linked.</i></center>
          </Typography>
        </CardActions>
        <div style={{textAlign: 'center', marginTop: '40px', width: '100%'}}>
          <a href="https://clearbit.com">Logos provided by Clearbit</a>
        </div>
      </Card>
    </div>
    </>
  );
};

export default withFirebase(Landing);