import { PURPLE } from '../../../../constants/colors';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({

    grow: {
      flexGrow: 1,
    },
  
    menuButton: {
      marginRight: theme.spacing(2),
    },
  
    update: {
      flexGrow: 1,
      position: 'relative',
      zIndex: theme.zIndex.drawer + 10,
    },
  
    appBar: {
      zIndex: 1,
      backgroundColor: PURPLE,
      [theme.breakpoints.down('sm')]: { height: theme.spacing(6) },
      [theme.breakpoints.up('md')]: { height: theme.spacing(7) },
    },
  
  }));
  