import { makeStyles } from '@material-ui/core/styles';
import { YELLOW } from '../../../../../constants/colors';

export const useStyles = makeStyles((theme) => ({
  
    infoFont: {
        fontSize: '1.6vh',
    },

    miniPanel : {
        padding:10,
        width: '100%',
        borderRadius: 5,
        border: '1px solid #DDD',
        backgroundColor: '#EFEFEF',
    },

    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },

    indicator: {
        backgroundColor: YELLOW,
        marginBottom: theme.spacing(1),
    },

    appBar: {
        evelation: 0,
        borderRadius: 5,
        boxShadow: 'none',
    },

    tabs: {
        height: 20,
        width: '100%',
        color: '#000',
        borderRadius: 5,
        backgroundColor: "#EFEFEF",
        borderTop: '1px solid #DDD',
        borderLeft: '1px solid #DDD',
        borderRight: '1px solid #DDD',
        marginTop: theme.spacing(-1),
        marginBottom: theme.spacing(-1),
    },
  
    tabPanelDiv: {
        padding:20,
        width: '100%',
        borderRadius: 5,
        border: '1px solid #DDD',
        backgroundColor: '#EFEFEF',
    },

}));
  