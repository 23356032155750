import React from 'react';
import { VERY_LIGHT_GRAY } from '../../../../constants/colors';
import { Grid, Typography, TextField } from '@material-ui/core';

const PreMoneyInfos = (props) => {

    const {precRoundName, precRoundPostMoneyValue, disabled, multiplier, preMoneyValue, onChange} = props;

    const error = (multiplier <= 0);

    const errorText = (multiplier.toString().length === 0) ? "Must be filled" : (multiplier <= 0) ? "Cannot be negative" : "";

    return (
        <Grid container spacing={2} style={{borderRadius: 5, background: VERY_LIGHT_GRAY, paddingTop: 8}}>

            <Grid item>
                <Typography variant="h6" gutterBottom>
                    {`Value of precedent round (${precRoundName}) : ${Math.round(precRoundPostMoneyValue).toLocaleString()} € x `}
                </Typography>
            </Grid>

            <Grid item >
                <div style={{marginTop: -15}}>
                <TextField                    
                    align="left"
                    type="number"
                    error={error}
                    margin="dense"
                    name="multiplier"
                    label="Multiplier"
                    variant="outlined"
                    value={multiplier}
                    disabled={disabled}
                    onChange={onChange}
                    helperText={errorText}
                />
                </div>
            </Grid>

            <Grid item>
                <Typography variant="h6" gutterBottom>
                    {` =  ${preMoneyValue.toLocaleString()} €`} <i>(Pre-money value)</i>
                </Typography>
            </Grid>

        </Grid>
    );

};

export default PreMoneyInfos;
