import * as COLORS from '../../../constants/colors';
import { makeStyles } from '@material-ui/core/styles';
import { divRoot, listPanel, rightPanel, boxShadow } from '../../utils';

export const useStyles = makeStyles((theme) => ({

    listPanel: listPanel({theme}),
    rightPanel: rightPanel({theme}),
    divRoot: divRoot({ theme, bar: false }),
      
    card: {
      width: '100%',
    },
        
    avatar: {
      backgroundColor: COLORS.PURPLE,
    }, 
        
    backdrop: {
      color: COLORS.PURPLE,
      backgroundColor: COLORS.WHITE,
      zIndex: theme.zIndex.drawer + 3,
      },
  
    expansion: {
      "&:hover": {
        backgroundColor: COLORS.VERY_LIGHT_GRAY3,
     },
     boxShadow: boxShadow,
    },
  
    media: {
      height: 0,
      paddingTop: '30%',
    },
  
    title: props => ({
      [theme.breakpoints.down('sm')]: {
        fontSize: '2rem'
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '1.3vw'
      },  
    }),
  
    subheader: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.7rem'
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '0.8vw'
      },  
    },

    formControl: {
      minWidth: 200,
      margin: theme.spacing(1),
    },

    impersonationPanel:{
      margin: 5,
      background: COLORS.PASTEL_RED,
      border: '1px solid transparent',
      borderRadius: theme.shape.borderRadius,
    },

    impersonationGrid:{
      margin: -10,
      border: '1px solid transparent'
    },

    impersonationTypo:{
      margin: 0,
      border: '1px solid transparent'
    },

    listItemText: {
      marginBottom: 0,
      marginLeft: theme.spacing(1),
    },

    listItemIcon: {
      marginLeft: theme.spacing(1),
      marginBottom: 0,
    },

    menuItem: {
      '&:hover': {
        borderRadius: '0.5rem',
      },
    }  
}));
  