import { boxShadow } from '../../utils';
import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Timeline from '@material-ui/lab/Timeline';
import GitHubIcon from '@material-ui/icons/GitHub';
import RedditIcon from '@material-ui/icons/Reddit';
import YouTubeIcon from '@material-ui/icons/YouTube';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import VpnLockIcon from '@material-ui/icons/VpnLock';
import Typography from '@material-ui/core/Typography';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import TimelineDot from '@material-ui/lab/TimelineDot';
import SettingsIcon from '@material-ui/icons/Settings';
import StarRateIcon from '@material-ui/icons/StarRate';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import TimelineItem from '@material-ui/lab/TimelineItem';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import DescriptionIcon from '@material-ui/icons/Description';
import TimelineContent from '@material-ui/lab/TimelineContent';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
const timeLine = [
  {
    timestamp: "January 30th, 2022",
    icon: <RedditIcon />,
    title: "We reach the 100+ users on Reddit",
    description: "Extending the community",
    color: "primary",
    variant: "outlined"
  },
  {
    timestamp: "December 11th, 2021",
    icon: <VpnLockIcon />,
    title: "We surfed the Log4J Vulnerability",
    description: "We produce a bouncer, a tracker & a lot of visibility",
    color: "primary",
    variant: "outlined"
  },
  {
    timestamp: "September 29th, 2021",
    icon: <AccessibilityNewIcon />,
    title: "We reached 20 000 Installations",
    description: "A new significant step",
    color: "primary",
    variant: "outlined"
  },
  {
    timestamp: "August 2th, 2021",
    icon: <MonetizationOnIcon />,
    title: "We raised 2M€ from BPI",
    description: "More money for the company R&D program (loan)",
    color: "primary",
    variant: ""
  },
  {
    timestamp: "July 13, 2021",
    icon: <StarRateIcon />,
    title: "We reached 3 000 Stars on GitHub",
    description: "Great recognition from the community",
    color: "primary",
    variant: ""
  },
  {
    timestamp: "May 28th, 2021",
    icon: <AccessibilityNewIcon />,
    title: "We reached 10 000 Users",
    description: "A first very significant step",
    color: "primary",
    variant: ""
  },
  {
    timestamp: "April 7, 2021",
    icon: <MonetizationOnIcon />,
    title: "We raised 4.2M€",
    description: "Welcome to Breega, our new partner",
    color: "primary",
    variant: ""
  },
  {
    timestamp: "February 1, 2021",
    icon: <CreditCardIcon />,
    title: "First paying customer !",
    description: "We are no more pre-revenue ;-)",
    color: "primary",
    variant: ""
  },
  {
    timestamp: "January 17, 2021",
    icon: <AccessibilityNewIcon />,
    title: "We reached 3 000 Users",
    description: "The community is still growing !",
    color: "primary",
    variant: ""
  },
  {
    timestamp: "January 14, 2021",
    icon: <LoyaltyIcon />,
    title: "We won the RSA Conference Innovation",
    description: "The Jury considers us as emerging players in the cyber defense space",
    color: "primary",
    variant: "outlined",
    link: "https://www.rsaconference.com/industry-topics/isp/1-cylon"
  },
  {
    timestamp: "January 13, 2021",
    icon: <LoyaltyIcon />,
    title: "We are laureate at the FIC STARTUP Award",
    description: "The Jury decided to reward our startup",
    color: "primary",
    variant: "outlined",
    link: "https://www.forum-fic.com/en/home/price/the-fic-start-up-award.htm"
  },
  {
    timestamp: "December 30, 2020",
    icon: <AccessibilityNewIcon />,
    title: "We reached 2 500 Users",
    description: "First year's goal reached !!",
    color: "primary",
    variant: "outlined"
  },
  {
    timestamp: "December 10, 2020",
    icon: <FiberNewIcon />,
    title: "CrowdSec v1.0 released",
    description: "Crowdsec now expose an API.",
    color: "primary",
    variant: "outlined"
  },
  {
    timestamp: "November 23, 2020",
    icon: <FiberNewIcon />,
    title: "We reached 2 000 Stars on GitHub",
    description: "+1 000 stars in one month...",
    color: "primary",
    variant: "outlined"
  },
  {
    timestamp: "November 17, 2020",
    icon: <AccessibilityNewIcon />,
    title: "We reached 1 000 Users",
    description: "We are present in 56 countries & 303 cities",
    color: "primary",
    variant: "outlined"
  },
  {
    timestamp: "October 21, 2020",
    icon: <StarRateIcon />,
    title: "We reached 1 000 Stars on GitHub",
    description: "We have a community !",
    color: "primary",
    variant: "outlined"
  },
  {
    timestamp: "October 19, 2020",
    icon: <YouTubeIcon />,
    title: "Mention of CrowdSec in podcast",
    description: "Dan Miessler (cybersecurity influencer)",
    color: "primary",
    variant: "outlined"
  },
  {
    timestamp: "October 15, 2020",
    icon: <DescriptionIcon />,
    title: "Our 1st Press Release",
    description: "Published in 'Les echos'",
    color: "primary",
    variant: "outlined"
  },
  {
    timestamp: "October 12, 2020",
    icon: <LaptopMacIcon />,
    title: "100k banned Ips and 250+ users",
    description: "Adoption adoption ...",
    color: "primary",
    variant: "outlined"
  },
  {
    timestamp: "October 8, 2020",
    icon: <RecordVoiceOverIcon />,
    title: "First conference",
    description: "SANS",
    color: "primary",
    variant: "outlined"
  },
  {
    timestamp: "July 14, 2020",
    icon: <LaptopMacIcon />,
    title: "30k banned Ips + first users",
    description: "It seems to work...",
    color: "primary",
    variant: "outlined"
  },
  {
    timestamp: "July 2020",
    icon: <MonetizationOnIcon />,
    title: "SMR Round",
    description: "Because we need strength (680k€ raised)",
    color: "primary",
    variant: "outlined"
  },
  {
    timestamp: "May 2020",
    icon: <LaptopMacIcon />,
    title: "CrowdSec Beta released",
    description: "First signals and banned Ips",
    color: "primary",
    variant: "outlined"
  },
  {
    timestamp: "Spring 2020",
    icon: <MonetizationOnIcon />,
    title: "Core Round",
    description: "Financial contribution from the founders (200k€)",
    color: "primary",
    variant: "outlined"
  },
  {
    timestamp: "February 2020",
    icon: <GitHubIcon />,
    title: "GitHub repo",
    description: "Opening of our open source repository on GitHub",
    color: "primary",
    variant: "outlined",
    link: "https://github.com/crowdsecurity/"
  },
  {
    timestamp: "January to March 2020",
    icon: <SettingsIcon />,
    title: "R & D",
    description: "Start of R&D: eat, code, sleep.",
    color: "primary",
    variant: "outlined"
  },
  
];
class ChangeLogPanel extends Component {

  constructor(props) {
    super(props);
    this.classes = props.classes;
    this.firebase = props.firebase;
  }


  render() {

    return (


      <Timeline align="alternate">

        {timeLine.map((item, index) => {
          return (
            <TimelineItem key={index}>
              <TimelineOppositeContent>
                <Typography variant="body2" color="textSecondary">
                  {item.timestamp}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color="primary">
                  {item.icon}
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} style={{padding: 6, boxShadow}}>
                  <Typography variant="h6" component="h1">
                    {item.title}
                  </Typography>
                  {item?.link 
                  ? <Typography><a target="_blank" rel="noopener noreferrer" href={item.link}>{item.description}</a></Typography>
                  : <Typography>{item.description}</Typography>
                  }
                </Paper>
              </TimelineContent>
            </TimelineItem>
          )
        })}
        
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot color="secondary">
            <FiberNewIcon />
          </TimelineDot>
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} style={{padding: 6, boxShadow}}>
            <Typography variant="h6" component="h1">Creation</Typography>
            <Typography>Creation of CrowdSec</Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>

    </Timeline>
    );
  }
  
}

export default ChangeLogPanel;