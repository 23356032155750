import { makeStyles } from '@material-ui/core/styles';
import { PURPLE } from '../../../../../constants/colors';

export const useStyles = makeStyles( theme => ( {

    circular: {
        color: PURPLE,
    },

    fileBox: {
        width: '100%',
        margin: 'auto',
        overflowY: 'auto',
        maxHeight: '30vh',
        height: 'calc(100% - 10px)',
    },

})); 
