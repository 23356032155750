import Disclaimer from '../disclaimer';
import Chart from "react-google-charts";
import { Grid } from '@material-ui/core';
import { nFormatter } from '../../../utils';
import { getTotalShares } from '../../../utils';
import React, { useState, useEffect } from 'react';
import TimelineIcon from '@material-ui/icons/Timeline';
import { PURPLE, YELLOW } from '../../../../constants/colors';
import FloatingCard from '../../../utils/GenericComponents/floatingCard';

import { useStyles } from './styles';

const chartOptions = {
    pointSize: 3,
    hAxis: { title: ''},
    vAxis: { title: 'Price (€)' },
    backgroundColor: 'transparent',
    chartArea: { width: "90%", height: "80%" },
    legend: {'position': 'top', 'alignment':'center'},
    series: {
        0: { color: PURPLE, lineDashStyle: [4, 4] },
        1: { color: YELLOW, pointShape: 'circle' },
    },
}

const StockPriceChart = (props) => {
    const classes = useStyles();
    const { steeringObj, currentSharePrice } = props;

    const [valuation, setValuation] = useState(0);
    const [chartData, setChartData] = useState([]);
    const [roundedCurrentSharePrice, setRoundedCurrentSharePrice] = useState(0);

    const getSharesData = (steeringObj) => {
        const data = Object.keys(steeringObj?.sharePriceEvolution || {}).map(date => [
            new Date(date),
            steeringObj?.sharePriceEvolution[date]?.target || NaN,
            (steeringObj?.sharePriceEvolution?.[date]?.real === -1) ? NaN : steeringObj.sharePriceEvolution[date].real,
        ]);
        return data.sort( (a, b) => (a[0] > b[0]) ? 1 : -1);
    }

    useEffect(() => {
        let chartData = [];
        const data = steeringObj ? getSharesData(steeringObj) : [];
        chartData.push(['x', 'Forecast', 'Real']);
        data.forEach(element => chartData.push(element));
        const totalSharesBreakdown = steeringObj ? getTotalShares(steeringObj) : [];
        const roundedCurrentSharePrice = Math.round(10000 * (currentSharePrice)) / 10000;
        const totalShares = totalSharesBreakdown.reduce((total, elt) => isNaN(elt[1].v) ? total + 0 : elt[1].v + total, 0);
        const valuation = totalShares * currentSharePrice;
        setChartData(chartData);
        setValuation(valuation);
        setRoundedCurrentSharePrice(roundedCurrentSharePrice);
    }, [steeringObj, currentSharePrice]);

    return (
        <FloatingCard
            avatar={<TimelineIcon />}
            title={`CrowdSec share price: ${roundedCurrentSharePrice} €`}
            subheader={`Based on a CrowdSec valuation of: ${nFormatter(valuation, 1)}`}
        >
            <Grid container spacing={2} direction="column" justifyContent="flex-start" alignItems="stretch">
                <Grid item xs={12}>
                    <div className={classes.stockPriceChart}>
                        <Chart
                            chartType="LineChart"
                            options={chartOptions}
                            data={chartData || []}
                            style={{marginTop: -35}}
                            rootProps={{ 'data-testid': '2' }}
                            loader={<div>Loading Chart...</div>}
                        />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Disclaimer message={"These indicated values are non contractual."} />
                </Grid>
            </Grid>
        </FloatingCard>
    );
};

export default StockPriceChart;