import React from 'react';
import Chip from '@material-ui/core/Chip';
import LockIcon from '@material-ui/icons/Lock';
import { Grid, Tooltip } from '@material-ui/core';
import { getTotalVestedEsops } from '../../../utils';
import Typography from '@material-ui/core/Typography';
import CardTravelIcon from '@material-ui/icons/CardTravel';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import { SHARES_TYPE, ESOP_TYPE } from '../../../../constants/infos';

const getToolTipInfos = (type, quantity, percentVested, currentSharePrice) => {
  let infos = `Value: ${(Math.floor(quantity * currentSharePrice * 100) / 100).toLocaleString()}€`;
  if (type === ESOP_TYPE)
    infos = `Value: ${(Math.floor(quantity * (percentVested / 100) * currentSharePrice * 100) / 100).toLocaleString()}€`;
  return infos;
};

const vestingInfos = (percentVested) => {
  if (percentVested >= 25) return <i>Vesting: {percentVested}%</i>
  return (
    <i>Vesting: 0% (1Y cliff) <LockIcon style={{ fontSize: 12 }} /></i>
  );
};

export const CardInvestTitle = (props) => {

    const { type, round, price, partner, ongoing, quantity, realDate, currentSharePrice, classesCardInvest, operationValidated } = props;

    const percentVested = getTotalVestedEsops(partner)[round] * 100;
  
    return (
      <Grid container spacing={1}  direction='row' justifyContent="center" alignItems="center">
                      
        <Grid item xs>
          <Grid container spacing={1}  direction='column' justifyContent="center" alignItems="center">
            <Grid item xs>
              <Tooltip title={getToolTipInfos(type, quantity, percentVested, currentSharePrice)} arrow>
                <Chip
                  size={(ongoing) ? null : "small"}
                  className={classesCardInvest.chip}
                  label={ `${quantity.toLocaleString()} ${type}`}
                  icon={(type === SHARES_TYPE) ? <CardTravelIcon /> : <CardGiftcardIcon/>}
                />
              </Tooltip>
            </Grid>
            <Grid item xs>
              <Typography variant="caption" color="textSecondary" component="p">
                {`${round} ROUND`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
  
        <Grid item xs>
          {!ongoing && (
            <Grid container spacing={0}  direction='column' justifyContent="center" alignItems="center">
              <Grid item xs>
                <Typography variant="caption" color="textSecondary" component="p"><i>{price}</i></Typography>
              </Grid>
              <Grid item xs>
                <Typography variant="caption" color="textSecondary" component="p"><i>{`Date: ${realDate}`}</i></Typography>
              </Grid>
              {type !== SHARES_TYPE && (
                <Grid item xs>
                  <Typography variant="caption" color="textSecondary" component="p">
                    {operationValidated
                      ? <>{vestingInfos(percentVested)}</>
                      : <i>Not yet distributed</i>
                    }
                  </Typography>
                </Grid>
              )}
            </Grid>
          )}
  
          {ongoing && (
            <Tooltip title={`${round} ROUND`}>
              <Typography className={classesCardInvest.investAmount} align="center">{price}</Typography>
            </Tooltip>
          )}
        </Grid>
  
      </Grid>

    );
  }
  
  export default CardInvestTitle;