import React from 'react';
import { Grid } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import InvestStepper from './investStepper';
import Button from '@material-ui/core/Button';
import LockIcon from '@material-ui/icons/Lock';
import Accordion from '@material-ui/core/Accordion';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import SlideshowIcon from '@material-ui/icons/Slideshow';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DescriptionIcon from '@material-ui/icons/Description';
import InvestIntentProgress from '../../InvestIntentProgress';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import CircularProgress from '@material-ui/core/CircularProgress';
import DataStudioCard from '../../utils/GenericComponents/dataStudioCard';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

const STAGE = 'stage';

const STEP = {
  P1: 'P1',
  P2: 'P2',
  P3: 'P3',
}

const PANELS = {
  PREZ: 'panelPrez',
  HEADER: 'panelHeader',
  PROCESS: 'panelProcess',
};

// Check if a round is in the partner's shares
const hasRoundInShares = (partner, round) => round in partner?.partnerInfo?.SHARES;
// Check if a round's stage is in P2 in the partner's shares
const hasStageP2InShares = (partner, round) => STAGE in partner?.partnerInfo?.SHARES?.[round] && partner?.partnerInfo?.SHARES?.[round]?.stage?.P2;
// Check if a round is in the partner's ESOP and its quantity is more than 0
const hasPositiveQuantityInESOP = (partner, round) => round in partner?.partnerInfo?.ESOP && partner?.partnerInfo?.ESOP?.[round]?.quantity > 0;

const checkPermission = (partner, round) => {
  if (hasRoundInShares(partner, round)) {
    if (hasStageP2InShares(partner, round)) return true;
    if (hasPositiveQuantityInESOP(partner, round)) return false;
  }
  return false;
}


const RoundExpansionDetail = (props) => {

  const { stage, title, round, classes, disabled, steeringObj, currentPanel, handleChange } = props;

  const panelName = `panelDataRoom${stage}`;

  const stageMessages = {
    [STEP.P1]: "you are not yet invited for this round",
    [STEP.P2]: "you are not yet invited for this round",
    [STEP.P3]: "you must set an investment intention to unlock this data room (click participate)"
  };  

  let titleName = `CrowdSec Data Room (${stage})`;
  if (disabled) titleName += ` ${stageMessages?.[stage] || ''}`;

  return (
    <Accordion
      disabled={disabled}
      className={classes.expansion}
      onChange={handleChange(panelName)}
      expanded={currentPanel === panelName}
    >
      <AccordionSummary
        id={`${panelName}-header`}
        aria-controls={`${panelName}-content`}
        expandIcon={ disabled ? <LockIcon/> : <ExpandMoreIcon /> }
      >  
        <DescriptionIcon />&nbsp;
        <Typography variant="body2">{titleName}</Typography>
      </AccordionSummary>

      <AccordionDetails>
        <Card className={classes.card}>
          <CardContent>
            <DataStudioCard
              {...props}
              noCard={true}
              title={title}
              ratio={569/960}
              url={steeringObj?.SHARES[round]?.description?.new_data_room?.[stage] || "null"}
            />
          </CardContent>
        </Card>
      </AccordionDetails>
    </Accordion>

  );
}

const Round = props => {

    const { round, classes, firebase, partner, steeringObj } = props;

    const [imageUrl, setImageUrl] = React.useState('');
    const [stepperHidden, setStepperHidden] = React.useState(true);
    const [currentPanel, setCurrentPanel] = React.useState(PANELS.HEADER);

    const minimum = steeringObj?.SHARES?.[round]?.minimum || 0;
    const title = steeringObj?.SHARES?.[round]?.description?.title || 'no title';
    const subTitle = steeringObj?.SHARES?.[round]?.description?.subTitle || 'no subtitle';

    firebase.getImage(steeringObj.SHARES[round].description.image, setImageUrl);
    
    const onCloseStepper = event => setStepperHidden(true);

    const handleChange = (panel) => (event, isExpanded) => setCurrentPanel(isExpanded ? panel : false );

    const checkIfPartnerCanAccessDataRoom = (phase) => {
      if ('new_data_room' in steeringObj.SHARES[round].description) {
        if (round in partner.partnerInfo.SHARES)
          return ((STAGE in partner.partnerInfo.SHARES[round]) && partner.partnerInfo.SHARES[round].stage[phase]);
      }
      return false;
    };

    const onSubmit = event => {
      event.preventDefault();
      setStepperHidden(false);
    }
   
    return(
      <Grid container spacing={1}>

        <Grid item xs={12} sm={12} md={stepperHidden ? 12 : 6} >

          <Accordion
            className={classes.expansion}
            onChange={handleChange(PANELS.HEADER)}
            expanded={currentPanel === PANELS.HEADER}
          >
            <AccordionSummary
              id={`${PANELS.HEADER}-header`}
              expandIcon={ <ExpandMoreIcon /> }
              aria-controls={`${PANELS.HEADER}-content`}
            > 
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    <span>{title} <i>({steeringObj.SHARES[round].description.period})</i></span>
                  </Typography>
                  <Typography variant="caption" gutterBottom>
                    &nbsp;{subTitle}
                  </Typography>
                </Grid>
                {currentPanel !== PANELS.HEADER &&
                  <Grid item xs={12}>
                    <div style={{margin: 10}}>
                      <InvestIntentProgress firebase={firebase} round={round} height={120} border={false}/>
                    </div>
                  </Grid>
                }
              </Grid>
            </AccordionSummary>

          <AccordionDetails>

            <Card className={classes.card} variant={"outlined"}>

              {imageUrl ? (
                <CardMedia
                  alt={title}
                  title={title}
                  image={imageUrl}
                  className={classes.media}
                />
              ) : (
                <div align="center">
                  <CircularProgress color="inherit" />
                </div>
              )}

              <CardContent>
                <InvestIntentProgress {...props} border={false} round={round}/>
              </CardContent>

              <div align="center" style={{paddingTop: 10, paddingBottom: 20}}>
                <Button
                  align="center"
                  color="primary"
                  onClick={onSubmit}
                  variant="contained"
                  disabled={(steeringObj.SHARES[round].locked || !steeringObj.SHARES[round].started || !checkPermission(partner, round))}
                >
                  Participate
                </Button>
              </div>

            </Card>

          </AccordionDetails>
        </Accordion>

        </Grid>

        {!stepperHidden && (<Grid item xs={12} sm={12} md={6}>
          <InvestStepper
            {...props}
            round={round}
            minimum={minimum}
            firebase={firebase}
            subheader={subTitle}
            stepperHidden={stepperHidden}
            onCloseStepper={onCloseStepper}
          />
        </Grid>
        )}

        {('process' in steeringObj.SHARES[round].description) && (
        <Grid item xs={12} sm={12} md={12}>
          <Accordion
            expanded={currentPanel === PANELS.PROCESS}
            onChange={handleChange(PANELS.PROCESS)}
            className={classes.expansion}
          >
            <AccordionSummary
              id={`${PANELS.PROCESS}-header`}
              expandIcon={ <ExpandMoreIcon /> }
              aria-controls={`${PANELS.PROCESS}-content`}
            >  
              <AssignmentTurnedInIcon />&nbsp;
              <Typography variant="body2">Round Process</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Card className={classes.card}>
              
                <CardHeader
                  classes={{
                    title: classes.title,
                    subheader: classes.subheader,
                  }}              
                  subheader={'Shows the different stages of the investment round'}
                />

                <CardContent>
                  <DataStudioCard
                    {...props}
                    noCard={true}
                    title={title} ratio={569/960}
                    url={steeringObj.SHARES[round].description.process}
                  />
                </CardContent>

              </Card>
            </AccordionDetails>
          </Accordion>

        </Grid>
        )}

        {('prez' in steeringObj.SHARES[round].description) && (
        <Grid item xs={12} sm={12} md={12}>
          <Accordion
            className={classes.expansion}
            onChange={handleChange(PANELS.PREZ)}
            expanded={currentPanel === PANELS.PREZ}
          >
            <AccordionSummary
              id={`${PANELS.PREZ}-header`}
              expandIcon={ <ExpandMoreIcon /> }
              aria-controls={`${PANELS.PREZ}-content`}
            >  
              <SlideshowIcon />&nbsp;
              <Typography variant="body2">CrowdSec Presentation</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Card className={classes.card}>

                <CardHeader
                  classes={{
                    title: classes.title,
                    subheader: classes.subheader,
                  }}              
                  subheader={"Presentation's deck"}
                />

                <CardContent>
                  <DataStudioCard
                    {...props}
                    noCard={true}
                    title={title}
                    ratio={569/960}
                    url={steeringObj.SHARES[round].description.prez}
                  />
                </CardContent>

              </Card>
            </AccordionDetails>
          </Accordion>
        </Grid>
        )}

        {Object.keys(STEP).map((key) => (
          <Grid item xs={12} sm={12} md={12} key={key}>
            <RoundExpansionDetail
              stage={key}
              title={title}
              round={round}
              classes={classes}
              steeringObj={steeringObj}
              handleChange={handleChange}
              currentPanel={currentPanel}
              disabled={!checkIfPartnerCanAccessDataRoom(key)}
            />
          </Grid>
        ))}

      </Grid>
    );
}

export default Round;