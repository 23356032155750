import React from 'react';
import { Grid } from '@material-ui/core';
import FileCard from '../../utils/GenericComponents/filesCard';

const ReportingComponent = (props) => {

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <FileCard {...props} path={"reporting"} title="Quarterly reports" description="Will appear in partner's area" showLast={true}/>
            </Grid>
            <Grid item xs={6}>
                <FileCard {...props} path={"balance"} title="Annual reports" description="Will appear in partner's area" showLast={true}/>
            </Grid>
        </Grid>
    );
}

export default ReportingComponent;
