import React from 'react';
import SimpleUserBase from './SimpleUserBase';
import * as ROLES from '../../constants/roles';
import withWidth from '@material-ui/core/withWidth';
import { AuthUserContext, withAuthorization } from '../Session';

import { useStyles } from './styles';

const condition = authUser => authUser != null;
const conditionToAccessMarketing = authUser => authUser && (!!authUser.roles[ROLES.ADMIN] || !!authUser.roles[ROLES.EMPLOYEE] || !!authUser.roles[ROLES.MARKETING]);

const SimpleUser = props => {

  const classes = useStyles();

  return(
      <AuthUserContext.Consumer>
      { authUser => <SimpleUserComponent {...props} authUser={authUser} classes={classes} conditionToAccessMarketing={conditionToAccessMarketing} /> }
      </AuthUserContext.Consumer>
  );
}

const SimpleUserComponent = withAuthorization(condition)(SimpleUserBase);

export default withWidth()(SimpleUser);