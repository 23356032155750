import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import { Typography } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import CardHeader from '@material-ui/core/CardHeader';
import BarChartIcon from '@material-ui/icons/BarChart';
import CardContent from '@material-ui/core/CardContent';
import React, {createRef, useRef, useState, useEffect} from 'react';

import { useStyles } from './styles';

const IFrameChart = (props) => {
  const { src, title, showChart, classesComponent } = props;
  return (
    <>
      { (showChart)
        ? <iframe
            src={src}
            title={title}
            allowFullScreen
            frameBorder="0"
            className={classesComponent.dashboardIframe}
        />
        : <Typography className={classesComponent.dashboardIframe} variant="body1">Loading...</Typography>
      }
    </>
  );
}

const DataStudioCard = props => {

  const { id, url, ratio, title, noCard, lastUpdate, getUrlDataStudio } = props;
  
  const container = createRef();
  const chartObserverRef = useRef(null);
  const [showChart, setShowChart] = useState(false);

  useEffect(() => {
    chartObserverRef.current = new IntersectionObserver(onChartIntersection, {
      threshold: 0.25,
      rootMargin: '100px 0px',
    });

    if (window && 'IntersectionObserver' in window) {
      if (container && container.current)
        chartObserverRef.current.observe(container.current);
    } else setShowChart(true);

    // Cleanup on unmount
    return () => {
      if (chartObserverRef.current)
        chartObserverRef.current.disconnect();
    };
  }, [container]);

  function onChartIntersection(entries) {
    if (!entries || entries.length <= 0) return;

    if (entries[0].isIntersecting) {
      setShowChart(true);
      chartObserverRef.current.disconnect();
    }
  }

  const sm = (props.width === 'sm' );
  const classes = useStyles({ ratio });
  const src = url === undefined ? getUrlDataStudio(id) : url;

  return(
    <>
      {(!noCard) && (
        <Card className={classes.indicatorCard}> 
          <CardHeader
            align="left"
            title={title}
            subheader={`Last update : ${lastUpdate}`}
            classes={{
              title: classes.title,
              subheader: classes.subheader,
            }}                          
            avatar={!sm ?
              <Avatar aria-label="recipe" className={classes.avatar}>
                <BarChartIcon />
              </Avatar> : null
            }
          />
          <CardContent className={classes.dashboardContainer} ref={container}>
            <IFrameChart src={src} title={title} showChart={showChart} classesComponent={classes} />
          </CardContent>
        </Card>
      )}

      {noCard && (
        <div className={classes.dashboardContainer} ref={container}>
          <IFrameChart src={src} title={title} showChart={showChart} classesComponent={classes} />
        </div>
      )}
    </>
  );
}

export default withWidth()(DataStudioCard)
