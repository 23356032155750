import React from 'react';
import PropTypes from 'prop-types';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';

import { useStyles } from './styles';

const TabPanel = (props) => {
    const { children, value, index, classes, ...other } = props;

    return (
        <div
            {...other}
            role="tabpanel"
            hidden={value !== index}
            id={`text-tabpanel-${index}`}
            className={classes.tabPanelDiv}
            aria-labelledby={`text-tab-${index}`}
        >
            {value === index && (
            <Box p={3} >
              <Typography variant="caption" className={classes.infoFont}>{children}</Typography>
            </Box>
        )}
        </div>
    );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `text-tab-${index}`,
    'aria-controls': `text-tabpanel-${index}`,
  };
}

const LaborInspectionText = (props) => {
    const { text } = props;
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => setValue(newValue);

    return (
        <div className={classes.root}>
            <AppBar position="static" className={classes.appBar}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    className={classes.tabs}
                    aria-label="text-en-fr-labor"
                    classes={{ indicator: classes.indicator}}
                >
                    <Tab disableRipple label="FR" {...a11yProps(0)} />
                    <Tab disableRipple label="EN" {...a11yProps(1)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0} classes={classes}>
                {text?.fr || "loading ..."}
            </TabPanel>
            <TabPanel value={value} index={1} classes={classes}>
                {text?.en || "loading ..."}
            </TabPanel>
        </div>
    );
}

export default LaborInspectionText;
