import * as ROLES from '../../../constants/roles';
import * as ROUTES from '../../../constants/routes';

const adminCondition = authUser => authUser && !!authUser.roles[ROLES.ADMIN];
const partnerCondition = authUser => authUser && (!!authUser.roles[ROLES.ADMIN] || !!authUser.roles[ROLES.PARTNER]);
const employeeCondition = authUser => authUser && (!!authUser.roles[ROLES.ADMIN] || !!authUser.roles[ROLES.EMPLOYEE]);
//const partnerShipsCondition = authUser => authUser && (!!authUser.roles[ROLES.ADMIN] || !!authUser.roles[ROLES.PARTNERSHIPS] || !!authUser.roles[ROLES.SALES] || !!authUser.roles[ROLES.PARTNERSHIPS_ADMIN]);

export const menus = [
    { label : "Company", to: ROUTES.SIMPLE_USER, condition: (u) => true },
    { label : "Employee's area", to: ROUTES.EMPLOYEE, condition: (u) => employeeCondition(u) },
    { label : "Partner's area", to: ROUTES.PARTNER, condition: (u) => partnerCondition(u) },
    { label : "Steering Tools", to: ROUTES.INDICATORS, condition: (u) => partnerCondition(u) },
    //{ label : "Partnerships area", to: ROUTES.PARTNERSHIPS, condition: (u) => partnerShipsCondition(u) },
    { label : "Admin", to: ROUTES.ADMIN, condition: (u) => adminCondition(u) },
];

