import React from 'react';
import LaborInspectionText from './text';
import PolicyIcon from '@material-ui/icons/Policy';
import { Grid, Typography } from '@material-ui/core';
import FloatingCard from '../../../utils/GenericComponents/floatingCard';

import { useStyles } from './styles';

const EmployeeLaborInspection = props => {

    const { inspectionObject } = props;

    const classes = useStyles();

    return (
        <FloatingCard
            noflex={true}
            avatar={<PolicyIcon />}
            title="Labor Inspection"
            subheader={<i>In accordance with labor law.</i>}
        >
            <div style={{ marginTop: -30 }}>
                <Grid container spacing={8} direction="row" justifyContent="space-evenly" alignItems="center">
                    <Grid item xs={12} sm={12} md={6}>
                        <Grid container spacing={1} direction="column" justifyContent="center" alignItems="center">
                            <Grid item xs={12}>
                                <div className={classes.miniPanel} align="center">
                                    <Typography variant="body1" align="center" className={classes.infoFont}>
                                        <strong>{inspectionObject.name}</strong><br/>
                                        <i>{inspectionObject.description}</i><br/>
                                        {inspectionObject.address}<br/>
                                        {inspectionObject.zip} {inspectionObject.city}<br/>
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="caption">
                                    Téléphone : <strong>{inspectionObject.phone}</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="caption">
                                    Mail : <strong>{inspectionObject.mail}</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="caption">
                                    Agents compétents : <strong>{inspectionObject.agent}</strong>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} align="justify">
                        <LaborInspectionText text={inspectionObject.text} />
                    </Grid>
                </Grid>
            </div>
        </FloatingCard>
    );
  }
  
  export default EmployeeLaborInspection;

  /*


*/