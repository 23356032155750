import { withStyles } from '@material-ui/core/styles';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';

export const DialogContent = withStyles((theme) => ({
    root: {
      marginTop: 20,
      width: '100%',
      padding: theme.spacing(1),
    },
}))(MuiDialogContent);
  
export const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(0),
    },
}))(MuiDialogActions);
