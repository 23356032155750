import axios from 'axios';
import React, { useState } from 'react';
import fileDownload from 'js-file-download';
import DescriptionIcon from '@material-ui/icons/Description';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Typography, Grid, Button, Tooltip } from '@material-ui/core';
import FloatingCard from '../../../utils/GenericComponents/floatingCard';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';

const widthToRatio = {
    xs: 0.5,
    sm: 0.5,
    md: 0.8,
    lg: 0.9,
    xl: 1.5,
};

const PdfCard = props => {

    const { width, lastPaySlipFile } = props;
    const { fileName, url } = lastPaySlipFile || {};
    
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [loadingError, setLoadingError] = useState(false);

    const openInTab = () => window.open(url);
    const getRatio = () => widthToRatio[width] || 0.5;
    const closeErrorDialog = () => setLoadingError(null);
    const nextPage = () => setPageNumber(pageNumber + 1);
    const previousPage = () => setPageNumber(pageNumber - 1);
    const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);
    const onLoadProgress = ({ loaded, total }) => (loaded === total) && setPageNumber(1);

    const handleDownload = async () => {
        try {
            const res = await axios.get(url, { responseType: 'blob' });
            fileDownload(res.data, fileName);
        } catch (error) {
            console.error("An error occurred while downloading the file: ", error);
            setLoadingError("An error occurred while downloading the file. Please try again later.");
        }
    };   

    return (
        <FloatingCard
            noflex={true}
            avatar={<DescriptionIcon />}
            title={fileName || "Document"}
        >
            { url && String(url).indexOf(".pdf") !== -1 && (
                <>
                    <Grid container justifyContent="space-between">
                        <Grid item xs={12} align="center">
                            <Document file={url || ""} onLoadSuccess={onDocumentLoadSuccess} onLoadProgress={onLoadProgress}>
                                <Page pageNumber={pageNumber} scale={getRatio()}/>
                            </Document>                            
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" color="textSecondary" gutterBottom style={{paddingTop: 10}}>
                                Page - {pageNumber} / Pages - {numPages}{"    "}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Tooltip title="Previous Page">
                                <span>
                                    <Button onClick={previousPage} color="primary" disabled={pageNumber === 1}>
                                        <ArrowBackIosIcon />
                                    </Button>
                                </span>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip title="Next Page">
                                <span>
                                    <Button onClick={nextPage} color="primary" disabled={pageNumber === numPages}>
                                        <ArrowForwardIosIcon />
                                    </Button>
                                </span>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Button autoFocus onClick={handleDownload} color="primary">
                                Download
                            </Button>
                            <Button onClick={openInTab} color="primary">
                                Open in tab
                            </Button>
                        </Grid>
                    </Grid>
                </>
            )}
            { url && String(url).indexOf(".pdf") === -1 && <img src={url} alt="last pay slip" />}
            { !url && (
                <Typography variant="body2" color="textSecondary" component="div" >
                    Nothing to show.
                </Typography>
            )}

            <Dialog open={!!loadingError} onClose={closeErrorDialog}>
                <DialogTitle>Error</DialogTitle>
                <DialogContent>
                    <DialogContentText>{loadingError}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeErrorDialog} color="primary" autoFocus>OK</Button>
                </DialogActions>
            </Dialog>

        </FloatingCard>
    );
};

export default PdfCard;
  