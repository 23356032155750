
import InfoBox from '../../infoBox';
import List from '@material-ui/core/List';
import PhoneInput from 'react-phone-input-2'
import Tooltip from '@material-ui/core/Tooltip';
import { Button, Grid } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import CreateIcon from '@material-ui/icons/Create';
import IconButton from '@material-ui/core/IconButton';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import { VERY_LIGHT_PURPLE3 } from '../../../../constants/colors';
import FloatingCard from '../../../utils/GenericComponents/floatingCard';

import 'react-phone-input-2/lib/style.css'

const PersonalDetails = (props) => {
  const [edit, setEdit] = useState(false);
  const [error, setError] = useState(false);
  const [employee, setEmployee] = useState({ ...props.employee });

  useEffect(() => {
    if (employee.uid !== props.employee.uid || JSON.stringify(props.employee) !== JSON.stringify(employee)) {
      setEmployee({ ...props.employee });
    }
  }, [props.employee, employee]);

  const handleSave = (event) => {
    setEdit(false);
    props.saveUserInfos(employee);
  };

  const handleEdit = (event) => {
    if (edit)
      props.reload();
    setEdit(!edit);
  };

  const changeUserValue = (key, value) => {
    const currentEmployee = { ...employee };
  
    const sepIndex = key.indexOf(".");
    if (sepIndex !== -1) {
      const key1 = key.substring(0, sepIndex);
      const key2 = key.substring(sepIndex + 1);
      currentEmployee[key1][key2] = value;
    } else {
      currentEmployee[key] = value;
    }
  
    const error = Object.values(currentEmployee.civil).some(val => val.length === 0);
    setError(error);
    setEmployee({ ...currentEmployee });
  };
  

  return (
    <FloatingCard
      noflex={true}
      title="Personal details"
      header={<ContactPhoneIcon />}
      buttonAction={
        <Tooltip title="edit" arrow>
          <IconButton
            aria-label="edit"
            onClick={handleEdit}
          >
            <CreateIcon />
          </IconButton>
        </Tooltip>
      }
      subheader={edit ? <i>editing</i> : <i>name, address, etc.</i>}
    >
      <List style={{width:'100%'}}>
        <InfoBox
          disabled={!edit}
          title={"Last Name"}
          copyClipboard={true}
          name={"civil.lastName"}
          changeUserValue={changeUserValue}
          value={employee?.civil?.lastName || ""}
        />
        <InfoBox
          disabled={!edit}
          copyClipboard={true}
          title={"First Name"}
          name={"civil.firstName"}
          changeUserValue={changeUserValue}
          value={employee?.civil?.firstName || ""}
        />
        <InfoBox
          disabled={!edit}
          copyClipboard={true}
          title={"Birth Date"}
          name={"civil.birthDate"}
          changeUserValue={changeUserValue}
          value={employee?.civil?.birthDate || ""}
        />
        <InfoBox
          disabled={!edit}
          copyClipboard={true}
          title={"Birth Place"}
          name={"civil.birthPlace"}
        changeUserValue={changeUserValue}
          value={employee?.civil?.birthPlace || ""}
        />
        <InfoBox
          name={"civil.id"}
          disabled={!edit}
          copyClipboard={true}
          title={"Social sec#"}
          changeUserValue={changeUserValue}
          value={employee?.civil?.id || employee?.id || ""}
        />
        <InfoBox
          disabled={!edit}
          title={"Address"}
          copyClipboard={true}
          name={"civil.address"}
          changeUserValue={changeUserValue}
          value={employee?.civil?.address || ""}
        />
        <InfoBox
          title={"ZIP"}
          disabled={!edit}
          name={"civil.zip"}
          copyClipboard={true}
          value={employee?.civil?.zip || ""}
          changeUserValue={changeUserValue}
        />
        <InfoBox
          title={"City"}
          disabled={!edit}
          name={"civil.city"}
          copyClipboard={true}
          changeUserValue={changeUserValue}
          value={employee?.civil?.city || ""}
        />
        <InfoBox
          title={"Country"}
          disabled={!edit}
          copyClipboard={true}
          name={"civil.country"}
          changeUserValue={changeUserValue}
          value={employee?.civil?.country || ""}
        />
        <InfoBox
          disabled={!edit}
          title={"pers. email"}
          copyClipboard={true}
          name={"civil.persomail"}
          changeUserValue={changeUserValue}
          value={employee?.civil?.persomail || ""}
        />
        <InfoBox
            value={null}
            disabled={!edit}
            title={"pers. phone"}
            >
              <PhoneInput
                  disabled={!edit}
                  style={{marginTop: 10}}
                  placeholder="Enter phone number"
                  value={employee?.civil?.persoPhone || ""}
                  onChange={phone => changeUserValue("civil.persoPhone", phone)}
                  inputStyle={{ width: '100%', backgroundColor: edit ? VERY_LIGHT_PURPLE3 : "inherit" }}
              />
        </InfoBox>
        {edit && (
          <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" style={{marginTop: 20}}>
            <Grid item>
              <Button
                align="center"
                color="primary"
                disabled={error}
                variant="contained"
                onClick={handleSave}
              >
                save
              </Button>
            </Grid>
            <Grid item>
              <Button
                align="center"
                color="primary"
                variant="outlined"
                onClick={handleEdit}
              >
                cancel
              </Button>
            </Grid>
          </Grid>
        )}
      </List>
    </FloatingCard>
  );
};

export default PersonalDetails;