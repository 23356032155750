import React  from 'react';
import { diffDate } from '../../utils';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import ErrorIcon from '@material-ui/icons/Error';
import * as ROLES from '../../../constants/roles';
import DescriptionIcon from '@material-ui/icons/Description';
import FileCard from '../../utils/GenericComponents/filesCard';
import CircularProgress from '@material-ui/core/CircularProgress';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';

import { useStyles } from './styles';

const DEFAULT_TRIGRAM = "OCH";

const PATHS = {
    USERS: "users",
    BUDGETS: "budgets",
    DROP_INVOICES: "drop_invoices"
};

const STATUS = {
    BUSY: "BUSY",
    NONE: "none",
    MATCH: "match",
    ERROR: "error",
    UPDATE: "update",
    MATCHED: "matched",
    ORDERED : "ordered",
    MATCHING: "matching",
    CREATING: "creating",
    UPDATING: "updating",
    PROCESSED: "processed",
    PROCESSING: "processing",
};

const ALLOWED_ROLES = [ROLES.HR, ROLES.ORG, ROLES.MARKETING, ROLES.SALES, ROLES.TECH, ROLES.SUPPORT];

const DropInvoice = (props) => {
    const classes = useStyles();
    const roles = ALLOWED_ROLES;
    const { firebase, authUser } = props;
    const [budgets, setBudgets] = React.useState({});
    const [invoices, setInvoices] = React.useState({});
    const [categories, setCategories] = React.useState([]);
    const [allTrigrams, setAllTrigrams] = React.useState([]);

    const retrieveCategories = React.useCallback(budgets => {
        const categories = ["---"];
        const now = new Date();
        Object.keys(budgets).forEach(businessUnitKey => {
            Object.keys(budgets[businessUnitKey])
            .filter(year => year === now.getFullYear().toString() || year === (now.getFullYear() - 1).toString() || year === (now.getFullYear() + 1).toString())
            .forEach(year => {
                Object.values(budgets[businessUnitKey][year]).forEach(quarter => {
                    Object.values(quarter).forEach(category => {
                        if (category?.description) {
                            const description = category.description + ` (${businessUnitKey})`;
                            if (!categories.includes(description))
                                categories.push(description);
                        }
                    });
                });
            });
        });
        setBudgets(budgets);
        setCategories(categories);
    }, []);

    React.useEffect(() => {
        let mounted = true;
        if (mounted) {
            firebase.db.ref(`${PATHS.BUDGETS}`).on('value', snapshot => {
                const budgets = snapshot.val();
                budgets && retrieveCategories(budgets);
            });
            firebase.db.ref(`${PATHS.DROP_INVOICES}`).on('value', snapshot => {
                const invoices = snapshot.val();
                invoices && setInvoices({...invoices});
            });
            firebase.db.ref(PATHS.USERS).on('value', snapshot => {
                const users = snapshot.val();
                const employees = Object.keys(users).filter(key => users[key].roles[ROLES.EMPLOYEE]).map(key => ({ uid: key, ...users[key] }));
                employees.forEach(employee => {
                    delete employee.id;
                    delete employee.app;
                    delete employee.civil;
                    delete employee.accounting;
                    delete employee.partnerInfo;
                    delete employee.notifications;
                });
                users && setAllTrigrams(employees.filter(employee => employee.contract.end.length === 0).sort((a, b) => diffDate(a.contract.start, b.contract.start)));
            });
        }
        return () => {
            mounted = false;
            firebase.db.ref(PATHS.USERS).off();
            firebase.db.ref(`${PATHS.BUDGETS}`).off();
            firebase.db.ref(`${PATHS.DROP_INVOICES}`).off();
        }
    }, [firebase.db, retrieveCategories]);

    const updateInvoiceData = React.useCallback((fileName, data) => {
        const key = forgeKeyFromFilename(fileName);
        // update the given data in the database for the given file and triggers the matching algorithm
        firebase.db.ref(`${PATHS.DROP_INVOICES}`).update({ status: STATUS.MATCH });
        firebase.db.ref(`${PATHS.DROP_INVOICES}/${key}`).update(data);
    }, [firebase.db]);

    const forgeKeyFromFilename = (filename) => filename?.replace(/[^a-zA-Z0-9]/g, '_')?.replace(/_+/g, '_') || "unknown";
    const handleAddTag = React.useCallback((tag, fileName) => updateInvoiceData(fileName, { tag }), [updateInvoiceData]);
    const callbackCategorySelected = React.useCallback((category, fileName) => updateInvoiceData(fileName, { category }), [updateInvoiceData]);

    const handleRemoveTag = React.useCallback(fileName => {
        const key = forgeKeyFromFilename(fileName);
        firebase.db.ref(`${PATHS.DROP_INVOICES}/${key}`).update( { tag: "" } );
    }, [firebase.db]);

    const callbackTrigramSelected = React.useCallback((trigram, fileName) => {
        const key = forgeKeyFromFilename(fileName);
        firebase.db.ref(`${PATHS.DROP_INVOICES}`).update({ status: STATUS.UPDATE });
        firebase.db.ref(`${PATHS.DROP_INVOICES}/${key}`).update({ trigram });
    }, [firebase.db]);

    const getRole = React.useCallback(() => {
        const foundRole = Object.keys(authUser.roles).find(userRole => roles.includes(userRole) && userRole !== ROLES.ORG && userRole !== ROLES.HR);
        return foundRole ? foundRole.toLowerCase() : "org";
    }, [authUser.roles, roles]);
    
    const callbackDelete = React.useCallback(fileName => {
        const key = forgeKeyFromFilename(fileName);
        firebase.db.ref(`${PATHS.DROP_INVOICES}/${key}`).remove();
    }, [firebase.db]);

    const giveClearBreadcrumb = React.useCallback(payload => {
        const elements = payload.breadcrumb.split(" > ");
        const category = budgets?.[elements?.[0]]?.[elements?.[1]]?.[elements?.[2]]?.[elements[3]]?.description || "unknown";
        const item = budgets?.[elements?.[0]]?.[elements?.[1]]?.[elements?.[2]]?.[elements[3]]?.[elements[4]]?.description || "unknown";
        elements[4] = item;
        elements[3] = category;
        return (
            <Box flexDirection={"column"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                <span>{elements.join(" > ")}</span>
                <span>Confidence: {payload.confidence}</span>
                <span>Amount: {payload.amount}</span>
            </Box>
        );
    }, [budgets]);

    const giveAmounts = React.useCallback(filePayload => {
        const amountHT = filePayload?.netAmount || 0;
        const amountTTC = filePayload?.totalAmount || 0;
        return (
            <Box flexDirection={"column"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                <span>Processed with AI</span>
                {amountHT ? <span>{`Amount HT: ${amountHT}\n`}</span> : null}
                <span>Amount TTC: {amountTTC}</span>
            </Box>
        );
    }, []);

    const setProcessing = React.useCallback((file) => {
        const key = forgeKeyFromFilename(file.name);
        const payload = {
            //test: true, // for testing purpose (will add a line in 'lolo_test' tab of the spreadsheet)
            test: false, // for production
            role: getRole(),
            timeStamp: Date.now(),
            status: STATUS.PROCESSING,
            url: file?.url || "unknown_url",
            name: file?.name || "unknown_file_name",
            trigram: authUser?.trigram || DEFAULT_TRIGRAM,
        }
        firebase.db.ref(`${PATHS.DROP_INVOICES}/${key}`).update(payload)
        .then(() => true)
        .catch(error => console.error("error while storing key [" + key + "] : " + error));
    }, [firebase.db, authUser, getRole]);

    const callbackUploadSuccess = React.useCallback(files => {
        (files instanceof FileList) ? Array.from(files).forEach(file => setProcessing(file)) : setProcessing(files);
        firebase.db.ref(`${PATHS.DROP_INVOICES}`).update({ status: STATUS.PROCESSING });
    }, [firebase.db, setProcessing]);

    const callbackGiveSpecificIcon = React.useCallback(filename => {
        filename = forgeKeyFromFilename(filename);
        if (invoices[filename]) {
            if (invoices[filename].status === STATUS.PROCESSING || invoices[filename].status === STATUS.MATCHING) {
                return (
                    <Tooltip title="Processing with AI" arrow>
                        <div className={classes.statusIcon}>
                            <CircularProgress size={15} />
                        </div>
                    </Tooltip>
                );
            } else if (invoices[filename].status === STATUS.ERROR) {
                return (
                    <Tooltip title="Error while processing" arrow>
                        <ErrorIcon color="error" className={classes.statusIcon} />
                    </Tooltip>
                );
            } else if (invoices[filename].status === STATUS.MATCHED || invoices[filename].matched) {
                return (
                    <Tooltip title={giveClearBreadcrumb(invoices[filename].matched)} arrow>
                        <PlaylistAddCheckIcon color="primary" className={classes.statusIcon} />
                    </Tooltip>
                );
            } else if (invoices[filename].status === STATUS.ORDERED) {
                return (
                    <Tooltip title="Payment ordered" arrow>
                        <MonetizationOnIcon color="primary" className={classes.statusIcon} />
                    </Tooltip>
                );
            } else if (invoices[filename].status === STATUS.PROCESSED) {
                return (
                    <Tooltip title={giveAmounts(invoices[filename])} arrow>
                        <DescriptionIcon color="primary" className={classes.statusIcon} />
                    </Tooltip>
                );
            }
        }
        return null;
    }, [classes.statusIcon, invoices, giveClearBreadcrumb]);

    return (
        <>
            <FileCard
                {...props}
                categories={categories}
                title={"Drop Invoices"}
                allTrigrams={allTrigrams}
                path={PATHS.DROP_INVOICES}
                callbackAddTag={handleAddTag}
                callbackDelete={callbackDelete}
                callbackRemoveTag={handleRemoveTag}
                callbackUploadSuccess={callbackUploadSuccess}
                callbackTrigramSelected={callbackTrigramSelected}
                callbackCategorySelected={callbackCategorySelected}
                callbackGiveSpecificIcon={callbackGiveSpecificIcon}
                filters={(authUser.roles?.[ROLES.ADMIN]) ? [] : [authUser.trigram]}
                description="Drop invoices here and qualify them with categories and tags"
            />
        </>
    );


}
export default DropInvoice;
