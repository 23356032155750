import React from 'react';
import * as ROLES from '../../constants/roles';
import PartnerPageBase from './PartnerPageBase';
import withWidth from '@material-ui/core/withWidth';
import { AuthUserContext, withAuthorization } from '../Session';

import { useStyles } from './styles';

const PartnerPage = props => {
  
  const options = { width : props.width };

  const classes = useStyles(options);

  return(
    <AuthUserContext.Consumer>
      { authUser => <PartnerPageComponent {...props} partner={authUser} classes={classes} />}
    </AuthUserContext.Consumer>
  );
}

const condition = authUser => authUser && (!!authUser.roles[ROLES.ADMIN] || !!authUser.roles[ROLES.PARTNER]);

const PartnerPageComponent = withAuthorization(condition)(PartnerPageBase);

export default withWidth()(PartnerPage);
