import React from 'react';
import Chart from "react-google-charts";
import Box from '@material-ui/core/Box';
import WarningIcon from '@material-ui/icons/Warning';
import { Grid, Typography } from '@material-ui/core';
import { YELLOW } from '../../../../../constants/colors';

import { chartOptionsForecast, chartOptionsRealized, useStyles, AntSwitch } from './styles';


const TYPE = {
    FORECAST : 'Forecast',
    REALIZED : 'Realized',
};

export const AntSwitchButton = (props) => {
    const { bpBudget, handleChange} = props;
    return (
        <Grid container spacing={1} alignItems="center" justifyContent="center" component="label" style={{paddingRight: 0, maxWidth: 180}}>
            <Grid item style={{paddingBottom: 8}}>
                <span style={{fontSize: 8}}>Only allocated</span>
            </Grid>
            <Grid item >
                <AntSwitch checked={bpBudget} onChange={handleChange} name="bpBudget" />
            </Grid>
            <Grid item style={{paddingBottom: 8}}>
                <span style={{fontSize: 8}}>All</span>
            </Grid>
        </Grid>
    );
}

const DonutChart = props => {

    return (
        <div >
            {props && props.data.length > 0 ? (
                <Chart
                    data={props.data}
                    chartType="PieChart"
                    loader={<div>Loading Chart...</div>}
                    rootProps={{ 'data-testid': props.title }}
                    options={props.type === TYPE.FORECAST ? chartOptionsForecast(props.title) : chartOptionsRealized(props.title)}
                />
            ) : (
                <Typography variant="h5" color="textSecondary" component="p" align="center">No data yet</Typography>
            )}
        </div>
    )
}

const DonutChartBudgetAllocation = (props) => {
    const classes = useStyles();
    const { year, chartData, allocated } = props;
    const [bpBudget, setBpBudget] = React.useState(false);
    const [remainingForecast, setRemainingForecast] = React.useState(0);
    const [remainingRealized, setRemainingRealized] = React.useState(0);
    const [bpBudgetAllocation, setBpBudgetAllocation] = React.useState([]);
    const [realizedBudgetAllocation, setRealizedBudgetAllocation] = React.useState([]);

    const handleChange = () => setBpBudget(!bpBudget);

    React.useEffect(() => {
        let mounted = true;
        if (mounted && chartData) {
            let totalRealized = 0;
            let totalForecast = 0;
            const realizedData = [['Realized', 'percent']];
            const forecastData = [['Forecast', 'percent']];
            Object.keys(chartData).forEach(category => {
                if (chartData[category].forecast > 0 || chartData[category].real > 0) {
                    const real = parseFloat(chartData[category].real);
                    const forecast = parseFloat(chartData[category].forecast);
                    totalRealized += real;
                    totalForecast += forecast;
                    realizedData.push([category, { v: real, f: `${real.toLocaleString()} €` }]);
                    forecastData.push([category, { v: forecast, f: `${forecast.toLocaleString()} €` }]);
                }
            });
            realizedData.splice(1, 0, ['Non consumed', bpBudget ? Math.max(0, allocated - totalRealized) : 0]);
            forecastData.splice(1, 0, ['Non allocated', bpBudget ? Math.max(0, allocated - totalForecast) : 0]);
            setBpBudgetAllocation(forecastData);
            setRealizedBudgetAllocation(realizedData);    
            setRemainingForecast(Math.round(allocated - totalForecast));
            setRemainingRealized(Math.round(allocated - totalRealized));
        }
        return () => mounted = false;
    }, [bpBudget, chartData, setBpBudgetAllocation, setRealizedBudgetAllocation]);

    
    return (
        <div style={{ border: '1px solid gray' }}>
            <Grid container spacing={0} justifyContent='center' alignItems='center'>
                <Grid item xs={12} className={classes.gridItem} >
                    <AntSwitchButton bpBudget={bpBudget} handleChange={handleChange} />
                </Grid>
                <Grid item xs={6}>
                    <DonutChart data={bpBudgetAllocation} title={`BP Budget Allocation ${year.value}`} type={TYPE.FORECAST}/>
                </Grid>
                <Grid item xs={6}>
                    <DonutChart data={realizedBudgetAllocation} title={`Realized Budget Allocation ${year.value}`} type={TYPE.REALIZED}/>
                </Grid>
                <Grid item xs={6} align="center">
                    <Box display="flex" justifyContent="center" alignItems="center" style={{marginTop: -10, marginBottom: 5}}>
                        {remainingForecast < 0 ? <WarningIcon style={{color: YELLOW, fontSize: 20, marginRight: 10}}/> : null}
                        <Typography variant="h6" color="textSecondary" component="p" align="center">Forecast: <i>(remaining: {remainingForecast.toLocaleString()}€)</i></Typography>
                    </Box>
                </Grid>
                <Grid item xs={6} align="center">
                    <Box display="flex" justifyContent="center" alignItems="center" style={{marginTop: -10, marginBottom: 5}}>
                        {remainingRealized < 0 ? <WarningIcon style={{color: YELLOW, fontSize: 20, marginRight: 10}}/> : null}
                        <Typography variant="h6" color="textSecondary" component="p" align="center">Realized : <i>(remaining: {remainingRealized.toLocaleString()}€)</i></Typography>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};

export default DonutChartBudgetAllocation;
