import React from 'react';
import Box from '@material-ui/core/Box';
import { useRowStyles } from './styles';
import Chip from '@material-ui/core/Chip';
import { Tooltip } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import { roundPercent } from '../../../utils';
import TableRow from '@material-ui/core/TableRow';
import Collapse from '@material-ui/core/Collapse';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import HistoryPartnerRow from './historyPartnerRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import * as COLORS from '../../../../constants/colors';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const getRoundStatus = (round) => {
  let status = "not yet open";
  if (round.started && round.funded)  status = "terminated";
  if (round.started && !round.funded) status = "in progress";
  return status;
}
  
const getRoundStatusColor = (round) => {
  let bgColor = COLORS.LIGHT_GRAY;
  if (round.started && round.funded)  bgColor = COLORS.LIGHT_GREEN;
  if (round.started && !round.funded) bgColor = COLORS.ORANGE;
  return bgColor;
}
  
const PartnerCell = ({sm, children, align}) => {
  return(
    <TableCell align={align}>
      <Typography component={'div'} variant={sm ? "caption" : "body2"} >{children}</Typography>
    </TableCell>
  );
};
    
const PartnerRow = (props) => {

  const { partner, steeringObj, rounds, firebase, width, handleDocumentList, onSaveUserWithPartnerInfos } = props;
  
  const sm = (width === 'sm') || (width === 'xs');

  const pnl = partner.pnl;
  const classes = useRowStyles({pnl : pnl});
  const [open, setOpen] = React.useState(false);

  const userInvestment = partner.totalInvestment;
  const percentPnl = Math.round(10000 * ((userInvestment) ? pnl / userInvestment : (pnl > 0) ? 1 : 0)) / 100;
  const multiple = userInvestment ? `x${Math.round((pnl + userInvestment) / userInvestment)}` : "-";

  return (
    <>
      <TableRow hover className={classes.root}>

        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        
        <TableCell component="th" scope="row">
          {partner.civil.lastName} {!sm ? partner.civil.firstName : ''}
        </TableCell>
        
        <PartnerCell sm={sm} align="center">
          {`${roundPercent(partner.own, 1)} %`}
        </PartnerCell>
        
        <PartnerCell sm={sm}  align="center">
          {rounds.map(round => (
              ((round.round in partner.partnerInfo.SHARES) && (
              <Tooltip title={getRoundStatus(round)} key={`${round.round}${partner.trigram}`}>
                <Chip
                  size="small"
                  style={{ backgroundColor: getRoundStatusColor(round)}}
                  label={<Typography variant="caption">{round.round}</Typography>}
                />
              </Tooltip>
              ))
          ))}
        </PartnerCell>
        
        <PartnerCell sm={sm}  align="center">{`${userInvestment} €`}</PartnerCell>
        
        <PartnerCell sm={sm}  align="center">{multiple}</PartnerCell>

        <PartnerCell sm={sm}  align="right">
            <Tooltip title={`${(percentPnl > 0) ? "+" : ""}${percentPnl}%`}>
                <Typography variant="body2" className={classes.pnl}>
                    {(pnl !== 0) ? `${pnl.toLocaleString()} €` : ' - €'}
                </Typography>
            </Tooltip>
        </PartnerCell>
      
      </TableRow>

      <TableRow style={{backgroundColor: COLORS.VERY_LIGHT_PURPLE3}}>
        
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1} >
              <Typography variant={sm ? "body1" : "h6"} gutterBottom component="div">
                Partner's details
              </Typography>
              <Table size="small" aria-label="partner-details" rowkey="id">
                <TableHead>
                  <TableRow>
                    <PartnerCell sm={sm} align="left">Round</PartnerCell>
                    <PartnerCell sm={sm} align="left">Files</PartnerCell>
                    <PartnerCell sm={sm} align="left">Date</PartnerCell>
                    <PartnerCell sm={sm} align="right">{sm ? "Shares" : "Nb shares"}</PartnerCell>
                    <PartnerCell sm={sm} align="right">{sm ? "BSPCE" : "Nb BSPCE"}</PartnerCell>
                    <PartnerCell sm={sm} align="right">{sm ? "Price" : "Price / Strike"}</PartnerCell>
                    <PartnerCell sm={sm} align="right">{sm ? "Invest" : "Total invest. (€)"}</PartnerCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {rounds.map(currentRound => (
                    <HistoryPartnerRow
                      width={width}
                      partner={partner}
                      firebase={firebase}
                      key={currentRound.round}
                      steeringObj={steeringObj}
                      currentRound={currentRound}
                      setSnackStatus={props.setSnackStatus}
                      handleDocumentList={handleDocumentList}
                      onSaveUserWithPartnerInfos={onSaveUserWithPartnerInfos}
                    />
                  ))}
                </TableBody>
                
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

    </>
  );
}

  export default PartnerRow;
