import { makeStyles } from '@material-ui/core/styles';
import * as COLORS from '../../../../../constants/colors';

export const useStyles = makeStyles( theme => ( {

    warning: {
        fontSize: 24,
        color: COLORS.YELLOW,
    },

    gridWarning: {
        height: '100%',
        backgroundColor: '#EEE',
        border: '1px solid black',
    },

    dragAndDropDiv: props => ({
        width: '100%',
        margin: 'auto',
        overflowY: 'auto',
        marginBottom: theme.spacing(2),
        height: props?.height || '100%',
    })

})); 
