import React from 'react';
import Grid from '@material-ui/core/Grid';
import Zoom from '@material-ui/core/Zoom';
import Card from '@material-ui/core/Card';
import ButtonBase from '@material-ui/core/ButtonBase';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import { Tooltip, Typography } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import { DARK_PURPLE } from '../../../../constants/colors';
import CardActionArea from '@material-ui/core/CardActionArea';
import FloatingCard from '../../../utils/GenericComponents/floatingCard';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

import { useStyles } from './styles';

const BalanceSheetCard = props => {
  
  const { balance } = props;

  const handleClickFile = () => window.open(balance.url);

  return(
    <Zoom in={true} timeout={1200} >
      <Card style={{width: "100%", border: "none", boxShadow: "none", backgroundColor: 'transparent'}} >
        <CardActionArea onClick={handleClickFile} >
            <CardContent >
                <Tooltip title={balance.name}>
                  <Grid container direction="column" alignItems="center" justifyContent="center">
                    <Grid item>
                      <Typography variant="caption" gutterBottom><DescriptionOutlinedIcon style={{color: DARK_PURPLE, fontSize: '10rem'}}/></Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="caption" ><center>{balance.name}</center></Typography>
                    </Grid>
                  </Grid>
                </Tooltip>
            </CardContent>
        </CardActionArea>
      </Card>
    </Zoom>
  );
};

const BalanceSheetComponent = props => {

  const classes = useStyles();
  const { balanceSheets } = props;
  const [position, setPosition] = React.useState(0);
  const leftHandleChange = () => setPosition((position) => position - 1);
  const rightHandleChange = () => setPosition((position) => position + 1);

  return(
    <FloatingCard title="Financial review" avatar={<MenuBookIcon />}>
    <Grid container direction="row" alignItems="center" justifyContent="center" className={classes.grid}>

        <Tooltip title={position === 0 ? "" : "Previous year"}>
          <Grid item xs={1}>
            <ButtonBase className={classes.image} onClick={leftHandleChange} disabled={position === 0} >
              <KeyboardArrowLeftIcon style={{color: position === 0 ? "#AAA" : "#444"}}/>
            </ButtonBase>
          </Grid>
        </Tooltip>

        <Grid item xs={10}>
          { balanceSheets && balanceSheets.map((balance, index) => (
            position === index && <BalanceSheetCard balance={balance} key={index}/>
          ))}
        </Grid>

        <Tooltip title={position === balanceSheets.length - 1 ? "" : "Next year"}>
          <Grid item xs={1}>
            <ButtonBase className={classes.image} onClick={rightHandleChange} disabled={position === balanceSheets.length - 1}>
              <KeyboardArrowRightIcon style={{color: position === balanceSheets.length - 1 ? "#AAA" : "#444"}}/>
            </ButtonBase>
          </Grid>
        </Tooltip>

      </Grid>
      </FloatingCard>
  );
}

export default BalanceSheetComponent;