import React from 'react';
import Typography from '@material-ui/core/Typography';

import { useStyles } from './styles';

const Disclaimer = ({message}) => {

    const classes = useStyles();

    return(
        <Typography className={classes.disclaimerText} variant="caption" color="textSecondary" component="p" >
            <i>{message}</i>
        </Typography>
    );
}

export default Disclaimer;