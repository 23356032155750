import React from 'react';
import { Grid } from '@material-ui/core';
import { TAGS, BUDGETS } from '../constants';
import Select from '@material-ui/core/Select';
import { PERIODS, TYPOLOGY } from './constants';
import { withFirebase } from '../../../Firebase';
import BarChartExpenses from './barChartExpenses';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import DonutChartBudgetAllocation from './donutChartBudgetAllocation';

import { useStyles } from './styles';

const GraphView = (props) => {

    const classes = useStyles();
    const { firebase } = props;
    const { year, businessUnit } = props;
    const [tags, setTags] = React.useState([]);
    const [chartData, setChartData] = React.useState([]);
    const [period, setPeriod] = React.useState(PERIODS.YEAR.value);
    const [typology, setTypology] = React.useState(TYPOLOGY.CATEGORIES.value);

    const handleChangePeriod = (event) => {
        event.stopPropagation();
        setPeriod(event.target.value);
        typology === TYPOLOGY.TAGS.value ? computeTags() : computeCategories(event.target.value);
    };

    const handleChangeTypology = React.useCallback((event) => {
        event.stopPropagation();
        setTypology(event.target.value);
        event.target.value === TYPOLOGY.TAGS.value ? computeTags() : computeCategories(period);
    }, [period]);

    const computeCategoriesForQuarter = (quarterToCompute, categoriesValues) => {
        const currentYear = year.value;
        Object.keys(businessUnit?.[currentYear] || {}).filter(quarter => quarter === quarterToCompute).forEach(quarter => {
            Object.keys(businessUnit[currentYear][quarter]).filter(category => category !== 'bp_budget').forEach(category => {
                let real = 0;
                let forecast = 0;
                Object.keys(businessUnit[currentYear][quarter][category]).filter(item => item !== 'description').forEach(item => {
                    Object.keys(businessUnit[currentYear][quarter][category][item]).filter(line => line.indexOf("month") !== -1).forEach(month=> {
                        real += parseFloat(businessUnit[currentYear][quarter][category][item][month].real);
                        forecast += parseFloat(businessUnit[currentYear][quarter][category][item][month].forecast);
                    });
                });
                const catDescription = businessUnit[currentYear][quarter][category].description;
                if (!categoriesValues[catDescription]) {
                    categoriesValues[catDescription] = {real, forecast};
                } else {
                    categoriesValues[catDescription].real += real;
                    categoriesValues[catDescription].forecast += forecast;
                }
            });
        });
        return categoriesValues;
    };

    const computeCategories = (quarterToCompute) => {
        const categoriesValues = {};
        if (quarterToCompute === PERIODS.YEAR.value) {
            computeCategoriesForQuarter(PERIODS.Q1.value, categoriesValues);
            computeCategoriesForQuarter(PERIODS.Q2.value, categoriesValues);
            computeCategoriesForQuarter(PERIODS.Q3.value, categoriesValues);
            computeCategoriesForQuarter(PERIODS.Q4.value, categoriesValues);
        }
        else computeCategoriesForQuarter(quarterToCompute, categoriesValues);
        setChartData(categoriesValues);
    }

    const computeTagsForQuarter = (quarterToCompute, categoriesValues) => {
        const currentYear = year.value;
        Object.keys(businessUnit?.[currentYear] || {}).filter(quarter => quarter === quarterToCompute).forEach(quarter => {
            Object.keys(businessUnit[currentYear][quarter]).filter(category => category !== 'bp_budget').forEach(category => {
                Object.keys(businessUnit[currentYear][quarter][category]).filter(item => item !== 'description').forEach(item => {
                    const tag = businessUnit[currentYear][quarter][category][item].tag || 'not tagged';
                    Object.keys(businessUnit[currentYear][quarter][category][item]).filter(line => line.indexOf("month") !== -1).forEach(month=> {
                        const real = parseFloat(businessUnit[currentYear][quarter][category][item][month].real);
                        const forecast = parseFloat(businessUnit[currentYear][quarter][category][item][month].forecast);
                        if (!categoriesValues[tag]) {
                            categoriesValues[tag] = {real, forecast};
                        } else {
                            categoriesValues[tag].real += real;
                            categoriesValues[tag].forecast += forecast;
                        }
                    });
                });
            });
        });
        return categoriesValues;
    };
                        

    const computeTags = () => {
        const tagsValues = {};
        tags.forEach(tag => tagsValues[tag] = {real: 0, forecast: 0});
        if (period === PERIODS.YEAR.value) {
            computeTagsForQuarter(PERIODS.Q1.value, tagsValues);
            computeTagsForQuarter(PERIODS.Q2.value, tagsValues);
            computeTagsForQuarter(PERIODS.Q3.value, tagsValues);
            computeTagsForQuarter(PERIODS.Q4.value, tagsValues);
        }
        else computeTagsForQuarter(period, tagsValues);
        setChartData(tagsValues);
    };

    React.useEffect(() => {
        let mounted = true;
        if (mounted) {
            typology === TYPOLOGY.TAGS.value ? computeTags() : computeCategories(period || PERIODS.YEAR.value);
            firebase.db.ref(`/${BUDGETS}/${TAGS}`).on('value', snapshot => setTags(snapshot.val()));
        }
        return () => {
            mounted = false;
            firebase.db.ref(`/${BUDGETS}/${TAGS}`).off();
        }
    }, [period, typology, firebase, businessUnit]);

    const allocated = (period === PERIODS.YEAR.value) ? year.bp_budget : businessUnit?.[year.value]?.[period]?.bp_budget;


    return (
        <Grid container spacing={1} justifyContent='center' alignItems='center' style={{ border: '1px solid gray'}}>
            <Grid item xs={6} align={'center'}>
                <FormControl className={classes.formControl}>
                    <InputLabel id="period-select-label">Period</InputLabel>
                    <Select
                        value={period}
                        id="period-select"
                        className={classes.select}
                        onChange={handleChangePeriod}
                        labelId="period-select-label"
                    >
                        <MenuItem value={PERIODS.Q1.value} className={classes.menuItem}>{PERIODS.Q1.label}</MenuItem>
                        <MenuItem value={PERIODS.Q2.value} className={classes.menuItem}>{PERIODS.Q2.label}</MenuItem>
                        <MenuItem value={PERIODS.Q3.value} className={classes.menuItem}>{PERIODS.Q3.label}</MenuItem>
                        <MenuItem value={PERIODS.Q4.value} className={classes.menuItem}>{PERIODS.Q4.label}</MenuItem>
                        <MenuItem value={PERIODS.YEAR.value} className={classes.menuItem}>{PERIODS.YEAR.label}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={6} align={'center'}>
                <FormControl className={classes.formControl}>
                    <InputLabel id="typology-select-label">Typology</InputLabel>
                    <Select
                        value={typology}
                        id="typology-select"
                        className={classes.select}
                        onChange={handleChangeTypology}
                        labelId="typology-select-label"
                    >
                        <MenuItem value={TYPOLOGY.TAGS.value} className={classes.menuItem}>{TYPOLOGY.TAGS.label}</MenuItem>
                        <MenuItem value={TYPOLOGY.CATEGORIES.value} className={classes.menuItem}>{TYPOLOGY.CATEGORIES.label}</MenuItem>
                    </Select>
            </FormControl>
            </Grid>

            <Grid item xs={12}>
                <BarChartExpenses {...props} chartData={chartData}/>
            </Grid>

            <Grid item xs={12}>
                <DonutChartBudgetAllocation {...props} chartData={chartData} allocated={allocated}/>
            </Grid>
        </Grid>
    );
}

export default withFirebase(GraphView);