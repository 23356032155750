import GraphView from './graphView';
import YearRow from './tableView/yearRow';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import React, { useCallback } from 'react';
import AddIcon from '@material-ui/icons/Add';
import TocIcon from '@material-ui/icons/Toc';
import { withFirebase } from '../../Firebase';
import LockIcon from '@material-ui/icons/Lock';
import FaceIcon from '@material-ui/icons/Face';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import DeleteIcon from '@material-ui/icons/Delete';
import { YELLOW } from '../../../constants/colors';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import SettingsIcon from '@material-ui/icons/Settings';
import TelegramIcon from '@material-ui/icons/Telegram';
import PieChartIcon from '@material-ui/icons/PieChart';
import DonutSmallIcon from '@material-ui/icons/DonutSmall';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import LocalAirportIcon from '@material-ui/icons/LocalAirport';
import FloatingCard from '../../utils/GenericComponents/floatingCard';
import { ITEM, TAGS, BUDGETS, CATEGORY, NEW_CATEGORY_TBD } from './constants';
import { CircularProgressWithLabel } from '../../utils/GenericComponents/progressBar';
import ConfirmDeleteAlertDialog from '../../utils/GenericComponents/dialogDeleteConfirm';
import { Accordion, AccordionDetails, AccordionSummary, useStyles, useBusinessUnitSelectorStyles } from './styles';

const createDefaultCategory = (index) => ({[`${CATEGORY}${Date.now() + index}`]: { description: NEW_CATEGORY_TBD } });

const TITLE = 'Budgets (Beta)';

const BUSINESS_UNITS = {
    HR: { value: 'hr', label: 'HR', icon: <FaceIcon /> },
    OPS: { value: 'ops', label: 'Ops', icon: <LocalAirportIcon /> },
    TECH: { value: 'tech', label: 'Tech', icon: <SettingsIcon /> },
    SALES: { value: 'sales', label: 'Sales', icon: <AttachMoneyIcon /> },
    MARKETING: { value: 'marketing', label: 'Marketing', icon: <TelegramIcon />}
};

const BudgetPanel = (props) => {
    const classes = useStyles();
    const currentYear = new Date().getFullYear();
    const { firebase, isManager, trigram } = props;
    const [graph, setGraph] = React.useState(false);
    const [snackMsg, setSnackMsg] = React.useState("");
    const [snackOpen, setSnackOpen] = React.useState(false);
    let [businessUnit, setBusinessUnit] = React.useState({});
    const [expanded, setExpanded] = React.useState(currentYear);
    const [selectedYear, setSelectedYear] = React.useState(currentYear);
    const [openDialogConfirmDelete, setOpenDialogConfirmDelete] = React.useState(false);
    const [businessUnitName, setbusinessUnitName] = React.useState(BUSINESS_UNITS.MARKETING.value);

    React.useEffect(() => {
        let mounted = true;
        if (businessUnitName && firebase.db && mounted) {
            firebase.db.ref(`/${BUDGETS}/${businessUnitName}`).on('value', snapshot => setBusinessUnit(snapshot.val()));
        }
        return () => {
            mounted = false;
            firebase.db && firebase.db.ref(`/${BUDGETS}/${businessUnitName}`).off();
        };
    }, [businessUnitName, firebase.db]);


    const onCancelDeleteYearBudget = useCallback(() => setOpenDialogConfirmDelete(false), []);
    const handleChangeBusinessUnitName = useCallback((event, bu) => setbusinessUnitName(bu || event.target.value), []);
    const handleChange = useCallback((panel) => (event, newExpanded) => setExpanded(newExpanded ? panel : false), []);

    const handleCloseSnackBar = useCallback((event, reason) => {
        if (reason === 'clickaway') return;
        setSnackOpen(false);
    }, []);

    const onSwitchGraph = useCallback((event, year) => {
        event.stopPropagation();
        setGraph(!graph);
    }, [graph]);

    const setSnackStatus = useCallback((status, msg) => {
        setSnackMsg(msg);
        setSnackOpen(status);
    }, []);
    
    const onDeleteYearBudget = useCallback((event, year) => {
        event.stopPropagation();
        setSelectedYear(year);
        setOpenDialogConfirmDelete(true);
    }, []);

    const computeAllocatedBudgetCategory = useCallback((category, type) => {
        return Object.keys(category)
        .filter(item => item.indexOf(ITEM) > -1)
        .reduce((acc, item) =>  acc + category[item].month1[type] + category[item].month2[type] + category[item].month3[type], 0);
    }, []);

    const computeAllocatedBudgetQuarter = useCallback((quarter, type) => {
        return Object.keys(quarter)
        .filter(category => category.indexOf(CATEGORY) > -1)
        .reduce((acc, category) => acc + computeAllocatedBudgetCategory(quarter[category], type), 0);
    }, [computeAllocatedBudgetCategory]);

    const computeAllocatedBudget = useCallback((year, type) => {
        return Object.keys(year)
        .filter(quarter => quarter.indexOf('Q') > -1)
        .reduce((acc, quarter) =>  acc + computeAllocatedBudgetQuarter(year[quarter], type), 0);
    }, [computeAllocatedBudgetQuarter]);

    const saveBudgetInfos = useCallback((msg = null) => {
        const payload = businessUnit;
        firebase.db.ref(`/${BUDGETS}/${businessUnitName}`).set(payload)
        .then(() => setSnackStatus(true, msg || "Budget infos saved successfully"))
        .catch(error => setSnackStatus(true, "Error while saving budget infos"));
    }, [businessUnit, businessUnitName, firebase.db, setSnackStatus]);

    const onConfirmDeleteYearBudget = useCallback(() => {
        delete businessUnit[selectedYear];
        setOpenDialogConfirmDelete(false);
        saveBudgetInfos(`Goal deleted successfully for ${selectedYear}`);
    }, [businessUnit, selectedYear, saveBudgetInfos]);

    const onCreateBudget = () => {
        let years = Object.keys(businessUnit || { [(new Date().getFullYear() - 1)]: ({})}).sort((a, b) => b - a);
        const lastYear = parseInt(years[0]) + 1;
        if (!businessUnit?.[lastYear]) {
            if (!businessUnit) businessUnit = {};
            businessUnit[lastYear] = {
                Q1: createDefaultCategory(1),
                Q2: createDefaultCategory(2),
                Q3: createDefaultCategory(3),
                Q4: createDefaultCategory(4),
            };
            saveBudgetInfos(`Budget created successfully for ${lastYear}`);
        }
    };

    const years = React.useMemo(() => Object.keys(businessUnit || {}).filter(year => year.indexOf(TAGS) === -1).map(year => {
        const realized = computeAllocatedBudget(businessUnit[year], 'real');
        const budgetized = computeAllocatedBudget(businessUnit[year], 'forecast');
        const bp_budget = Object.values(businessUnit[year]).reduce((total, quarter) => quarter?.bp_budget ? total + quarter.bp_budget : total, 0);
        return { value: year, ...businessUnit[year], realized, bp_budget, budgetized };
    }).sort((a, b) => b.value - a.value), [businessUnit, computeAllocatedBudget]);

    const BPBudgetLabel = (year) => {
        return (
            <Typography variant="body2" color="textSecondary" component="p">
                <span style={{ fontWeight: 'bold' }}>BP Budget: </span>
                <span style={{ fontWeight: 'bold', marginRight: 12 }}>{Math.round(year.bp_budget).toLocaleString()}€</span>
            </Typography>
        )
    };

    const RealizedLabel = (year) => {
        return (
            <Typography variant="body2" color="textSecondary" component="p">
                <span style={{ fontWeight: 'bold' }}>Realized: </span>
                <span style={{ fontWeight: 'bold', marginRight: 12 }}>{Math.round(year.realized).toLocaleString()}€</span>
            </Typography>
        )
    };

    const BusinessUnitChip = ({bu}) => {
        const useBusinessUnitSelectorClasses = useBusinessUnitSelectorStyles( {selected: businessUnitName === bu.value });
        return (
            <Chip icon={bu.icon} label={bu.value.toUpperCase()} className={useBusinessUnitSelectorClasses.chip} onClick={e => handleChangeBusinessUnitName(e,bu.value)} style={{marginRight: 12}} />
        )
    };

    const BusinessUnitSelector = () => {
        return (
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 15}}>
                {Object.values(BUSINESS_UNITS).map(bu => <BusinessUnitChip bu={bu} key={bu.value} />)}
            </div>
        )
    };

    return (
        <FloatingCard
            title={`${TITLE}`}
            avatar={<DonutSmallIcon />}
            buttonAction= {<BusinessUnitSelector />}
            subheader={`Budgets for each year & quarter`}
            action={
                <Tooltip arrow title={ isManager ? 'Add a new yearly budget' : 'Locked' }>
                  <span>
                    <IconButton
                      disabled={!isManager}
                      aria-label="add-budget"
                      onClick={onCreateBudget}
                    >
                      {isManager ? <AddIcon style={{ color: YELLOW }} /> : <LockIcon />}
                    </IconButton>
                  </span>
                </Tooltip>
            }    
        >
            <div style={{flexGrow: 1 }}>

                {years.map(year => (
                    <Accordion expanded={expanded === year.value} onChange={handleChange(year.value)} key={year.value}>
                        <AccordionSummary
                            id={`${year.value}-header`}
                            expandIcon={<ExpandMoreIcon />}
                            IconButtonProps={{edge: 'start'}}
                            aria-controls={`${year.value}-content`}
                        >
                            <Grid container spacing={0} direction="row" alignItems="center" justifyContent='center' style={{margin: -5, flexGrow: 1}}>
                                <Grid item xs={5} align="left" >
                                    <Grid container spacing={0} direction="row" alignItems="center" justifyContent='flex-start'>
                                        <Grid >
                                            <Typography variant='h6' gutterBottom style={{paddingTop: 8, fontWeight: expanded === year.value ? 'bold' : 'normal', }}>
                                                {year.value} {year?.description}
                                            </Typography>
                                        </Grid>
                                        <Grid item style={{maxWidth: 45, marginLeft: 10}}>
                                            <Tooltip arrow title={`Switch to ${graph ? 'Table' : 'Graph'} view`}>
                                                <span>
                                                    <IconButton aria-label="delete-year" onClick={(event) => onSwitchGraph(event, year.value)} >
                                                        {graph
                                                            ? <TocIcon className={classes.tocIcon} />
                                                            : <PieChartIcon className={classes.pieIcon} />
                                                        }
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={3} align="right">
                                    <BPBudgetLabel {...year} />
                                </Grid>
                                <Grid item xs={3} align="right">
                                    <RealizedLabel {...year} />
                                </Grid>
                                <Grid item xs={1} align="right">
                                    <Grid container spacing={0} direction="row" alignItems="center" justifyContent='center'>
                                        <Grid item style={{maxWidth: 45}}>
                                            <CircularProgressWithLabel
                                                size={36}
                                                normal={false}
                                                title={'Consumed budget'}
                                                value={(year.realized / (year?.bp_budget || 1)) * 100}
                                            />
                                        </Grid>
                                        { (<Grid item style={{maxWidth: 45}}>
                                            <Tooltip arrow title="Delete yearly budget">
                                                <span>
                                                    <IconButton aria-label="delete-year" onClick={(event) => onDeleteYearBudget(event, year.value)} disabled={!isManager}>
                                                        <DeleteIcon style={{fontSize: '1.0rem'}}/>
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        </Grid>)}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        {<AccordionDetails>
                            {graph
                                ? <GraphView
                                    {...props}
                                    year={year}
                                    businessUnit={businessUnit}
                                />
                                : <YearRow
                                    {...props}
                                    year={year}
                                    trigram={trigram}
                                    businessUnit={businessUnit}
                                    setSnackStatus={setSnackStatus}
                                    setBusinessUnit={setBusinessUnit}
                                    saveBudgetInfos={saveBudgetInfos}
                                />
                            }
                        </AccordionDetails>}
                    </Accordion>
                ))}

                {years.length === 0 ? <Typography variant='h5' gutterBottom><center>{`No budget set yet`}</center></Typography> : null}

                <ConfirmDeleteAlertDialog
                    open={openDialogConfirmDelete}
                    title={`Delete ${selectedYear} budget?`}
                    onCancelDeleteCallback={onCancelDeleteYearBudget}
                    onConfirmDeleteCallback={onConfirmDeleteYearBudget}
                    message={`Are you sure you want to delete ${selectedYear} budget?`}
                />

                <Snackbar
                    open={snackOpen}
                    message={snackMsg}
                    autoHideDuration={2000}
                    onClose={handleCloseSnackBar}
                    anchorOrigin={ {vertical: 'top', horizontal: 'center' }}
                    action={
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackBar}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    }
                />

            </div>
        </FloatingCard>
    );
}

export default withFirebase(BudgetPanel);