import React from 'react';
import { getDownloadURL } from 'firebase/storage';

const FileUploader = (props) => {
    const {
        path,
        accept,
        disabled,
        firebase,
        filename,
        children,
        multiple,
        onProgress,
        onUploadStart,
        onUploadError,
        onUploadSuccess,
    } = props;
     
    function handleChange(event) {
        const { target: { files } } = event;
        for (let i = 0; i < files.length; i++)
          startUpload(files[i]);
        event.preventDefault();
    }
     
    const startUpload = (file) => {
        if (disabled) return;
        if (!file) {
            onUploadError ? onUploadError('No file selected') : alert("Please upload a valid file!");
            return;
        }

        let filenameToUse = file.name;
        if (filename) {
            filenameToUse = typeof filename === 'function' ? filename(file) : filename;
        }
    
        if (onUploadStart)
            onUploadStart(filenameToUse);
        
        const storageRef = firebase.storage.ref(path);
        const uploadTask = storageRef.child(filenameToUse).put(file);
        
        uploadTask.on( "state_changed",
            (snapshot) => { onProgress && onProgress(Math.round( (snapshot.bytesTransferred / snapshot.totalBytes) * 100 )) },
            (err) => { onUploadError ? onUploadError(err) : alert(err) },
            () => { onUploadSuccess && getDownloadURL(uploadTask.snapshot.ref).then(url => onUploadSuccess(filenameToUse, url)) }
        );
    };
    
    return (
        <>
            <label htmlFor={`file-uploader-${path}`}>{children}</label>
            {!disabled ? (
                <input
                    type="file"
                    accept={accept}
                    onChange={handleChange}
                    id={`file-uploader-${path}`}
                    multiple={multiple ? "multiple" : ""}
                    style={{
                        opacity: 0,
                        zIndex: -1,
                        width: '0.1px',
                        height: '0.1px',
                        overflow: 'hidden',
                        position: 'absolute',
                    }}
                />
            ) : null}
        </>
    );
}

export default FileUploader;