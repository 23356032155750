import React from 'react';
import { Grid } from '@material-ui/core';
import DatePicker from 'react-datepicker';
import "../../calendarConstants/date_picker.css";
import "react-datepicker/dist/react-datepicker.css";
import { addDays, subDays, parseISO } from 'date-fns';
import Typography from '@material-ui/core/Typography';
import { DAYS_OFF } from '../../calendarConstants/daysoff';
import { PENDING, REFUSED } from '../../../../holidays_constants';

import { useStyles } from '../styles';

const isDayOff = (date) => {
    const day = date.getDay();
    const dday = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    const daysoff = DAYS_OFF[year];
    if (daysoff) {
        const key  = `${year}-${month + 1 < 10 ? '0' + (month + 1) : month + 1}-${dday < 10 ? '0' + dday : dday}`;
        if (daysoff[key]) return false;
    }
    return day !== 0 && day !== 6;
};

const TableRecap = (props) => {
    const { daysOffToTake, remainingDays } = props;

        const classes = useStyles({ remainingDays });

        return (
            <Grid container spacing={1} justifyContent="flex-start" alignItems="center" className={classes.gridDaysOff}>
                <Grid item >
                    <Typography variant="body1" gutterBottom className={classes.normalText}>Days off to take</Typography>
                </Grid>
                <Grid item >
                    <Typography variant="h6" className={classes.daysOff}>{daysOffToTake}</Typography>
                </Grid>
                <Grid item >
                    <Typography variant="body1" gutterBottom className={classes.normalText}>Remaining days</Typography>
                </Grid>
                <Grid item >
                    <Typography variant="h6" className={classes.remaining}>{remainingDays}</Typography>
                </Grid>
            </Grid>
        );
    }

const fillPreviousRequestedDaysOff = (employee) => {
    const requests = Object.values(employee.contract.daysOff?.holidays || {});
    const highlightWithRanges = [
        { "react-datepicker__day--highlighted-custom-1": [] },
        { "react-datepicker__day--highlighted-custom-2": [] },
        { "react-datepicker__day--highlighted-custom-3": [] },
    ];
    requests.forEach(request => {
        const to = parseISO(request.to);
        const from = parseISO(request.from);
        const key = request.status === PENDING ? 2 : request.status === REFUSED ? 3 : 1;
        for (let i = from; i <= to; i = addDays(i, 1)) {
            isDayOff(i) && highlightWithRanges[key-1][`react-datepicker__day--highlighted-custom-${key}`].push(i);
        }
    });
    return highlightWithRanges;
}     

const CalendarPanel = (props) => {

    const { width, startDate, endDate, setDates, employee, availableDays, daysOffToTake, setDaysOffToTake } = props;

    const checkBoundaries = (dates) => {
        const [start, end] = dates;
        if (start && end) {
            start.setHours(0,0,0,0);
            end.setHours(23,59,0,0);
            const endDateDay = end.getDate();
            const endDateMonth = end.getMonth();
            const startDateDay = start.getDate();
            const endDateYear = end.getFullYear();
            const startDateMonth = start.getMonth();
            const startDateYear = start.getFullYear();
            let daysoff = DAYS_OFF[startDateYear];
            if (daysoff) {
                const monthdays = `${startDateYear}-${startDateMonth + 1 < 10 ? '0' + (startDateMonth + 1) : startDateMonth + 1}-${startDateDay < 10 ? '0' + startDateDay : startDateDay}`;
                daysoff[monthdays] && start.setDate(startDateDay + 1);
            }
            daysoff = DAYS_OFF[endDateYear];
            if (daysoff) {
                const monthdays = `${endDateYear}-${endDateMonth + 1 < 10 ? '0' + (endDateMonth + 1) : endDateMonth + 1}-${endDateDay < 10 ? '0' + endDateDay : endDateDay}`;
                daysoff[monthdays] && end.setDate(endDateDay - 1);
            }
        }
        return [start, end];
    }

    const checkForDaysOffBetweenIntervals = (startDate, endDate) => {
        if (startDate && endDate) {
            const diff = endDate.getTime() - startDate.getTime();
            const days = Math.round(diff / (1000 * 60 * 60 * 24));
            let countDayOff = 0;
            for (let i = 0; i < days; i++) {
                const date = addDays(startDate, i);
                (isDayOff(date) === false) && countDayOff++;
            }
            return days - countDayOff;
        }
        return 0;
    }

    const onChange = (dates) => {
        const [start, end] = checkBoundaries(dates);
        setDaysOffToTake(checkForDaysOffBetweenIntervals(start, end));
        setDates(start, end);
    };

    const monthsToShow = () => {
        switch(width) {
            case 'xs': return 3;
            case 'sm': return 3;
            case 'md': return 3;
            case 'lg': return 4;
            case 'xl': return 6;
            default: return 3;
        }
    };

    const remainingDays = availableDays - checkForDaysOffBetweenIntervals(startDate, endDate);

    return (
        <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
            <Grid item xs={9}>
                <TableRecap daysOffToTake={daysOffToTake} remainingDays={remainingDays} />
            </Grid>
            <Grid item xs={12} >
                <DatePicker
                    inline
                    selectsRange
                    endDate={endDate}
                    onChange={onChange}
                    selected={startDate}
                    startDate={startDate}
                    filterDate={isDayOff}
                    monthsShown={monthsToShow()}
                    minDate={subDays(new Date(), 30)}
                    highlightDates={fillPreviousRequestedDaysOff(employee)}
                />
            </Grid>
        </Grid>
    );
}

export default CalendarPanel;