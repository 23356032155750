import Chart from "react-google-charts";
import PublicIcon from '@material-ui/icons/Public';
import * as COLORS from '../../../../constants/colors';
import React, { useLayoutEffect, useState, useRef } from "react";
import FloatingCard from '../../../utils/GenericComponents/floatingCard';

const GeoChart = (props) => {

  const { partners } = props;
  let movement_timer = null;
  const RESET_TIMEOUT = 100;
  const targetRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const test_dimensions = () => {
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight
      });
    }
  };

  useLayoutEffect(() => { test_dimensions() }, []);

  window.addEventListener("resize", () => {
    clearInterval(movement_timer);
    movement_timer = setTimeout(test_dimensions, RESET_TIMEOUT);
  });

  
  const geoChartData = [];
  geoChartData.push(["Country", "Number of partners"]);
  partners.forEach(partner => {
    geoChartData.push([partner.country, partners.filter(candidate => candidate.country === partner.country).length]);
  });
  

  return(
    <div ref={targetRef} >
      <FloatingCard
        title="Partnerships geographical distribution"
        subheader={`by countries`}
        avatar={<PublicIcon />}
      >
        <Chart
          chartType="GeoChart"
          width={dimensions.width * 1.6}
          height={dimensions.height * 3.6}
          rootProps={{ 'data-testid': '3' }}
          loader={<div>Loading Chart...</div>}
          options={{
            legend: 'none',
            backgroundColor: 'transparent',
            colorAxis: { colors: [COLORS.LIGHT_PURPLE, COLORS.PURPLE] },
          }}
          mapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
          data={geoChartData || []}
        />
      </FloatingCard>
    </div>
  );
}

export default GeoChart;