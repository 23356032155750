import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const FloatingFilesMenu = props => {

    const {anchorEl, files, handleCloseFileList, handleMenuItemClick, open} = props;
  
    return (
      <Menu
        id="menu-files"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleCloseFileList}
      >
        {files.map((file, index) => (
          <MenuItem
            key={index}
            disabled={false}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
            {file.name}
          </MenuItem>
        ))}
        {(files.length === 0) && <MenuItem disabled={true}>no files</MenuItem>}
      </Menu>
    );
}

export default FloatingFilesMenu;
