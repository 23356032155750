import React from 'react';
import MemberAvatar from '../../memberAvatar';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { ListItemIcon, ListItemText, Typography } from '@material-ui/core';

import { useStyles } from './styles';

const TrigramSelector = (props) => {
    const classes = useStyles();
    const { tri, allTrigrams } = props;
    const { callbackTrigramSelected } = props;
    const [trigram, setTrigram] = React.useState(tri);

    const handleChange = (event, reason) => {
        event.stopPropagation();
        if (reason === 'clickaway') return;
        setTrigram(event.target.value);
        callbackTrigramSelected && callbackTrigramSelected(event.target.value);
    };

    const EmployeeMenuItemContent = ({candidate, color}) => {
        return (
            <ListItemIcon className={classes.listItemIcon}>
                <MemberAvatar member={candidate} size={3} />
                    <ListItemText className={classes.listItemText}>
                        <Typography color={color} className={classes.typoText}>
                            {candidate.trigram}
                        </Typography>
                </ListItemText>
            </ListItemIcon>
        )
    };

    return (
        <div>
            { allTrigrams && allTrigrams.length && (<FormControl className={classes.formControl}>
                <Select
                    value={trigram}
                    id="trigram-selector"
                    onChange={handleChange}
                    labelId="trigram-selector-label"
                >
                    { allTrigrams.map((candidate) => (
                        <MenuItem key={candidate.trigram} value={candidate.trigram}>
                            <EmployeeMenuItemContent candidate={candidate} color="textPrimary"/>
                        </MenuItem>))
                    } 
                </Select>
            </FormControl>)
            }
        </div>
    );
}

export default TrigramSelector;