import { makeStyles } from '@material-ui/core/styles';
import { VERY_LIGHT_GRAY3 } from '../../../../../constants/colors';

export const useStyles = makeStyles(theme => ({

    grid: {
      marginTop: theme.spacing(-2),
    },

    image: {
      width: theme.spacing(2),
      height: theme.spacing(2),
      backgroundColor: VERY_LIGHT_GRAY3,
      "&:hover": {
        backgroundColor: "#eee",
      }
    },

}));
