import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({

  version: {
    fontSize: "0.5rem",
  },

  version_div: {
    top: 35,
    right: 0,
    width: 70,
    height: '100%',
    position: 'absolute',
    backgroundColor: 'transparent'
  },

  bottom_line: {
    position: "fixed",
    fontSize: "0.5rem",
    bottom: theme.spacing(1),
    left: theme.spacing(1.5),
    right: theme.spacing(1.5),
    border: "1px solid #e0e0e0",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
  },
  
}));
  