import { makeStyles } from '@material-ui/core/styles';
import * as COLORS from '../../../../../constants/colors';
import { SHARES_TYPE } from '../../../../../constants/infos';

const PATH = "https://firebasestorage.googleapis.com/v0/b/react-compta-automation.appspot.com/o/images";

const GENERAL_INFOS_BG = `url(${PATH}%2Fcrowdsec2.png?alt=media&token=${process.env.HEADER_WHITE_ALPACAS})`;

export const useStyles = makeStyles(theme => ({

  waitButton: {
    color: '#AAA'
  },

  stepper: {
    padding: 0,
    background: 'transparent'
  },

  mainGrid : { 
    [theme.breakpoints.up('md')]: { paddingTop: theme.spacing(2) },
    [theme.breakpoints.down('sm')]: { paddingTop: theme.spacing(1) },
  },

  chip: {
    backgroundColor: COLORS.VERY_LIGHT_PURPLE2,
    [theme.breakpoints.up('md')]: { margin: theme.spacing(1) },
    [theme.breakpoints.down('sm')]: { margin: theme.spacing(0.5) },
  },

  content: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(223, 226, 236, 0.7)',
    "&:hover": {
      backgroundColor: 'rgba(231, 233, 243, 0.7)',
    },
  },

  goldBar: props => ({
    width: '100%',
    height: '100%',
    backgroundColor: COLORS.VERY_LIGHT_PURPLE,
    "&:hover": { backgroundColor: COLORS.VERY_LIGHT_PURPLE3 },
    padding: props.sm ? theme.spacing(0.3) : theme.spacing(0.7),
    borderLeft: `${props.sm ? 5 : 9}px solid ${props.type === SHARES_TYPE ? COLORS.YELLOW : COLORS.PURPLE}`,
  }),

  investAmount: {
    color: COLORS.YELLOW,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: { fontSize: '1.6vw' },
    [theme.breakpoints.down('sm')]: { fontSize: '1.4rem' },
  },

  background: {
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    [theme.breakpoints.up('md')]: {
      backgroundImage: GENERAL_INFOS_BG,
    },
  },

}));
  