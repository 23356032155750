import React from 'react';
import { Grid } from '@material-ui/core';
import List from '@material-ui/core/List';
import Tooltip from '@material-ui/core/Tooltip';
import BackupIcon from '@material-ui/icons/Backup';
import DescriptionIcon from '@material-ui/icons/Description';
import FileBox from '../../../../utils/GenericComponents/fileBox';
import CircularProgress from '@material-ui/core/CircularProgress';
import DragAndDrop from '../../../../utils/GenericComponents/dragAndDrop';
import FileUploader from '../../../../utils/GenericComponents/fileUploader';
import LinearProgressWithLabel from '../../../../utils/GenericComponents/progressBar';

import { useStyles } from './styles';

const AddFilesPanel = (props) => {

  const classes = useStyles();

  const { files, employee, firebase, currentYear, saveUserInfos } = props;

  const REPOSITORY = `ndf/${employee.trigram}`;
  const [progress, setProgress] = React.useState(0);
  const [isUploading, setIsUploading] = React.useState(false);
  const [uploadedFiles, setUploadedFiles] = React.useState(files || []);

  React.useEffect(() => {
    setUploadedFiles(files);
  }, [files]);
  

  const handleProgress = progress => setProgress(progress);

  const handleUploadStart = () => {
    setProgress(0);
    setIsUploading(true);
  };
 
  const handleUploadError = error => {
    setIsUploading(false);
    console.error(error);
  };

  const sendSlackNotification = (filename, url) => {
    const msg = `${employee.civil.firstName} (${employee.trigram}) has uploaded a new expense report : <${url}|${filename}>`;
    firebase.webHooks.sendAccountingFeedbackNotification(msg);
  }

  const handleUploadSuccess = async (filename, downloadURL) => {
    setProgress(100);
    setIsUploading(false);
    setUploadedFiles(prevState => [...prevState, { name: filename, url: downloadURL }]);
    sendSlackNotification(filename, downloadURL);
  };

  const forgeFileName = (file) => {
    if (file.name.length > 3) {
      const date = new Date();
      const extension = file.name.split('.').pop();
      const time = date.getTime().toString().slice(-5);
      return `ndf_${employee.trigram}_${time}.${extension}`;
    }
    return file.name;
  }

  const callbackGiveSpecificIcon = (filename, downloadURL) => {
    filename = filename.replace(".", "_");
    if (Object.keys(employee?.accounting?.ndf?.[currentYear] || [])?.length > 0) {
      if (employee.accounting.ndf[currentYear] && employee.accounting.ndf[currentYear][filename])
        return (
          <Tooltip title={`Found a total of ${employee.accounting.ndf[currentYear][filename].total_amount} ${employee.accounting.ndf[currentYear][filename].currency}`}>
            <DescriptionIcon color="primary" style={{ fontSize: 16 }} />
          </Tooltip>
        );
      return (
        <Tooltip title="Wait ... Using AI to find the amount of the expense report.">
          <CircularProgress size={14} />
        </Tooltip>
      );
    }
    return null;
  }

  const deleteCallback = (filename) => {
    const storageRef = firebase.storage.ref(REPOSITORY);
    const desertRef = storageRef.child(filename);
    desertRef.delete()
    .then(async () => {
      setUploadedFiles(prevState => prevState.filter(file => file.name !== filename));
      const key = filename.replace(".", "_");
      const amount = parseFloat(employee.accounting.ndf[currentYear]?.amount) - parseFloat(employee.accounting.ndf[currentYear][key]?.total_amount || 0);
      employee.accounting.ndf[currentYear].amount = Math.max(amount, 0).toFixed(2);
      employee.accounting.ndf.nbr = Math.max(employee.accounting.ndf.nbr - 1, 0);
      delete employee.accounting.ndf[currentYear][key];
      saveUserInfos(employee);
    })
    .catch(error => console.log(`ERROR deleting <${filename}> : ${JSON.stringify(error)}`));
  }

  async function uploadFile(file, i) {
    try {
      const filename = forgeFileName(file);
      const storageRef = firebase.storage.ref(REPOSITORY);
      const snapshot = await storageRef.child(filename).put(file);
      const url = await snapshot.ref.getDownloadURL();
      sendSlackNotification(filename, url);
      setUploadedFiles(prevState => [...prevState, { name: filename, url: url }])
      await new Promise(resolve => setTimeout(resolve, i * 200));
    } catch (error) {
      handleUploadError(error);
    }
  }
  
  const handleDrop = async (files) => {
    setProgress(0);
    let success = 0;
    let progression = 0;
    setIsUploading(true);
    files = Object.values(files).sort((a, b) => a.size - b.size);
    const globalSize = files.reduce((acc, file) => acc + file.size, 0);

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (!file.name) return
      success++;
      progression += file.size;
      await uploadFile(file, i);
      handleProgress((progression / globalSize) * 100);
    }
    
    (success === files.length) && setIsUploading(false);
  }

  return (
    <Grid container spacing={2} direction="column" justifyContent="center" alignItems="stretch" >
      <Grid item xs={12}>
        <DragAndDrop handleDrop={handleDrop} >
          <div className={classes.drag_drop}>
            { uploadedFiles.length > 0 && (
                <List dense={true}>
                  {uploadedFiles.map((file, i) => <FileBox key={i} fileName={file.name} url={file.url} disabled={false} deleteCallback={deleteCallback} callbackGiveSpecificIcon={callbackGiveSpecificIcon} />)}
                </List>
            )}
            { uploadedFiles.length === 0 && (
                <div style={{ marginTop: 75, marginBottom: 75 }}>
                  <center>You can drop your invoices here. Please make sure that the file name is not too long.</center>
                </div>
            )}
            {isUploading && <LinearProgressWithLabel value={progress} />}
          </div>

        </DragAndDrop>
      </Grid>
      <Grid item xs={12}>
        { !isUploading && (
          <Tooltip title="upload file(s)" arrow>
            <div className={classes.center}>
              <FileUploader
                multiple={ true }
                path={ REPOSITORY }
                firebase={firebase}
                onProgress={handleProgress}
                accept="application/pdf,image/*"
                onUploadStart={handleUploadStart}
                onUploadError={handleUploadError}
                onUploadSuccess={handleUploadSuccess}
                filename={file => forgeFileName(file)}
              >
                <Grid container spacing={1} justifyContent="center" alignItems="center">
                  <Grid item>
                    <span className={classes.uploadButton}>{`drag & drop`} or upload file(s)</span>
                  </Grid>
                  <Grid item>
                    <BackupIcon className={classes.backupIcon} />
                  </Grid>
                </Grid>
              </FileUploader>
            </div>
          </Tooltip>
        )}
      </Grid>
    </Grid>
  );
}

export default AddFilesPanel;