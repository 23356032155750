import React from 'react';
import CardInvest from '../cardInvest';
import * as UTILS from '../../../utils';
import { Grid } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { ESOP_TYPE, SHARES_TYPE } from '../../../../constants/infos';

const PANEL_NAME = 'panelInvest';

const MyCompletedInvestments = props => {
    
  const { classesComponent, partner, steeringObj } = props;
  
  const partnerESOP = partner.partnerInfo.ESOP;
  const partnerShares = partner.partnerInfo.SHARES;
  const hasPartnerSomeShares = UTILS.hasSomeShares(partner);
  const [expanded, setExpanded] = React.useState(PANEL_NAME);

  const handleChange = (panel) => (event, newExpanded) => setExpanded(newExpanded ? panel : false);

  const isRoundFunded = React.useCallback((round) => steeringObj?.SHARES?.[round]?.funded || false, [steeringObj]);
  const hasPartnerSomeEsopForRound = React.useCallback((round) => (partnerESOP?.[round]?.quantity || -1) > 0, [partnerESOP]);
  const hasPartnerSomeSharesForRound = React.useCallback((round) => (partnerShares?.[round]?.quantity || -1) > 0, [partnerShares]);

  const esopCondition = React.useMemo(() => {
    if (!partnerESOP) return false;
    return Object.keys(partnerESOP).some(roundInWhichPartnerIs =>
      isRoundFunded(roundInWhichPartnerIs) && hasPartnerSomeEsopForRound(roundInWhichPartnerIs)
    );
  }, [partnerESOP, isRoundFunded, hasPartnerSomeEsopForRound]);
  
  const renderInvestmentCards = (investmentData, type) => {
    return Object.keys(investmentData).map((round, index) => {
      const isRoundFunded = steeringObj?.SHARES?.[round]?.funded || false;
      const priceType = type === SHARES_TYPE ? 'SHARES' : 'ESOP';
      const priceLabel = type === SHARES_TYPE ? 'Price' : 'Strike';
      const hasInvestmentInRound = type === SHARES_TYPE ? hasPartnerSomeSharesForRound(round) : hasPartnerSomeEsopForRound(round);

      if (hasInvestmentInRound && isRoundFunded) {
        return (
          <CardInvest
            {...props}
            type={type}
            key={index}
            round={round}
            ongoing={false}
            quantity={investmentData[round].quantity}
            date={`${investmentData[round]?.date || 'TBD'}`}
            price={`${priceLabel}: ${Math.round((steeringObj?.[priceType]?.[round]?.price || 0) * 1000) / 1000} €`}
          />
        );
      }
      return null;
  });
};
  

  return(
    <>      
      { (hasPartnerSomeShares || esopCondition) && (

        <Accordion
          expanded={expanded === PANEL_NAME}
          onChange={handleChange(PANEL_NAME)}
          className={classesComponent.hideBorder}
        >
          <AccordionSummary
            id="panelPtf-header"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panelPtf-content"
          >
            <Typography variant="body1" color="textSecondary">My completed Investments</Typography>
          </AccordionSummary>

          <AccordionDetails className={classesComponent.accordionDetails}>
            <Grid container spacing={0} >
              { renderInvestmentCards(partnerShares, SHARES_TYPE) }
              { renderInvestmentCards(partnerESOP, ESOP_TYPE) }              
            </Grid>
          </AccordionDetails>

        </Accordion>
      )}

    </>
  );

}

export default MyCompletedInvestments; 
