import FileBox from '../fileBox';
import React, { Component } from 'react';
import DragAndDrop from '../dragAndDrop';
import List from '@material-ui/core/List';
import FileUploader from '../fileUploader';
import FloatingCard from '../floatingCard';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import withWidth from '@material-ui/core/withWidth';
import LinearProgressWithLabel from '../progressBar';
import { YELLOW } from '../../../../constants/colors';
import { IconButton, Tooltip } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';

import { useStyles } from './styles';

const STATUS = {
    ERROR: "Error uploading file",
    DELETE_ERROR: "Error deleting file",
    SUCCESS: "File(s) uploaded successfully",
    DELETE_SUCCESS: "File deleted successfully",
}

const FileCard = (props) => {
    const classes = useStyles();
    return <FileCardComponent {...props} classes={classes} />
}

class FileCardComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            files: [],
            progress: 0,
            snackMsg: "",
            snackOpen: false,
            isUploading: false,
            listOfFilesInDB: null,
            openAddFileDialog: false,
            filters: props?.filters || [],
            showLast: props?.showLast || false,
        }
        this.unMounted = false;
        this.classes = props.classes;
        this.firebase = props.firebase;
    }

    componentDidMount() {
        if ((!this.props.filters) || this.props.filters?.length === 0) this.reloadFiles();
        this.firebase.db.ref(`${this.props.path}`).on('value', snapshot => {
            const listOfFilesInDB = snapshot.val();
            if (listOfFilesInDB) {
                delete listOfFilesInDB.status;
                delete listOfFilesInDB.numberOfFiles;
                this.setState({ listOfFilesInDB });
                this.reloadFiles();
            } else {
                console.log(`Error: no data found at ${this.props.path} : reloading`);
                this.reloadFiles();
            }
        });
    }

    componentWillUnmount() {
        this.unMounted = true;
        this.firebase.db.ref(`${this.props.path}`).off();
    }

    handleProgress = progress => this.setState( { progress });
    loadFiles = () => this.firebase.getFiles(this.props.path, this.setFiles);
    handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });
    handleUploadError = error => this.setState({ isUploading: false, snackOpen: true, snackMsg: STATUS.ERROR, progress: 0 });
    
    reloadFiles() {
        this.setState({ files: [] });
        setTimeout(() => this.loadFiles(), 200);
    }

    handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') return;
        this.setState({ snackOpen: false });
    };

    addFileInList(fileInfos) {
        const fileExists = this.state.files.find(file => file.name === fileInfos.name);
        if (fileExists) return;
        this.setState(prevState => ({ files: [...prevState.files, fileInfos] }));
    }
    
    handleUploadSuccessFromDrop = files => {
        this.reloadFiles();
        this.setState({ isUploading: false, snackOpen: true, snackMsg: STATUS.SUCCESS, progress: 100 });
        this.props.callbackUploadSuccess && this.props.callbackUploadSuccess(files);
    }

    deleteFile = (fileName) => {
        const storageRef = this.firebase.storage.ref(this.props.path);
        storageRef.child(fileName).delete()
        .then(() => {
            this.reloadFiles();
            console.log(`file ${fileName} deleted successfully`);
            this.setState({ snackOpen: true, snackMsg: STATUS.DELETE_SUCCESS });
            this.props.callbackDelete && this.props.callbackDelete(fileName);
        })
        .catch(error => {
            console.log(`ERROR deleting <${fileName}> : ${JSON.stringify(error)}`)
            this.setState({ snackOpen: true, snackMsg: STATUS.DELETE_ERROR });
        });
    }
    
    handleUploadSuccess = async (files) => {
        this.reloadFiles();
        this.setState({ isUploading: false, snackOpen: true, snackMsg: STATUS.SUCCESS, progress: 100 });
        if (this.props.callbackUploadSuccess) {
            if (files instanceof Array) {
                const filesList = [];
                files.forEach(async file => {
                    const url = await this.firebase.storage.ref(this.props.path).child(file).getDownloadURL();
                    filesList.push({ name: file, url });
                });
                this.props.callbackUploadSuccess(filesList);
            } else {
                const url = await this.firebase.storage.ref(this.props.path).child(files).getDownloadURL();
                this.props.callbackUploadSuccess({ name: files, url });
            }
        }
    };

    setFiles = fileInfos => {
        if (!this.unMounted) {
            const listOfFiles = this.state.listOfFilesInDB;
            if (listOfFiles) {
                if (this.state.filters.length === 0) {
                    Object.keys(listOfFiles).forEach(key => this.addFileInList(listOfFiles[key]));
                } else {
                    Object.keys(listOfFiles).forEach(key => {
                        const fileData = listOfFiles[key];
                        if (this.state.filters.includes(fileData.trigram)) {
                            this.addFileInList(fileData);
                        }
                    });
                }
            } else {
                this.addFileInList(fileInfos);
            }
        }
    }

    handleDrop = async (files) => {
        let success = 0;
        this.setState({ isUploading: true, progress: 0 })
        const storageRef = this.firebase.storage.ref(this.props.path);
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          if (!file.name) return
          try {
            const snapshot = await storageRef.child(file.name).put(file);
            file.url = await snapshot.ref.getDownloadURL();
            this.handleProgress(files.length > 1 ? (i + 1) * 100 / files.length : 100);
            success++;
          } catch (error) {
            console.log("error", error);
            this.handleUploadError(error);
          }
        }
        //(success === files.length) && this.handleUploadSuccess(files);
        (success === files.length) && this.handleUploadSuccessFromDrop(files);
    };
    
    render() {
    
        const { files, progress, isUploading, showLast, snackMsg, snackOpen } = this.state;

        const UploadButton = () => {
            return (
                <>
                    {!isUploading && (
                        <Tooltip title="upload a document" arrow>
                            <span>
                                <FileUploader
                                    multiple={true}
                                    path={this.props.path}
                                    accept="application/pdf"
                                    firebase={this.firebase}
                                    onProgress={this.handleProgress}
                                    onUploadStart={this.handleUploadStart}
                                    onUploadError={this.handleUploadError}
                                    onUploadSuccess={this.handleUploadSuccess}
                                >
                                    <IconButton aria-label={`upload-document-${this.props.path}`} component="span">
                                        <AddIcon style={{ color: YELLOW }}/>
                                    </IconButton>
                                </FileUploader>
                            </span>
                        </Tooltip>)}
                    {isUploading && <LinearProgressWithLabel value={progress} />}
                </>
            );
        }

        return (
            <>
                <FloatingCard
                    title={this.props.title}
                    avatar={<DescriptionIcon />}
                    buttonAction={<UploadButton />}
                    subheader={this.props.description}
                >
                    <DragAndDrop handleDrop={this.handleDrop} >
                        <div className={this.classes.dragAndDropDiv}>
                            <List dense={true} >
                                {files.sort((a, b) => ( a.name < b.name ? 1 : -1 )).map((file, index) => {
                                    const fileName = file.name;
                                    return <FileBox
                                        {...this.props}
                                        url={file.url}
                                        disabled={false}
                                        fileName={fileName}
                                        tag={file?.tag || null}
                                        key={`${fileName}-${index}`}
                                        trigram={file?.trigram || null}
                                        category={file?.category || null}
                                        deleteCallback={this.props.callbackDelete ? this.deleteFile : null}                                        
                                    />
                                })}
                            </List>
                        </div>
                    </DragAndDrop>
                </FloatingCard>

                <Snackbar
                    open={snackOpen}
                    message={snackMsg}
                    autoHideDuration={ 3000 }
                    onClose={this.handleCloseSnackBar}
                    anchorOrigin={ {vertical: 'top', horizontal: 'center'} }
                    action={
                        <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleCloseSnackBar}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    }
                />
            </>
        );
    }
}

export default withWidth()(FileCard);
