import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import { YELLOW } from '../../../../../../constants/colors';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

const HEIGHT = 45;

export const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

export const AccordionSummary = withStyles({
  root: {
    minHeight: HEIGHT,
    maxHeight: HEIGHT,
    marginBottom: -1,
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    '&$expanded': {
      minHeight: HEIGHT,
      maxHeight: HEIGHT,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
  expandIcon: { order: -1 },
})(MuiAccordionSummary);

export const AccordionDetails = withStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

export const useStyles = makeStyles((theme) => ({

  warningIcon: {
    color: YELLOW,
    fontSize: theme.spacing(3),
  },

  cellTextFieldGeneric: (props) => ({
    maxWidth: 150,
    fontSize: theme.spacing(1.9),
    border: `1px solid rgba(0, 0, 0, 0.1)`,
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
  }),
  
  cellTextFieldGenericFocused: (props) => ({
    maxWidth: 150,
    border: `1px solid blue`,
    fontSize: theme.spacing(1.9),
    backgroundColor: props.edition ? 'rgba(225, 0, 0, 0.2)' : 'rgba(0, 225, 0, 0.2)',
  }),        
}));

