import React from 'react';
import Contact from './contact';
import List from '@material-ui/core/List';
import AddUserDialog from './addUserDialog';
import AddIcon from '@material-ui/icons/Add';
import { YELLOW } from '../../../../../constants/colors';
import { Tooltip, IconButton } from '@material-ui/core';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import FloatingCard from '../../../../utils/GenericComponents/floatingCard';

import { useStyles } from './styles';

const Contacts = (props) => {

    const classes = useStyles();
    const { dealID, partner, contacts, conditionRW } = props;
    const [openAddUserDialog, setOpenAddUserDialog] = React.useState(false);

    const handleCloseAddUserDialog = () => setOpenAddUserDialog(false);
    const handleOpenAddUserDialog = () => setOpenAddUserDialog(true);

    const addUserInList = (firstname, lastname, email) => {
        setOpenAddUserDialog(false);
        props.updateCompanyContacts(dealID, { firstname, lastname, email });
    };

    return (
        <>
            <FloatingCard
                title={"Contacts"}
                subheader={""}
                avatar={<ContactMailIcon />}
                buttonAction={conditionRW(partner) &&
                    <Tooltip title="Add user" arrow>
                        <IconButton style={{paddingTop: 10, paddingBottom: 10}} onClick={handleOpenAddUserDialog}>
                            <AddIcon style={{color: YELLOW}}/>
                        </IconButton>
                    </Tooltip>
                }
            >
                <List className={classes.list} dense={true}>
                    {contacts && contacts.map((contact, index) => <Contact {...props} contact={contact} partner={partner} key={index}/>)}
                </List>
            </FloatingCard>
            <AddUserDialog
                addUserInList={addUserInList}
                openAddUserDialog={openAddUserDialog}
                handleCloseAddUserDialog={handleCloseAddUserDialog}
            />
        </>
    );
};

export default Contacts;