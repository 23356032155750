import React from 'react';
import LegalPage from '../Legal';
import AdminPage from '../Admin';
import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';
import AccountPage from '../Account';
import PartnerPage from '../Partner';
import LandingPage from '../Landing';
import VersionDiv from './versionDiv';
import Navigation from '../Navigation';
import SimpleUser from '../SimpleUser';
import EmployeePage from '../Employee';
import IndicatorsPage from '../Indicators';
import WebIcon from '@material-ui/icons/Web';
import Hidden from '@material-ui/core/Hidden';
import PartnershipsPage from '../Partnerships';
import * as INFOS from '../../constants/infos';
import { withAuthentication } from '../Session';
import * as ROUTES from '../../constants/routes';
import GavelIcon from '@material-ui/icons/Gavel';
import ForumIcon from '@material-ui/icons/Forum';
import PasswordForgetPage from '../PasswordForget';
import GetAppIcon from '@material-ui/icons/GetApp';
import SecurityIcon from '@material-ui/icons/Security';
import LanguageIcon from '@material-ui/icons/Language';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

import { useStyles } from './styles';

const App = (props) => {

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    event.preventDefault();
    setValue(newValue);
    switch(newValue) {
      case 0: window.open(INFOS.HUB_URL); break;
      case 1: window.open(INFOS.CONSOLE_URL); break;
      case 2: window.open(INFOS.REPO_URL); break;
      case 3: window.open(INFOS.WEBSITE_URL); break;
      case 4: window.open(INFOS.DISCORD_URL); break;
      case 5: window.open(ROUTES.LEGAL); break;
      default: window.open(INFOS.WEBSITE_URL); break;
    }
  }

  return(
    <Router>
      <div >
        <Navigation />
        <Route exact path="/" component={LandingPage} />
        <Route path={ROUTES.LEGAL} component={LegalPage} />
        <Route path={ROUTES.ADMIN} component={AdminPage} />
        <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
        <Route path={ROUTES.SIGN_IN} component={SignInPage} />
        <Route path={ROUTES.PARTNER} component={PartnerPage} />
        <Route path={ROUTES.ACCOUNT} component={AccountPage} />
        <Route path={ROUTES.EMPLOYEE} component={EmployeePage} />
        <Route path={ROUTES.SIMPLE_USER} component={SimpleUser} />
        <Route exact path={ROUTES.LANDING} component={LandingPage} />
        <Route path={ROUTES.INDICATORS} component={IndicatorsPage} />
        <Route path={ROUTES.PARTNERSHIPS} component={PartnershipsPage} />
        <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
      </div>

      <Hidden smDown >
        <BottomNavigation
            showLabels
            value={value}
            onChange={handleChange}
            className={classes.bottom_line}
        >
          <BottomNavigationAction label="Hub" icon={<SecurityIcon />}   />
          <BottomNavigationAction label="Console" icon={<WebIcon />}   />
          <BottomNavigationAction label="Latest version" icon={<GetAppIcon />}  />
          <BottomNavigationAction label="Website" icon={<LanguageIcon />} />
          <BottomNavigationAction label="Discord" icon={<ForumIcon />} />
          <BottomNavigationAction label="Legal notice" icon={<GavelIcon />} />
          <VersionDiv classes={classes} firebase={props.firebase} />
        </BottomNavigation>

      </Hidden>

    </Router>
  );
}  

export default withAuthentication(App);
