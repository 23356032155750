import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Accordion from '@material-ui/core/Accordion';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

const AccordionPanel = (props) => {
  const { classes, panelName, expanded, handleChange, avatar, infos, customBackground, detailComponent, actionsComponent } = props;

  return (
    <Accordion
      expanded={expanded === panelName}
      onChange={handleChange(panelName)}
    >
      <AccordionSummary
        id={`${panelName}-header`}
        expandIcon={ <ExpandMoreIcon /> }
        aria-controls={`${panelName}-content`}
      >
        <Avatar aria-label="recipe" className={classes.avatar}>
          {avatar}
        </Avatar>

        <Typography className={classes.heading} gutterBottom>
          {infos}
        </Typography>
      </AccordionSummary>
      
      <div className={customBackground}>
        <div className={classes.content}>
          <AccordionDetails>
            {detailComponent}
          </AccordionDetails>
        </div>
      </div>
      {actionsComponent}
    </Accordion>
  );
};

export default AccordionPanel;
