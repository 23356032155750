import React  from 'react';
import * as ROLES from '../../constants/roles';
import withWidth from '@material-ui/core/withWidth';
import IndicatorsPageBase from './IndicatorsPageBase';
import { AuthUserContext, withAuthorization } from '../Session';

import { useStyles } from './styles';

const IndicatorsPage = props => {

  const classes = useStyles();

  return(
      <AuthUserContext.Consumer>
        { authUser => <IndicatorsPageComponent {...props} authUser={authUser} classes={classes}/>}        
      </AuthUserContext.Consumer>
  );
}

const condition = authUser => authUser && (!!authUser.roles[ROLES.ADMIN] || !!authUser.roles[ROLES.PARTNER] || !!authUser.roles[ROLES.EMPLOYEE]);

const IndicatorsPageComponent = withAuthorization(condition)(IndicatorsPageBase);

export default withWidth()(IndicatorsPage);