import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import * as COLORS from '../../../../../../constants/colors';

const slicesColors = {
    0: { color: COLORS.GREEN, offset: 0.2 },
};

export const AntSwitch = withStyles((theme) => ({
    root: {
        width: 28,
        height: 16,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        padding: 2,
        color: theme.palette.grey[500],
        '&$checked': {
            transform: 'translateX(12px)',
            color: theme.palette.common.white,
        '& + $track': {
            opacity: 1,
            backgroundColor: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
        width: 12,
        height: 12,
        boxShadow: 'none',
    },
    track: {
        opacity: 1,
        borderRadius: 16 / 2,
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.palette.grey[500]}`,
    },
    checked: {},
}))(Switch);

export const chartOptionsForecast = (props) => ({
    pieHole: 0,
    legend: 'none',
    slices: slicesColors, 
    chart: { title: props.title },
    backgroundColor: { fill: 'transparent' },
    chartArea: { width: '85%', height: '85%' },
    tooltip: { text: 'value', showColorCode: true },
});

export const chartOptionsRealized = (props) => ({
    pieHole: 0,
    legend: 'none',
    chart: { title: props.title },
    backgroundColor: { fill: 'transparent' },
    chartArea: { width: '85%', height: '85%' },
    tooltip: { text: 'value', showColorCode: true },
});

export const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
        maxWidth: 120,
        margin: theme.spacing(1),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    gridItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'right',
    },
    noDataDiv: {
        height: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
}));