import Logo from '../../../Logo';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { useHistory } from "react-router-dom";
import ForwardIcon from '@material-ui/icons/Forward';
import Typography from '@material-ui/core/Typography';
import { SIGN_IN } from '../../../../constants/routes';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import PhonelinkOffIcon from '@material-ui/icons/PhonelinkOff';
import DialogContentText from '@material-ui/core/DialogContentText';
import React, { useState, useEffect, useCallback, useRef } from 'react';

import { useStyles } from './styles';

const TEST = 30;
const AUTO_WARNING_DURATION = TEST * 60 * 1000; // TEST minutes
const AUTO_LOGOUT_DURATION = AUTO_WARNING_DURATION + ((TEST / 2) * 1000);

const SessionTimeoutDialog = ({isOpen, handleClose, doSignOut}) => {

    const classes = useStyles();

    return (
        <Dialog open={isOpen}>

            <DialogContent>
                <Grid container justifyContent="center">
                    <div className={classes.logoPanel}>
                        <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
                            <Grid item xs>
                                <Logo height={45}/>
                            </Grid>
                            <Grid item xs>
                                <ForwardIcon className={classes.icons}/>
                            </Grid>
                            <Grid item xs>
                                <PhonelinkOffIcon className={classes.icons}/>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <DialogContentText className={classes.dialogContent} component="div">
                    <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" >
                        <Grid item xs={12} align="center">
                            <Typography variant="h5">
                                Are you still there ?
                            </Typography>
                        </Grid>
                        <Grid item xs={12} align="center">
                            <Typography variant="body1">
                                You will be disconnected in 30 seconds...
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
                    <Grid item xs container direction="row" justifyContent="center" alignItems="center">
                        <Button onClick={handleClose} color="primary" variant='contained'>I'm there</Button>
                    </Grid>
                    <Grid item xs container direction="row" justifyContent="center" alignItems="center">
                        <Button onClick={doSignOut} color="primary" variant='outlined'>Logout me out</Button>
                    </Grid>                    
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

const SessionTimeout = (props) => {

    const { doSignOut } = props;
    const logoutTimerIdRef = useRef(null);
    const warningTimerIdRef = useRef(null);
    const [isOpen, setOpen] = useState(false);

    const history = useHistory();
  
    const handleClose = () => {
        setOpen(false);
        window.clearTimeout(logoutTimerIdRef.current);
        window.clearTimeout(warningTimerIdRef.current);
        logoutTimerIdRef.current = null;
    };

    const openWarningWindow = useCallback(() => setOpen(true) , []);

    const logoutUser = useCallback(() => {
        if (logoutTimerIdRef.current) {
            setTimeout(() => doSignOut(), 2000);
            history.push(SIGN_IN);
        }
    }, [doSignOut, history]);

    useEffect(() => {

        const lostFocus = () => {
            if (document.visibilityState === 'hidden') {
                window.clearTimeout(logoutTimerIdRef.current);
                window.clearTimeout(warningTimerIdRef.current);
                logoutTimerIdRef.current = window.setTimeout(logoutUser, AUTO_LOGOUT_DURATION);
                warningTimerIdRef.current = window.setTimeout(openWarningWindow, AUTO_WARNING_DURATION);
            } else {
                window.clearTimeout(logoutTimerIdRef.current);
                window.clearTimeout(warningTimerIdRef.current);
            }
        };
        
        const blurHappened = () => {
            window.clearTimeout(logoutTimerIdRef.current);
            window.clearTimeout(warningTimerIdRef.current);
            logoutTimerIdRef.current = window.setTimeout(logoutUser, AUTO_LOGOUT_DURATION);
            warningTimerIdRef.current = window.setTimeout(openWarningWindow, AUTO_WARNING_DURATION);
        };
        
        const focusHappened = () => {
            window.clearTimeout(logoutTimerIdRef.current);
            window.clearTimeout(warningTimerIdRef.current);
        };
        
        window.addEventListener('blur', blurHappened);
        window.addEventListener('focus', focusHappened);
        window.addEventListener('visibilitychange', lostFocus);

        return () => {
            window.removeEventListener('blur', blurHappened);
            window.removeEventListener('focus', focusHappened);
            window.removeEventListener('visibilitychange', lostFocus);
        }

    //}, [logoutUser, openWarningWindow]);
    });
  
    return (isOpen) ? <SessionTimeoutDialog isOpen={isOpen} handleClose={handleClose} doSignOut={doSignOut}/> : null;
  };
  
  export default SessionTimeout;