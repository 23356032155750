export const LOGO = 'crowdsec2';
export const WARNING = 'Warning';
export const ESOP_TYPE = "BSPCE";
export const WORLDMAP = 'worldmap';
export const SHARES_TYPE = "shares";
export const OFFICE_MANAGER = "Kenza";
export const SEEDING = 'seeding_round';
export const LOGO_ALT = 'CrowdSec Logo';
export const PROFILE = 'profile_ae3f428';
export const SPLASH_SCREEN = 'splashscreen';
export const LOGO_YELLOW = 'crowdsec_yellow';
export const PAGE_NOT_FOUND = '404 : not found';
export const LOGO_ORIGINAL = 'crowdsec_original';
export const WEBSITE_URL = 'https://crowdsec.net';
export const HUB_URL = 'https://hub.crowdsec.net/';
export const CONSOLE_URL = 'https://app.crowdsec.net/';
export const REPO_URL = 'https://github.com/crowdsecurity/crowdsec/releases/latest';
export const DISCORD_URL = 'https://discord.com/channels/921520481163673640/921706975983697931';