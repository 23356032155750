import React from 'react';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import CaptablePartnerRow from './captablePartnerRow';
import TableContainer from '@material-ui/core/TableContainer';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';
import { getInfoFromUser, getESOPFromUser } from '../../../utils';

import { useStyles } from './styles';

const STAGE = 'stage';
const NAME = 'lastName';
const ESOP_QUANTITY = 'esop_quantity';
const INVEST_CONFIRMED = 'investment';
const INVEST_INTENT = 'investmentIntent';
const SHARES_QUANTITY = 'shares_quantity';

const headCells = [
    { id: NAME, numeric: false, disablePadding: true, label: 'Name' },
    { id: STAGE, numeric: true, disablePadding: true, label: 'Stage' },
    { id: INVEST_INTENT, numeric: true, disablePadding: false, label: 'Investment Intention' },
    { id: INVEST_CONFIRMED, numeric: true, disablePadding: false, label: 'Investment Confirmed' },
    { id: SHARES_QUANTITY, numeric: true, disablePadding: false, label: 'Shares / Warrants' },
    { id: ESOP_QUANTITY, numeric: true, disablePadding: false, label: 'BSPCE (ESOP)' },
  ];
  
const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
}

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(partners, round, orderBy, comparator) {
  const stabilizedThis = partners.map((partner, index) => [partner, index]);

  if(orderBy === STAGE) {
    stabilizedThis.sort((a, b) => {
      let stagesA = { stage: '' };
      let stagesB = { stage: '' };

      if (('stage' in a[0].partnerInfo.SHARES[round]) && !('stage' in b[0].partnerInfo.SHARES[round])) {
        stagesA.stage += 'A';
        stagesA.stage += a[0].partnerInfo.SHARES[round].stage.P1 ? 'P1' : '';
        stagesA.stage += a[0].partnerInfo.SHARES[round].stage.P2 ? 'P2' : '';
        stagesA.stage += a[0].partnerInfo.SHARES[round].stage.P3 ? 'P3' : '';
      }
      else if (!('stage' in a[0].partnerInfo.SHARES[round]) && ('stage' in b[0].partnerInfo.SHARES[round])) {
        stagesB.stage += 'A';
        stagesB.stage += b[0].partnerInfo.SHARES[round].stage.P1 ? 'P1' : '';
        stagesB.stage += b[0].partnerInfo.SHARES[round].stage.P2 ? 'P2' : '';
        stagesB.stage += b[0].partnerInfo.SHARES[round].stage.P3 ? 'P3' : '';
      }
      else if (('stage' in a[0].partnerInfo.SHARES[round]) && ('stage' in b[0].partnerInfo.SHARES[round])) {
        stagesA.stage += 'A';
        stagesA.stage += a[0].partnerInfo.SHARES[round].stage.P1 ? 'P1' : '';
        stagesA.stage += a[0].partnerInfo.SHARES[round].stage.P2 ? 'P2' : '';
        stagesA.stage += a[0].partnerInfo.SHARES[round].stage.P3 ? 'P3' : '';
        stagesB.stage += 'A';
        stagesB.stage += b[0].partnerInfo.SHARES[round].stage.P1 ? 'P1' : '';
        stagesB.stage += b[0].partnerInfo.SHARES[round].stage.P2 ? 'P2' : '';
        stagesB.stage += b[0].partnerInfo.SHARES[round].stage.P3 ? 'P3' : '';
      }
      const order = comparator(stagesA, stagesB);
      return (order !== 0) ? order : a[1] - b[1];
    });

  } else if(orderBy === NAME) {
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0].civil, b[0].civil);
      return (order !== 0) ? order : a[1] - b[1];
    });
  } else if(orderBy === INVEST_INTENT || orderBy === INVEST_CONFIRMED || orderBy === SHARES_QUANTITY) {
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0].partnerInfo.SHARES[round], b[0].partnerInfo.SHARES[round]);
      return (order !== 0) ? order : a[1] - b[1];
    });
  } else if(orderBy === ESOP_QUANTITY) {
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0].partnerInfo.ESOP[round], b[0].partnerInfo.ESOP[round]);
      return (order !== 0) ? order : a[1] - b[1];
    });
  }

  return stabilizedThis.map((el) => el[0]);
}

const checkPermission = (partner, round) => (round in partner.partnerInfo.SHARES);

export function checkEligibility(partner, round) {
  if (partner === null) return false;
  if (!checkPermission(partner, round)) return false;
  const investment = getInfoFromUser(partner, 'investment', round);
  const esopQuantity = getESOPFromUser(partner, 'quantity', round);
  const sharesQuantity = getInfoFromUser(partner, 'quantity', round);
  const investmentIntent = getInfoFromUser(partner, 'investmentIntent', round);
  return (investmentIntent >= 0) || (investment > 0) || (sharesQuantity > 0) || (esopQuantity > 0) || partner.connected;
}

const CaptablePartnerList = (props) => {

  const classes = useStyles();

  const { round, partners } = props;
  const [page, setPage] = React.useState(0);
  const { onSaveUserWithPartnerInfos } = props;
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState(NAME);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const eligiblePartners = partners.filter(partner => checkEligibility(partner, round.round));

  const handleChangePage = (event, newPage) => setPage(newPage);
  const createSortHandler = (property) => (event) => handleRequestSort(event, property);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <>

      <TableContainer className={classes.container} component={Paper}>

        <Table stickyHeader aria-label="collapsible table" >

          <TableHead>
            <TableRow>
              <TableCell />
              {headCells.map((headCell) => (              
                <TableCell
                  key={headCell.id}
                  align={headCell.numeric ? 'center' : 'left'}
                  padding={headCell.disablePadding ? 'none' : 'normal'}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    onClick={createSortHandler(headCell.id)}
                    direction={orderBy === headCell.id ? order : 'asc'}
                  >
                    <strong>{headCell.label}</strong>
                    {orderBy === headCell.id ? (
                      <span className={classes.visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}            
            </TableRow>
          </TableHead>

          <TableBody>
            {stableSort(eligiblePartners, round.round, orderBy, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((partner) => (
                <CaptablePartnerRow
                  round={round}
                  partner={partner}
                  key={partner.trigram}
                  onSaveUserWithPartnerInfos={onSaveUserWithPartnerInfos}
                />
              ))
            }
          </TableBody>

        </Table>

      </TableContainer>

      {<TablePagination
        page={page}
        component="div"
        count={eligiblePartners.length}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[10, 25, 100]}
        onRowsPerPageChange={handleChangeRowsPerPage}
        />
      }

    </>
  );
}

export default CaptablePartnerList;
