import * as UTILS from '../../../utils';
import Chart from "react-google-charts";
import Grid from '@material-ui/core/Grid';
import { getTotalShares } from '../../../utils';
import * as COLORS from '../../../../constants/colors';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import FloatingCard from '../../../utils/GenericComponents/floatingCard';
import React, { useRef, useMemo, useLayoutEffect, useState } from "react";

import { AntSwitch } from './styles';

const slicesColors = {
    0: { color: COLORS.PURPLE },
    1: { color: COLORS.YELLOW },
    2: { color: COLORS.LIGHT_PURPLE },
    3: { color: COLORS.VERY_LIGHT_PURPLE2 },
    4: { color: COLORS.VERY_LIGHT_PURPLE3 },
    5: { color: COLORS.DARK_PURPLE },
};

export const AntSwitchButton = (props) => {
    const { disabled, foreCast, handleChange} = props;
    return (
        <Grid container spacing={1} alignItems="center" justifyContent="center" component="label" style={{paddingRight: 5}}>
            <Grid item xs={4} style={{paddingBottom: 8, marginRight: -6}}>
                <span style={{fontSize: 8}}>Actual</span>
            </Grid>
            <Grid item xs={4}>
                <AntSwitch checked={foreCast} onChange={handleChange} name="forecast" disabled={disabled}/>
            </Grid>
            <Grid item xs={4} style={{paddingBottom: 8}}>
                <span style={{fontSize: 8}}>Forecast</span>
            </Grid>
        </Grid>
    );
}

const CapitalChart = (props) => {

    const { steeringObj } = props;

    const [foreCast, setForeCast] = useState(false);
    const handleChange = () => setForeCast(!foreCast);
    const totalSharesBreakdown = useMemo(() => steeringObj ? getTotalShares(steeringObj, foreCast) : [], [steeringObj, foreCast]);
    const totalShares = useMemo(() => totalSharesBreakdown.reduce((total, elt) => isNaN(elt[1].v) ? total + 0 : elt[1].v + total, 0), [totalSharesBreakdown]);
    
    const rounds = [];
    Object.values(steeringObj?.SHARES || {}).forEach(key => rounds.push({ order: key.order, funded: key.funded }));
    rounds.sort((a, b) => a.order - b.order);

    let movement_timer = null;
    const RESET_TIMEOUT = 100;
    const targetRef = useRef();
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  
    const test_dimensions = () => {
      if (targetRef.current) {
        setDimensions({
          width: targetRef.current.offsetWidth,
          height: targetRef.current.offsetHeight
        });
      }
    };
  
    useLayoutEffect(() => { test_dimensions() }, []);
  
    window.addEventListener("resize", () => {
      clearInterval(movement_timer);
      movement_timer = setTimeout(test_dimensions, RESET_TIMEOUT);
    });

    return(
        <div ref={targetRef} >
            <FloatingCard
                title="Capital allocation"
                avatar={<DonutLargeIcon />}
                subheader={`${UTILS.nFormatter(totalShares, 1)} shares (fully diluted)`}
                action={<AntSwitchButton foreCast={foreCast} handleChange={handleChange} disabled={rounds[rounds.length - 1]?.funded || true}/>}
            >
                <Chart
                    options={{
                        title: '',
                        pieHole: 0.34,
                        legend: 'none',
                        slices: slicesColors, 
                        backgroundColor: { fill:'transparent' },
                        tooltip: { text: 'value', showColorCode: true },
                    }}
                    chartType="PieChart"
                    data={totalSharesBreakdown}
                    height={dimensions.height * 1.6}
                    rootProps={{ 'data-testid': '1' }}
                    loader={<div>Loading Chart...</div>}
                    style={{ marginTop: -(dimensions.height / 8), marginBottom: -(dimensions.height / 3)}}
                />
            </FloatingCard>
        </div>
    );
}

export default CapitalChart;