import React from 'react';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import * as CONST from '../../../../constants';
import { LIGHT_PURPLE, LIGHT_GRAY } from '../../../../../../../constants/colors';

import { useStyles } from './styles';

const initValues = (news) => [
  { key:  0, country: 'en', label: CONST.LANGUAGES.en, selected: (news.lang.indexOf('en') !== - 1) },
  { key:  1, country: 'fr', label: CONST.LANGUAGES.fr, selected: (news.lang.indexOf('fr') !== - 1) },
  { key:  2, country: 'es', label: CONST.LANGUAGES.es, selected: (news.lang.indexOf('es') !== - 1) },
  { key:  3, country: 'ar', label: CONST.LANGUAGES.ar, selected: (news.lang.indexOf('ar') !== - 1) },
  { key:  4, country: 'cmn', label: CONST.LANGUAGES.cmn, selected: (news.lang.indexOf('cmn') !== - 1) },
  { key:  5, country: 'ru', label: CONST.LANGUAGES.ru, selected: (news.lang.indexOf('ru') !== - 1) },
  { key:  6, country: 'pt', label: CONST.LANGUAGES.pt, selected: (news.lang.indexOf('pt') !== - 1) },
  { key:  7, country: 'de', label: CONST.LANGUAGES.de, selected: (news.lang.indexOf('de') !== - 1) },
  { key:  8, country: 'ja', label: CONST.LANGUAGES.ja, selected: (news.lang.indexOf('ja') !== - 1) },
  { key:  9, country: 'hi', label: CONST.LANGUAGES.hi, selected: (news.lang.indexOf('hi') !== - 1) },
  { key: 10, country: 'ms', label: CONST.LANGUAGES.ms, selected: (news.lang.indexOf('ms') !== - 1) },
  { key: 11, country: 'fa', label: CONST.LANGUAGES.fa, selected: (news.lang.indexOf('fa') !== - 1) },
  { key: 12, country: 'sw', label: CONST.LANGUAGES.sw, selected: (news.lang.indexOf('sw') !== - 1) },
  { key: 13, country: 'ta', label: CONST.LANGUAGES.ta, selected: (news.lang.indexOf('ta') !== - 1) },
  { key: 14, country: 'it', label: CONST.LANGUAGES.it, selected: (news.lang.indexOf('it') !== - 1) },
  { key: 15, country: 'nl', label: CONST.LANGUAGES.nl, selected: (news.lang.indexOf('nl') !== - 1) },
  { key: 16, country: 'bn', label: CONST.LANGUAGES.bn, selected: (news.lang.indexOf('bn') !== - 1) },
  { key: 17, country: 'tr', label: CONST.LANGUAGES.tr, selected: (news.lang.indexOf('tr') !== - 1) },
  { key: 18, country: 'vi', label: CONST.LANGUAGES.vi, selected: (news.lang.indexOf('vi') !== - 1) },
  { key: 19, country: 'pl', label: CONST.LANGUAGES.pl, selected: (news.lang.indexOf('pl') !== - 1) },
  { key: 20, country: 'jv', label: CONST.LANGUAGES.jv, selected: (news.lang.indexOf('jv') !== - 1) },
  { key: 21, country: 'pa', label: CONST.LANGUAGES.pa, selected: (news.lang.indexOf('pa') !== - 1) },
  { key: 22, country: 'th', label: CONST.LANGUAGES.th, selected: (news.lang.indexOf('th') !== - 1) },
  { key: 23, country: 'ko', label: CONST.LANGUAGES.ko, selected: (news.lang.indexOf('ko') !== - 1) },
  { key: 24, country: 'cz', label: CONST.LANGUAGES.cz, selected: (news.lang.indexOf('cz') !== - 1) },
  { key: 25, country: 'gr', label: CONST.LANGUAGES.gr, selected: (news.lang.indexOf('gr') !== - 1) },
  { key: 26, country: 'az', label: CONST.LANGUAGES.az, selected: (news.lang.indexOf('az') !== - 1) },
  { key: 27, country: 'da', label: CONST.LANGUAGES.da, selected: (news.lang.indexOf('da') !== - 1) },
];

const LanguagesTags = (props) => {

    const { currentNews, onChangeCurrentNews } = props;
    const classes = useStyles();
    const [chipData, setChipData] = React.useState(initValues(currentNews));

    const handleClick = (event, data) => {
      data.selected = !data.selected;
      chipData[data.key] = data;
      setChipData([...chipData]);
      event.target.name = CONST.LANG_SELECTION;
      event.target.value = chipData.filter(lang => lang.selected).map(lang => lang.country).join(",");
      onChangeCurrentNews(event, currentNews);
      event.preventDefault();
    };

  return (
    <Box component="ul" className={classes.root}>
      {chipData.map(chip => {
        return (
          <li key={currentNews.key + chip.key}>
            <Chip
                size="small"
                label={chip.label}
                className={classes.chip}
                id={currentNews.key + chip.key}
                name={currentNews.key + chip.key}
                onClick={event => handleClick(event, chip)}
                style={{ backgroundColor: (chip.selected) ? LIGHT_PURPLE : LIGHT_GRAY }}
            />
          </li>
        );
      })}
    </Box>
  );
}

export default LanguagesTags;