import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { diffDate } from '../../components/utils';
import IconButton from '@material-ui/core/IconButton';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import FormControl from '@material-ui/core/FormControl';
import ListSubheader from '@material-ui/core/ListSubheader';
import MemberAvatar from '../../components/utils/GenericComponents/memberAvatar';
import { Grid, ListItemIcon, MenuItem, Select, ListItemText, Typography } from '@material-ui/core';

const UidRenderer = ({ uid }) => {
  const [isHovered, setIsHovered] = useState(false);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <div onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <Typography variant="caption" gutterBottom color="textSecondary" >
        ID: [{uid}]
      </Typography>
      {isHovered && (
        <IconButton onClick={() => copyToClipboard(uid)}>
          <FileCopyIcon fontSize="small" />
        </IconButton>
      )}
    </div>
  );
}

const ImpersonationPanel = (props) => {

  const { width, isAdmin, classes, employee, isManagerOf, employees, employeeID, handleSelectEmployee } = props;

  let sortedEmployees = (isManagerOf) ? employees.filter(employee => isManagerOf?.includes(employee.trigram)) : employees;
  sortedEmployees = sortedEmployees.sort((a, b) => diffDate(a.contract.start, b.contract.start));
  const sortedEmployeesWithEnd = sortedEmployees.filter(employee => employee.contract.end.length > 0);
  const sortedEmployeesWithoutEnd = sortedEmployees.filter(employee => employee.contract.end.length === 0);

  const addedText = (isAdmin ? " (admin" : " (manager") + ")";

  const checkIfAllEmployeeAreLoaded = () => employeeID && (sortedEmployees.find(employee => employee.uid === employeeID) || sortedEmployeesWithEnd.find(employee => employee.uid === employeeID));

  const EmployeeMenuItemContent = ({candidate, color}) => {
    return (
      <ListItemIcon className={classes.listItemIcon}>
        <MemberAvatar member={candidate} size={3.5}/>
        <ListItemText className={classes.listItemText}>
          <Typography gutterBottom variant="body1" color={color}>
            {candidate?.civil?.firstName}
          </Typography>
        </ListItemText>
      </ListItemIcon>
    )
  };

  if (!checkIfAllEmployeeAreLoaded()) return null

  return (
    <>
      {/* impersonation panel */}
      {(isAdmin || isManagerOf?.length > 0) && (sortedEmployeesWithEnd.length >= 0) && (sortedEmployeesWithoutEnd.length >= 0) && (
        <div className={classes.impersonationPanel}>
          <Grid container spacing={2} justifyContent="flex-start" alignItems="center" className={classes.impersonationGrid}>
            <Grid item >
              <FormControl className={classes.formControl}>
                  <Select
                    value={employeeID}
                    onChange={handleSelectEmployee}
                  >
                    {sortedEmployeesWithoutEnd && sortedEmployeesWithoutEnd.map(candidate => (
                      <MenuItem key={candidate.uid} value={candidate.uid} className={classes.menuItem}>
                        <EmployeeMenuItemContent candidate={candidate} color="textPrimary"/>
                      </MenuItem>
                    ))}
                    {sortedEmployeesWithEnd?.length > 0 && <ListSubheader>Olds</ListSubheader>}
                    {sortedEmployeesWithEnd && sortedEmployeesWithEnd.map(candidate => (
                      <MenuItem key={candidate.uid} value={candidate.uid} className={classes.menuItem}>
                        <EmployeeMenuItemContent candidate={candidate} color="textSecondary"/>
                      </MenuItem>
                    ))}
                  </Select>
              </FormControl>
            </Grid>
            <Grid item >
              <Typography variant={width === 'xs' ? "body1" : "h6"} gutterBottom color="textSecondary" className={classes.impersonationTypo}>
                Select member {width === 'xs' ? "" : <>panel <i>{addedText}</i></>}
              </Typography>
            </Grid>
            {(width !== 'xs') && isAdmin && (
              <Grid item>
                <UidRenderer uid={employee.uid} />
              </Grid>
            )}
          </Grid>
        </div>
      )}
    </>
  );
}

ImpersonationPanel.propTypes = {
  isManagerOf: PropTypes.array,
  width: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  employee: PropTypes.object.isRequired,
  firebase: PropTypes.object.isRequired,
  employeeID: PropTypes.string.isRequired,
  handleSelectEmployee: PropTypes.func.isRequired,
  employees: PropTypes.arrayOf(PropTypes.object).isRequired,
};

ImpersonationPanel.defaultProps = {
  isManagerOf: [],
};


export default ImpersonationPanel;
