
import React from 'react';
import * as MENUS from '../menus';
import Tab from '@material-ui/core/Tab';
import { Link } from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';

import { useStyles } from './styles';

const NavigationAuthTabs = (props) => {

  const classes = useStyles();
  const { authUser, firebase } = props;
  const [value, setValue] = React.useState(authUser?.tab || 0);
  const handleChangeTab = (event, newValue) => setValue(newValue);

  React.useEffect(() => {
    if (authUser)
      firebase.user(authUser.uid).update({ tab: value })
  }, [value, authUser, firebase]);

  return (
    <Tabs
      value={value}
      variant="scrollable"
      indicatorColor="primary"
      onChange={handleChangeTab}
      aria-label="menu-auth-user"
      classes={{ indicator: classes.indicator }}
    >
      {MENUS.menus.map(item => item.condition(authUser) && (
        <Tab
          to={item.to}
          key={item.label}
          component={Link}
          label={item.label}
          className={classes.tab}
        />
      ))}
              
    </Tabs>
  );
}
export default NavigationAuthTabs;