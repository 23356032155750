import React, { Component } from 'react';
import Chip from '@material-ui/core/Chip';
import List from '@material-ui/core/List';
import AddIcon from '@material-ui/icons/Add';
import EuroIcon from '@material-ui/icons/Euro';
import { Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import * as INFOS from '../../../../constants/infos';
import { YELLOW } from '../../../../constants/colors';
import IconButton from '@material-ui/core/IconButton';
import * as COLORS from '../../../../constants/colors';
import DescriptionIcon from '@material-ui/icons/Description';
import { CONTRACT_TYPE } from '../../contract_type_constants';
import FileBox from '../../../utils/GenericComponents/fileBox';
import CircularProgress from '@material-ui/core/CircularProgress';
import FileUploader from '../../../utils/GenericComponents/fileUploader';
import FloatingCard from '../../../utils/GenericComponents/floatingCard';
import LinearProgressWithLabel from '../../../utils/GenericComponents/progressBar';

const { useStyles } = require('./styles');

const PAID = "paie";

const STATUS = {
  ERROR: "Error uploading file",
  DELETE_ERROR: "Error deleting file",
  SUCCESS: "File(s) uploaded successfully",
  DELETE_SUCCESS: "File deleted successfully",
}

const EmployeeFiles = props => {
  const classes = useStyles();
  return <EmployeeFilesComponent {...props} classes={classes}/>
};

class EmployeeFilesComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      progress: 0,
      snackMsg: "",
      fileNumber: 0,
      loading: true,
      employeeID: null,
      snackOpen: false,
      filteredFiles: [],
      adminFilter: false,
      salaryFilter: true,
      selectedFile: null,
      isUploading: false,
    };
    this.unMounted = false;
    this.width = props.width;
    this.isAdmin = props.isAdmin;
    this.classes = props.classes;
    this.firebase = props.firebase;
    this.setLastPaySlipFile = props.setLastPaySlipFile;
  }

  componentDidMount() {
    this.updateFiles(this.props.employee.uid);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.employee.uid !== this.props.employee.uid) {
      this.setState({ loading: true });
      this.updateFiles(this.props.employee.uid);
      if (this.props.employee.contract.type === CONTRACT_TYPE['OYSTER'] || this.props.employee.contract.type === CONTRACT_TYPE['SELF EMPLOYED']) {
        this.setState({ salaryFilter: false, adminFilter: true });
      } else {
        this.setState({ salaryFilter: true, adminFilter: false });
      }
    }
  }

  componentWillUnmount() {
    this.unMounted = true;
  }

  getPath = (uid) => `members/${uid}/employee/`;
  handleProgress = progress => this.setState( { progress });
  getColor = (filter) => (filter) ? COLORS.YELLOW : COLORS.LIGHT_GRAY;
  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });
  buildFileName = (file, emp) => `${file.name.split('.')[0]}_${this.props.employee.trigram}.${file.name.split('.')[1]}`;
  handleUploadError = error => this.setState({ isUploading: false, snackOpen: true, snackMsg: STATUS.UPLOAD_ERROR, progress: 0 });
  
  handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') return;
    this.setState({ snackOpen: false });
  };

  onAdminFilter = () => {
    this.filterFiles(this.state.files, this.state.salaryFilter, !this.state.adminFilter);
    this.setState({ adminFilter: !this.state.adminFilter });
  }
  
  onSalaryFilter = () => {
    this.filterFiles(this.state.files, !this.state.salaryFilter, this.state.adminFilter);
    this.setState({ salaryFilter: !this.state.salaryFilter });
  }

  updateFiles = (employeeID) => {
    this.setState({ files: [], employeeID, loading: true }, () => {
      this.firebase.getFilesNumber(`members/${employeeID}/employee`, this.setFilesNumber);
    });
  }

  handleClick = (file) => {
    this.setLastPaySlipFile(file);
    this.setState({ selectedFile: file.fileName });
  }

  handleUploadSuccess = filename => {
    this.setState({ files: [], filteredFiles: [] });
    setTimeout(() => this.updateFiles(this.state.employeeID), 1200);
    this.setState({ isUploading: false, snackOpen: true, snackMsg: STATUS.UPLOAD_SUCCESS, progress: 100 });
  };

  setFilesNumber = (number) => {
    if (!this.unMounted) {
      this.setState({ fileNumber: number });
      this.firebase.getFiles(`members/${this.state.employeeID}/employee`, this.setFiles);
    }
  }

  setFiles = (fileInfos) => {
    if (!this.unMounted) {
      this.setState(prevState => ({
        files: [...prevState.files, fileInfos]
      }), () => {
        if (this.state.files.length === this.state.fileNumber) {
          this.setState({ loading: false });
          this.filterFiles(this.state.files, this.state.salaryFilter, this.state.adminFilter);
        }
      });
    }
  }    

  filterFiles = (files, salaryFilter, adminFilter) => {
    const filteredFiles = files.filter(file => {
        const lowerCaseFileName = file.name.toLowerCase();
        const isNotProfile = !lowerCaseFileName.includes(INFOS.PROFILE);
        const adminCondition = adminFilter && !lowerCaseFileName.includes(PAID);
        const salaryCondition = salaryFilter && lowerCaseFileName.includes(PAID);
        return (salaryCondition || adminCondition) && isNotProfile;
    });

    filteredFiles.sort((fileB, fileA) => fileA.name.toLowerCase().localeCompare(fileB.name.toLowerCase()));
    
    this.setState({ filteredFiles: filteredFiles });
    this.setLastPaySlipFile({fileName: filteredFiles[0]?.name || "", url: filteredFiles[0]?.url || null});
  }


  deleteFile = (fileName) => {
    const storageRef = this.firebase.storage.ref(`members/${this.state.employeeID}/employee/`);
    const desertRef = storageRef.child(fileName);
    desertRef.delete()
    .then(() => {
      console.log(`file ${fileName} deleted successfully`);
      this.setState({ snackOpen: true, snackMsg: STATUS.DELETE_SUCCESS });
      this.updateFiles(this.state.employeeID);
    })
    .catch(error => {
      console.log(`ERROR deleting <${fileName}> : ${JSON.stringify(error)}`)
      this.setState({ snackOpen: true, snackMsg: STATUS.DELETE_ERROR });
    });
  }

  render() {

    const { files, progress, snackMsg, snackOpen, isUploading, employeeID, adminFilter, salaryFilter, filteredFiles } = this.state;

    const nbrOfWagesFiles = files.filter(file => file.name.toLowerCase().includes(PAID)).length;
    const nbrOfAdminFiles = Math.max(files.filter(file => !file.name.toLowerCase().includes(PAID)).length - 1, 0);

    const UploadButton = () => {
      return (
        <>
          {!isUploading && (
            <Tooltip title="Add document" arrow>
              <span>
                <FileUploader
                  multiple={true}
                  accept="application/pdf"
                  firebase={this.props.firebase}
                  path={this.getPath(employeeID)}
                  onProgress={this.handleProgress}
                  onUploadStart={this.handleUploadStart}
                  onUploadError={this.handleUploadError}
                  onUploadSuccess={this.handleUploadSuccess}
                  filename={file => this.buildFileName(file, this.props.employee)}
                >
                  <IconButton aria-label={`upload-document-employee`} component="span">
                    <AddIcon style={{ color: YELLOW }} />
                  </IconButton>
                </FileUploader>
              </span>
            </Tooltip>)}
          {isUploading && <LinearProgressWithLabel value={progress} />}
        </>
      );
    };

    return (
      <>
        <FloatingCard
          title="Files"
          avatar={<DescriptionIcon />}
          subheader={
            <div style={{paddingTop: 10}}>
              <Chip
                icon={<EuroIcon />}
                onClick={this.onSalaryFilter}
                label={`wages (${nbrOfWagesFiles})`}
                size={this.width === 'xl' ? 'medium' : 'small'}
                style={{ backgroundColor: this.getColor(salaryFilter)}}
              />
              &nbsp;
              <Chip
                icon={<DescriptionIcon />}
                onClick={this.onAdminFilter}
                label={`admin (${nbrOfAdminFiles})`}
                size={this.width === 'xl' ? 'medium' : 'small'}
                style={{ backgroundColor: this.getColor(adminFilter)}}
              />
            </div>
          }
          buttonAction={this.state.loading
            ? <CircularProgress size={24} className={this.classes.circular} />
            : this.props.isAdmin ? <UploadButton /> : null}
          >
          <div className={this.classes.fileBox}>
            {(filteredFiles.length === 0)
            ? <Typography variant="button">no files</Typography>
            : <List dense={true}>
                {filteredFiles.map((file, index) => (
                  <FileBox
                    key={index}
                    url={file.url}
                    width={this.width}
                    fileName={`${file.name}`}
                    clickCallback={this.handleClick}
                    selectedFile={this.state.selectedFile === file.name}
                    deleteCallback={this.isAdmin ? this.deleteFile : null}
                  />
                ))}
              </List>
            }
          </div>
        </FloatingCard>

        <Snackbar
          open={snackOpen}
          message={snackMsg}
          autoHideDuration={3000}
          onClose={this.handleCloseSnackBar}
          anchorOrigin={ {vertical: 'top', horizontal: 'center'} }
          action={
            <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleCloseSnackBar}>
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />
      </>

    );
  }
}

export default EmployeeFiles;
  