import { makeStyles } from '@material-ui/core/styles';
import { PURPLE } from '../../../../constants/colors';

export const useStyles = makeStyles(theme => ({

    grid: {
        marginTop: theme.spacing(2),
    },

    avatar: {
        color: "#FFF",
        backgroundColor: PURPLE,
        width: theme.spacing(4),
        height: theme.spacing(4),
        marginRight: theme.spacing(1),
    },

    heading: {
        fontSize: theme.typography.pxToRem(17),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
    },

}));
