import React from 'react';
import { withFirebase } from '../../Firebase';
import withWidth from '@material-ui/core/withWidth';
import NavigationAuthBase from './navigationAuthBase';

import { useStyles } from './styles';

const NavigationAuth = ({ firebase, authUser, width }) => {
  
  const classesNavigationAuth = useStyles();
  
  return <NavigationAuthComponent classes={classesNavigationAuth} firebase={firebase} authUser={authUser} width={width}/>
}

const NavigationAuthComponent = withFirebase(NavigationAuthBase);

export default withWidth()(NavigationAuth); 
