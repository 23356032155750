import React from "react";
import GeoChart from "./geoChart";
import StackedChart from './stackedChart';
import Grid from '@material-ui/core/Grid';
import BreakdownChart from './breakdownChart';
import * as COLORS from '../../../constants/colors';

const slicesColors = {
    0: { color: COLORS.PURPLE }, // MSSP
    1: { color: COLORS.YELLOW }, // DISTRIBUTOR
    2: { color: COLORS.VERY_LIGHT_PURPLE }, // TECHNOLOGY
    3: { color: COLORS.VERY_LIGHT_PURPLE2 }, // CONSULTING_FIRM
    4: { color: COLORS.GRAY }, // OTHERS
};

const OverviewPanel = (props) => {

    const { partners } = props;

    return(
        <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="stretch">
            <Grid item xs={12}>
                <BreakdownChart partners={partners} slicesColors={slicesColors} />
            </Grid>
            <Grid item xs={12}>
                <StackedChart partners={partners} slicesColors={slicesColors} />
            </Grid>
            <Grid item xs={12}>
                <GeoChart partners={partners} />
            </Grid>
        </Grid>
    );
}

export default OverviewPanel;