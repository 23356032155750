export const options = { axes: true };

export const COLORS = {
  0: '#0042FE',
  1: '#0088FE',
  2: '#00C49F',
  3: '#FFBB28',
  4: '#FF8042'
};

export const captions = {
  skill01: "Skills",
  skill02: "Values",
  skill03: "English",
  skill04: "Initiative",
  skill05: "Adaptability",
  skill06: "Communication",
  skill07: "Creativity in problem-solving & Analytical skills",
  skill08: "Ownership",
  skill09: "Collaboration & Leadership",
  skill10: "Reliability",
};

// ajouter une icon (i) qui affiche les définitions des compétences