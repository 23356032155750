import Logo from '../Logo';
import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import SignInButton from './signInButton';
import { withFirebase } from '../Firebase';
import { withRouter } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import CredentialsForm from './credentialsForm';
import CloseIcon from '@material-ui/icons/Close';
import * as ROUTES from '../../constants/routes';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { LOGO_ORIGINAL } from '../../constants/infos';
import { PasswordForgetLink } from '../PasswordForget';
import CardContent from '@material-ui/core/CardContent';

import { useStyles } from './styles';

const SignInPage = () => {
  
  const classes = useStyles();

  return (
    <div className={classes.root}> 
      <Grid container spacing={1} direction="column" justifyContent="center" alignItems="center" className={classes.signinGrid}>
        <Grid item xs={12}>
          <Card className={classes.card} >
            <CardContent align="center">
              <div className={classes.logoPanel}>
                <center><Logo height={155} name={LOGO_ORIGINAL}/></center>
              </div>
              <Typography variant='h6' color="textSecondary" gutterBottom className={classes.signin}>
                Sign In to Companion
              </Typography>
              <SignInForm classes={classes}/>
              <PasswordForgetLink />
              {/* <SignUpLink /> */}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

const SignInFormBase = ({ firebase, classes }) => {
  const history = useHistory();
  const [error, setError] = useState('');
  const [open, setOpen] = useState(false);

  const onSubmit = (email, password) => {
    firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        setError('');
        setOpen(false);
        history.push(ROUTES.SIMPLE_USER);
      })
      .catch(error => {
        setOpen(true);
        setError(error);
      });
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setOpen(false);
  };

  return (
    <>
      <CredentialsForm classes={classes} onSubmit={onSubmit} />

      <Snackbar
        open={open}
        onClose={handleClose}
        autoHideDuration={4000}
        message={error.message}
        anchorOrigin={ { vertical: 'bottom', horizontal: 'center' } }
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />   
    </>
  );
};

const SignInForm = withRouter(withFirebase(SignInFormBase));

export default SignInPage;
export { SignInForm, SignInButton };
