import React from 'react';
import * as ROLES from '../../constants/roles';
import withWidth from '@material-ui/core/withWidth';
import PartnershipsPageBase from './PartnershipsPageBase';
import { AuthUserContext, withAuthorization } from '../Session';

import { useStyles } from './styles';

const conditionRW = authUser => authUser && (!!authUser.roles[ROLES.ADMIN] || !!authUser.roles[ROLES.SALES] || !!authUser.roles[ROLES.PARTNERSHIPS_ADMIN]);
const conditionRO = authUser => authUser && (!!authUser.roles[ROLES.ADMIN] || !!authUser.roles[ROLES.PARTNERSHIPS] || !!authUser.roles[ROLES.SALES] || !!authUser.roles[ROLES.PARTNERSHIPS_ADMIN]);

const PartnershipsPage = props => {
  
  const options = { width : props.width };

  const classes = useStyles(options);

  return(
    <AuthUserContext.Consumer>
      { authUser => <PartnershipsPageComponent {...props} partner={authUser} classes={classes} conditionRO={conditionRO} conditionRW={conditionRW} />}
    </AuthUserContext.Consumer>
  );
}

const PartnershipsPageComponent = withAuthorization(conditionRO)(PartnershipsPageBase);

export default withWidth()(PartnershipsPage);
