import TableCell from '@material-ui/core/TableCell';
import * as COLORS from '../../../../../constants/colors';
import { withStyles, makeStyles } from '@material-ui/core/styles';

export const StyledTableCell = withStyles({
  head: {
    margin: 0,
    padding: 0,
    height: 30,
    backgroundColor: COLORS.DARK_PURPLE,
    color: COLORS.YELLOW,
    "&:hover": {
      color: COLORS.VERY_LIGHT_GRAY3,
    },
  },
  body: {
    fontSize: 14,
  },
})(TableCell);

export const useStyles = makeStyles( theme => ({

    root: {
      width: '100%',
    },

    container: {
      maxHeight: '60vh',
    },

    paginationPanel : {
      marginRight: theme.spacing(6),
    },

    searchBar: {
      height: 35,
      boxShadow: 'none',
      backgroundColor: '#EEE',
      border: '1px solid #CCC',
      marginRight: theme.spacing(1),
    },

    visuallyHidden: {
      top: 20,
      width: 1,
      border: 0,
      height: 1,
      margin: -1,
      padding: 0,
      overflow: 'hidden',
      position: 'absolute',
      clip: 'rect(0 0 0 0)',
    },

}));
