import { makeStyles } from '@material-ui/core/styles';
import { YELLOW } from '../../../../../../constants/colors';

export const useStyles = makeStyles(theme => ({

    icon: {
        color: YELLOW,
    },

    iconButton: {
        paddingTop: 10,
        paddingBottom: 7
    },

    noDoc: {
        width: '100%',
        textAlign: 'center',
        border:'1px dotted gray',
        backgroundColor: "#EFEFEF"
    },

    list: props => ({
        width: '100%',
        paddingLeft: theme.spacing(0),
        paddingBottom: theme.spacing(1),
        height: 'calc(100% - 10px)'
    }),
    
}));
