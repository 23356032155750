import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

const ConfirmDeleteHolidaysDialog = (props) => {

    const { open, onClose, onConfirm, deleteWithoutGoogleCalendar } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="confirm-deletion-dialog-title"
        aria-describedby="confirm-deletion-dialog-description"
      >
        <DialogTitle id="confirm-deletion-dialog-title">{"Are you sure to delete this request?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-deletion-dialog-description">
            {deleteWithoutGoogleCalendar === null && (
              <span>
                This request will be deleted.
              </span>
            )}
            {deleteWithoutGoogleCalendar === true && (
              <>
                <br/>
                <span style={{color: 'red'}}>
                  <b>This request will be deleted without checking your Google Calendar.</b>
                </span>
              </>
            )}
            {deleteWithoutGoogleCalendar === false && (
              <>
                <br/>
                <span style={{color: 'green'}}>
                  <b>This request will be deleted and all your associated Google Calendar events will be deleted.</b>
                </span>
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Disagree
          </Button>
          <Button onClick={onConfirm} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ConfirmDeleteHolidaysDialog;