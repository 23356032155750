
import * as ROLES from '../../constants/roles';

const TRACK_URL = "trackUrl";
const SLACK_URL = "slackUrl";
const HR_URL = "hrChannelUrl";
const ZAPIER_URL = "zapierUrl";
const ACCOUNTING_URL = "accountingUrl";
const SALES_CHANNEL_URL = "salesChannelUrl";
const JSON_HEADER = { 'Accept': 'application/json, text/plain, */*', };

class WebHooks {

    constructor(firebase) {
      this.firebase = firebase;
    }

    sendNotification = (url, msg) => {
      fetch(url, {
        body: msg,
        method: 'post',
        headers: JSON_HEADER,
      })
      .then(response => console.log)
      .catch(error => console.error);
    }

    sendNewConnectionNotification = (authUser) => {
      if (!authUser.roles[ROLES.ADMIN]) {
        this.firebase.public().on('value', snapshot => {
          const url = snapshot.val().trackUrl;
          if (url !== "none") {
            this.sendNotification(url, JSON.stringify({ text : `*${authUser.email}* has just logged in.` }));
          }
        });    
      }
    }

    sendSimpleFeedbackNotification = (msg) => {
      this.firebase.url(TRACK_URL).then(url => {
        this.sendNotification(url, JSON.stringify({ text : msg }));
      });
    }

    sendHrFeedbackNotification = (msg) => {
      this.firebase.url(HR_URL).then(url => {
        msg?.attachments ? this.sendNotification(url, JSON.stringify(msg)) : this.sendNotification(url, JSON.stringify({ text : msg }));
      });
    }

    sendAccountingFeedbackNotification = (msg) => {
      this.firebase.url(ACCOUNTING_URL).then(url => {
        this.sendNotification(url, JSON.stringify({ text : msg }));
      });
    }

    sendSalesChannelNotification = (msg) => {
      this.firebase.url(SALES_CHANNEL_URL).then(url => {
        msg?.attachments ? this.sendNotification(url, JSON.stringify(msg)) : this.sendNotification(url, JSON.stringify({ text : msg }));
      });
    }

    sendFeedbackInvestNotification = (authUser, round) => {
      this.firebase.url(TRACK_URL).then(url => {
        const lastName = authUser.civil.lastName;
        const firstName = authUser.civil.firstName;
        const investmentIntent = (round in authUser.partnerInfo.SHARES) ? authUser.partnerInfo.SHARES[round].investmentIntent : 0;
        const message = `*${firstName} ${lastName}* has entered : *${investmentIntent} €* _(round ${round})_`;
        this.sendNotification(url, JSON.stringify({ text : message }));
      });
    }
      
    sendSlackNotification = (authUser, round) => {
      this.firebase.url(SLACK_URL).then(url => {
        const lastName = authUser.civil.lastName;
        const firstName = authUser.civil.firstName;
        const investmentIntent = (round in authUser.partnerInfo.SHARES) ? authUser.partnerInfo.SHARES[round].investmentIntent : 0;
        const message = `New possible investment from *${firstName} ${lastName}* : *${investmentIntent} €* _(round ${round})_`;
        this.sendNotification(url, JSON.stringify({ text : message }));
      });
    } 
      
    fillGoogleSheet = (authUser, round) => {
      this.firebase.url(ZAPIER_URL).then(url => {
        const body = JSON.stringify( {
          contact: {
            email: authUser.email,
            lastName: authUser.civil.lastName,
            firstName: authUser.civil.firstName,
            date: authUser.partnerInfo.SHARES[round].date,
            amount: authUser.partnerInfo.SHARES[round].investmentIntent,
          }
        });
        this.sendNotification(url, body);
      });
    }
}

export default WebHooks;