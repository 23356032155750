import React from 'react';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';
import { YELLOW, PURPLE } from '../../../../constants/colors';

const styles = (props) => ({
    badge: {
        padding: '0 4px',
        top: props?.top || 0,
        right: props?.right || 0,
        backgroundColor: props?.backgroundcolor || YELLOW,
        border: `2px solid ${props?.bordercolor || PURPLE}`,
      },    
});

const StyledBadge = (props) => {
    const myStyle = styles({ ...props });
    const ModifiedBadge = withStyles(myStyle)(Badge);
    return <ModifiedBadge {...props} />
};

export default StyledBadge;

