import React from 'react';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';

const getWelcomeMessageToPrint = (props) => `${props?.message || "Welcome"} ${props?.authUser?.civil?.firstName || ""}`;

const WelcomeBar = props => {

    return (
        <Card style={{marginTop: 3}}>          
            <CardContent>
                <Typography align="center" style={{ fontSize: 24 }}>
                    { getWelcomeMessageToPrint(props) }
                </Typography>
            </CardContent>
        </Card>
    );
}

export default WelcomeBar;