import { makeStyles } from '@material-ui/core/styles';
import { GRAY,VERY_LIGHT_PURPLE3 } from '../../../../constants/colors';

export const useStyles = makeStyles((theme) => ({
  
  label: {
    color: GRAY,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.0rem'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '0.9vw',
      marginTop: theme.spacing(1.8),
    },  
  },

  iconFileCopy: {
    zIndex: 10,
  },

  textField: props => ({
    zIndex: 0,
    width: '100%',
    backgroundColor: props.disabled ? null : VERY_LIGHT_PURPLE3,
  }),

  resize:{
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.0rem'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '0.9vw',
      marginTop: theme.spacing(1.1),
    },  
  },

})); 
