import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import BusinessIcon from '@material-ui/icons/Business';
import FloatingCard from '../../utils/GenericComponents/floatingCard';
import DataStudioCard from '../../utils/GenericComponents/dataStudioCard';

import { useStyles } from './styles';

const MarketingMaterial = (props) => {
    
    const { steeringObj } = props;
    const classes = useStyles();

    return(
        <FloatingCard
            title={"Marketing material"}
            subheader={<i>Files, logos, decks ...</i>}
            avatar={<BusinessIcon />}
            noflex={true}
        >
            <Card className={classes.card}>
                <CardContent>
                    <DataStudioCard
                        {...props}
                        noCard={true}
                        title={"Files"} ratio={569/960}
                        url={steeringObj.partnershipUrl}
                    />
              </CardContent>
            </Card>
        </FloatingCard>
    );
}

export default MarketingMaterial;
