import Chart from "react-google-charts";
import { TYPE } from "../../partnersType";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import React, { useLayoutEffect, useState, useRef } from "react";
import FloatingCard from '../../../utils/GenericComponents/floatingCard';

const OPTIONS = {
  LineChart: { label: "Lines", type: "LineChart" },
  ColumnChart: { label: "Bars", type: "ColumnChart" },
}

const StackedChart = (props) => {

    const { partners, slicesColors } = props;
    let movement_timer = null;
    const RESET_TIMEOUT = 100;
    const targetRef = useRef();
    const [option, setOption] = useState(OPTIONS.ColumnChart.type);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  
    const test_dimensions = () => {
      if (targetRef.current) {
        setDimensions({
          width: targetRef.current.offsetWidth,
          height: targetRef.current.offsetHeight
        });
      }
    };
  
    useLayoutEffect(() => { test_dimensions() }, []);
    const handleChangeOption = (event) => setOption(event.target.value);
  
    window.addEventListener("resize", () => {
      clearInterval(movement_timer);
      movement_timer = setTimeout(test_dimensions, RESET_TIMEOUT);
    });

    const countPartners = {};
    const stackedChartData = [];
    const sortedTypes = Object.keys(TYPE).sort((a, b) => TYPE[a].id - TYPE[b].id);
    const sortedPartersByDate = partners.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
    stackedChartData.push(["Date", ...sortedTypes]);
    sortedTypes.forEach(type => countPartners[type] = 0);
    sortedPartersByDate.forEach(partner => {
      countPartners[partner.type] += 1;
      stackedChartData.push([new Date(partner.createdAt), ...Object.values(countPartners)]);
    });    

    return(
      <div ref={targetRef} >
        <FloatingCard
          title="Partnerships Evolutions"
          subheader={`by categories`}
          avatar={<DonutLargeIcon />}
          buttonAction={
            <FormControl style={{ fontSize: 12 }}>
              <InputLabel id="stacked-choose-option-label">Options</InputLabel>
                <Select
                  value={option}
                  style={{ fontSize: 12 }}
                  id="stacked-choose-option"
                  onChange={handleChangeOption}
                  labelId="stacked-choose-option-label"
                >
                  <MenuItem value={OPTIONS.ColumnChart.type} >{OPTIONS.ColumnChart.label}</MenuItem>
                  <MenuItem value={OPTIONS.LineChart.type} >{OPTIONS.LineChart.label}</MenuItem>
              </Select>
            </FormControl>
          }
        >
          <Chart
            width={dimensions.width * 1}
            data={stackedChartData || []}
            height={dimensions.height * 2}
            chartType={OPTIONS[option].type}
            rootProps={{ 'data-testid': '2' }}
            loader={<div>Loading Chart...</div>}
            options={{
              isStacked: true,
              hAxis: { title: ''},
              series: slicesColors,
              backgroundColor: 'transparent',
              vAxis: { title: 'Number of partners' },
              chartArea: { width: "90%", height: "80%" },
              legend: {'position': 'top', 'alignment':'center'},
            }}
          />
        </FloatingCard>
    </div>
  );
}

export default StackedChart;