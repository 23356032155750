import React from "react";
import PdfCard from "./pdfCard";
import { Grid } from '@material-ui/core';
import WagesChart from "./employeeWages";
import EmployeeFiles from "./employeeFiles";
import { CONTRACT_TYPE } from '../contract_type_constants';


const EmployeeWages = props => {

    const [lastPaySlipFile, setLastPaySlipFile] = React.useState(null);

    const contractType = props.employee.contract.type;
        
    return (
        <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="stretch">
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <Grid container spacing={2} direction="column" justifyContent="flex-start" alignItems="stretch" >
                    <Grid item xs={12}>
                        <PdfCard {...props} lastPaySlipFile={lastPaySlipFile} />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
                <Grid container spacing={2} direction="column" justifyContent="flex-start" alignItems="stretch" >
                    <Grid item xs={12} >
                        <EmployeeFiles {...props} setLastPaySlipFile={setLastPaySlipFile} />
                    </Grid>
                    {contractType !== CONTRACT_TYPE['OYSTER'] && contractType !== CONTRACT_TYPE['SELF EMPLOYED'] && (
                        <Grid item xs={12}>
                            <WagesChart {...props} />
                        </Grid>
                    )}
                </Grid>
            </Grid>
    </Grid>
    );
};

export default EmployeeWages;