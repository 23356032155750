import { makeStyles } from '@material-ui/core/styles';
import { GRAY, PURPLE } from '../../../../../constants/colors';

export const useStyles = makeStyles(theme => ({

    grid: {
        marginTop: theme.spacing(2),
    },

    metricsSmall : {
        color: GRAY,
        fontSize: '0.9em',
        textAlign : 'center',
    },

    metrics : {
        color: PURPLE,
        textAlign : 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.1em'
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '2em'
        },
    },

}));
