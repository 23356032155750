import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Tooltip, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import LinearProgressWithLabel from '../../../../utils/GenericComponents/progressBar';

const { useStyles, PrettoSlider } = require('./styles');

const QuarterRow = (props) => {

    const classes = useStyles();
    const { year, width, quarter, edition, employee, setError, setSnackStatus } = props;
    const goals = Object.keys(quarter).map(key => (key.indexOf('goal') > -1) ? { name: key, ...quarter[key] } : null).filter(goal => goal);
    goals.sort((a, b) => (a.name > b.name) ? 1 : -1);
    const [sliderValues, setSliderValues] = React.useState(goals.map(goal => goal.progress));
    const [descriptions, setDescriptions] = React.useState(goals.map(goal => goal.description));

    const sm = (width === 'sm') || (width === 'xs');

    React.useEffect(() => {
        if (sliderValues.length !== goals.length || goals.map(goal => goal.description).join('') !== descriptions.join('') || goals.map(goal => goal.progress).join('') !== sliderValues.join('')) {
            setSliderValues(goals.map(goal => goal.progress));
            setDescriptions(goals.map(goal => goal.description));
        }
    }, [goals, sliderValues.length, descriptions, sliderValues]);

    const onDeleteGoal = (goalName) => {
        delete employee.contract.goals[year.value][quarter.value][goalName];
        setSnackStatus(true, "Goal deleted successfully");
    };

    const onChangeSlider = (newValue, goalName) => {
        employee.contract.goals[year.value][quarter.value][goalName].progress = newValue;
        const goal = goals.find(goal => goal.name === goalName);
        setSliderValues(sliderValues.map((value, index) => (index === goals.indexOf(goal)) ? newValue : value));
    };

    const onMoveUpGoal = (goalName) => {
        const goal = employee.contract.goals[year.value][quarter.value][goalName];
        const previousQuarter = (quarter.value === 'Q1') ? 'Q4' : `Q${parseInt(quarter.value[1]) - 1}`;
        employee.contract.goals[year.value][previousQuarter][goalName] = goal;
        delete employee.contract.goals[year.value][quarter.value][goalName];
        setSnackStatus(true, "Goal moved up successfully");
    };

    const onMoveDownGoal = (goalName) => {
        const goal = employee.contract.goals[year.value][quarter.value][goalName];
        const nextQuarter = (quarter.value === 'Q4') ? 'Q1' : `Q${parseInt(quarter.value[1]) + 1}`;
        employee.contract.goals[year.value][nextQuarter][goalName] = goal;
        delete employee.contract.goals[year.value][quarter.value][goalName];
        setSnackStatus(true, "Goal moved down successfully");
    };

    const onChangeDescription = (event, goalName) => {
        event.stopPropagation();
        setError((event.target.value.length === 0));
        const goal = goals.find(goal => goal.name === goalName);
        employee.contract.goals[year.value][quarter.value][goalName].description = event.target.value;
        setDescriptions(descriptions.map((description, index) => (index === goals.indexOf(goal)) ? event.target.value : description));
    };

    return (
        <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center" className={classes.root}>
            {goals.map(goal => (
                <div key={goal.name} style={{width: '100%'}}>
                    <Grid item xs={12}>
                        <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center" className={edition ? classes.edition : classes.noedition}>
                            <Grid item xs={edition ? 7 : 9}>
                                <TextField
                                    fullWidth
                                    multiline
                                    maxRows={3}
                                    disabled={!edition}
                                    variant={"standard"}
                                    id={`goal-${goal.name}`}
                                    name={`goal-${goal.name}`}
                                    placeholder="Fill an objective"
                                    value={descriptions?.[goals.indexOf(goal)] || ""}
                                    error={descriptions?.[goals.indexOf(goal)]?.length === 0}
                                    onChange={(event) => onChangeDescription(event, goal.name)}
                                    InputProps={{ style: { fontSize: '0.8rem' }, disableUnderline: edition ? false : true }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                {!edition && !sm && (<LinearProgressWithLabel value={goal.progress} backcolor='#f5c96e'/>)}
                                {!edition && sm && (<Typography variant="caption" color="textSecondary" component="p" style={{fontSize: '0.8rem', textAlign: 'center'}}>{goal.progress}%</Typography>)}
                                {edition && (
                                    <PrettoSlider
                                        min={0}
                                        step={1}
                                        max={100}
                                        valueLabelDisplay="auto"
                                        className={classes.slider}
                                        aria-labelledby={`slider-${goal.name}`}
                                        value={sliderValues?.[goals.indexOf(goal)] || 0}
                                        onChange={(event, value) => onChangeSlider(value, goal.name)}
                                    />)}
                            </Grid>
                            {edition && (
                                <Grid item xs={2}>
                                    <Grid container spacing={0} direction='row' justifyContent="flex-end" alignItems="center">
                                        <Grid item style={{maxWidth: 45}}>
                                            <Tooltip arrow title="Move to previous quarter">
                                                <span>
                                                    <IconButton disabled={goals.length < 2} aria-label="delete" onClick={() => onMoveUpGoal(goal.name)}>
                                                        <ArrowUpwardIcon style={{fontSize: '1.0rem'}}/>
                                                    </IconButton>
                                                    </span>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item style={{maxWidth: 45}}>
                                            <Tooltip arrow title="Move to next quarter">
                                                <span>
                                                    <IconButton disabled={goals.length < 2} aria-label="delete" onClick={() => onMoveDownGoal(goal.name)}>
                                                        <ArrowDownwardIcon style={{fontSize: '1.0rem'}}/>
                                                    </IconButton>
                                                    </span>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item style={{maxWidth: 45}}>
                                            <Tooltip arrow title="Delete goal">
                                                <span>
                                                    <IconButton disabled={goals.length < 2} aria-label="delete" onClick={() => onDeleteGoal(goal.name)}>
                                                        <DeleteIcon style={{fontSize: '1.0rem'}}/>
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                            </Grid>)}
                        </Grid>
                    </Grid>
                </div>
                ))}
        </Grid>
    );
}
  
export default QuarterRow;