import { makeStyles } from '@material-ui/core/styles';
import { PURPLE, YELLOW, RED, GREEN } from '../../../../../constants/colors';

export const useQontoStepIconStyles = makeStyles({
    root: {
      color: '#eaeaf0',
      display: 'flex',
      height: 22,
      alignItems: 'center',
    },
    active: {
      color: '#784af4',
    },
    circle: {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
    completed: {
      color: '#784af4',
      zIndex: 1,
      fontSize: 18,
    },
  });

export const useStyles = makeStyles(theme => ({

    grid: {
        marginTop: theme.spacing(2),
    },

    select: {
        fontSize: 12,
    },

    stepper: {
        padding: 0,
        background: 'transparent'
    },

    stepButton: {
        color: PURPLE,
    },

    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    
    circularProgress: props => ({
        maxWidth: 43,
        maxHeight: 43,
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
        color: props.completedValue < 30 ? RED : props.completedValue < 90 ? YELLOW : GREEN,
    }),

    circularProgressGray: {
        marginTop: 0,
        maxWidth: 43,
        maxHeight: 43,
        color: '#DDD',
    },

    box: {
        left: 0,
        bottom: 0,
        display: "flex",
        position: "absolute",
        alignItems: "center",
        top: theme.spacing(1),
        right: theme.spacing(1),
        justifyContent: "center",
    },


}));
