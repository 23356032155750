import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import * as COLORS from '../../../../../constants/colors';
import LinearProgress from '@material-ui/core/LinearProgress';

export const BorderLinearProgress = withStyles((theme) => ({
    
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },

    root: {
        height: 10,
        borderRadius: 5,
    },

    bar: props => ({
        borderRadius: 5,
        backgroundColor: props?.backcolor || COLORS.YELLOW,
    }),

})) (LinearProgress);


export const useStyles = makeStyles( theme => ( {

    circularProgressGray: props => ({
        marginTop: 0,
        color: '#DDD',
        maxWidth: props.size || 43,
        maxHeight: props.size || 43,
    }),

    circularProgress: props => ({
        maxWidth: props.size || 43,
        maxHeight: props.size || 43,
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
        color: props?.normal
        ? props.value > (props?.level3 || 70) ? COLORS.GREEN : props.value > (props?.level2 || 50) ? COLORS.ORANGE : COLORS.RED
        : props.value < (props?.level1 || 30) ? COLORS.GREEN : props.value < (props?.level2 || 50) ? COLORS.ORANGE : COLORS.RED,
    }),

    text: props => ({
        color: COLORS.PURPLE,
        fontSize: props.size ? props.size / 4 : 12,
    }),

    box: {
        left: 0,
        bottom: 0,
        display: "flex",
        position: "absolute",
        alignItems: "center",
        top: theme.spacing(1),
        right: theme.spacing(1),
        justifyContent: "center",
    },
}));

