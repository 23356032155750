import React from "react";
import TeamCard from "./employeeTeam";
import { Grid } from '@material-ui/core';
import EmployeeProfile from "./employeeProfile";
import PersonalDetails from "./personalDetails";
import CompanyInfosDetails from "./employeeCompanyInfos";

const EmployeeInCompany = props => {
    
    return (
      <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="stretch">

        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Grid container spacing={2} direction="column" justifyContent="flex-start" alignItems="stretch">
            <Grid item xs={12}>
              <EmployeeProfile {...props} editable={true} />
            </Grid>
            <Grid item xs={12}>
              <TeamCard {...props} />
            </Grid>
          </Grid>
        </Grid>
  
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <PersonalDetails {...props} />
        </Grid>
  
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <CompanyInfosDetails {...props} />
        </Grid>
  
      </Grid>
    );
};

export default EmployeeInCompany;