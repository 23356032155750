import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import RadarChart from 'react-svg-radar-chart';
import TuneIcon from '@material-ui/icons/Tune';
import { DialogTitle } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import 'react-svg-radar-chart/build/css/index.css'
import Textfield from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Typography from "@material-ui/core/Typography";
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import { COLORS, captions, options } from '../performancesDetails';
import FloatingCard from "../../../../../utils/GenericComponents/floatingCard";

import { DialogContent, DialogActions, useStyles, IOSSlider, marks } from './styles';

const EditableChart = (props) => {

    const { data, width, classes, evaluator, setSaveDisabled, setEvaluationData } = props;
    const [evaluatorValue, setEvaluatorValue] = React.useState(evaluator || "");

    if (Object.keys(data).length === 0) {
        Object.keys(captions).forEach(key => { data[key] = 0.5 });
    }

    const [performances, setPerformances] = React.useState({...data});

    const valuetext = (value) => {
        let text = "";
        if (value > 0 && value <= 0.25) text = "far below expectations";
        if (value > 0.25 && value <= 0.45) text = "below expectations";
        if (value > 0.45 && value <= 0.55) text = "meets expectations";
        if (value > 0.55 && value <= 0.75) text = "above expectations";
        if (value > 0.75 && value <= 1) text = "walks on water";
        return <div className={classes.sliderPopup}>{text}</div>;
    }

    React.useEffect(() => {
        setSaveDisabled(evaluatorValue.length === 0);
    }, [evaluatorValue, setSaveDisabled]);

    const onChange = (event, value, key) => {
        performances[key] = value;
        setPerformances({...performances});
    };

    const handleEvaluatorChange = (event) => {
        setEvaluatorValue(event.target.value);
        setEvaluationData(performances, event.target.value);
    };

    const onChangeCommitted = (event, value, key) => {
        performances[key] = value;
        setPerformances({...performances});
        setEvaluationData(performances, evaluatorValue);
    };

    return (
        <Grid container spacing={2} direction="row" justifyContent="center" alignItems="stretch" style={{padding: 10}}>
            <Grid item xs={12} sm={8} >
                <FloatingCard
                    title="360° Performance diagram"
                    avatar={<DonutLargeIcon />}
                >
                    <RadarChart
                        size={width}
                        colors={COLORS}
                        options={options}
                        captions={captions}
                        data={[{ data: performances, meta: { color: COLORS[0] } }]}
                    />
                </FloatingCard>
            </Grid>
            <Grid item xs={12} sm={4}>
                <FloatingCard
                    title="Parameters"
                    avatar={<TuneIcon />}
                >
                    <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" style={{marginTop: -30}}>
                        <Grid item xs={12}>
                            <Textfield
                                fullWidth
                                id="evaluator"
                                margin="normal"
                                variant="outlined"
                                value={evaluatorValue}
                                label="Evaluator's name"
                                error={evaluatorValue === ''}
                                onChange={handleEvaluatorChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {Object.keys(captions).map((key, index) => (
                                <div key={index} style={{marginTop: -4}}>
                                    <Typography id="skills-slider-small-steps" gutterBottom variant="caption">
                                        {captions[key]}
                                    </Typography>
                                    <IOSSlider
                                        min={0.0}
                                        max={1.0}
                                        step={0.01}
                                        marks={marks}
                                        defaultValue={0.0}
                                        valueLabelDisplay="auto"
                                        value={performances[key]}
                                        valueLabelFormat={valuetext}
                                        aria-labelledby={`skills-slider-small-steps-${key}`}
                                        onChange={(event, value) => onChange(event, value, key)}
                                        onChangeCommitted={(event, value) => onChangeCommitted(event, value, key)}
                                    />
                                </div>
                            ))}
                        </Grid>
                    </Grid>
                </FloatingCard>
            </Grid>
        </Grid>
    );
}


const EditablePerformanceChartDialog = (props) => {

    const classes = useStyles();
    const [saveDisabled, setSaveDisabled] = React.useState(true);
    const { open, year, data, width, edition, evaluator, handleClose, handleSave, employee } = props;

    let performances = {};
    let evaluatorNewValue = evaluator;

    const handleSaveWrapper = () => handleSave(year, performances, evaluatorNewValue);

    const setEvaluationData = (data, evaluator) => {
        performances = data;
        evaluatorNewValue = evaluator;
    }

    return (
        <Dialog fullScreen onClose={handleClose} aria-labelledby="team-dialog" open={open} className={classes.dialog}>
            <DialogTitle className={classes.dialogTitle}>
                {edition ? "Edit" : "New" } evaluation for {employee?.civil?.firstName} {employee?.civil?.lastName} for {year}
                <IconButton aria-label="close" onClick={handleClose} className={classes.closeButton}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
  
            <DialogContent dividers className={classes.dialogContent}>
                <EditableChart
                    data={data}
                    width={width}
                    classes={classes}
                    evaluator={evaluator}
                    setSaveDisabled={setSaveDisabled}
                    setEvaluationData={setEvaluationData}
                />
            </DialogContent>
  
            <DialogActions>
                <Button onClick={handleClose} color="primary" >
                    Close
                </Button>
                <Button onClick={handleSaveWrapper} color="primary" disabled={saveDisabled}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditablePerformanceChartDialog;