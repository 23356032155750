import React from 'react';
import { Grid } from '@material-ui/core';
import { DAY_OPTIONS } from '../dayOptions';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';

const DayOption = (props) => {
    
    const { id, oneDayOnly, date, selection, handleChangeOption } = props;

    return (
        <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center" >
            <Grid item >
                <Typography>Select option for <strong>{date.toLocaleDateString()}</strong>:</Typography>
            </Grid>
            <Grid item >
                <FormControl >
                    <Select
                        id={id}
                        value={selection}
                        onChange={handleChangeOption}
                        style={{ marginLeft: 5, marginRight: 5, paddingLeft: 5, paddingRight: 5 }}
                    >
                        <MenuItem value={DAY_OPTIONS.HALF_DAY.option}>{oneDayOnly ? DAY_OPTIONS.MORNING.label : DAY_OPTIONS.HALF_DAY.label}</MenuItem>
                        {oneDayOnly && (
                        <MenuItem value={DAY_OPTIONS.AFTERNOON.option}>{DAY_OPTIONS.AFTERNOON.label}</MenuItem>)}
                        <MenuItem value={DAY_OPTIONS.FULL_DAY.option}>{DAY_OPTIONS.FULL_DAY.label}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
}

const HalfFullDayOptionsPanel = (props) => {
    const { endDate, startDate, endDayOption, startDayOption, setEndDayOption, setStartDayOption } = props;

    const oneDayOnly = endDate.getTime() - startDate.getTime() < 86400000;

    const handleChangeOption = (event) => {
        setStartDayOption(event);
        if (oneDayOnly) setEndDayOption(null);
    }

    return (
        <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
            <Grid item align="center">
                <DayOption
                    date={startDate}
                    id="start-date-select"
                    oneDayOnly={oneDayOnly}
                    selection={startDayOption}
                    handleChangeOption={handleChangeOption}
                />
            </Grid>
            <Grid item align="center">
                {!oneDayOnly && (
                    <DayOption
                        date={endDate}
                        id="end-date-select"
                        selection={endDayOption}
                        handleChangeOption={setEndDayOption}
                    />
                )}
            </Grid>
        </Grid>
    );
}

export default HalfFullDayOptionsPanel;