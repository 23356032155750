import React from 'react';
import * as UTILS from '../../../utils';
import { Grid, Tooltip } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import FloatingCard from '../../../utils/GenericComponents/floatingCard';

import { useStyles } from './styles';

const Metrics = ({steeringObj}) => {

    const classes = useStyles();

    const bannedIps = UTILS.nFormatter(steeringObj?.metrics?.banned_ip?.total || null, 1);
    const totalCountries = UTILS.nFormatter(steeringObj?.metrics?.watchers?.countries || null, 1);
    const totalWatchers = UTILS.nFormatter(steeringObj?.metrics?.watchers?.total_watchersv2 || null, 1);
    const date = UTILS.dateToPrettyString(new Date(parseInt((steeringObj?.metrics?.date * 1000) || Date.now())));

    return (
        <FloatingCard>
            <Tooltip title={`Last update: ${date}`}>
                <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center" className={classes.grid}>
                    <Grid item xs>
                        <Typography variant="h4" className={classes.metrics}>{bannedIps || "(error)" }</Typography>
                        <Typography variant={"body2"} className={classes.metricsSmall}>ip banned</Typography>
                    </Grid>
                    <Grid item xs>
                        <Typography variant="h4" className={classes.metrics}>by</Typography>
                    </Grid>
                    <Grid item xs>
                        <Typography variant="h4" className={classes.metrics}>{totalWatchers}</Typography>
                        <Typography variant={"body2"} className={classes.metricsSmall}>watchers</Typography>
                    </Grid>
                    <Grid item xs>
                        <Typography variant="h4" className={classes.metrics}>in</Typography>
                    </Grid>
                    <Grid item xs>
                        <Typography variant="h4" className={classes.metrics}>{totalCountries || "(error)"}</Typography>
                        <Typography variant={"body2"} className={classes.metricsSmall}>countries</Typography>
                    </Grid>
                </Grid>
            </Tooltip>
        </FloatingCard>
    );
};

export default Metrics;