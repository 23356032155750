import { makeStyles } from '@material-ui/core/styles';
import * as COLORS from '../../../../../constants/colors';

export const useStyles = makeStyles(theme => ({

    typographyPopover: {
        padding: theme.spacing(1),
    },

    amount: {
        border: '1px dashed #aaa',
        borderRadius: theme.spacing(0.7),
    },

    payment: (props) => ({
        margin: 1,
        width: '99%',
        border: '1px solid #888',
        padding: theme.spacing(1),
        borderRadius: theme.spacing(0.5),
        backgroundColor: COLORS.VERY_LIGHT_PURPLE,
        "&:hover": {
            backgroundColor: COLORS.VERY_LIGHT_PURPLE3,
        },
        borderLeft: `7px solid ${props.type === 'transit' ? '#090' : 'orange'}`,
    }),

}));
