import React from "react";
import { Grid } from '@material-ui/core';
import EmployeeLinks from "./employeeLinks";
import ChartersAndPolicies from "./chartersAndPolicies";
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import { OFFICE_MANAGER } from "../../../constants/infos";
import EmployeeLaborInspection from "./employeeInspection";
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import EmployeeInteractiveFolderView from "../../utils/GenericComponents/employeeInteractiveFolderView";

const FOLDERS = {
    remote: {
        option: "remote",
        icon: <LaptopMacIcon />,
        title: "Various certificates",
        subheader: "needed for remote work",
    },
    medical: {
        option: "medical",
        icon: <LocalHospitalIcon />,
        title: "Medical certificates",
        subheader: "mandatory medical examination",
    }
};

const EmployeeInfos = props => {
    
    const { width, firebase, employee, inspectionObject } = props;

    return (
        <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="stretch">
            {Object.values(FOLDERS).map((folder, index) => (
                <Grid item xs={12} sm={12} md={4} lg={4} key={index}>
                    <EmployeeInteractiveFolderView
                        height={250}
                        width={width}
                        icon={folder.icon}
                        id={folder.option}
                        employee={employee}
                        firebase={firebase}
                        title={folder.title}
                        option={folder.option}
                        subheader={folder.subheader}
                        message={`Please contact ${ OFFICE_MANAGER }`}
                    />
                </Grid>
            ))}
            <Grid item xs={12} sm={12} md={4} lg={4}>
                <EmployeeLinks />
            </Grid>
            <Grid item xs={12}>
                <EmployeeLaborInspection inspectionObject={inspectionObject}/>
            </Grid>
            <Grid item xs={12}>
                <ChartersAndPolicies width={width} firebase={firebase}/>
            </Grid>
        </Grid>
    );
};

export default EmployeeInfos;