import React from 'react';
import Popover from '@material-ui/core/Popover';
import EmailIcon from '@material-ui/icons/Email';
import ListItem from '@material-ui/core/ListItem';
import PersonIcon from '@material-ui/icons/Person';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import { Avatar, Tooltip, Grid } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import ConfirmAuthorizationDialog from './confirmAuthorizationDialog';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import { useStyles } from './styles';

const Contact = (props) => {

    const { dealID, contact, createUser, partner, conditionRW } = props;

    const classes = useStyles({contact});
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openConfirmAuthorizationDialog, setOpenConfirmAuthorizationDialog] = React.useState(false);
    
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const handleClose = () => setAnchorEl(null);
    const handleClick = (event) => setAnchorEl(event.currentTarget);

    const handleAskToAuthorizeUser = (event) => {
        event.stopPropagation();
        setOpenConfirmAuthorizationDialog(true);
    }

    const handleConfirmAuthorization = () => {
        setOpenConfirmAuthorizationDialog(false);
        const payload = {
            email: contact.email,
            lastname: contact?.lastname || 'tbd',
            firstname: contact?.firstname || 'tbd',
        }
        createUser(dealID, payload);
    };

    const handleCloseConfirmAuthorizationDialog = () => setOpenConfirmAuthorizationDialog(false);

    return (
        <>
            <ListItem
                disabled={false}
                onClick={handleClick}
                className={classes.contactBox}
            >
                <ListItemAvatar>
                    <EmailIcon className={classes.descriptionIcon}/>
                </ListItemAvatar>
                <ListItemText
                    primary={contact.email}
                    className={classes.listItemText}
                />
                
                {contact.authorized && (
                    <ListItemSecondaryAction >
                        <HowToRegIcon className={classes.setPrimaryIcon}/>
                    </ListItemSecondaryAction>
                )}
                {conditionRW(partner) && !contact?.authorized && (
                    <ListItemSecondaryAction onClick={handleAskToAuthorizeUser}>
                        <Tooltip title="authorize" arrow>
                            <HowToRegIcon className={classes.setPrimaryIcon}/>
                        </Tooltip>
                    </ListItemSecondaryAction>
                )}

            </ListItem>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Grid container spacing={1} direction="row" className={classes.popoverTypo} justifyContent="space-evenly">
                    <Grid item xs>
                        <Avatar>{contact?.authorized ? <VerifiedUserIcon /> : <PersonIcon />}</Avatar>
                    </Grid>
                    <Grid item xs>
                        {`${contact?.firstname || "undefined"} ${contact?.lastname || "undefined"}`}
                    </Grid>
                </Grid>
            </Popover>

            <ConfirmAuthorizationDialog
                handleConfirmAuthorization={handleConfirmAuthorization}
                openConfirmAuthorizationDialog={openConfirmAuthorizationDialog}
                handleCloseConfirmAuthorizationDialog={handleCloseConfirmAuthorizationDialog}
            />
        </>
    );
};

export default Contact;