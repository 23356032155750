import React from 'react';
import List from '@material-ui/core/List';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import { Tooltip, IconButton } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import FileBox from '../../../../utils/GenericComponents/fileBox';
import { PARTNERSHIPS } from '../../../../../constants/realtimeDB';
import FloatingCard from '../../../../utils/GenericComponents/floatingCard';
import FileUploader from '../../../../utils/GenericComponents/fileUploader';
import LinearProgressWithLabel from '../../../../utils/GenericComponents/progressBar';

import { useStyles } from './styles';

const NoDoc = (props) => {
    const { classes } = props;
    return(
        <div className={classes.noDoc} >
            <AddDocButton {...props} />
        </div>
    );
}

const AddDocButton = (props) => {

    const { dealID, classes, partnerID, firebase, handleUploadSuccess, handleUploadStart, handleProgress, handleUploadError } = props;

    return (
        <Tooltip title="Add document" arrow>
            <span>
                <FileUploader
                    multiple={true}
                    firebase={firebase}
                    onProgress={handleProgress}
                    accept="application/pdf,image/*"
                    onUploadStart={handleUploadStart}
                    onUploadError={handleUploadError}
                    onUploadSuccess={handleUploadSuccess}
                    path={`${PARTNERSHIPS}/${partnerID}/${dealID}`}
                >
                    <IconButton aria-label={`upload-document-partner-docs`} component="span">
                        <AddIcon className={classes.icon}/>
                    </IconButton>
                </FileUploader>
            </span>
        </Tooltip>
    );
}

const Documents = (props) => {

    const { width, loadFiles, documents } = props;
    const [progress, setProgress] = React.useState(0);
    const classes = useStyles({length: documents.length});
    const [isUploading, setIsUploading] = React.useState(false);
    const [snackBarMessage, setSnackBarMessage] = React.useState('');
    const [snackBarOpened, setSnackBarOpened] = React.useState(false);

    const handleProgress = progress => setProgress(progress);

    const handleUploadStart = () => {
        setIsUploading(true);
        setProgress(0);
    };
    
    const handleUploadError = (error) => {
        setIsUploading(false);
        setSnackBarMessage("Error uploading file");
        setSnackBarOpened(true);
    };

    const handleUploadSuccess = (filename) => {
        setIsUploading(false);
        setSnackBarMessage(`${filename} uploaded successfully`);
        loadFiles();
        setSnackBarOpened(true);
    }

    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') return;
        setSnackBarOpened(false);
    };

    return(
        <>
        <FloatingCard
            width={width}
            title="Documents"
            avatar={<DescriptionIcon />}
            action={isUploading && <LinearProgressWithLabel value={progress} />}
            buttonAction={documents.length > 0
                ? <AddDocButton
                    {...props}
                    classes={classes}
                    handleProgress={handleProgress}
                    handleUploadError={handleUploadError}
                    handleUploadStart={handleUploadStart}
                    handleUploadSuccess={handleUploadSuccess}
                />
                : null
            }
        >
            {documents.length > 0
            ? ( <List className={classes.list} dense={true}>
                {documents.sort((a, b) => ( a.name < b.name ? 1 : -1 )).map((file, index) => (
                    <FileBox key={index} disabled={false} width={width} fileName={`${file.name}`} url={file.url}/>
                ))}
            </List>)
            : (<NoDoc
                {...props}
                classes={classes}
                handleProgress={handleProgress}
                handleUploadError={handleUploadError}
                handleUploadStart={handleUploadStart}
                handleUploadSuccess={handleUploadSuccess}
            />)
            }
        </FloatingCard>
        <Snackbar
            open={snackBarOpened}
            autoHideDuration={3000}
            message={snackBarMessage}
            onClose={handleCloseSnack}
            anchorOrigin={ {vertical: 'top', horizontal: 'center' }}
            action={
                <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            }
          />   

        </>
    );
};

export default Documents;