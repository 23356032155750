import { WorldMap } from './worldmap';
import { schema } from './staffRegister';
import { Grid } from '@material-ui/core';
import React, { useState }  from 'react';
import { diffDate } from '../../../utils';
import writeXlsxFile from 'write-excel-file';
import Tooltip from '@material-ui/core/Tooltip';
import GroupIcon from '@material-ui/icons/Group';
import { TeamFilter, TeamList } from './teamList';
import PublicIcon from '@material-ui/icons/Public';
import { getTotalOwnedEsops } from '../../../utils';
import ListAltIcon from '@material-ui/icons/ListAlt';
import * as ROLES from '../../../../constants/roles';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import MemberAvatar from '../../../utils/GenericComponents/memberAvatar';
import FloatingCard from '../../../utils/GenericComponents/floatingCard';

import { useStyles } from './styles';

const getAvatarSize = (width) => {
    const base = 5;
    const step = 1;
    switch(width) {
        case 'xs' : return base + 2 * step;
        case 'sm' : return base + 3 * step;
        case 'md' : return base + 0 * step;
        case 'lg' : return base + 1 * step;
        case 'xl' : return base + 5 * step;
        default : return base;
    }
}

const StaffRegisterButton = props => {
    const { employees } = props;

    const handleClick = () => {
        const objects = [];
        employees.forEach(employee => {
            const { civil, contract,  } = employee;
            const { start, end } = contract;
            const object = {
                end: end,
                start: start,
                zip: civil.zip,
                city: civil.city,
                type: contract.type,
                role: contract.role,
                address: civil.address,
                country: civil.country,
                salary: contract.salary,
                lastName: civil.lastName,
                firstName: civil.firstName,
                birthDate: civil.birthDate,
                persoMail: civil.persomail,
                id: civil.id || employee.id,
                birthPlace: civil.birthPlace,
                persoPhone: civil.persoPhone,
                esops: parseInt(getTotalOwnedEsops(employee)),
                age: Math.floor((new Date() - new Date(civil.birthDate.split('/')[2], civil.birthDate.split('/')[0], civil.birthDate.split('/')[1])) / 31536000000),
            }
            objects.push(object);
        });
        writeXlsxFile(objects, { schema, fileName: 'staff_register.xlsx' })
        .then(() => console.log('File written.'))
        .catch(error => console.error(error));
    }

    return (
        <Tooltip title={ "Staff register" } arrow>
            <IconButton
                aria-label="edit"
                onClick={handleClick}
            >
                <ListAltIcon />
            </IconButton>
        </Tooltip>
    );
}

const WorldmapButton = props => {
    const { onClickWorldMap } = props;

    return (
        <Tooltip title={ "See alpacas world domination" } arrow>
            <IconButton
                aria-label="world"
                onClick={onClickWorldMap}
            >
                <PublicIcon />
            </IconButton>
        </Tooltip>
    );
}

const RightButtonPanel = props => {
    const { isAdmin, employees, onClickWorldMap } = props;

    return (
        <>
            {isAdmin ? <StaffRegisterButton employees={employees}/> : null}
            <WorldmapButton onClickWorldMap={onClickWorldMap}/>
        </>
    )
}

const TeamCard = props => {

    const { width, firebase, employees } = props;
    const [openTeamList, setOpenTeamList] = useState(false);
    const [openWorldMap, setOpenWorldMap] = useState(false);
    const [filters, setFilters] = useState([ROLES.HR, ROLES.MARKETING, ROLES.SALES, ROLES.TECH, ROLES.SUPPORT]);

    const employeeTeamCard = useStyles();
    const onClickTeamList = () => setOpenTeamList(true);
    const onClickWorldMap = () => setOpenWorldMap(true);
    const handleCloseTeamList = () => setOpenTeamList(false);
    const handleCloseWorldMap = () => setOpenWorldMap(false);
    const currentEmployees = employees?.filter(employee => employee?.contract?.end?.length === 0);
    currentEmployees.sort((a, b) => diffDate(a.contract.start, b.contract.start));

    const onFilterEmployeesByTeam = (event, role) => {
        event.preventDefault();
        if (filters.includes(role))
            setFilters(filters.filter(filter => filter !== role));
        else setFilters([...filters, role]);
    }

    const filteredEmployees = currentEmployees.filter(employee => Object.keys(employee.roles).reduce((filtered, role) => filtered |= filters.includes(role), false));

    return (
        <>
            <FloatingCard
                title={`The ${currentEmployees.length} alpacas team`}
                avatar={<GroupIcon />}
                subheader={<div style={{paddingTop: 8}}><TeamFilter filters={filters} onFilterEmployeesByTeam={onFilterEmployeesByTeam}/></div>}
                buttonAction={<RightButtonPanel {...props} onClickWorldMap={onClickWorldMap}/>}
            >
                <div onClick={onClickTeamList} className={employeeTeamCard.smallTeamMembersList}>
                    <center>
                        <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center" style={{paddingLeft: 8}}>
                            {filteredEmployees.map(employee => (
                                <Grid item key={employee.uid}>
                                    <MemberAvatar member={employee} size={getAvatarSize(width)} beautify={true} />
                                </Grid>
                            ))}
                            {filteredEmployees.length === 0 && (
                                <Grid item align="center">
                                    <CircularProgress className={employeeTeamCard.circular} />
                                </Grid>
                            )}
                        </Grid>
                    </center>
                </div>
            </FloatingCard>

            <TeamList
                width={width}
                open={openTeamList}
                firebase={firebase}
                employees={currentEmployees}
                employeeTeamCard={employeeTeamCard}
                handleCloseDialog={handleCloseTeamList}
            />

            <WorldMap
                width={width}
                open={openWorldMap}
                firebase={firebase}
                employees={currentEmployees}
                employeeTeamCard={employeeTeamCard}
                handleCloseDialog={handleCloseWorldMap}
            />

        </>
    );
}

export default TeamCard;