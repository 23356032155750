import axios from 'axios';
import React, { useState }  from 'react';
import fileDownload from 'js-file-download';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Tooltip from '@material-ui/core/Tooltip';
import withWidth from '@material-ui/core/withWidth';
import { Grid, Typography } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import { DialogContent } from './styles';

const widthToRatio = {
  xs: 0.5,
  sm: 0.5,
  md: 0.8,
  lg: 0.9,
  xl: 1.5,
};

const PDFDialog = (props) => {

  const {open, width, fileName, currentUrl, handleCloseDialog} = props;

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const openInTab = () => window.open(currentUrl);
  const getRatio = () => widthToRatio[width] || 0.5;
  const nextPage = () => setPageNumber(pageNumber + 1);
  const previousPage = () => setPageNumber(pageNumber - 1);
  const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);
  const onLoadProgress = ({ loaded, total }) => (loaded === total) && setPageNumber(1);

  const handleDownload = () => {
    axios.get(currentUrl, { responseType: 'blob' })
    .then(res => fileDownload(res.data, fileName));
  }

  return (
    <Dialog onClose={handleCloseDialog} aria-labelledby="pdf-dialog" open={open}>

      <DialogContent dividers>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} align="center">
              <Document file={currentUrl || ""} onLoadSuccess={onDocumentLoadSuccess} onLoadProgress={onLoadProgress}>
                  <Page pageNumber={pageNumber} scale={getRatio()}/>
              </Document>                            
          </Grid>
          <Grid item>
              <Typography variant="body2" color="textSecondary" gutterBottom style={{paddingTop: 10}}>
                  Page - {pageNumber} / Pages - {numPages}{"    "}
              </Typography>
          </Grid>
          <Grid item>
              <Tooltip title="Previous Page">
                  <span>
                      <Button onClick={previousPage} color="primary" disabled={pageNumber === 1}>
                          <ArrowBackIosIcon />
                      </Button>
                  </span>
              </Tooltip>
          </Grid>
          <Grid item>
              <Tooltip title="Next Page">
                  <span>
                      <Button onClick={nextPage} color="primary" disabled={pageNumber === numPages}>
                          <ArrowForwardIosIcon />
                      </Button>
                  </span>
              </Tooltip>
          </Grid>
          <Grid item>
              <Button autoFocus onClick={handleDownload} color="primary">
                  Download
              </Button>
              <Button onClick={openInTab} color="primary">
                  Open in tab
              </Button>
          </Grid>
        </Grid>
      </DialogContent>

    </Dialog>
  );
}

export default withWidth()(PDFDialog);
