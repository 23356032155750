import TabPanel from '../TabPanel';
import ListFooter from '../ListFooter';
import React, { Component } from 'react';
import InfoIcon from '@material-ui/icons/Info';
import CompanyInfosPanel from './companyinfos';
import { YELLOW } from '../../constants/colors';
import CloseIcon from '@material-ui/icons/Close';
import OverviewPanel from "./partnershipOverview";
import ContractsTableComponent from './contracts';
import MarketingMaterial from './marketingMaterial';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import StyledBadge from '../utils/GenericComponents/styledBadge';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { VERITAS, PARTNERSHIPS } from '../../constants/realtimeDB';
import CustomListItem from '../utils/GenericComponents/customListItem';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import WaitUntilConfirmationModal from '../utils/GenericComponents/modalWaitUntilConfirmation';
import {
  List,
  Grid,
  Select,
  Snackbar,
  Backdrop,
  MenuItem,
  Typography,
  IconButton,
  FormControl,
  CssBaseline,
  ListItemIcon,
  ListItemText,
  CircularProgress,
} from '@material-ui/core';


const MENUS = {
  OVERVIEW: "Overview",
  COMPANY_INFOS : "Company Infos",
  MARKETING_MATERIAL: "Marketing material",
  CLIENTS_REGISTRATION : "Clients Registration",
}

const ImpersonationPanel = (props) => {
  const { width, classes, partners, partnerID, handleSelectPartner} = props;
  return (
    <div className={classes.impersonationPanel}>
      <Grid container spacing={2} justifyContent="flex-start" alignItems="center" className={classes.impersonationGrid}>
        <Grid item >
          <FormControl className={classes.formControl}>
            <Select
              value={partnerID}
              onChange={handleSelectPartner}
            >
              {partners && partners.map((candidate, index) => (
                  <MenuItem key={index} value={candidate.uid} className={classes.menuItem}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ListItemText className={classes.listItemText}>
                      <Typography gutterBottom variant="body1" color={"primary"}>
                        {candidate.name}
                      </Typography>
                    </ListItemText>
                  </ListItemIcon>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item >
          <Typography variant={width === 'xs' ? "body1" : "h6"} gutterBottom color="textSecondary" className={classes.impersonationTypo}>
            Impersonation {width === 'xs' ? "" : <>panel <i>(admin only)</i></>}
          </Typography>
        </Grid>
        {(width !== 'xs') && (
            <Grid item >
              <Typography variant="caption" gutterBottom color="textSecondary" >
                ID: [{partnerID}]
              </Typography>
            </Grid>
        )}
      </Grid>
    </div>
  ); 
};


class PartnershipsPageBase extends Component {
  
    constructor(props) {
      super(props);
      this.state = {
        partners: [],
        partnerID: '',
        loading: false,
        snackBarMsg: '',
        selectedIndex: 0,
        steeringObj: null,
        snackBarOpened: false,
        partner: { ...props.partner },
        isAdmin: props.conditionRW(props.partner),
      };
      this.unMounted = false;
      this.classes = props.classes;
      this.conditionRW = props.conditionRW;
    }
  
    componentDidMount() {
      this.setState({ loading: true });

      this.props.firebase.steering().on('value', snapshot => {
        const value = snapshot.val();
        this.setState({ steeringObj: value });
      });

      this.props.firebase.db.ref(PARTNERSHIPS).on('value', snapshot => {
        const partnershipsObject = snapshot.val();
        const partnersList = Object.keys(partnershipsObject).map(key => ({ uid: key, ...partnershipsObject[key] }));

        partnersList.sort((a, b) => (a.name < b.name) ? -1 : ((a.name > b.name) ? 1 : 0));

        if (this.state.partnerID === '') {
          const defaultSelectedCompany = this.findCompanyFromPartnerEmail(partnersList, this.props.partner.email);
          const defaultPartnerID = defaultSelectedCompany?.uid || partnersList[0].uid;
          this.setState({ partners: partnersList, partnerID: defaultPartnerID, loading: false });
        } else {
          this.setState({ partners: partnersList, loading: false });
        }
      });
    }

    componentWillUnmount() {
      this.unMounted = true;
      this.props.firebase.steering().off();
      this.props.firebase.db.ref(PARTNERSHIPS).off();
      this.props.firebase.db.ref(`${VERITAS}/users`).off();
    }
    
    findCompanyFromPartnerEmail = (companies, email) => companies.find(company => Object.keys(company.deals).find(deal => company.deals[deal]?.contacts?.find(contact => contact.email === email) || false));

    handleListItemClick = (event, index) => this.setState({ selectedIndex: index });
    handleSelectPartner = (event) => this.setState({ partnerID: event.target.value });
    
    handleCloseSnack = (event, reason) => {
      if (reason === 'clickaway') return;
      this.setState( { snackBarOpened: false } );
    };
  
    createUser = (dealID, user) => {
      const { partnerID, partners } = this.state;
      const partner = partners.find(partner => partner.uid === partnerID);
      const newPartner = { ...partner };
      const contacts = newPartner?.deals?.[dealID]?.contacts || null;
      
      if (contacts) {
        const candidate = contacts.find(contact => contact.email === user.email);
        if (candidate?.authorized === false) {
          const payload = {
            email : user.email,
            newlyCreated: true,
            contract: { role: 'PARTNERSHIP' },
            civil: { firstName: user.firstname, lastName: user.lastname },
          }
          
          // prod
          //*
          this.props.firebase.db.ref(`${VERITAS}/users`).update({ status: this.state.partner.uid, user: payload })
          .then(() => {
            contacts.find(contact => contact.email === user.email).authorized = true;
            return this.props.firebase.db.ref(`${PARTNERSHIPS}/${partnerID}/deals/${dealID}/contacts`).set(contacts);
          })
          .catch(error => {
            this.setState( { snackBarOpened: true, snackBarMsg: "Error while creating user" } );
            console.log(error);
          });
          
          // test
          /*/
          this.props.firebase.db.ref(`${VERITAS}/users`).update({ status: this.state.partner.uid })
          setTimeout(() => {
            this.props.firebase.db.ref(`${VERITAS}/users`).update({ status: 'done' });
          }, 5000);
          //*/
          
        } else {
          this.setState( { snackBarOpened: true, snackBarMsg: "Error: user has already been authorized" } );
        }
      } else {
        this.setState( { snackBarOpened: true, snackBarMsg: `Bargl... cannot find any contact for this deal :(` } );
      }
    }

    createNewUserCallback = (value) => {
      if (value === 'done') {
        setTimeout(() => {
          this.setState( { snackBarOpened: true, snackBarMsg: "Successfully created user" } );
          this.props.firebase.db.ref(`${VERITAS}/users`).update({ status: 'none', error: 'none' });
        }, 1000);
      } else if (value === this.state.partner.uid) {
        setTimeout(() => {
          this.setState( { snackBarOpened: true, snackBarMsg: "Error while creating user" } );
          this.props.firebase.db.ref(`${VERITAS}/users`).update({ status: 'none', error: 'none' });
        }, 1000);
      }
    }
  

    updateCompanyTag = (step, value) => {
      const { partnerID, partners } = this.state;
      const partner = partners.find(partner => partner.uid === partnerID);
      const newPartner = { ...partner };
      if (!newPartner.tags) newPartner.tags = [];
      if (value)
        newPartner.tags.push(step);
      else
        newPartner.tags.splice(newPartner.tags.indexOf(step), 1);
      this.props.firebase.db.ref(`${PARTNERSHIPS}/${partnerID}`).update({ tags: newPartner.tags });
    };

    updateCompanyType = (type) => {
      const { partnerID, partners } = this.state;
      const partner = partners.find(partner => partner.uid === partnerID);
      const newPartner = { ...partner };
      newPartner.type = type;
      this.props.firebase.db.ref(`${PARTNERSHIPS}/${partnerID}`).update({ type: newPartner.type });
    };

    updateCompanyCountry = (country) => {
      const { partnerID, partners } = this.state;
      const partner = partners.find(partner => partner.uid === partnerID);
      const newPartner = { ...partner };
      newPartner.country = country;
      this.props.firebase.db.ref(`${PARTNERSHIPS}/${partnerID}`).update({ country: newPartner.country });
    };

    // user = { firstName: '', lastName: '', email: '' };
    updateCompanyContacts = (dealID, user) => {
      const { partnerID, partners } = this.state;
      const partner = partners.find(partner => partner.uid === partnerID);
      const newPartner = { ...partner };
      if (user) {
        user.authorized = false;
        newPartner.deals[dealID].contacts.push(user);
      }
      this.props.firebase.db.ref(`${PARTNERSHIPS}/${partnerID}/deals/${dealID}`).update({ contacts: newPartner.deals[dealID].contacts })
      .then(() => {
        this.setState( { snackBarOpened: true, snackBarMsg: `${user.firstname} ${user.lastname} has been added to the contacts list` } );
      })
      .catch(error => {
        this.setState( { snackBarOpened: true, snackBarMsg: "Error while creating user" } );
        console.log(error);
      });
    };
    
    onCreateNewContract = (contract) => {
      const date = new Date();
      const currentContract = {
        active: false,
        plan: [contract.plan],
        timestamp: date.getTime(),
        clientName: contract.clientName,
        description: contract.description,
      };
      this.props.firebase.db.ref(`${PARTNERSHIPS}/${this.state.partnerID}/contracts/${currentContract.timestamp}`).set(currentContract)
      .then(() => {
        const company = this.state.partners.find(partner => partner.uid === this.state.partnerID);
        const msg = `:tada: A new Client's registration has been created by partner *${company.name}*`;
        const infosMsg = (sep) => `author: ${this.state.partner.email}${sep}Client: ${contract.clientName}${sep}Plan: ${contract.plan}`;
        const payload = {
          attachments: [ {
                pretext: msg,
                fallback: msg,
                color: YELLOW,
                footer: infosMsg('\n'),
          } ]
        };        
        this.props.firebase.webHooks.sendSalesChannelNotification(payload);
        this.setState( { snackBarOpened: true, snackBarMsg: "Client's registration created" } );
      })
      .catch(error => {
        this.setState( { snackBarOpened: true, snackBarMsg: "Error creating Clients's registration" } );
        console.log(error);
      });
    };

    onUpdateContract = (contract) => {
      this.props.firebase.db.ref(`${PARTNERSHIPS}/${this.state.partnerID}/contracts/${contract.timestamp}`).update(contract)
      .then(() => {
        this.setState( { snackBarOpened: true, snackBarMsg: "Client's registration updated" } );
      })
      .catch(error => {
        this.setState( { snackBarOpened: true, snackBarMsg: "Error updating Client's registration" } );
        console.log(error);
      });
    };
  
    render() {
  
      const {
        partner,
        loading,
        isAdmin,
        partners,
        partnerID,
        snackBarMsg,
        steeringObj,
        selectedIndex,
        snackBarOpened,
      } = this.state;
  
      const sm = (this.props.width === 'sm' || this.props.width === 'xs');
      const currentCompany = partners.find(partner => partner.uid === partnerID);
      const numberOfContracts = currentCompany?.contracts ? Object.keys(currentCompany?.contracts || []).length : 0;
      const createOverviewTitle = () => <><span>{MENUS.OVERVIEW}</span><span style={{paddingLeft: 10, fontSize: 8}}><i>(admin)</i></span></>;
      const createRegistrationTitle = () => {
        return (
          <StyledBadge
            top={13}
            right={-13}
            overlap="rectangular"
            badgeContent={numberOfContracts}
          >
              {MENUS.CLIENTS_REGISTRATION}
          </StyledBadge>
        );
      };

      return(
        <>
          <Backdrop className={this.classes.backdrop} open={loading} >
            <CircularProgress color="inherit" />
          </Backdrop> 

          {isAdmin && (partners.length > 0) && (
            <ImpersonationPanel
              partners={partners}
              partnerID={partnerID}
              classes={this.classes}
              width={this.props.width}
              handleSelectPartner={this.handleSelectPartner}
            />
          )}

        <div className={this.classes.divRoot} style={{marginTop: isAdmin ? 60 : 0}}>
  
          <CssBaseline />

          <Grid container spacing={0} alignItems="stretch">
  
            <Grid item xs={1} sm={sm ? 1 : 2}>
              <List
                dense={sm}
                component="nav"
                aria-label="partner-info-list"
                className={this.classes.listPanel}
              >
                <CustomListItem
                  index={0}
                  title={MENUS.COMPANY_INFOS}
                  selectedIndex={selectedIndex}
                  headerIcon={<AccountBalanceWalletIcon />}
                  handleListItemClick={this.handleListItemClick}
                />

                <CustomListItem
                  index={1}
                  selectedIndex={selectedIndex}
                  title={createRegistrationTitle()}
                  headerIcon={<MonetizationOnIcon />}
                  handleListItemClick={this.handleListItemClick}
                />

                <CustomListItem
                  index={2}
                  selectedIndex={selectedIndex}
                  headerIcon={<PermMediaIcon />}
                  title={MENUS.MARKETING_MATERIAL}
                  handleListItemClick={this.handleListItemClick}
                />
                {this.conditionRW(partner) && (
                  <CustomListItem
                    index={3}
                    headerIcon={<InfoIcon />}
                    title={createOverviewTitle()}
                    selectedIndex={selectedIndex}
                    handleListItemClick={this.handleListItemClick}
                  />
                )}

                <ListFooter sm={sm} firebase={this.props.firebase} />
              </List>
            </Grid>
  
              <Grid item xs={11} sm={sm ? 11 : 10}>
  
                <div className={this.classes.rightPanel}>

                  {selectedIndex === 0 && 
                    <TabPanel value={selectedIndex} index={0}>
                      {partners && partners.length > 0 && (
                        <CompanyInfosPanel
                          partner={partner}
                          partnerID={partnerID}
                          width={this.props.width}
                          company={currentCompany}
                          createUser={this.createUser}
                          firebase={this.props.firebase}
                          conditionRO={this.props.conditionRO}
                          conditionRW={this.props.conditionRW}
                          updateCompanyTag={this.updateCompanyTag}
                          updateCompanyType={this.updateCompanyType}
                          updateCompanyCountry={this.updateCompanyCountry}
                          updateCompanyContacts={this.updateCompanyContacts}
                        />
                      )}
                    </TabPanel>
                  }
    
                  {selectedIndex === 1 && 
                    <TabPanel value={selectedIndex} index={1}>
                      <ContractsTableComponent 
                        company={currentCompany}
                        firebase={this.props.firebase}
                        conditionRO={this.props.conditionRO}
                        conditionRW={this.props.conditionRW}
                        onUpdateContract={this.onUpdateContract}
                        onCreateNewContract={this.onCreateNewContract}
                      />
                    </TabPanel>
                  }
  
                  {selectedIndex === 2 && 
                    <TabPanel value={selectedIndex} index={2}>
                      <MarketingMaterial {...this.props} steeringObj={steeringObj} />
                    </TabPanel>
                  }

                  {selectedIndex === 3 && 
                    <TabPanel value={selectedIndex} index={3}>
                      <OverviewPanel partners={partners} />
                    </TabPanel>
                  }

                </div>
              </Grid>
  
            </Grid>
    
          </div>

          <WaitUntilConfirmationModal
            type="users"
            title="Creating new user"
            firebase={this.props.firebase}
            callBack={this.createNewUserCallback}
            message="Processing, wait a few seconds..."
            authUserUID={this.state.partner?.uid || ""}
          />

          <Snackbar
            message={snackBarMsg}
            open={snackBarOpened}
            autoHideDuration={3000}
            onClose={this.handleCloseSnack}
            anchorOrigin={ {vertical: 'top', horizontal: 'center' }}
            action={
                <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleCloseSnack}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            }
          />   
          </>
      );
    }
}

export default PartnershipsPageBase;
  