import React from 'react';
import Fab from '@material-ui/core/Fab';
import { Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CreatePayoutDetailsDialog from './createPayoutDetailsDialog';

import { useStyles } from './styles';

const CreateNewPayoutInTransit = (props) => {
  
  const classes = useStyles();
  const { onCreateNewPayoutInTransit } = props;
  const [open, setOpen] = React.useState(false);

  const handleCloseCreatePayoutDetailsDialog = () => setOpen(false);
  const submitCreatePayoutDetailsDialog = (payout) => {
    onCreateNewPayoutInTransit(payout);
    setOpen(false);
  };

  return (
    <>
        <Tooltip title="Create new payout in transit">
            <Fab size="medium" color="secondary" aria-label="add" className={classes.fab} onClick={() => setOpen(true)}>
            <AddIcon />
            </Fab>
        </Tooltip>
        <CreatePayoutDetailsDialog submitCreatePayoutDetailsDialog={submitCreatePayoutDetailsDialog} open={open} handleCloseCreatePayoutDetailsDialog={handleCloseCreatePayoutDetailsDialog}/>
    </>
  );
}

export default CreateNewPayoutInTransit;

