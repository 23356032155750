import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import * as ROUTES from '../../constants/routes';

const SignInButton = () => {

  return (
    <Button color="inherit" to={ROUTES.SIGN_IN} component={Link}>
      Sign In
    </Button>  
  );
}

export default SignInButton;