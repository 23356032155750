import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
import Step from '@material-ui/core/Step';
import Check from '@material-ui/icons/Check';
import Select from '@material-ui/core/Select';
import Stepper from '@material-ui/core/Stepper';
import MenuItem from '@material-ui/core/MenuItem';
import { TYPE, COUNTRIES } from '../../partnersType';
import InputLabel from '@material-ui/core/InputLabel';
import StepButton from '@material-ui/core/StepButton';
import BusinessIcon from '@material-ui/icons/Business';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';
import FloatingCard from '../../../utils/GenericComponents/floatingCard';
import { Box, Grid, Typography, Tooltip, StepLabel } from '@material-ui/core';

import { useStyles, useQontoStepIconStyles } from './styles';

const TAGS = {
    LOGO: "Logo",
    REVENUE: "Revenue",
    SIGNALS: "Signals",
    ACTIVITY: "Activity",
    USE_CASE: "Use case",
    INTEGRATION: "Integration",
    TECH_CONTRIB: "Tech Contrib",
};

const CircularProgressWithLabel = (props) => {
    return (
        <Tooltip title={`${Math.round(props.value)}% of a gold partner`} arrow>
            <Box position="relative" display="inline-flex">
                <div className={props.classes.box}>
                    <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(props.value)}%`}</Typography>
                </div>
                <div className={props.classes.box}>
                    <CircularProgress variant="determinate" size={100} value={100} className={props.classes.circularProgressGray}/>
                </div>
                <CircularProgress variant="determinate" thickness={5} size={props.size} value={props.value} className={props.classes.circularProgress}/>
            </Box>
        </Tooltip>
    );
}

CircularProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
};

const NorthPanel = (props) => {

    const { classes, company, completedValue, updateCompanyType, updateCompanyCountry } = props;
    const handleChangeType = (event) => updateCompanyType(Object.keys(TYPE).find(type => TYPE[type].id === event.target.value));
    const handleChangeCountry = (event) => updateCompanyCountry(Object.keys(COUNTRIES).find(country => country === event.target.value));

    const sortedCountries = Object.keys(COUNTRIES).sort((a, b) => {
        if (COUNTRIES[a] < COUNTRIES[b]) return -1;
        if (COUNTRIES[b] < COUNTRIES[a]) return 1;
        return 0;
    });

    return (
        <Grid container spacing={4} direction="row" justifyContent="center" alignItems="center" >
            <Grid item>
                <FormControl className={classes.formControl}>
                    <InputLabel id="crowdsec-choose-partner-country-label">Country</InputLabel>
                        <Select
                            className={classes.select}
                            onChange={handleChangeCountry}
                            value={company.country || "XX"}
                            id="crowdsec-choose-partner-country"
                            labelId="crowdsec-choose-partner-country-label"
                        >
                            {sortedCountries.map(country => (
                                <MenuItem key={country} value={country} className={classes.select} >{COUNTRIES[country]}</MenuItem>
                            ))}
                        </Select>
                </FormControl>
            </Grid>
            <Grid item>
                <FormControl className={classes.formControl}>
                    <InputLabel id="crowdsec-choose-partner-type-label">Type</InputLabel>
                        <Select
                            className={classes.select}
                            onChange={handleChangeType}
                            id="crowdsec-choose-partner-type"
                            labelId="crowdsec-choose-partner-type-label"
                            value={TYPE[company.type]?.id || TYPE.OTHER.id}
                        >
                            {Object.values(TYPE).sort((a, b) => a.id - b.id).map(type => (
                                <MenuItem key={type.id} value={type.id} className={classes.select} >{type.name}</MenuItem>
                            ))}
                        </Select>
                </FormControl>
            </Grid>
            <Grid item>
                <CircularProgressWithLabel value={completedValue} size={100} classes={classes}/>
            </Grid>
        </Grid>

    );
};

  
const QontoStepIcon = (props) => {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;
    return (
      <div className={clsx(classes.root, { [classes.active]: active })} >
        {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
      </div>
    );
}

const CompanyIcon = ({domain}) => {
    if (domain === null || domain === undefined) return <BusinessIcon />;
    const ClearBitImg = () => {
        return (
            <img
                src={`https://logo.clearbit.com/${domain}?size=90&format=png`}
                alt={domain}
                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                onError={(e) => {
                    e.target.src = 'https://firebasestorage.googleapis.com/v0/b/react-compta-automation.appspot.com/o/images%2Fdefault-company.png?alt=media&token=2d4fd120-ec67-4eb2-ad46-0780f98cfc8c';
                    e.target.style.width = '80%';
                    e.target.style.height = '80%';
                }}
            />
        );
    };

    return (
        <Box display="flex" alignItems="center" justifyContent="center">
            <ClearBitImg />
        </Box>
    );
}
  

const Company = (props) => {

    const { partner, company, updateCompanyTag, conditionRW } = props;

    const completedValue = ((Object.keys(company?.tags || []).length / Object.keys(TAGS).length) * 100);

    const classes = useStyles({completedValue});
    const condition = () => conditionRW(partner);
    const handleStep = (step) => () => setActiveStep(step);

    const setActiveStep = (step) => {
        const value = !(company.tags ? Object.values(company.tags).includes(step) : false);
        updateCompanyTag(step, value);
    };

    return (
        <FloatingCard
            noflex={true}
            avatarSize={80}
            avatarBg={'#FFF'}
            title={company.name}
            subheader={company.domain}
            avatar={<CompanyIcon domain={company.domain}/>}
            buttonAction={<NorthPanel {...props} classes={classes} completedValue={completedValue} />}
        >
            <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center" className={classes.grid}>
                <Grid item xs>
                    <Stepper nonLinear activeStep={-1} className={classes.stepper} >
                        {Object.keys(TAGS).map(label => (
                            <Step key={label} className={classes.stepButton}>
                                <StepButton
                                    disabled={!condition()}
                                    onClick={handleStep(label)}
                                    completed={company.tags ? Object.values(company.tags).includes(label) : false}
                                >
                                    <StepLabel StepIconComponent={QontoStepIcon}>{TAGS[label]}</StepLabel>
                                </StepButton>
                            </Step>
                        ))}
                    </Stepper>
                </Grid>
            </Grid>
        </FloatingCard>
    );
};

export default Company;