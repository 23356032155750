import React from "react";
import ApiCalendar from './ApiCalendar';
import EmployeeHolidays from "./employeeHolidays";
import { STEERING } from '../../../constants/realtimeDB';

const EmployeeDaysOff = props => {
    return <EmployeeDaysOffComponent {...props} />;        
};
class EmployeeDaysOffComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = { apiCalendar: null }
    }

    componentDidMount() {    
        this.props.firebase.db.ref(`${STEERING}/calendar`).once('value', snapshot => {
            const config = {
                "apiKey": snapshot.val().api_key,
                "clientId": snapshot.val().client_id,
                "scope": "https://www.googleapis.com/auth/calendar",
                "discoveryDocs": ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"]
            };
            const apiCalendar = new ApiCalendar(config);
            //apiCalendar.onLoadCallback = () => console.log(`callback => gapi loaded ${apiCalendar.sign}`);
            this.setState({ apiCalendar });
        });
    }

    componentWillUnmount() {
        this.props.firebase.db.ref(`${STEERING}/calendar`).off();
        if (this.state.apiCalendar) this.state.apiCalendar.handleSignoutClick();
    }
        
    render() {
        return <EmployeeHolidays {...this.props} apiCalendar={this.state.apiCalendar}/>
    }
}

export default EmployeeDaysOff;