import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import { YELLOW } from '../../../../../../constants/colors';

export const YellowSwitch = withStyles({
  switchBase: {
    '&$checked': {
      color: YELLOW,
    },
    '&$checked + $track': {
      backgroundColor: YELLOW,
    },
  },
  checked: {},
  track: {},
})(Switch);

export const useStyles = makeStyles((theme) => ({

  divider: {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(1.25),
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },

}));
