import Contacts from './contacts';
import Documents from './documents';
import { Grid } from '@material-ui/core';
import React, { Component } from 'react';
import { PARTNERSHIPS } from '../../../../constants/realtimeDB';

class Deal extends Component {
  
    constructor(props) {
      super(props);
      this.state = {
        documents: [],
        deal: this.props.deal,
        dealID: this.props.dealID,
        partnerID: this.props.partnerID,
      };
      this.unMounted = false;
    }
  
    loadFiles = () => {
        this.setState({ documents: [] });
        this.props.firebase.getFiles(`${PARTNERSHIPS}/${this.props.partnerID}/${this.props.dealID}`, this.setDocumentsFiles);
    }

    componentDidMount = () => this.loadFiles()
    componentWillUnmount = () => this.unMounted = true;

    componentDidUpdate(prevProps) {
        if (prevProps.dealID !== this.props.dealID) {
            this.setState({
                deal: this.props.deal,
                dealID: this.props.dealID,
                partnerID: this.props.partnerID,
            });
            this.loadFiles();
        }
    }

    setDocumentsFiles = (fileInfos) => {
        if (!this.unMounted) {
          this.state.documents.push(fileInfos)
          this.setState( { documents: this.state.documents } );      
        }
      }  

    render() {

        const { deal, dealID } = this.state;

        return (
            <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center" >
                <Grid item xs={12}>
                    <Documents {...this.props} documents={this.state.documents} loadFiles={this.loadFiles}/>
                </Grid>
                <Grid item xs={12}>
                    <Contacts {...this.props} contacts={deal.contacts} dealID={dealID} />
                </Grid>
            </Grid>
        );
    }
}

export default Deal;