import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    
    root: {
        width: '100%',
        height: '100%',
    },

    normalText: {
        paddingTop: theme.spacing(1),
    },
    
    closeButton: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
        color: theme.palette.grey[500],
    },

    dialog: {
        [theme.breakpoints.up('xs')]: {
            padding: theme.spacing(0),
        },
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(0),
        },
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(12),
        },
    },

}));