import React from 'react';
import Grid from '@material-ui/core/Grid';
import ClickableMediaCard from '../clickableMediaCard';

const RATIO = 0.5;

const softwareNews = [
    {
        media: '</>',
        ratio: RATIO,
        type: "image",
        timestamp: "2020-01-01",
        title: "Install CrowdSec",
        image: "crowdsec_install.gif",
        url: 'https://doc.crowdsec.net/getting_started/installation/',
    },
    {
        media: '</>',
        ratio: RATIO,
        image: "hub",
        type: "image",
        title: "CrowdSec HUB",
        timestamp: "2020-02-01",
        url: 'https://hub.crowdsec.net',
    },
    {
        media: '</>',
        ratio: RATIO,
        image: "faq",
        type: "image",
        title: "F.A.Q.",
        timestamp: "2020-03-01",
        url: 'https://doc.crowdsec.net/index.html',
    },
    {
        media: '</>',
        ratio: RATIO,
        image: "github",
        type: "image",
        title: "Discover our Github repository",
        timestamp: "2020-04-01",
        url: 'https://hub.crowdsec.net',
    },
    {
        media: '</>',
        ratio: RATIO,
        image: "discourse",
        type: "image",
        title: "Discover our Discourse",
        timestamp: "2020-05-01",
        url: 'https://discourse.crowdsec.net/',
    },
    {
        media: '</>',
        ratio: RATIO,
        image: "packages",
        type: "image",
        title: "Available CrowdSec packages",
        timestamp: "2020-06-01",
        url: 'https://packagecloud.io/crowdsec/crowdsec',
    },
];


const SoftwarePanel = ({classes, firebase}) => {

    return (
        <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="stretch">
            {softwareNews.map((news, index) => 
                <Grid key={index} item xs={12} sm={6} md={4} lg={3} xl={3}>
                    <ClickableMediaCard
                        rating={false}
                        editable={false}
                        sendToEdit={null}
                        firebase={firebase}
                        currentNews={softwareNews[index]}
                    />
                </Grid>
            )}
        </Grid>        
    );
}

export default SoftwarePanel;