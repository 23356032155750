import React from 'react';
import * as CONST from '../constants';
import { Grid, Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import { useStyles } from './styles';

const StatMiniPanel = (props) => {
    const {classes, sm, news, label, category} = props;
    return (
        <>
            <Typography variant={sm ? "h6" : "h5"} className={classes.metrics}>
                {news.filter(currentNews => currentNews.media.indexOf(category) !== -1).length}
            </Typography>
            <Typography variant={sm ? "caption" : "body2"} className={classes.metricsSmall}>{label}</Typography>
        </>
    );
}

const StatisticsPanel = (props) => {

    const { width } = props;

    const classes = useStyles();
    const sm = (width === 'sm' ) || (width === 'xs' );

    return (
        <Paper>
            <Grid container spacing={2} justifyContent="space-evenly" alignItems="center" >
                <Grid item >
                    <StatMiniPanel {...props} classes={classes} sm={sm} category={CONST.MEDIA_TYPES.ADS} label="Ads" />
                </Grid>
                <Grid item >
                    <StatMiniPanel {...props} classes={classes} sm={sm} category={CONST.MEDIA_TYPES.POST} label="Posts" />
                </Grid>
                <Grid item >
                    <StatMiniPanel {...props} classes={classes} sm={sm} category={CONST.MEDIA_TYPES.TWEET} label="Tweets" />
                </Grid>
                <Grid item >
                    <StatMiniPanel {...props} classes={classes} sm={sm} category={CONST.MEDIA_TYPES.ARTICLE} label="Articles" />
                </Grid>
                <Grid item>
                    <StatMiniPanel {...props} classes={classes} sm={sm} category={CONST.MEDIA_TYPES.PODCAST} label="Podcasts" />
                </Grid>
                <Grid item>
                    <StatMiniPanel {...props} classes={classes} sm={sm} category={CONST.MEDIA_TYPES.CONFERENCE} label="Conferences" />
                </Grid>
                <Grid item>
                    <StatMiniPanel {...props} classes={classes} sm={sm} category={CONST.MEDIA_TYPES.PRESS_RELEASE} label="Press Release" />
                </Grid>
            </Grid>
        </Paper>
    );
};

export default StatisticsPanel;