import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({

    card: {
      padding: 20,
      width: '100%',
      border: 'none',
      height: '100%',
      boxShadow: "none",
      backgroundColor: 'transparent',
    },
      
    media: {
      height: 0,
      paddingTop: '66%',
    },
  
    root: {
      top: '50%',
      left: '50%',
      position: 'absolute',
      transform: 'translate(-50%, -50%)',
    },

    title: {
      fontWeight: 400,
      [theme.breakpoints.down('sm')]: {
        fontSize: 16
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '1.8vw'
      },  
    },
    
  }));
  