import React, { Component } from 'react';
import { Typography } from '@material-ui/core';
import { APP } from '../../constants/realtimeDB';

const VersionDiv = (props) => {
  return <VersionDivComponent {...props} />;
}

class VersionDivComponent extends Component {
  constructor(props) {
    super(props);
    this.unMounted = true;
    this.state = { version: '' };
    this.classes = this.props.classes;
    this.firebase = this.props.firebase;
  }

  componentDidMount() {
    this.unMounted = false;
    this.props.firebase.db.ref(`${APP}/properties`).on('value', (snapshot) => {
      if (this.unMounted) return;
      if (this.state.version !== snapshot.val().version)
        setTimeout(() => this.setState({ version: snapshot.val().version }), 2000);
    });
  }

  componentWillUnmount() {
    this.unMounted = true;
    this.props.firebase.db.ref(`${APP}/properties`).off();
  }

  render() {
    return (
      <div className={this.classes.version_div} >
        <Typography variant="caption" color="textSecondary" className={this.classes.version}>
          <i>vers. {this.state.version}</i>
        </Typography>
      </div>
    );  
  }
}

export default VersionDiv;