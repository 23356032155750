
import NewsTable from './newsTable';
import * as CONST from './constants';
import * as utils from '../../utils';
import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import CreateNewsButton from './createNews';
import StatisticsPanel from './statisticsPanel';
import CloseIcon from '@material-ui/icons/Close';
import Backdrop from '@material-ui/core/Backdrop';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import { PUBLIC } from '../../../constants/realtimeDB';
import CircularProgress from '@material-ui/core/CircularProgress';

const NEWS = `${PUBLIC}/news`;
class MarketingPanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      news: [],
      loading: false,
      snackBarOpened: false,
      snackBarMsg: "Infos saved",
    };
    this.classes = props.classes;
  }

  componentDidMount() {
    this.setState({ loading: true });

    this.props.firebase.db.ref(NEWS).on('value', snapshot => {
      const newsObject = snapshot.val();
      const newsList = Object.keys(newsObject).map(key => {
        const currentNews = newsObject[key];
        return {
          key: key,
          url: currentNews?.url || "",
          timestamp: currentNews.timestamp,
          ratio: currentNews?.ratio || 0.5,
          hidden: currentNews?.hidden || false,
          type: currentNews?.type || CONST.IMAGE,
          title: currentNews?.title || CONST.TITLE,
          image: currentNews?.image || CONST.IMAGE,
          media: currentNews?.media?.split(",") || [],
          amount: parseFloat(currentNews?.amount || 0),
          lang: currentNews?.lang?.split(",") || ['fr'],
          feedback: parseFloat(currentNews?.feedback || 0),
        }
      });
      newsList.sort( (a, b) => (a.timestamp < b.timestamp) ? 1 : -1);
      this.setState({ news: newsList, loading: false });
    }, error => {
      console.error("Error reading from Firebase:", error);
      this.setState({ loading: false });
    });

  }

  componentWillUnmount() {
    this.props.firebase.db.ref(NEWS).off();
  }

  saveNews = (currentNews) => {
    this.props.firebase.db.ref(`${NEWS}/${currentNews.key}`).update(this.sanitizeNews({ ...currentNews }))
    .catch(error => console.error("Error updating news:", error));
  }
  
  handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') return;
    this.setState( { snackBarOpened: false } );
  };

  onDeleteCurrentNews = (event, currentNews) => {
    this.props.firebase.db.ref(`${NEWS}/${currentNews.key}`).remove();
    this.setState( { snackBarOpened: true, snackBarMsg: "News deleted" } );
    event.preventDefault();
  };

  sanitizeNews = (currentNews) => {
    delete currentNews.key;
    currentNews.media = currentNews.media.join(",");
    currentNews.lang = currentNews.lang.join(",");
    return currentNews;
  };

  onSaveCurrentNews = (event, currentNews) => {
    if (currentNews) {
      this.saveNews(currentNews);
      this.setState( { snackBarOpened: true, snackBarMsg: "Infos saved" } );
      event.preventDefault();
    }
  };

  onCreateNews = (event) => {
    const date = new Date();
    const currentNews = {
      media: [],
      lang: ['fr'],
      hidden: true,
      timestamp: date.getTime(),
      key: `news${utils.dateToSortableString(date)}-${date.getTime()}`,
    };
    this.saveNews(currentNews);
    this.setState( { snackBarOpened: true, snackBarMsg: "News created" } );
    event.preventDefault();
  };

  onChangeCurrentNews = (event, currentNews) => {
    if (currentNews) {
      const name = event.target.name;
      const target = event.target;
      
      if (name === CONST.HIDDEN) {
        currentNews.hidden = !target.checked;
      } else if (name.indexOf(CONST.FEEDBACK) !== -1) {
        currentNews.feedback = parseFloat(target.value);
      } else if (name === CONST.AMOUNT) {
        currentNews.amount = parseFloat(target.value);
      } else if (name === CONST.LANG_SELECTION) {
        currentNews.lang = target.value.split(",");
      } else if (name === CONST.MEDIA_SELECTION) {
        currentNews.media = target.value.split(",");
      } else if (name === CONST.TIMESTAMP) {
        currentNews.timestamp = target.value;
      } else if (name === CONST.FILENAME_CHANGED) {
        currentNews.image = target.value;
      } else if (name === CONST.TYPE) {
        currentNews.type = target.checked ? CONST.IMAGE : CONST.VIDEO;
        if (currentNews.image.length === 0) currentNews.image = CONST.IMAGE;
      } else {
        currentNews[name] = target.value;
      }

      const newsCopy = this.state.news.slice();
      newsCopy.forEach((element, index) => {
        if (element.key === currentNews.key)
          newsCopy[index] = { ...currentNews };
      });
      this.setState({ news: newsCopy });
    }
  };

  render() {
    const { news, loading, snackBarOpened, snackBarMsg } = this.state;
    const { classes, firebase, sendToEdit, currentNewsToEdit, width } = this.props;
    
    return (
      <>
        <Backdrop className={classes.backdrop} open={loading} >
          <CircularProgress color="inherit" />
        </Backdrop> 

        <Grid container spacing={0} className={classes.marketingPanel}>

          <Grid item xs={12}>
            <StatisticsPanel news={news} width={width}/>
          </Grid>

          <Grid item xs={12}>
            <NewsTable
              news={news}
              width={width}
              firebase={firebase}
              sendToEdit={sendToEdit}
              currentNewsToEdit={currentNewsToEdit}
              onSaveCurrentNews={this.onSaveCurrentNews}
              onDeleteCurrentNews={this.onDeleteCurrentNews}
              onChangeCurrentNews={this.onChangeCurrentNews}
            />          
            </Grid>

        </Grid>

        <CreateNewsButton onCreateNews={this.onCreateNews}/>

        <Snackbar
          message={snackBarMsg}
          open={snackBarOpened}
          autoHideDuration={3000}
          onClose={this.handleCloseSnack}
          anchorOrigin={ {vertical: 'top', horizontal: 'center' }}
          action={
              <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleCloseSnack}>
                  <CloseIcon fontSize="small" />
              </IconButton>
          }
        />   

      </>
    );
  }

};

export default MarketingPanel;