import * as ROLES from '../../constants/roles';
import React, { useEffect, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import FormControl from '@material-ui/core/FormControl';
import MemberAvatar from '../../components/utils/GenericComponents/memberAvatar';
import { Grid, ListItemIcon, MenuItem, Select, ListItemText, Typography } from '@material-ui/core';

const UidRenderer = ({ uid }) => {
  const [isHovered, setIsHovered] = useState(false);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <div onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <Typography variant="caption" gutterBottom color="textSecondary" >
        ID: [{uid}]
      </Typography>
      {isHovered && (
        <IconButton onClick={() => copyToClipboard(uid)}>
          <FileCopyIcon fontSize="small" />
        </IconButton>
      )}
    </div>
  );
}


export const ImpersonationPanel = (props) => {
  
  const { width, classes, partners, partnerID, handleSelectPartner} = props;

  const [sortedPartners, setSortedPartners] = useState([]);

  useEffect(() => {
    const sortedPartners = partners.sort((a, b) => a.civil.lastName.localeCompare(b.civil.lastName));
    setSortedPartners(sortedPartners);
  }, [partners]);

  const PartnerMenuItemContent = ({ candidate }) => {
    return (
      <ListItemIcon className={classes.listItemIcon}>
        <MemberAvatar member={candidate} size={3.5}/>
        <ListItemText className={classes.listItemText}>
          <Typography gutterBottom variant="body1" color={candidate?.roles[ROLES.EMPLOYEE] ? "primary" : "textPrimary"}>
            {candidate.civil.lastName}
          </Typography>
        </ListItemText>
      </ListItemIcon>
    );
  };


  return (
    <>
    {sortedPartners && sortedPartners?.length > 0 && (
      <div className={classes.impersonationPanel}>
        <Grid container spacing={2} justifyContent="flex-start" alignItems="center" className={classes.impersonationGrid}>
          <Grid item >
            <FormControl className={classes.formControl}>
              <Select value={partnerID} onChange={handleSelectPartner}>
                {sortedPartners && sortedPartners.map((partner) => (
                  <MenuItem key={partner.uid} value={partner.uid} className={classes.menuItem}>
                    <PartnerMenuItemContent candidate={partner} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item >
            <Typography variant={width === 'xs' ? "body1" : "h6"} gutterBottom color="textSecondary" className={classes.impersonationTypo}>
              Impersonation {width === 'xs' ? "" : <>panel <i>(admin only)</i></>}
            </Typography>
          </Grid>
          {(width !== 'xs') && (
            <Grid item >
              <UidRenderer uid={partnerID} />
            </Grid>
          )}
        </Grid>
      </div>
    )}
    </>
  ); 
};
  