import axios from 'axios';
import PDFDialog from '../pdfDialog';
import ImageDialog from '../imageDialog';
import fileDownload from 'js-file-download';
import TrigramSelector from './trigramSelector';
import FloatingTagList from '../floatingTagList';
import CategorySelector from './categorySelector';
import { Tooltip, Grid } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import withWidth from '@material-ui/core/withWidth';
import React, { useState, useCallback } from 'react';
import DescriptionIcon from '@material-ui/icons/Description';
import CloudDownload from '@material-ui/icons/CloudDownload';
import ConfirmDeleteAlertDialog from '../dialogDeleteConfirm';

import { useStyles } from './styles';

const ICON_SIZE = 20;

const DeleteIconComponent = ({classes, deleteCallback, handleClickDeleteIconButton}) => deleteCallback
  ? (
    <Tooltip title="Delete" arrow>
      <span>
        <DeleteIcon className={classes.downloadIcon} onClick={handleClickDeleteIconButton}/>
      </span>
    </Tooltip>
  ) : null;


const DownloadIconComponent = ({url, width, classes, handleClickDownloadIconButton}) => (width !== 'md' && url)
  ? (
    <Tooltip title="Download" arrow>
      <span>
        <CloudDownload className={classes.downloadIcon} onClick={handleClickDownloadIconButton}/>
      </span>
    </Tooltip>
  ) : null;



const FileBox = (props) => {
  const { width, userClasses = {} } = props;
  const { allTrigrams } = props;
  const { clickCallback, deleteCallback, callbackGiveSpecificIcon } = props;
  const { tag, url, trigram, category, fileName, categories, selectedFile } = props;

  const defaultClasses = useStyles();
  const classes = { ...defaultClasses, ...userClasses };
  const [openPDFDialog, setOpenPDFDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  
  const handleClosePDFDialog = () => setOpenPDFDialog(false);
  const handleCloseDeleteDialog = () => setOpenDeleteDialog(false);
  const callbackRemoveTag = useCallback(() => props.callbackRemoveTag(fileName), [fileName, props]);
  const callbackAddTag = useCallback((tag) => props.callbackAddTag(tag, fileName), [fileName, props]);
  const download = (url, filename) => axios.get(url, { responseType: 'blob' }).then(res => fileDownload(res.data, filename));
  const callbackTrigramSelected = useCallback((trigram) => props.callbackTrigramSelected(trigram, fileName), [fileName, props]);
  const callbackCategorySelected = useCallback((category) => props.callbackCategorySelected(category, fileName), [fileName, props]);
  const getFileName = useCallback(() => (selectedFile) ? <strong>{fileName?.toLowerCase() || "unknown"}</strong> : fileName?.toLowerCase() || "unknown", [selectedFile, fileName]);
  const handleClick = useCallback((event) => (clickCallback) ? clickCallback({fileName, url}) : setOpenPDFDialog(true), [clickCallback, fileName, url]);

  const handleConfirmDelete = useCallback(() => {
    setOpenDeleteDialog(false);
    deleteCallback(fileName);
  }, [deleteCallback, fileName]);

  const handleClickDeleteIconButton = useCallback((event) => {
    event.stopPropagation();
    setOpenDeleteDialog(true);
  }, []);

  const handleClickDownloadIconButton = useCallback((event) => {
    event.stopPropagation();
    download(url, fileName);
  }, [url, fileName]);

  const spaces = {
    tag: { minWidth: 200, maxWidth: 200, textAlign: 'center' },
    trigram: { minWidth: 100, maxWidth: 100 },
    category: { minWidth: 200, maxWidth: 200 },
    icon: { minWidth: ICON_SIZE , maxWidth: ICON_SIZE },
    deleteIcon: { minWidth: ICON_SIZE, maxWidth: ICON_SIZE },
    downloadIcon: { minWidth: ICON_SIZE, maxWidth: ICON_SIZE },
    name: () => {
      if (trigram && props.callbackAddTag && categories && deleteCallback) return { minWidth: "calc(100% - 560px)", maxWidth: "calc(100% - 560px)" };
      if (trigram && props.callbackAddTag && deleteCallback) return { minWidth: "calc(100% - 360px)", maxWidth: "calc(100% - 360px)" };
      if (trigram && props.callbackAddTag) return { minWidth: "calc(100% - 340px)", maxWidth: "calc(100% - 340px)" };
      if (trigram && deleteCallback) return { minWidth: "calc(100% - 160px)", maxWidth: "calc(100% - 160px)" };
      if (props.callbackAddTag && deleteCallback) return { minWidth: "calc(100% - 260px)", maxWidth: "calc(100% - 260px)" };
      if (deleteCallback) return { minWidth: "calc(100% - 60px)", maxWidth: "calc(100% - 60px)" };
      return { minWidth: "calc(100% - 40px)", maxWidth: "calc(100% - 40px)" };
    }
  }

  return (
    <>
      <Grid container direction="row" alignItems="center" justifyContent="center" spacing={0}>
        <Grid item style={ spaces.icon } >
          {(callbackGiveSpecificIcon === undefined || callbackGiveSpecificIcon === null)
            ? <DescriptionIcon className={classes.descriptionIcon}/>
            : callbackGiveSpecificIcon(fileName, url) || <DescriptionIcon className={classes.descriptionIcon}/>}
        </Grid>
        <Grid item style={ spaces.name() } >
          <span className={classes.listItemText} onClick={handleClick}>{ getFileName() }</span>
        </Grid>
        { categories && (<Grid item style={ spaces.tag } >
            <CategorySelector categories={categories} category={category} callbackCategorySelected={callbackCategorySelected} />
          </Grid>
        )}
        { props.callbackAddTag && (<Grid item style={ spaces.tag } >
            <FloatingTagList tag={tag} id={fileName} callbackAddTag={callbackAddTag} callbackRemoveTag={callbackRemoveTag} isManager={true} />
          </Grid>
        )}
        { trigram && (<Grid item style={ spaces.trigram } >
            <TrigramSelector tri={trigram} allTrigrams={allTrigrams} callbackTrigramSelected={callbackTrigramSelected} />
          </Grid>
        )}
        <Grid item style={ spaces.downloadIcon } >
          <DownloadIconComponent url={url} width={width} classes={classes} handleClickDownloadIconButton={handleClickDownloadIconButton}/>
        </Grid>
        { deleteCallback && (<Grid item style={ spaces.deleteIcon } >
            <DeleteIconComponent deleteCallback={deleteCallback} classes={classes} handleClickDeleteIconButton={handleClickDeleteIconButton}/>
          </Grid>
        )}
      </Grid>
      
      {(url !== '' && url?.includes("pdf")) && <PDFDialog open={openPDFDialog} handleCloseDialog={handleClosePDFDialog} currentUrl={url} fileName={fileName}/>}
      {(url !== '' && !url?.includes("pdf")) && <ImageDialog open={openPDFDialog} handleCloseDialog={handleClosePDFDialog} currentUrl={url} fileName={fileName}/>}

      <ConfirmDeleteAlertDialog
        open={openDeleteDialog}
        title={`Confirm Delete file`}
        onConfirmDeleteCallback={handleConfirmDelete}
        onCancelDeleteCallback={handleCloseDeleteDialog}
        message={`Are you sure you want to delete ${fileName}?`}
      />
    </>
  );
}

export default withWidth()(FileBox);