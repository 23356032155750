import { withStyles } from '@material-ui/core/styles';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';

export const DialogContent = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(0),
    },
}))(MuiDialogContent);
  
export const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(0),
    },
}))(MuiDialogActions);  