import { makeStyles } from '@material-ui/core/styles';
import * as COLORS from '../../../../../constants/colors';

export const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
  
    chip: {
      margin: 1,
      backgroundColor: COLORS.VERY_LIGHT_PURPLE,
    },

    rowText: props => ({
      color: (props.employee.contract.end.length > 0) ? COLORS.LIGHT_GRAY : '#000'
    }),
      
});
