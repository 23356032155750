import { VERY_LIGHT_PURPLE4, VERY_LIGHT_GRAY3 } from '../../constants/colors';

export const boxShadow = '0px 2px 2px 0px rgba(81, 70, 174, 0.4)';

const NBR_DAYS_IN_YEAR = 365;
const ONE_DAY = 24 * 60 * 60 * 1000;

export const divRoot = (props) => ({
    flexGrow: 1,
    position: "fixed",
    overflowY: "hidden",
    overflowX: "hidden",
    left: props.theme.spacing(1),
    right: props.theme.spacing(1),
    [props.theme.breakpoints.down('sm')]: {
      top: props.theme.spacing(7),
      bottom: props.theme.spacing(0.5),
    },
    [props.theme.breakpoints.up('md')]: {
      top: props.theme.spacing(props.bar ? 17 : 8),
      bottom: props.theme.spacing(8),
    },  
    backgroundColor: VERY_LIGHT_PURPLE4, //props.theme.palette.background.paper,
});

export const listPanel = (props) => ({
  zIndex: 1,
  overflowY: 'auto',
  overflowX: 'hidden',
  position: 'absolute',
  boxShadow: boxShadow,
  height: props.theme.maxHeight,
  top: props.theme.spacing(0.5),
  bottom: props.theme.spacing(0),
  marginBottom: props.theme.spacing(1),
  [props.theme.breakpoints.down('xs')]: {
    width: '10%',
    marginLeft: 1,
  },
  [props.theme.breakpoints.up('sm')]: {
    width: '6%',
    marginLeft: 1,
  },
  [props.theme.breakpoints.up('md')]: {
    width: '16%',
    marginLeft: props.theme.spacing(0.5),
  },
  //border: '1px solid #EEE',
  //boxShadow: '0 1px 2px 0 rgba(0, 0, 0,0.3)',
  borderRadius: props.theme.shape.borderRadius,
  backgroundColor: props.theme.palette.background.paper,
});

export const rightPanel = (props) => ({
    overflowY: 'auto',
    overflowX: 'hidden',
    position: 'absolute',
    //top: 0,
    top: props.theme.spacing(-1.5),
    bottom: 0,
    marginTop: 0,
    paddingTop: 0,
    marginLeft: 0,
    marginBottom: props.theme.spacing(1),

    [props.theme.breakpoints.down('xs')]: {
      width: '90%',
    },
    [props.theme.breakpoints.up('sm')]: {
      width: '93%',
    },
    [props.theme.breakpoints.up('md')]: {
      width: '83.5%',
    },
    //backgroundColor: VERY_LIGHT_GRAY2 //props.theme.palette.background.paper,
    backgroundColor: VERY_LIGHT_PURPLE4,
});

export const card = (props) => ({
    "&:hover": {
      backgroundColor: VERY_LIGHT_GRAY3,
    },
    boxShadow: boxShadow,
});

export function nFormatter(num, digits) {
  if (num) {
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    const si = [
      { value: 1, symbol: "" },
      { value: 1E3, symbol: "k" },
      { value: 1E6, symbol: "M" },
      { value: 1E9, symbol: "G" },
      { value: 1E12, symbol: "T" },
      { value: 1E15, symbol: "P" },
      { value: 1E18, symbol: "E" }
    ];
    let i;
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break;
      }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
  }
  return null;
}

export const dateToPrettyString = (date) => {
  const options = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' };
  return date.toLocaleDateString('en-US', options);
}

export const diffDate = (start, end) => {
  const startDate = new Date(stringToDate(start));
  const endDate = new Date(stringToDate(end));
  const diff = endDate.getTime() - startDate.getTime();
  return diff;
}

export const dateToString = (date) => {
  const dd = String(date.getDate()).padStart(2, '0');
  const mm = String(date.getMonth() + 1).padStart(2, '0');
  const yyyy = date.getFullYear();
  return `${dd}/${mm}/${yyyy}`;
}

export const dateToSortableString = (date) => {
  const dd = String(date.getDate()).padStart(2, '0');
  const mm = String(date.getMonth() + 1).padStart(2, '0');
  const yyyy = date.getFullYear();
  return `${yyyy}${mm}${dd}`;
}

export const stringToDate = (stringDate) => {
  const [day, month, year] = stringDate.split('/');
  return new Date(year, month - 1, day);
}


export const sendNotification = (firebase, authUser, title, content) => {

  const data = {};
  const timeStamp = "Notif" + Date.now();
  
  data[timeStamp] = {
    read: false,
    title: title,
    content: content,
  };

  firebase.getUserValue(authUser.uid, 'notifications').update( data );
}

const getCompletedInvest = (partner, round) => parseFloat(partner?.partnerInfo?.SHARES?.[round]?.investment || 0);
const getInvestIntention = (partner, round) => parseFloat(partner?.partnerInfo?.SHARES?.[round]?.investmentIntent || 0);

export const hasSomeESOP = (partner) => getTotalOwnedEsops(partner) > 0;
export const hasSomeShares = (partner) => getTotalOwnedShares(partner) > 0;
export const roundPercent = (value, total) => (total > 0) ? Math.round((value / total) * 10000) / 100 : 0;
export const getESOPFromUser = (partner, info, round) => partner?.partnerInfo?.ESOP?.[round]?.[info] || 0;
export const getInfoFromUser = (partner, info, round) => partner?.partnerInfo?.SHARES?.[round]?.[info] || 0;

export const getTotalOwnedShares = (partner) => {
  return Object.keys(partner?.partnerInfo?.SHARES || {}).reduce((total, element) => total + parseInt(partner?.partnerInfo?.SHARES?.[element]?.quantity || 0), 0);
}

export const getTotalOwnedEsops = (partner) => {
  return Object.keys(partner?.partnerInfo?.ESOP || {}).reduce((total, element) => total + parseInt(partner?.partnerInfo?.ESOP?.[element]?.quantity || 0), 0);
}

export const getInvestIntentionsForRound = (partners, round) => {
  return partners.filter(partner => partner?.partnerInfo?.SHARES?.[round]).reduce((total, partner) => total + getInvestIntention(partner, round), 0);
}

export const getCompletedInvestmentsForRound = (partners, round) => {
  return partners.filter(partner => partner?.partnerInfo?.SHARES?.[round]).reduce((total, partner) => total + getCompletedInvest(partner, round), 0);
}

export const getCompletedInvestments = (partner) => {
  return Object.keys(partner?.partnerInfo?.SHARES || {}).reduce((total, element) => total + parseFloat(partner?.partnerInfo?.SHARES?.[element]?.investment || 0), 0);
}

export const getOngoingInvestments = (partner) => {
  return Object.keys(partner?.partnerInfo?.SHARES || {}).reduce((total, element) => total + parseFloat(partner?.partnerInfo?.SHARES?.[element]?.investmentIntent || 0), 0);
}


export const computePnl = (partner, steeringObj) => {
  let pnl = getPnlFromShares(partner, steeringObj);
  pnl += getPnlFromESOP(partner, steeringObj);
  pnl -= getCompletedInvestments(partner);
  return Math.round(pnl * 100) / 100;
}

export const computeRatioFromStartDate = (employee) => {
  const today = new Date();
  const lastDayOftheYear = new Date(today.getFullYear(), 11, 31);
  const nbrDaysSinceStart = Math.min(Math.round((lastDayOftheYear - stringToDate(employee.contract.start)) / ONE_DAY), NBR_DAYS_IN_YEAR);
  return nbrDaysSinceStart / NBR_DAYS_IN_YEAR;
};

export const getPnlFromShares = (partner, steeringObj) => {
  let pnl = 0;
  const currentSharePrice = steeringObj?.currentSharePrice || 0;
  Object.keys(partner?.partnerInfo?.SHARES || {}).forEach(element => {
    const sharesOwned = parseInt(partner.partnerInfo.SHARES[element]?.quantity || 0);
    (sharesOwned > 0) && (pnl += Math.round( 100 * (sharesOwned * currentSharePrice)) / 100);
  });
  return pnl;
}

export const getPnlFromESOP = (partner, steeringObj) => {
  let pnl = 0;
  const vestedValues = getTotalVestedEsops(partner);
  const currentSharePrice = steeringObj?.currentSharePrice || 0;

  Object.keys(partner?.partnerInfo?.ESOP || {}).forEach(element => {
      const esopOwned = parseInt(partner.partnerInfo.ESOP[element]?.quantity || 0);
      const currentRound = steeringObj?.SHARES?.[element] || null;
      if (currentRound) {
        if (esopOwned > 0 && currentRound.funded) {
          const esopVested = vestedValues[element];
          pnl += (esopOwned * esopVested * currentSharePrice) - (esopOwned * esopVested * currentRound.price);
        }
      }
  });
  return pnl;
}

export const getSortedRoundList = (steeringObj) => {
  if (steeringObj) {
    const roundList = Object.keys(steeringObj?.SHARES || {}).map(round => ({ round, order: steeringObj.SHARES[round].order }));
    return roundList.sort( (a, b) => (a.order > b.order) ? 1 : -1);
  }
  return [];
}

export const getTotalShares = (steeringObj, foreCast) => {

  const roundList = getSortedRoundList(steeringObj);

  let totalShares = 0;
  if (steeringObj) {
    roundList.forEach(element => {
      totalShares += (steeringObj.SHARES[element.round].funded || foreCast) ? parseInt(steeringObj.SHARES[element.round].quantity) : 0;
      if (steeringObj.ESOP?.[element.round])
        totalShares += (steeringObj.SHARES[element.round].funded || foreCast) ? parseInt(steeringObj.ESOP[element.round].quantity) : 0;
    });
  }

  const values = [];
  const founders = ['Founders', {v: 0, f: 0}];
  values.push(['Round', 'percent']);
  values.push(founders);
  if (steeringObj) {
    roundList.forEach(element => {
      let shares = (steeringObj.SHARES[element.round].funded || foreCast) ? parseInt(steeringObj.SHARES[element.round].quantity) : 0;
      if (steeringObj.ESOP?.[element.round])
        shares += (steeringObj.SHARES[element.round].funded || foreCast) ? parseInt(steeringObj.ESOP[element.round].quantity) : 0;
      if (element.round === 'CORE' || element.round === 'FOUNDERS') {
        founders[1].v += shares;
        founders[1].f = `${roundPercent(founders[1].v, totalShares)}% | ${nFormatter(founders[1].v, 1)}`;
      }
      else if (shares > 0)
        values.push([element.round, {v: shares, f: `${roundPercent(shares, totalShares)}% | ${nFormatter(shares, 1)}`}]);
    });
  }
  return values;
}

export const getTotalVestedEsops = (partner) => {
  let vestedValues = {};
  const now = new Date();
  const oneYear = ONE_DAY * NBR_DAYS_IN_YEAR;
  const VESTING_PER_QUARTER = 0.0625;
  //const quarters = ["01/01", "04/01", "07/01", "10/01"];

  Object.keys(partner?.partnerInfo?.ESOP || {}).forEach(element => {
    if (parseInt(partner.partnerInfo.ESOP[element]?.quantity || 0) > 0) {
      const startDate = new Date(stringToDate(partner.partnerInfo.ESOP[element]?.date || "01/01/2024"));
      // calculate if it's been 1 year since the start date
      const diff = now - startDate;
      const diffYears = Math.floor(diff / oneYear);
      // calculate number of quarters since the start date
      const diffQuarters = Math.floor(diff / (oneYear / 4));
      vestedValues[element] = diffQuarters * VESTING_PER_QUARTER;
      if (diffYears < 1) vestedValues[element] = 0;
    }
  });

  return vestedValues;
}
