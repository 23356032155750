export const HR = 'HR';
export const ORG = 'ORG';
export const OPS = 'OPS';
export const USER = 'USER';
export const TECH = 'TECH';
export const ADMIN = 'ADMIN';
export const SALES = 'SALES';
export const DUMMY = 'DUMMY';
export const MANAGER = 'MANAGER';
export const SUPPORT = 'SUPPORT';
export const PARTNER = 'PARTNER';
export const EMPLOYEE = 'EMPLOYEE';
export const MARKETING = 'MARKETING';
export const PARTNERSHIPS = 'PARTNERSHIPS';
export const PARTNERSHIPS_ADMIN = 'PARTNERSHIPS_ADMIN';

export const BETA_TESTER = 'BETA_TESTER';