import { makeStyles } from '@material-ui/core/styles';
import { PURPLE } from '../../../../../constants/colors';

export const useStyles = makeStyles((theme) => ({
  size: props => ({
    width: theme.spacing(props.size || 5),
    height: theme.spacing(props.size || 5),
  }),
  avatarInvert: {
      color: PURPLE,
      width: theme.spacing(17),
      height: theme.spacing(17),
  },    
}));
