import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({

    statusIcon: {
        fontSize: theme.spacing(3),
        marginTop: theme.spacing(1),
    },

}));
