import React, { Component } from 'react';
import TableRow from '@material-ui/core/TableRow';
import { Tooltip, Badge } from '@material-ui/core';
import BackupIcon from '@material-ui/icons/Backup';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { YELLOW } from '../../../../../constants/colors';
import DescriptionIcon from '@material-ui/icons/Description';
import FileUploader from '../../../../utils/GenericComponents/fileUploader';
import LinearProgressWithLabel from '../../../../utils/GenericComponents/progressBar';

import { CrowdSecCheckbox } from './styles';

const HistoryPartnerCell = ({sm, children, align}) => {
  return(
    <TableCell align={align} >
      <Typography component={'span'} variant={sm ? "caption" : "body2"} >{children}</Typography>
    </TableCell>
  );
};
  
class HistoryPartnerRow extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      progress: 0,
      isUploading: false,
      roundChecked: false,
    }
    this.unMounted = false;
    this.partner = props.partner;
    this.currentRound = props.currentRound;
    this.roundName = props.currentRound.round;
  }

  loadPartnerFiles() {
    this.props.firebase.getFiles(`members/${this.partner.uid}/partner/${this.roundName}`, this.setFiles);
  }

  reloadPartnerFiles() {
    this.setState({ files: [] });
    this.loadPartnerFiles();
  }

  componentDidMount() {
    this.loadPartnerFiles();
    this.setState( { roundChecked: this.checkIfPartnerHasBeenSelectedForThisRound() });
  }

  componentWillUnmount = () => this.unMounted = true;

  handleProgress = progress => this.setState( { progress: progress });

  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });

  checkIfPartnerHasBeenSelectedForThisRound = () => (this.roundName in this.partner.partnerInfo.SHARES);

  setFiles = (fileInfos) => {
    if (!this.unMounted) {
      this.state.files.push(fileInfos)
      this.state.files.sort( (a, b) => (a.name > b.name) ? 1 : -1);
      this.setState( {files: this.state.files} );
    }
  }  

  handleUploadError = error => {
    this.setState({ isUploading: false });
    this.props.setSnackStatus(true, "Error uploading file");
  };

  handleUploadSuccess = filename => {
    this.props.setSnackStatus(true, "File saved");
    this.reloadPartnerFiles();
    this.setState({ isUploading: false, progress: 100 });
  };

  handlePartnerDocumentList = (event) => {
    event.preventDefault();
    if (this.state.files.length > 0)
      this.props.handleDocumentList(event, this.state.files);
  }

  handlePartnerElligibility = (event) => {
    if (!this.checkIfPartnerHasBeenSelectedForThisRound()) {
      const roundInfos = {
        quantity: 0,
        investment: 0,
        shares_quantity: 0,
        investmentIntent: 0,
        stage: { P1: false, P2: false, P3: false }
      }
      this.partner.partnerInfo.SHARES[this.roundName] = roundInfos;
      this.partner.partnerInfo.ESOP[this.roundName] = { quantity: 0 };
      this.setState({ roundChecked: true });
    } else {
      delete this.partner.partnerInfo.ESOP[this.roundName];
      delete this.partner.partnerInfo.SHARES[this.roundName];
      this.setState({ roundChecked: false });
    }
    this.props.onSaveUserWithPartnerInfos(this.partner);
  }  

  buildFileName = (file, partner) => {
      if (file.name.toLowerCase().includes(partner.trigram.toLowerCase()))
        return file.name;
      return `${file.name.split('.')[0]}_${partner.trigram}.${file.name.split('.')[1]}`
  }    

  render() {
    
    const {partner, steeringObj, currentRound, firebase, width} = this.props;
    
    if ((!(this.roundName in partner.partnerInfo.SHARES) || !(this.roundName in partner.partnerInfo.ESOP)) && currentRound.funded) return (<></>);

    const sm = (width === 'sm') || (width === 'xs');
    const { files, progress, isUploading } = this.state;
    const shares = partner.partnerInfo.SHARES[this.roundName];
    const sharePrice = steeringObj.SHARES[this.roundName].price;

    let isCheckBoxDisabled = currentRound.funded;
    if (this.roundName in partner.partnerInfo.SHARES) {
      isCheckBoxDisabled = isCheckBoxDisabled || shares.investment > 0 || shares.investmentIntent > 0;
      isCheckBoxDisabled = isCheckBoxDisabled || partner.partnerInfo.ESOP[this.roundName].quantity > 0;
    }

    return (
      <TableRow key={`${currentRound.round}${partner.trigram}`}>

        <HistoryPartnerCell sm={sm}>
          <CrowdSecCheckbox
            color="primary"
            disabled={isCheckBoxDisabled}
            onChange={this.handlePartnerElligibility}
            checked={ this.state.roundChecked }
          />
          <b>{this.roundName}</b>
        </HistoryPartnerCell>

        {!(this.roundName in partner.partnerInfo.SHARES) && (
          <>
            <HistoryPartnerCell sm={sm}>
              <Typography variant={sm ? "caption" : "body2"} color="textSecondary">
                <i>{`${partner.civil.firstName} is not part of this investment round.`}</i>
              </Typography>
            </HistoryPartnerCell>
          </>
        )}

        {(this.roundName in partner.partnerInfo.SHARES) && (
          <>
          <HistoryPartnerCell sm={sm}>
            <Badge badgeContent={files.length} color="primary" overlap="rectangular">
              <IconButton onClick={this.handlePartnerDocumentList} disabled={files.length === 0}>
                <DescriptionIcon style={{fontSize: sm ? 12 : 20}}/>
              </IconButton>
            </Badge>
            {!isUploading && (
              <Tooltip title="upload a document" arrow>
                <span>
                  <FileUploader
                    multiple={true}
                    firebase={firebase}
                    accept="application/pdf"
                    onProgress={this.handleProgress}
                    onUploadStart={this.handleUploadStart}
                    onUploadError={this.handleUploadError}
                    onUploadSuccess={this.handleUploadSuccess}
                    filename={file => this.buildFileName(file, partner)}
                    path={`members/${partner.uid}/partner/${currentRound.round}`}
                  >
                    <IconButton aria-label="upload-document" component="span">
                      <BackupIcon style={{fontSize: sm ? 12 : 20, color: YELLOW}}/>
                    </IconButton>
                  </FileUploader>
                </span>
              </Tooltip>)}
            {isUploading && <LinearProgressWithLabel value={progress} />}
          </HistoryPartnerCell>
          
          <HistoryPartnerCell sm={sm}>{shares.date}</HistoryPartnerCell>
          
          <HistoryPartnerCell sm={sm} align="right">
            {(shares.investmentIntent > 0) ?  (
              <Tooltip title="not yet validated">
                <Typography variant={sm ? "caption" : "body2"} style={{color: 'orange'}}>
                  {`(${Math.floor((shares.investmentIntent / sharePrice)).toLocaleString()})`}
                </Typography> 
              </Tooltip>
            ) : shares.quantity.toLocaleString()}
          </HistoryPartnerCell>
          
          <HistoryPartnerCell sm={sm} align="right">{partner.partnerInfo.ESOP[currentRound.round].quantity.toLocaleString()}</HistoryPartnerCell>
          
          <HistoryPartnerCell sm={sm} align="right">
            {`${Math.floor(sharePrice * (sm ? 100 : 1000)) / (sm ? 100 : 1000)} ${sm ? '' : '€'}`}
          </HistoryPartnerCell>
          
          <HistoryPartnerCell sm={sm} align="right">
            {(shares.investmentIntent > 0) ?  (
              <Tooltip title="not yet validated">
                <Typography variant={sm ? "caption" : "body2"} style={{color: 'orange'}}>
                  {`(${shares.investmentIntent.toLocaleString()} €)`}
                </Typography> 
              </Tooltip>
            ) : `${shares.investment.toLocaleString()} €`}
          </HistoryPartnerCell>
        </>
        )}
      
      </TableRow>
    );
  }
}

export default HistoryPartnerRow;