import React, { useState } from 'react';
import { useRowStyles } from './styles';
import TableRow from '@material-ui/core/TableRow';
import FormGroup from '@material-ui/core/FormGroup';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import { Badge, Chip, Tooltip } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { YELLOW, LIGHT_GRAY } from '../../../../../constants/colors';
import { getInfoFromUser, getESOPFromUser } from '../../../../utils';
import MemberAvatar from '../../../../utils/GenericComponents/memberAvatar';

const StagePanel = (props) => {

  const { stage, onSaveUserWithPartnerInfos, partner } = props;

  const [stageP1, setStageP1] = useState(stage.P1);
  const [stageP2, setStageP2] = useState(stage.P2);
  const [stageP3, setStageP3] = useState(stage.P3);

  const handleChange = (eventName) => {
    if (eventName === 'P1') {
      if (stageP1 === false) {
        stage['P1'] = true;
        setStageP1(true);
      } else {
        stage['P1'] = false;
        stage['P2'] = false;
        stage['P3'] = false;
        setStageP1(false);
        setStageP2(false);
        setStageP3(false);
      }
    }
    else if (eventName === 'P2') {
      if (stageP2 === false) {
        stage['P1'] = true;
        stage['P2'] = true;
        setStageP1(true);
        setStageP2(true);
      } else {
        stage['P2'] = false;
        stage['P3'] = false;
        setStageP2(false);
        setStageP3(false);
      }
    }
    else if (eventName === 'P3') {
      if (stageP3 === false) {
        stage['P1'] = true;
        stage['P2'] = true;
        stage['P3'] = true;
        setStageP1(true);
        setStageP2(true);
        setStageP3(true);
      } else {
        stage['P3'] = false;
        setStageP3(false);
      }
    }
    onSaveUserWithPartnerInfos(partner);
  };

  return(
    <FormGroup row>
      <FormControlLabel
        control={<Chip label="P1" size="small" onClick={() => handleChange('P1')} style={{ backgroundColor: (stageP1) ? YELLOW : LIGHT_GRAY}}/>}
      />    
      <FormControlLabel
        control={<Chip label="P2" size="small" onClick={() => handleChange('P2')} style={{ backgroundColor: (stageP2) ? YELLOW : LIGHT_GRAY}}/>}
        />    
      <FormControlLabel
        control={<Chip label="P3" size="small" onClick={() => handleChange('P3')} style={{ backgroundColor: (stageP3) ? YELLOW : LIGHT_GRAY}}/>}
        />    
    </FormGroup>
  );
};

const CaptablePartnerRow = (props) => {

  const { partner, round, onSaveUserWithPartnerInfos } = props;
  
  const classes = useRowStyles( {connected: partner.connected} );

  const investmentIntent = getInfoFromUser(partner, 'investmentIntent', round.round);
  partner.partnerInfo.SHARES[round.round].shares_quantity = (investmentIntent > 0) ? Math.floor(investmentIntent / round.price) : getInfoFromUser(partner, 'quantity', round.round);
  if (partner?.partnerInfo?.ESOP[round.round]) 
    partner.partnerInfo.ESOP[round.round].esop_quantity = getESOPFromUser(partner, 'quantity', round.round)

  return (
    <>
      <TableRow hover className={classes.root}>
        <TableCell>
          <Badge classes={{ badge: classes.customBadge }} variant="dot" anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} overlap="rectangular">
            <MemberAvatar member={partner}/>
          </Badge>
        </TableCell>

        <TableCell scope="row">
          {partner.civil.lastName} {partner.civil.firstName}
        </TableCell>

        <TableCell scope="row">
          {('stage' in partner.partnerInfo.SHARES[round.round]) &&
            <StagePanel
              partner={partner}
              stage={partner.partnerInfo.SHARES[round.round].stage}
              onSaveUserWithPartnerInfos={onSaveUserWithPartnerInfos}
            />}
        </TableCell>

        <TableCell align="center">
          {(investmentIntent > 0) ?  (
            <Tooltip title="not yet validated">
              <Typography variant='body2' style={{color: 'orange'}}>
                {`(${investmentIntent.toLocaleString()}) €`}
              </Typography> 
            </Tooltip>
          ) : investmentIntent.toLocaleString()}
        </TableCell>

        <TableCell align="center">
            {`${getInfoFromUser(partner, 'investment', round.round).toLocaleString()} €`}
        </TableCell>

        <TableCell align="center">
          {(investmentIntent > 0) ?  (
            <Tooltip title="not yet validated">
              <Typography variant='body2' style={{color: 'orange'}}>
                {`(${partner.partnerInfo.SHARES[round.round].shares_quantity.toLocaleString()})`}
              </Typography> 
            </Tooltip>
          ) : partner.partnerInfo.SHARES[round.round].shares_quantity.toLocaleString()}
        </TableCell>

        <TableCell align="center">
            {`${partner.partnerInfo.ESOP[round.round]?.esop_quantity?.toLocaleString() || 0}`}
        </TableCell>

      </TableRow>
    </>
  );
}

export default CaptablePartnerRow;