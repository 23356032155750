import React from 'react';
import * as UTILS from '../../../utils';
import List from '@material-ui/core/List';
import { nFormatter } from '../../../utils';
import { getTotalShares } from '../../../utils';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import * as COLORS from '../../../../constants/colors';
import ListItemText from '@material-ui/core/ListItemText';
import { Grid, Tooltip, Hidden } from '@material-ui/core';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import FloatingCard from '../../../utils/GenericComponents/floatingCard';

import { useStyles } from './styles';

const ParameterizedTrendIcon = ({pnl}) => {
    return(
        <>
            { (pnl > 0)
                ? <TrendingUpIcon style={{ color: COLORS.DARK_GREEN}}/>
                : (pnl < 0) ? <TrendingDownIcon style={{ color: COLORS.DARK_RED}}/> : ""
            }
        </>
    );
};

const ForecastPnl = props => {
    const { steeringObj, totalSharesOwned } = props;
    const totalSharesBreakdown = steeringObj ? getTotalShares(steeringObj, true) : [];
    const totalShares = totalSharesBreakdown.reduce((total, elt) => isNaN(elt[1].v) ? total + 0 : elt[1].v + total, 0);
    return(
        <Grid container spacing={1}>
            <Grid item xs={12} align="center">
                Estimation on a valuation of 100M€: <b>~{nFormatter((100*Math.pow(10,6)) / totalShares * totalSharesOwned, 0)}€</b>
            </Grid>
            <Grid item xs={12} align="center">
                Estimation on a valuation of 200M€: <b>~{nFormatter((200*Math.pow(10,6)) / totalShares * totalSharesOwned, 0)}€</b>
            </Grid>
            <Grid item xs={12} align="center">
                Estimation on a valuation of 300M€: <b>~{nFormatter((300*Math.pow(10,6)) / totalShares * totalSharesOwned, 0)}€</b>
            </Grid>
            <Grid item xs={12} align="center">
                Estimation on a valuation of 1 B€: <b>~{nFormatter((1000*Math.pow(10,6)) / totalShares * totalSharesOwned, 0)}€</b>
            </Grid>
            <Grid item xs={12} align="center">
                <i>! Those values are not contractual !</i>
            </Grid>
        </Grid>
    );
};


const InvestRecapList = props => {

    const { pnl, width, steeringObj, investment, percentPnl, investIntent, numberOfESOPs, numberOfShares } = props;

    const sm = (width === 'md' );
    const classesComponent = useStyles({pnl : pnl});
    const formatCond = (width === 'xs' || width === 'md');
    const displayPercentPnL = React.useMemo(() => formatCond ? UTILS.nFormatter(percentPnl || null, 1) : percentPnl, [formatCond, percentPnl]);
    const displayPnL = React.useMemo(() => formatCond ? UTILS.nFormatter(pnl || null, 1) : Math.round(pnl).toLocaleString(), [formatCond, pnl]);
    const displayInvestment = React.useMemo(() => formatCond ? UTILS.nFormatter(investment || null, 1) : investment.toLocaleString(), [formatCond, investment]);
    
    return(
        <FloatingCard>
            <List className={classesComponent.list} >

                <ListItem className={classesComponent.listItemHeader}>
                    <Grid container spacing={1}>
                        <Hidden smDown>
                            <Grid item xs={1} align="center">
                                <ListItemText primary=" " />
                            </Grid>
                        </Hidden>
                        <Grid item xs={4} align="center">
                            <ListItemText secondary={sm ? "My Invest." : "My Investment"} classes={{secondary: classesComponent.listItemText}}/>
                        </Grid>
                        <Grid item xs={4} align="center">
                            <ListItemText secondary="PnL" classes={{secondary: classesComponent.listItemText}}/>
                        </Grid>
                        <Grid item xs={3} align="center">
                            <ListItemText secondary={sm ? "%" : "PnL (%)"} classes={{secondary: classesComponent.listItemText}}/>
                        </Grid>
                    </Grid>
                </ListItem>

                <ListItem className={classesComponent.listItemContent}>
                    <Grid container spacing={1}>
                        <Hidden smDown>
                            <Grid item xs={1} align="center">
                                <ListItemText primary={ <ParameterizedTrendIcon pnl={pnl} /> } />
                            </Grid>
                        </Hidden>
                        <Grid item xs={4} align="center">
                            <ListItemText primary={
                                <Tooltip title={investIntent > 0 ? `${investIntent}€ not yet validated` : ""} arrow>
                                    <Typography variant="body2" style={{color: investIntent > 0 ? 'orange' : investment ? 'green' : COLORS.GRAY}} className={classesComponent.pnl}>
                                        { investment
                                        ? `${displayInvestment}€`
                                        : ' - €'}
                                    </Typography>
                                </Tooltip>
                            }/>
                        </Grid>
                        <Grid item xs={4} align="center">
                            <ListItemText primary={ 
                                <Tooltip title={<ForecastPnl steeringObj={steeringObj} totalSharesOwned={numberOfESOPs + numberOfShares} />} arrow>
                                    <Typography variant={sm ? 'caption' : 'body2'} className={classesComponent.pnl}>
                                        {(pnl !== 0) ? `${displayPnL}€` : ' - €'}
                                    </Typography>
                                </Tooltip>
                            }/>
                        </Grid>
                        <Grid item xs={3} align="center">
                            <ListItemText primary={
                                <Typography variant={sm ? 'caption' : 'body2'} className={classesComponent.pnl}>
                                    {`${(percentPnl > 0) ? "+" : ""}${displayPercentPnL}%`}
                                </Typography>
                            }/>
                        </Grid>
                    </Grid>
                </ListItem>
                
            </List>
        </FloatingCard>
    );
}

export default InvestRecapList;
