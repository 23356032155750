import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({

    card: (props) => ({
      marginTop: 5,
      marginBottom: 5,
      height: props.height || "100%",
    }),
  
});
  