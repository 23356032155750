import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },

    formControl: {
        minWidth: 120,
        margin: theme.spacing(1),
    },

}));
  