import React, { useState, useMemo }  from 'react';

import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import EmployeeProfile from '../../employeeProfile';
import { DialogTitle, Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { DialogContent, DialogActions } from './styles';
import * as ROLES from '../../../../../constants/roles';
import * as COLORS from '../../../../../constants/colors';

const KNOWN_ROLES = [
    { label: 'ops', value: ROLES.HR },
    { label: 'tech', value: ROLES.TECH },
    { label: 'sales', value: ROLES.SALES },
    { label: 'mktg', value: ROLES.MARKETING },
    { label: 'support', value: ROLES.SUPPORT },
];

export const TeamFilter = props => {
    const { big, filters, onFilterEmployeesByTeam } = props;
    const fontSize = big ? 14 : 9;
    
    const getTextColor = (role) => filters.includes(role) ? 'black' : COLORS.LIGHT_GRAY;
    const getBorderColor = (role) => filters.includes(role) ? COLORS.YELLOW : COLORS.LIGHT_GRAY;

    return (
        <>
            {KNOWN_ROLES.map(role => (
                <div key={role.label} style={{float:'left'}}>
                    <Chip
                        size={'small'}
                        label={role.label}
                        variant="outlined"
                        onClick={e => onFilterEmployeesByTeam(e, role.value)}
                        style={{ fontSize, borderColor: getBorderColor(role.value), color: getTextColor(role.value) }}
                    />
                    <span style={{fontSize}}>&nbsp;</span>
                </div>
            ))}
        </>
    );
}


export const TeamList = props => {

    const { open, width, firebase, employees, employeeTeamCard, handleCloseDialog } = props;
    const [filters, setFilters] = useState([ROLES.HR, ROLES.MARKETING, ROLES.SALES, ROLES.TECH, ROLES.SUPPORT]);

    const onFilterEmployeesByTeam = (event, role) => {
        event.preventDefault();
        if (filters.includes(role))
            setFilters(filters.filter(filter => filter !== role));
        else setFilters([...filters, role]);
    }

    const filteredEmployees = useMemo(() => employees.filter(employee => Object.keys(employee.roles).some(role => filters.includes(role))), [employees, filters]);

    return (
      <Dialog fullScreen onClose={handleCloseDialog} aria-labelledby="team-dialog" open={open} className={employeeTeamCard.dialog}>

        <DialogTitle className={employeeTeamCard.dialogTitle}>
            <IconButton aria-label="close" onClick={handleCloseDialog} className={employeeTeamCard.closeButton}>
                <CloseIcon />
            </IconButton>
        </DialogTitle>
  
        <DialogContent dividers className={employeeTeamCard.dialogContent}>
            <div className={employeeTeamCard.container}>
                <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="stretch">
                    {filteredEmployees && filteredEmployees.map(employee => (
                        <Grid item xs={12} sm={6} md={3} key={employee.trigram}>
                            <EmployeeProfile
                                width={width}
                                editable={false}
                                employee={employee}
                                firebase={firebase}
                            />
                        </Grid>
                    ))}
                </Grid>
            </div>
        </DialogContent>
  
        <DialogActions>
            <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
                <Grid item xs={11} >
                    <div className={employeeTeamCard.dialogChipContainer}>
                        <TeamFilter filters={filters} onFilterEmployeesByTeam={onFilterEmployeesByTeam} big={true}/>
                    </div>
                </Grid>
                <Grid item xs={1} >
                    <Button onClick={handleCloseDialog} color="primary" >
                        Close
                    </Button>
                </Grid>
            </Grid>
        </DialogActions>
      </Dialog>
    );
}