import { makeStyles } from '@material-ui/core/styles';

export const useRowStyles = makeStyles((theme) => ({
    root: {
        '& > *': { borderBottom: 'unset' },
    },
  
    rootOpen: {
        '& > *': { borderBottom: 'unset' },
    },

    iconDelete: {
        zIndex: 10,
    },

    addCategoryIcon: {
        color: '#3fb551',
        fontSize: theme.spacing(3),
    },

    tagList: {
        padding: theme.spacing(0.5),
        backgroundColor: 'rgba(0, 0, 0, 0.01)',
    },

    tag: {
        padding: theme.spacing(0.5),
        fontSize: theme.spacing(1.5),
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
        },
    },

    historyRowTableBox: {
        padding: 0,
        marginLeft: 0,
        marginRight: 0,
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1),
    },

    historyRowTable: {
        margin: 0,
        padding: 0,
    },

    historyRow: {
        margin: 0,
        padding: 0,
    },

    headerTableCell:{
        margin: 0,
        padding: 0,
        width: "10%",
    },

    headerLabelTableCell:{
        margin: 0,
        padding: 0,
        width: "26%",
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },

    tableCell: {
        margin: 0,
        padding: 0,
        width: "10%",
        border: `1px solid lightgray`,
    },

    tableCellMiddle: {
        margin: 0,
        padding: 0,
        width: "10%",
        border: `1px solid lightgray`,
        borderRight: `3px solid lightgray`,
    },

    labelTableCell: {
        margin: 0,
        padding: 0,
        width: "26%",
        border: `1px solid lightgray`,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        borderRight: `3px solid lightgray`,
    },

    firstTableCell: {
        margin: 0,
        padding: 0,
        width: "4%",
        borderBottom: 'unset',
        paddingLeft: theme.spacing(1),
    },

    cellTextFieldGeneric: (props) => ({
        margin: 0,
        padding: 0,
        height: "100%",
        fontSize: theme.spacing(1.75),
        '&:hover': {
            //backgroundColor: props.editionMode ? 'none' : 'rgba(0, 0, 0, 0.02)',
        },
    }),
    
    cellTextFieldGenericFocused: (props) => ({
        margin: 0,
        padding: 0,
        height: "100%",
        fontSize: theme.spacing(1.75),
        backgroundColor: props.editionMode ? 'rgba(225, 0, 0, 0.2)' : 'rgba(0, 225, 0, 0.2)',
        border: `1px solid lightblue`,
    }),        
}));
