import WelcomeBar from '../WelcomeBar';
import React, { Component } from 'react';
import AccordionPanel from './accordionPanel';
import HelpIcon from '@material-ui/icons/Help';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import PasswordChangeForm from '../PasswordChange';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import { PasswordForgetForm } from '../PasswordForget';
import MemberAvatar from '../utils/GenericComponents/memberAvatar';
import { GeneralInfos, GeneralInfosActionsPanel } from './generalinfos';


const PANEL = {
  GENERAL: "general_panel",
  RESET_PASSWORD: "general_reset_pwd",
  CHANGE_PASSWORD: "general_change_pwd",
};

const LABELS = {
  FORGET_PASSWORD: "Forget Password?",
  CHANGE_PASSWORD: "Change Password",
};
class AccountPageBase extends Component {

  constructor(props) {
    super(props);
    this.state = { 
      edit: false,
      open: false,
      expanded: PANEL.GENERAL,
      authUser: { ...props.authUser }
    }
  }
  
  updateUser = (user) => this.setState(user);
  
  handleChange = (panel) => (event, isExpanded) => this.setState({ expanded : isExpanded ? panel : false });

  handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    this.setState({ open: false });
  };

  onEdit = event => {
    const { edit } = this.state;
    if (edit)
      this.setState({ authUser: {...this.props.authUser} });
    this.setState({ edit: !edit });
  }

  sanitizeUser = (user) => {
    delete user.ndf;
    delete user.uid;
    delete user.email;
    delete user.roles;
    delete user.trigram;
    delete user.contract;
    delete user.partnerInfo;
    delete user.notifications;
    
    return user;
  }

  onSubmit = event => {
    const { authUser } = this.state;
    this.props.firebase.user(authUser.uid).update(this.sanitizeUser({...authUser}))
    .then(r => this.setState( { edit: false, open: true}))
    .catch(error => alert("Error : " + error));

    event.preventDefault();
  }

  render() {

    const { classes, highCondition } = this.props;
    const { edit, open, expanded, authUser } = this.state;

    let error = !!(authUser?.civil?.firstName?.length === 0) || false;
      
    if (this.props.highCondition(authUser)) {
      //error |= !!('civil' in authUser && (authUser.civil.lastName.length === 0 || authUser.civil.birthDate.length === 0));
      error |= !!(authUser?.civil?.lastName?.length === 0 || authUser?.civil?.birthDate?.length === 0);
    }

    return (
      <Container fixed>
        <div style={{paddingTop: 10}} />          
        <WelcomeBar message="Welcome to your account," authUser={authUser}/>

        {/* GENERAL INFOS */}
        <AccordionPanel
          classes={classes} 
          expanded={expanded}
          panelName={PANEL.GENERAL}
          handleChange={this.handleChange}
          avatar={<MemberAvatar member={authUser} />}
          customBackground={classes.headerGeneralInfos}
          infos={<>General Infos for account <strong>{authUser.email}</strong></>}
          actionsComponent={<GeneralInfosActionsPanel {...this.state} onEdit={this.onEdit} onSubmit={this.onSubmit} error={error}/>}
          detailComponent={<GeneralInfos authUser={authUser} edit={edit} updateUser={this.updateUser} highCondition={highCondition}/>}
        />

        {/* PASSWORD FORGET */}
        <AccordionPanel
          classes={classes} 
          expanded={expanded}
          avatar={<HelpIcon />}
          actionsComponent={null}
          infos={LABELS.FORGET_PASSWORD}
          panelName={PANEL.RESET_PASSWORD}
          handleChange={this.handleChange}
          customBackground={classes.headerPassword}
          detailComponent={<PasswordForgetForm classes={classes}/>}
        />

        {/* PASSWORD CHANGE */}
        <AccordionPanel
          classes={classes} 
          expanded={expanded}
          actionsComponent={null}
          avatar={<VpnKeyIcon />}
          infos={LABELS.CHANGE_PASSWORD}
          handleChange={this.handleChange}
          panelName={PANEL.CHANGE_PASSWORD}
          customBackground={classes.headerPassword}
          detailComponent={<PasswordChangeForm classes={classes} authUser={authUser}/>}
        />

        <Snackbar
          open={open}
          message="Infos saved"
          autoHideDuration={3000}
          onClose={this.handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          action={
            <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />   
      </Container>
    )
  };
}

export default AccountPageBase;

              // avatar={ 'civil' in authUser && `${authUser.civil.firstName.substring(0,1).toUpperCase()}${(authUser.civil.lastName) ? authUser.civil.lastName.substring(0,1).toUpperCase() : ""}`}
