import React from 'react';
import { TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';

const PasswordChangeComponent = (props) => {

    const { classes } = props;
  
    const [passwordOne, setPasswordOne] = React.useState('');
    const [passwordTwo, setPasswordTwo] = React.useState('');
  
    const isInvalid = passwordOne !== passwordTwo || passwordOne === '';
  
    const onSubmit = event => {
      props.onSubmit(passwordOne);
      event.preventDefault();
    }
  
    const onChangePasswordOne = event => setPasswordOne(event.target.value);
    const onChangePasswordTwo = event => setPasswordTwo(event.target.value);
  
  
    return (
  
      <form className={classes.form_root} onSubmit={onSubmit}>
        <TextField
          align="left"
          margin="dense"
          type="password"
          id="passwordOne"
          name="passwordOne"
          variant="outlined"
          value={passwordOne}
          label="New Password"
          style={{maxWidth: 300}}
          onChange={onChangePasswordOne}
        />
        <TextField
          align="left"
          margin="dense"
          type="password"
          id="passwordTwo"
          name="passwordTwo"
          variant="outlined"
          value={passwordTwo}
          style={{maxWidth: 300}}
          onChange={onChangePasswordTwo}
          label="Confirm New Password"
        />
  
        <Button disabled={isInvalid} type="submit">
          Change My Password
        </Button>
  
      </form>
  
    );
}

export default PasswordChangeComponent;  