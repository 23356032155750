import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    formControl: {
        marginTop: theme.spacing(0.5),
        minWidth: 200,
        maxWidth: 200,
        margin: theme.spacing(1),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    select: {
        fontSize: theme.spacing(3),
    },
    menuItem: {
        fontSize: theme.spacing(3),
    },
}));