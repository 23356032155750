import Logo from '../../Logo';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { SignInButton } from '../../SignIn';
import { withFirebase } from '../../Firebase';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { PURPLE } from '../../../constants/colors';
import * as ROUTES from '../../../constants/routes';
import { APP } from '../../../constants/realtimeDB';
import withWidth from '@material-ui/core/withWidth';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

const NavigationNonAuth = ({firebase, width}) => {

  const sm = (width === 'sm' ) || (width === 'xs');

  const [version, setVersion] = useState('');

  firebase.db.ref(`${APP}/properties`).on('value', snapshot => {
    if (version !== snapshot.val().version)
      setVersion(snapshot.val().version);
  });

  return (
    <>
      <AppBar position="static" style={{ backgroundColor: PURPLE, }}>
      <Toolbar>

        <Grid container spacing={1}  alignItems="center">

          <Grid item xs={3} sm={2} align="left">
            <IconButton to={ROUTES.LANDING} component={Link}>
              <Logo height={!sm ? 50 : 40}/>
            </IconButton>
          </Grid>
          
          <Grid item xs={6} sm={8}>
            <Typography variant={!sm ? 'h5' : 'body2'} align="center">
              CrowdSec Companion <i>Beta {version}</i>
            </Typography>
          </Grid>

          <Grid item xs={3} sm={2} align="right" >
              <SignInButton/>
          </Grid>

        </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
}

  export default withWidth()(withFirebase(NavigationNonAuth)); 
  