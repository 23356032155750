import React, { Component } from 'react';
import { withFirebase } from '../Firebase';
import * as ROLES from '../../constants/roles';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';

import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import { TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';

import { useStyles } from './styles';

const INITIAL_STATE = {
  error: '',
  email: '',
  open: false,
  firstName: '',
  passwordOne: '',
  passwordTwo: '',
};

const SignUpPage = () => {
  
  const classes = useStyles();

  return (
    <div align="center" style={{padding: "70px 0"}}>
      <Card className={classes.root} >
        <CardContent align="center">
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            Sign Up
          </Typography>
          <SignUpForm classes={classes}/>
        </CardContent>
      </Card>
    </div>
  );
}

class SignUpFormBase extends Component {
  
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }
  
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onSubmit = event => {
    const { firstName, email, passwordOne } = this.state;
    const roles = {};
    roles[ROLES.USER] = ROLES.USER;
    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        // Create a user in your Firebase realtime database
        return this.props.firebase
          .user(authUser.user.uid)
          .set({
            civil: { firstName: firstName },
            email,
            roles
          });
      })
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.SIMPLE_USER);
      })
      .catch(error => {
        this.setState({ open: true, error });
      });
    event.preventDefault();
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ open: false });
  };
  
  render() {
    const {
      open,
      error,
      email,
      firstName,
      passwordOne,
      passwordTwo,
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      email === '' ||
      firstName === '';

    return (
        <form className={this.props.classes.form_root} onSubmit={this.onSubmit}>
          <TextField
            align="left"
            label="Name"
            id="firstName"
            name="firstName"
            value={firstName}
            variant="outlined"
            style={{maxWidth: 300}}
            onChange={this.onChange} />
          <TextField
            id="email"
            name="email"
            align="left"
            value={email}
            variant="outlined"
            label="Email Address"
            style={{maxWidth: 300}}
            onChange={this.onChange} />
          <TextField
            align="left"
            type="password"
            label="Password"
            id="passwordOne"
            variant="outlined"
            name="passwordOne"
            value={passwordOne}
            style={{maxWidth: 300}}
            onChange={this.onChange} />
          <TextField
            align="left"
            type="password"
            id="passwordTwo"
            variant="outlined"
            name="passwordTwo"
            value={passwordTwo}
            style={{maxWidth: 300}}
            label="Confirm Password"
            onChange={this.onChange} />
          <Button disabled={isInvalid} type="submit">
            Sign Up
          </Button>          

          <Snackbar
            anchorOrigin={ { vertical: 'bottom', horizontal: 'center' } }
            open={open}
            autoHideDuration={4000}
            onClose={this.handleClose}
            message={error.message}
            action={
              <>
                <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleClose}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </>
            }
          />   

        </form>
    );
  }

}

const SignUpLink = () => {

  const classes = useStyles();

  return (
    <Typography className={classes.title} color="textSecondary" gutterBottom>
      Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
    </Typography>
  );
  
}

const SignUpForm = withRouter(withFirebase(SignUpFormBase));

export default SignUpPage;
export { SignUpForm, SignUpLink };