import { makeStyles } from '@material-ui/core/styles';
import { PURPLE } from '../../../../../../constants/colors';

export const useStyles = makeStyles((theme) => ({
    
    normalText: {
        paddingTop: theme.spacing(1),
    },

    actionsContainer: {
        paddingBottom: theme.spacing(1),
    },

    holidayReason: {
        [theme.breakpoints.up('md')]: { minWidth: 200 },
    },

    root: {
        width: '100%',
        height: '100%',
    },

    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },

    closeButton: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
        color: theme.palette.grey[500],
    },

    resetContainer: {
        padding: theme.spacing(2),
        backgroundColor: '#fafafa',
    },

    textField:{
        backgroundColor: '#fff',
        marginTop: theme.spacing(3),
        [theme.breakpoints.up('md')]: { minWidth: 400 },
    },

    day: {
        paddingTop: '0.3rem',
        borderRadius: '0.5rem',
        paddingBottom: '0.3rem',
        backgroundColor: PURPLE,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        color: theme.palette.primary.contrastText,
    },

    step1:{
        width: '100%',
        margin: theme.spacing(2),
    },

    recapMessage: {
        [theme.breakpoints.up('xs')]: { fontSize: '0.9rem' },
        [theme.breakpoints.up('sm')]: { fontSize: '0.9rem' },
        [theme.breakpoints.up('md')]: { fontSize: '1.2rem' },

    },

    recapPanel: {
        height: '100%',
        borderRadius: '5px',
        margin: theme.spacing(1),
        border: '1px solid #CCC',
        backgroundColor: '#fdfdfd',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(1),
        //paddingLeft: theme.spacing(0.5),
        //paddingRight: theme.spacing(0.5),
        [theme.breakpoints.up('xs')]: { maxWidth: '100%' },
        [theme.breakpoints.up('sm')]: { maxWidth: '100%' },
        [theme.breakpoints.up('md')]: { maxWidth: '85%' },
    },

    daysOff: {
        borderRadius: "5px",
        backgroundColor: "#F5F5F5",
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },

    remaining: props => ({
        fontWeight: 'bold',
        borderRadius: "5px",
        backgroundColor: 'inherit',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        color: props.remainingDays <= 0 ? '#F00' : '#0A0',
    }),
    
    dialog: {
        [theme.breakpoints.up('xs')]: { padding: theme.spacing(0) },
        [theme.breakpoints.up('sm')]: { padding: theme.spacing(0) },
        [theme.breakpoints.up('md')]: { padding: theme.spacing(12) },
    },    

}));