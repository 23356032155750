import { makeStyles } from '@material-ui/core/styles';
import * as COLORS from '../../../../../constants/colors';

export const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
      backgroundColor: COLORS.VERY_LIGHT_PURPLE3,
    },

    tableRow: {
      backgroundColor: COLORS.WHITE,
    },
  
    chip: {
      margin: 1,
      backgroundColor: COLORS.VERY_LIGHT_PURPLE,
    },

    activeIcon: props => ({
      color: props?.contract.active ? COLORS.GREEN : COLORS.RED,
    }),

    rowText: {
      color: '#000'
    },
      
});
