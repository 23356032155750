import React from 'react';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { TextField, Grid } from '@material-ui/core';
import AccordionActions from '@material-ui/core/AccordionActions';

const FIELDS = {
  SECURITYNUMBER: "civil.id",
  LASTNAME: "civil.lastName",
  FIRSTNAME: "civil.firstName",
  BIRTHDATE: "civil.birthDate",
  BIRTHPLACE: "civil.birthPlace",
};

const LABELS = {
  LASTNAME: "Last name",
  FIRSTNAME: "First name",
  BIRTHDATE: "Birth date",
  BIRTHPLACE: "Birth place",
  SECURITYNUMBER: "Social Security Number",
  MUST_BE_FILLED: "This field is required",
};

export const GeneralInfosActionsPanel = (props) => {

  const { edit, error, onEdit, onSubmit, authUser } = props;
  
  return (
    <>
      <Divider />
      {'civil' in authUser && (
        <AccordionActions>
          <Button size="small" color="primary" onClick={onEdit}>
            {!edit ? "Edit" : "Cancel"}
          </Button>
          <Button size="small" color="primary" onClick={onSubmit} disabled={!!(!edit || error)}>
            Save
          </Button>
        </AccordionActions>)
      }
    </>
  );
}

const GenericTextField = (props) => {

  const { edit, type, value, onChange } = props;
  
  return (
    <TextField
      align="left"
      margin="dense"
      value={value}
      disabled={!edit}
      id={FIELDS[type]}
      variant="outlined"
      name={FIELDS[type]}
      label={LABELS[type]}
      onChange={onChange}
      error={value?.length === 0}
      helperText={value?.length === 0 ? LABELS.MUST_BE_FILLED : ""}
    />
  );
}

export const GeneralInfos = props => {

    const { authUser, edit } = props;

    const onChange = (event) => {
        const key = event.target.name;
        const sepIndex = key.indexOf(".");
        if ( sepIndex !== -1) {
          const key1 = key.substring(0, sepIndex);
          const key2 = key.substring(sepIndex + 1, key.length);
          authUser[ key1 ][ key2 ] = event.target.value;
        }
        else authUser[ key ] = event.target.value;
        props.updateUser({ authUser });
    };
    

    return (
      <>
        {'civil' in authUser && (
          <div>
                
            <Grid container direction="row" spacing={3} >
              <Grid item xs={12} sm={props.highCondition(authUser) ? 4 : 12}>
                {/* FIRST NAME */}
                <GenericTextField
                  edit={edit}
                  type="FIRSTNAME"
                  onChange={onChange}
                  value={authUser?.civil?.firstName || ""}
                />                
              </Grid>

              {props.highCondition(authUser) && (
                <Grid item xs={12} sm={4}>
                  {/* LAST NAME */}
                  <GenericTextField
                    edit={edit}
                    type="LASTNAME"
                    onChange={onChange}
                    value={authUser?.civil?.lastName || ""}
                  />                
                </Grid>
              )}
            </Grid>

            <Grid container direction="row" spacing={3}>

              {props.highCondition(authUser) && (
                <Grid item xs={12} sm={4}>
                  {/* BIRTH DATE */}
                  <GenericTextField
                    edit={edit}
                    type="BIRTHDATE"
                    onChange={onChange}
                    value={authUser?.civil?.birthDate || ""}
                  />                
                </Grid>
              )}

              {props.highCondition(authUser) && (
                <Grid item xs={12} sm={4}>
                  {/* BIRTH PLACE */}
                  <GenericTextField
                    edit={edit}
                    type="BIRTHPLACE"
                    onChange={onChange}
                    value={authUser?.civil?.birthPlace || ""}
                  />                
                </Grid>
              )}

              {props.highCondition(authUser) && (
                <Grid item xs={12} sm={4}>
                  {/* SECU NUMBER */}
                  <GenericTextField
                    edit={edit}
                    onChange={onChange}
                    type="SECURITYNUMBER"
                    value={authUser?.civil?.id || authUser?.id || ""} 
                  />                
                </Grid>
              )}

            </Grid>
          </div>
          )}
      </>
    );
}

//export default GeneralInfos;