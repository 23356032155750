import QuarterRow from './quarterRow';
import Grid from '@material-ui/core/Grid';
import React, { useCallback } from 'react';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import { ITEM, CATEGORY } from '../../constants';
import TextField from '@material-ui/core/TextField';
import WarningIcon from '@material-ui/icons/Warning';
import Typography from '@material-ui/core/Typography';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InputAdornment from '@material-ui/core/InputAdornment';
import AccordionActions from '@material-ui/core/AccordionActions';
import { CircularProgressWithLabel } from '../../../../utils/GenericComponents/progressBar';

import { Accordion, AccordionDetails, AccordionSummary, useStyles } from './styles';

const TextFieldQuarterBPBudget = (props) => {

    const { year, quarter } = props;
    const { classes, isManager } = props;
    const { edition, setEdition, textfield, setTextfield } = props;
    const { businessUnit, setBusinessUnit, saveBudgetInfos } = props;

    const id = `quarter-budget-bp-${year.value}-Q${quarter.value}`;

    const onFocus = useCallback(() => setTextfield(id), [id, setTextfield]);
    const onClick = useCallback((event) => event.stopPropagation(), []);

    const onBlur = useCallback(() => {
        setTextfield("");
        edition && saveBudgetInfos();
        setEdition(false);
    }, [edition, saveBudgetInfos, setEdition, setTextfield]);

    const onKeyDown = useCallback((event) => {
        if (event.keyCode === 13) {
            setTextfield("");
            saveBudgetInfos();
            setEdition(false);
        }
    }, [saveBudgetInfos, setEdition, setTextfield]);

    const onChangeBPBudget = useCallback((event) => {
        event.stopPropagation();
        const value = event.target.value;
        if (value >= 0) {
            businessUnit[year.value][quarter.value].bp_budget = parseInt(value || 0);
            setEdition(true);
            setBusinessUnit({ ...businessUnit });
        }
    }, [businessUnit, setBusinessUnit, year, quarter, setEdition]);

    const valueToDisplay = (quarter.bp_budget === undefined || quarter.bp_budget === null) ? "" : quarter.bp_budget;
    const remaining = quarter.bp_budget - quarter.forecast;

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title={`remaining budget: ${remaining.toLocaleString()}€`} placement="left" arrow>
                <Typography variant="body2" style={{ marginTop: 0, marginRight: 5, color: remaining < 0 ? 'red' : 'black'  }}>
                    BP budget
                </Typography>
            </Tooltip>
            <TextField
                id={id}
                type="tel"
                size="small"
                onBlur={onBlur}
                onFocus={onFocus}
                onClick={onClick}
                disabled={!isManager}
                onKeyDown={onKeyDown}
                value={valueToDisplay}
                onChange={onChangeBPBudget}
                inputProps={{ style: { textAlign: 'right' } }}
                inputRef={input => textfield === id && input && input.focus()}
                InputProps={{
                    disableUnderline: true,
                    classes: {
                        root: classes.cellTextFieldGeneric,
                        focused: classes.cellTextFieldGenericFocused,
                    },
                    endAdornment: <InputAdornment position="end">€</InputAdornment>,
                    startAdornment: remaining < 0 ? <InputAdornment position="start"><WarningIcon className={classes.warningIcon} /></InputAdornment> : null,
                }}
            />
        </div>
    )    
};


const YearRow = (props) => {
    const { year, isManager, businessUnit } = props;
    const [edition, setEdition] = React.useState(false);
    const [textfield, setTextfield] = React.useState("");
    const { saveBudgetInfos, setSelectedQuarterCallback } = props;
    const [expanded, setExpanded] = React.useState(parseInt(year.value) !== (new Date().getFullYear()) ? `Q1` : `Q${Math.ceil((new Date().getMonth() + 1) / 3)}`);
    
    const classes = useStyles({ edition });

    const handleChange = useCallback((panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    }, [setSelectedQuarterCallback]);

    const handleAddCategory = useCallback((quarter) => {
        businessUnit[year.value][quarter][`${CATEGORY}${Date.now()}`] = { description: "New category" };
        saveBudgetInfos();
    }, [businessUnit, year, saveBudgetInfos]);

    const computeExpensesCategory = useCallback((category, type) => {
        return Object.keys(category)
        .filter(item => item.indexOf(ITEM) > -1)
        .reduce((acc, item) =>  acc + category[item].month1[type] + category[item].month2[type] + category[item].month3[type], 0);
    }, []);

    const computeExpenses = useCallback((quarter, type) => {
        return Object.keys(quarter)
        .filter(category => category.indexOf(CATEGORY) > -1)
        .reduce((acc, category) => acc + computeExpensesCategory(quarter[category], type), 0);
    }, [computeExpensesCategory]);

    const handleCopyFromPrevious = useCallback((quarter) => {
        const previousQuarter = quarter === "Q1" ? "Q4" : `Q${parseInt(quarter[1]) - 1}`;
        Object.keys(businessUnit[year.value][previousQuarter]).forEach((category, index) => {
            if (category.indexOf(CATEGORY) > -1)
                businessUnit[year.value][quarter][`${CATEGORY}${Date.now() + index}`] = { ...businessUnit[year.value][previousQuarter][category] };
        });
        saveBudgetInfos();
    }, [businessUnit, year, saveBudgetInfos]);

    const quarters = React.useMemo(() => Object.keys(year).map(quarterKey => {
        if (quarterKey.indexOf("Q") > -1) {
            const realized = computeExpenses(year[quarterKey], "real");
            const forecast = computeExpenses(year[quarterKey], "forecast");
            return {...year[quarterKey], value: quarterKey, realized, forecast};
        }
        return null;
    }).filter(quarter => quarter), [year, computeExpenses]);

    const ToolsButtons = (props) => {
        const { quarter } = props;
        if (!isManager) return null;
        return (
            <>
                <Divider />
                <AccordionActions>
                    <Tooltip title="Copy from previous" placement="top" arrow>
                        <Button size="small" onClick={() => handleCopyFromPrevious(quarter.value)} startIcon={<FileCopyIcon />} >
                            Copy from previous
                        </Button>
                    </Tooltip>
                    <Divider orientation="vertical" flexItem />
                    <Tooltip title="Add Category" placement="top" arrow>
                        <Button size="small" onClick={() => handleAddCategory(quarter.value)} startIcon={<AddCircleIcon />} >
                            Add Category
                        </Button>
                    </Tooltip>
                </AccordionActions>
            </>
        );
    };

    return (
        <div style={{ width: '100%' }}>
            {quarters.map(quarter => (
                <Accordion
                    key={quarter.value}
                    expanded={expanded === quarter.value}
                    onChange={handleChange(quarter.value)}
                    style={{ border: `${expanded === quarter.value ? '3px solid #3fb551' : '1px solid #D9D9D9'}`, borderRadius: 5 }}
                >
                    <AccordionSummary
                        id={`${quarter.value}-header`}
                        expandIcon={<ExpandMoreIcon />}
                        IconButtonProps={{edge: 'start'}}
                        aria-controls={`${quarter.value}-content`}
                    >
                        <Grid container spacing={0} alignItems="center" justifyContent='center'>
                            <Grid item xs={3}>
                                <Typography variant='body1' gutterBottom style={{ fontWeight: expanded === quarter.value ? 'bold' : 'normal' }}>
                                    {quarter.value}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <TextFieldQuarterBPBudget
                                    {...props}
                                    classes={classes}
                                    quarter={quarter}
                                    edition={edition}
                                    textfield={textfield}
                                    setEdition={setEdition}
                                    setTextfield={setTextfield}
                                />
                            </Grid>
                            <Grid item xs={1} align="right">
                                <CircularProgressWithLabel
                                    size={38}
                                    level1={100.1}
                                    normal={false}
                                    title={`Allocated budget`}
                                    value={(quarter.forecast / (quarter?.bp_budget || 1)) * 100}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <Divider orientation="vertical" flexItem style={{ backgroundColor: 'black', height: 30}} />
                            </Grid>
                            <Grid item xs={3}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography variant="body2" style={{ marginRight: 5 }}>Realized:</Typography>
                                    <span style={{ width: 100, textAlign: 'right'}}>{Math.round(quarter.realized).toLocaleString()}€</span>
                                </div>
                            </Grid>
                            <Grid item xs={1} align="right">
                                <CircularProgressWithLabel
                                    size={38}
                                    normal={false}
                                    title={`Consumed budget`}
                                    value={(quarter.realized / (quarter?.bp_budget || 1)) * 100}
                                />
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    {<AccordionDetails>
                        <QuarterRow {...props} quarter={quarter} edition={edition} />
                    </AccordionDetails>}

                    <ToolsButtons quarter={quarter} />

                </Accordion>
            ))}
        </div>
    );
}

export default YearRow;
