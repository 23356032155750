import * as COLORS from '../../../constants/colors';
import { makeStyles } from '@material-ui/core/styles';
import { divRoot, listPanel, rightPanel } from '../../utils';


export const useStyles = makeStyles(theme => ({

    divRoot: divRoot({theme, bar: false}),
    listPanel: listPanel({theme}),
    rightPanel: rightPanel({theme}),
    
    backdrop: {
      color: COLORS.PURPLE,
      backgroundColor: COLORS.WHITE,
      zIndex: theme.zIndex.drawer + 3,
    },
  
}));
  