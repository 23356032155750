import { makeStyles } from '@material-ui/core/styles';
import { PURPLE } from '../../../../../constants/colors';

export const useStyles = makeStyles((theme) => ({
  
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  paper: {
    textAlign: 'center',
    boxShadow: theme.shadows[5],
    border: `2px solid ${PURPLE}`,
    padding: theme.spacing(2, 4, 3),
    backgroundColor: theme.palette.background.paper,
  },

}));
