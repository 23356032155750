import { boxShadow } from '../../../../utils';
import { makeStyles } from '@material-ui/core/styles';
import { PURPLE, VERY_LIGHT_GRAY3 } from '../../../../../constants/colors';

export const useStyles = makeStyles(theme => ({

    spacer: {
        flexGrow: 1,
    },

    cardHeader: {
        //width: '100%',
    },

    subheader: {
        fontSize: '0.9em',
    },

    avatar: props => ({
        width: props?.avatarSize || 35,
        height: props?.avatarSize || 35,
        backgroundColor: props.avatarBg || PURPLE,
    }),

    cardActions: {
        //flexGrow: 1,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },

    cardContent: props => ({
        //width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flex: props.noflex ? '' : '1 0 auto',
    }),
    
    cardTitle : {
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.1rem'
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.1vw'
        },
        color: PURPLE,
    },

    card: {
        height: '100%',
        display: 'flex',
        boxShadow: boxShadow,
        borderTop: `5px solid ${PURPLE}`,
        flexDirection: 'column',
        "&:hover": {
            backgroundColor: VERY_LIGHT_GRAY3
        },
    },

}));
