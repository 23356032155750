import React from 'react';
import * as ROLES from '../../constants/roles';
import EmployeePageBase from './EmployeePageBase';
import withWidth from '@material-ui/core/withWidth';
import { AuthUserContext, withAuthorization } from '../Session';

import { useStyles } from './styles';

const EmployeePage = props => {
  
  const classes = useStyles();

  return(
      <AuthUserContext.Consumer>
      { authUser => <EmployeePageComponent {...props} employee={authUser} classes={classes}/>}
      </AuthUserContext.Consumer>
  );
}

const condition = authUser => authUser && (!!authUser.roles[ROLES.ADMIN] || !!authUser.roles[ROLES.EMPLOYEE] || !!authUser.roles[ROLES.HR]);

const EmployeePageComponent = withAuthorization(condition)(EmployeePageBase);

export default withWidth()(EmployeePage);