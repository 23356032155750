import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import { withFirebase } from '../Firebase';
import Button from '@material-ui/core/Button';
import * as ROUTES from '../../constants/routes';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import { TextField, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CardContent from '@material-ui/core/CardContent';

import { useStyles } from './styles';

const PasswordForgetPage = () => {
  
  const classes = useStyles();

  return (
    <Grid container spacing={1} justifyContent="center" style={{ padding: "70px 10px" }}>
      <Grid item xs={12} sm={4} >
      <Card className={classes.root} >
        <CardContent align="center">
          <Typography className={classes.title} color="textSecondary" gutterBottom >
            Forgot your password?
          </Typography>
          <PasswordForgetForm classes={classes}/>
        </CardContent>
      </Card>
      </Grid>
    </Grid>
  );
}

const INITIAL_STATE = {
  email: '',
  open: false,
  error: null,
};
class PasswordForgetFormBase extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onChange = event => this.setState({ [event.target.name]: event.target.value });

  handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    this.setState({ open: false });
  };

  onSubmit = event => {
    const { email } = this.state;
    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.setState({ open: true });
      })
      .catch(error => {
        this.setState({ error });
      });
    event.preventDefault();
  };


  render() {

    const { email, error, open } = this.state;
    const isInvalid = email === '';

    return (
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12}>
          <form className={this.props.classes.form_root} onSubmit={this.onSubmit}>
            <TextField
              id="email"
              align="left"
              name="email"
              value={email}
              margin="dense"
              variant="outlined"
              label="Email Address"
              onChange={this.onChange}
              style={{maxWidth: 300}}
            />
            <Button disabled={isInvalid} type="submit" >
              Reset My Password
            </Button>
            {error && <p>{error.message}</p>}
          </form>
        </Grid>

        <Snackbar
          open={open}
          autoHideDuration={3000}
          onClose={this.handleClose}
          message="A new password has been sent to you"
          anchorOrigin={ { vertical: 'top', horizontal: 'center' } }
          action={
            <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />

      </Grid>
    );
  }
}

const PasswordForgetLink = () => (
  <p>
    <Link style={{fontSize:11, color:'#888'}} to={ROUTES.PASSWORD_FORGET}><i>Forgot Password ?</i></Link>
  </p>
);

export default PasswordForgetPage;

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };