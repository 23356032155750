import React from 'react';
import { Grid } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { PLAN } from '../../contractDescription';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import TableCell from '@material-ui/core/TableCell';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { useStyles, YellowSwitch } from './styles';

const HistoryContractCell = ({sm, children, align}) => {
    return(
      <TableCell align={align}>
        <Typography component={'span'} variant={sm ? "caption" : "body2"} >{children}</Typography>
      </TableCell>
    );
};

const HistoryContractRow = (props) => {

    const classes = useStyles();
    const { contract, onUpdateContract } = props;
    const [active, setActive] = React.useState(contract.active);
    const [clientName, setClientName] = React.useState(contract?.clientName || '');
    const [plan, setPlan] = React.useState(PLAN[contract?.plan]?.id || PLAN.FREE.id);
    const [description, setDescription] = React.useState(contract?.description || '');

    const handleChangePlan = (event) => setPlan(event.target.value);
    const handleChangeActive = (event) => setActive(event.target.checked);
    const handleChangeClientName = (event) => setClientName(event.target.value);
    const handleChangeDescription = (event) => setDescription(event.target.value);

    const onSubmit = () => {
        console.log(active, clientName, plan, description);
        onUpdateContract({
            ...contract,
            active,
            clientName,
            plan: Object.values(PLAN).find(p => p.id === plan).name,
            description,
        });
    }

    const checkIfChanged = () => {
        return (
            active !== contract.active ||
            clientName !== contract.clientName ||
            plan !== PLAN[contract?.plan]?.id ||
            description !== contract.description
        );
    }


    const sm = true;
    return (
        <TableRow >
            <HistoryContractCell sm={sm} align={'left'}>
                <Grid container spacing={4} justifyContent="center" alignItems='center'>
                    <Grid item xs={9}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    align="left"
                                    type='text'
                                    margin="dense"
                                    fullWidth={true}
                                    variant="outlined"
                                    value={clientName}
                                    label="Client name"
                                    name={"client-name"}
                                    onChange={handleChangeClientName}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    multiline
                                    align="left"
                                    type='text'
                                    margin="dense"
                                    fullWidth={true}
                                    variant="outlined"
                                    value={description}
                                    name={"projet-description"}
                                    label="Project Description"
                                    onChange={handleChangeDescription}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id="crowdsec-choose-plan-label">Plan</InputLabel>
                                        <Select
                                            labelId="crowdsec-choose-plan-label"
                                            id="crowdsec-choose-plan"
                                            value={plan}
                                            onChange={handleChangePlan}
                                        >
                                            <MenuItem value={1}>{PLAN.FREE.name}</MenuItem>
                                            <MenuItem value={2}>{PLAN.PREMIUM.name}</MenuItem>
                                            <MenuItem value={3}>{PLAN.PRO.name}</MenuItem>
                                        </Select>
                                    <FormHelperText>Choose appropriate plan</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<YellowSwitch checked={active} onChange={handleChangeActive} name="checkedActive"/>}
                                    label="Active"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Divider className={classes.divider}/>

                <div style={{ float: 'right'}}>
                    <Grid component="div" container alignItems="center" spacing={1}>
                        <Grid item>
                            <Button disabled={!checkIfChanged()} size="small" variant="contained" color="primary" onClick={onSubmit}>save</Button>
                        </Grid>
                    </Grid>
                </div>

            </HistoryContractCell>
        </TableRow>
    );
};

export default HistoryContractRow;