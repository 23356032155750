import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { PURPLE, GRAY2 } from '../../../../../../constants/colors';

export const CrowdSecCheckbox = withStyles({
    root: {
      color: PURPLE,
      '&$checked': {
        color: PURPLE,
      },
      '&$disabled': {
        color: GRAY2,
      },
    },
    checked: {},
    disabled: {},
})((props) => <Checkbox color="default" {...props} />);
  