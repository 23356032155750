import React from 'react';
import Chart from "react-google-charts";
import * as constants from '../constants';
import * as utils from '../../../utils/index';
import Switch from '@material-ui/core/Switch';
import LockIcon from '@material-ui/icons/Lock';
import StopIcon from '@material-ui/icons/Stop';
import PreMoneyInfos from '../preMoneyInfoPanel';
import { Grid, TextField } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import * as COLORS from '../../../../constants/colors';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { Tooltip, Typography } from '@material-ui/core';
import EvStationIcon from '@material-ui/icons/EvStation';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import ExposureZeroIcon from '@material-ui/icons/ExposureZero';
import FormControlLabel from '@material-ui/core/FormControlLabel';


const getTotalShares = (steeringObj, round, roundSharesQty) => {

    const roundList = utils.getSortedRoundList(steeringObj);
  
    let totalActualShares = roundSharesQty;
    roundList.forEach(element => {
        if (element.order < round.order) {
            totalActualShares += parseInt(steeringObj.ESOP[element.round]?.quantity || 0);
            totalActualShares += parseInt(steeringObj.SHARES[element.round].quantity || 0);
        }
    });
    
    const values = [];
    values.push(['Round', 'percent']);
    roundList.forEach(element => {
        if (element.order < round.order) {
            let shares = parseInt(steeringObj.SHARES[element.round].quantity || 0);
            shares += parseInt(steeringObj.ESOP[element.round]?.quantity || 0);
            values.push([element.round, {v: shares, f: `${utils.roundPercent(shares, totalActualShares)}% | ${shares.toLocaleString()}`}]);
        }
    });
    values.push([round.round, {v: roundSharesQty, f: `${utils.roundPercent(roundSharesQty, totalActualShares)}% | ${roundSharesQty.toLocaleString()}`}]);

    return values;
}

const CapitalChart = ({steeringObj, round, roundSharesQty}) => {

    const slicesColors = {
        0: { color: COLORS.PURPLE },
        1: { color: COLORS.YELLOW },
        2: { color: COLORS.VERY_LIGHT_PURPLE },
        3: { color: COLORS.LIGHT_PURPLE },
        4: { color: COLORS.VERY_LIGHT_PURPLE2 },
        5: { color: COLORS.DARK_PURPLE },
    };

    slicesColors[round.order - 1] = { color: COLORS.GREEN, offset: 0.2 };

    return(
        <div style={{zIndex: 2}}>
            <Chart
                chartType="PieChart"
                loader={<div>Loading Chart...</div>}
                data={getTotalShares(steeringObj, round, roundSharesQty)}
                options={{
                    pieHole: 0.24,
                    legend: 'none',
                    slices: slicesColors,
                    title: 'Capital allocation',
                    backgroundColor: { fill:'transparent' },
                    tooltip: { showColorCode: true, text: 'value' },
                }}
                rootProps={{ 'round-capital': '1' }}
            />
        </div>
    );
};  

const PostMoneyInfos = ({totalNumberOfShares, sharePrice}) => {
    return (
        <div style={{borderRadius: 5, background: COLORS.VERY_LIGHT_GRAY, padding: 8}} align="center">
            <Typography variant="h6" gutterBottom>
                {`Value post money = ${Math.round(totalNumberOfShares * sharePrice).toLocaleString()} €`}
            </Typography>
        </div>
    );
};

const RoundDetailsPanel = (props) => {

    const {lock, started, funded, partners, round, rounds, onChange, steeringObj, esopQuantity, handleSwitch} = props;

    const order = round.order;

    let precRoundName = "None"
    let totalNumberOfSharesFD = 0;
    let precRoundSharePrice = 0.01;
    let precRoundPostMoneyValue = 0;

    if (order > 1) {
        const precRound = rounds.filter(r => r.order === order - 1);
        precRoundName = precRound[0].round;
        precRoundSharePrice = precRound[0].price;
        precRoundPostMoneyValue = precRound[0].postMoney;

        const allPrecRounds = rounds.filter(r => r.order < order);
        allPrecRounds.forEach(r => {
            totalNumberOfSharesFD += r.quantity;
            totalNumberOfSharesFD += steeringObj.ESOP?.[r.round]?.quantity || 0;
        });
    }

    const sharePrice = (isNaN(round.multiplier * precRoundSharePrice)) ? 1 : (round.multiplier * precRoundSharePrice * Math.pow(10,5)) / Math.pow(10,5);
    const sharesQuantity = (round.funded) ? (Math.round(round.finalRaised / sharePrice)) : (Math.round(round.target / sharePrice));
    const preMoneyValue = (isNaN(round.multiplier * precRoundPostMoneyValue)) ? 0 : Math.round(round.multiplier * precRoundPostMoneyValue) ;
    const raisedMoney = utils.getInvestIntentionsForRound(partners, round.round) + utils.getCompletedInvestmentsForRound(partners, round.round);

    round.price = sharePrice;
    round.quantity = sharesQuantity;
    totalNumberOfSharesFD += (esopQuantity + sharesQuantity);

    const percentBSPCE = utils.roundPercent(esopQuantity, totalNumberOfSharesFD);
    const percentSHARES = utils.roundPercent(sharesQuantity, totalNumberOfSharesFD);

    round.postMoney = totalNumberOfSharesFD * sharePrice;

    return(
        <Grid container spacing={2}>

            <Grid item xs={12}>
                <PreMoneyInfos
                    disabled={!lock}
                    onChange={onChange}
                    multiplier={round.multiplier}
                    precRoundName={precRoundName}
                    preMoneyValue={preMoneyValue}
                    precRoundPostMoneyValue={precRoundPostMoneyValue}
                />
            </Grid>


            <Grid item xs={3}>

                <Grid container spacing={2} justifyContent="flex-start" style={{paddingLeft: 20}}>

                    <Grid item xs={12}>
                        <TextField
                            align="left"
                            type="number"
                            name="target"
                            label="Target"
                            margin="dense"
                            disabled={!lock}
                            variant="outlined"
                            onChange={onChange}
                            value={round.target}
                            error={round.target <= 0}
                            helperText={(round.target.toString().length === 0) ? "Must be filled" : (round.target <= 0) ? "Cannot be negative" : ""}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={1} justifyContent="flex-start">
                            <Grid item wx={11} >
                                <TextField
                                    align="left"
                                    type="number"
                                    margin="dense"
                                    disabled={!lock}
                                    variant="outlined"
                                    onChange={onChange}
                                    value={esopQuantity}
                                    error={esopQuantity < 0}
                                    label="Nbr. of new BSPCE"
                                    name={constants.ESOP_QUANTITY}
                                />
                            </Grid>
                            <Grid item wx={1} style={{paddingTop : 20}}>
                                <Typography gutterBottom variant="caption" color="textSecondary"><i>({percentBSPCE}%)</i></Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            align="left"
                            type="number"
                            margin="dense"
                            name="minimum"
                            disabled={!lock}
                            variant="outlined"
                            onChange={onChange}
                            value={round.minimum}
                            label="Minimum invest."
                            error={round.minimum <= 0}
                            helperText={(round.minimum.toString().length === 0) ? "Must be filled" : (round.minimum <= 0) ? "Cannot be negative" : ""}
                        />
                    </Grid>
                </Grid>
            </Grid>
            
            <Grid item xs={3}>

                <Grid container spacing={2} justifyContent="flex-start" style={{paddingLeft: 20}}>

                    <Grid item xs={12}>
                        <TextField
                            align="left"
                            margin="dense"
                            type="number"
                            disabled={true}
                            variant="outlined"
                            label="Raised money"
                            onChange={onChange}
                            value={raisedMoney}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={1} justifyContent="flex-start">
                            <Grid item wx={11} >
                                <TextField
                                    align="left"
                                    type="number"
                                    margin="dense"
                                    disabled={true}
                                    name="quantity"
                                    variant="outlined"
                                    onChange={onChange}
                                    value={sharesQuantity}
                                    label="Nbr. of new shares"
                                />
                            </Grid>
                            <Grid item wx={1} style={{paddingTop : 20}}>
                                <Typography gutterBottom variant="caption" color="textSecondary"><i>({percentSHARES}%)</i></Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            align="left"
                            name="price"
                            label="Price"
                            type="number"
                            margin="dense"
                            disabled={true}
                            variant="outlined"
                            onChange={onChange}
                            value={(Math.round(sharePrice * Math.pow(10,5)) / Math.pow(10,5))}
                        />
                    </Grid>
                </Grid>

            </Grid>

            <Grid item xs={4}>
                 <CapitalChart steeringObj={steeringObj} round={round} roundSharesQty={esopQuantity + sharesQuantity}/>
            </Grid>

            <Grid item xs={2}>
                <Grid container spacing={2} align="center">
                    <Grid item xs={12}>
                        <FormGroup row >
                            <LockIcon style={{marginTop: 4, marginRight: 10}}/>
                            <Tooltip title={`Lock / Unlock round`}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={lock}
                                            color="primary"
                                            onChange={handleSwitch}
                                            name={constants.SWITCH_LOCK}
                                        />   
                                    }
                                    label={<LockOpenIcon />}
                                />
                            </Tooltip>            
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <FormGroup row >
                            <StopIcon style={{marginTop: 4, marginRight: 10}}/>
                            <Tooltip title={`Start round`}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            color="primary"
                                            checked={started}
                                            onChange={handleSwitch}
                                            name={constants.SWITCH_START}
                                        />   
                                    }
                                    label={<PlayArrowIcon />}
                                />
                            </Tooltip>            
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <FormGroup row >
                            <ExposureZeroIcon style={{marginTop: 4, marginRight: 10}}/>
                            <Tooltip title={`Round funded`}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            color="primary"
                                            checked={funded}
                                            onChange={handleSwitch}
                                            name={constants.SWITCH_FUND}
                                        />   
                                    }
                                    label={<EvStationIcon />}
                                />
                            </Tooltip>            
                        </FormGroup>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <PostMoneyInfos sharePrice={sharePrice} totalNumberOfShares={totalNumberOfSharesFD}/>
            </Grid>

        </Grid>
    );
};

export default RoundDetailsPanel;