import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import React, { useState, useCallback } from 'react';
import IconButton from '@material-ui/core/IconButton';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { Grid, Tooltip, Typography } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';

import { useStyles } from './styles';

const InfoBox = props => {
  
  const {name, icon, title, value, disabled, tooltipInfos, copyClipboard, changeUserValue} = props;
  
  const infosBoxClasses = useStyles({ disabled });
  const [isHovered, setIsHovered] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleMouseOver = () => setIsHovered(true);
  const handleMouseOut = () => setIsHovered(false);

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(value);
    setOpenSnackbar(true);
  }, [value]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setOpenSnackbar(false);
  };
  
  const handlePress = (event) => {
    event.stopPropagation();
    changeUserValue(event.target.name, event.target.value);
  }

  const getEndIcon = useCallback(() => {
    return (
      <>
        {tooltipInfos
          ? <Tooltip title={tooltipInfos} arrow>
              <InputAdornment position="end">{icon}</InputAdornment>
            </Tooltip>
          : <InputAdornment position="end">{icon}</InputAdornment>
        }
      </>
    );
  }, [icon, tooltipInfos]);

  const getCopyIcon = useCallback(() => {
      return isHovered ? (
        <IconButton onClick={handleCopy} className={infosBoxClasses.iconFileCopy}>
          <FileCopyIcon style={{ fontSize: 14 }} />
        </IconButton>
      ) : null;
  }, [handleCopy, infosBoxClasses.iconFileCopy, isHovered]);


  return (
    <> 
      <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={2}>
        <Grid item xs={4}>
          <Typography variant="body2" color="textPrimary" gutterBottom className={infosBoxClasses.label}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          {value !== null && (
            <div onMouseOver={handleMouseOver} onMouseLeave={handleMouseOut}>
              <TextField
                id={name}
                name={name}
                value={value}
                disabled={disabled}
                onChange={handlePress}
                error={value.length === 0}
                className={infosBoxClasses.textField}
                InputProps={{
                  endAdornment: (
                    <>
                      {icon ? getEndIcon() : null}
                      {copyClipboard ? getCopyIcon() : null}
                    </>
                  ),
                  classes: { input: infosBoxClasses.resize },
                }}
              />
            </div>
          )}
          
          { value === null && props.children }
        </Grid>
      </Grid>

      <Snackbar
        open={openSnackbar}
        onClose={handleClose}
        autoHideDuration={2000}
        message="Copied to clipboard"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />

    </>
  );
}

export default InfoBox;