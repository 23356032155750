import React from 'react';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import { PURPLE } from '../../../../../constants/colors';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 0,
        display: 'flex',
        flexWrap: 'wrap',
        listStyle: 'none',
        justifyContent: 'left',
        padding: theme.spacing(0.5),
      },
    chip: {
        margin: theme.spacing(0.5),
    },
}));

const LangTags = (props) => {

    const classes = useStyles();

    const { currentNews } = props;

    return (
        <Box component="ul" className={classes.root}>
        {currentNews.lang.map(chip => {
          return (
            <li key={currentNews.key + chip}>
              <Chip
                  size="small"
                  label={chip}
                  className={classes.chip}
                  id={currentNews.key + chip}
                  name={currentNews.key + chip}
                  style={{ color: 'white', backgroundColor: PURPLE }}
              />
            </li>
          );
        })}
      </Box>
    );
};

export default LangTags;
