import React from 'react';
import NewsRating from './rating';
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import LinkIcon from '@material-ui/icons/Link';
import EditIcon from '@material-ui/icons/Edit';
import { Grid, Tooltip } from '@material-ui/core';
import CardMedia from '@material-ui/core/CardMedia';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { IMAGE, VIDEO } from '../marketingPanel/constants';
import { useIntersectionObserver } from "./interactionObserver";

import CircularProgress from '@material-ui/core/CircularProgress';

import { useStyles } from './styles';

const DEFAULT_IMG = 'https://via.placeholder.com/300';

const ClickableMediaCard = (props) => {

  const {currentNews, firebase, sendToEdit, editable, rating} = props;

  const target = React.useRef();
  const classes = useStyles({ratio: 53});
  const [image, setImage] = React.useState(DEFAULT_IMG);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const controller = React.useRef(new AbortController());
  const [isVisible, setIsVisible] = React.useState(false);


  React.useEffect(() => {
    const currentController = controller.current;
    return () => currentController.abort();
  }, []);

  const onIntersect = async ([{ isIntersecting }], observerElement) => {
    if (isIntersecting) {
      setIsVisible(true);
      if (currentNews?.image && image === DEFAULT_IMG) {
        firebase.getImageAsync(currentNews.image, controller.current.signal)
        .then(url => {
          setImage(url);
          setIsLoaded(true);
        })
        .catch(err => {});
      }
      observerElement.unobserve(target.current);
    }
  }

  useIntersectionObserver({ target, onIntersect });

  const onClickNews = () => window.open(currentNews.url, '_blank');

  const transformUrl = (url) => {
    if (url.includes('youtube')) {
      return url.replace('watch?v=', 'embed/');
    }
    //url.replace('youtube.com', 'youtube-nocookie.com');
    return url;
  };

  if (!isVisible)
    return <div ref={target} ><center><CircularProgress size={20} /></center></div>;

  const upperRightIcon = () => {
    if (isLoaded && editable) {
      return (
        <IconButton aria-label="settings" onClick={() => sendToEdit(currentNews)}>
          <EditIcon />
        </IconButton>
      )
    }
    if (isLoaded && !editable)
      return null;
    return <CircularProgress size={20} /> 
  }


  return (
    <div ref={target} className={classes.refDiv}>
      <Card className={classes.card}>

        <CardHeader
          className={classes.cardHeader}
          avatar={
            <Tooltip title={currentNews?.media || "unknown"}>
              <Avatar aria-label="recipe" className={classes.avatar}>
                {currentNews?.media?.substring(0,3).toUpperCase() || '?'}
              </Avatar>
            </Tooltip>
          }
          action={upperRightIcon()}
          title={<strong>{currentNews?.title}</strong>}
          subheader={<span style={{fontSize: 9}}>{new Date(currentNews?.timestamp).toDateString()}</span>}
        />

        {currentNews?.type === IMAGE && (
          <CardMedia
            image={image}
            className={classes?.media}
            title={currentNews?.title}
          />
        )}
        {currentNews?.type === VIDEO && (
          <CardContent className={classes?.videoContent} >
            <iframe
              width="100%"
              height="100%"
              title="video"
              frameBorder="0"
              allowFullScreen
              src={transformUrl(currentNews?.url)}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            />
          </CardContent>
        )}
        <div className={classes.spacer} />
        <CardActions className={classes.cardActions}>
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item>
              {rating && <NewsRating value={currentNews?.feedback || 0}/>}
            </Grid>
            {currentNews.url && (
            <Grid item>
              <Tooltip title={"link"}>
                <IconButton aria-label="link" onClick={onClickNews}>
                  <LinkIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
          </Grid>
        </CardActions>

      </Card>
    </div>
  );
}

export default ClickableMediaCard;