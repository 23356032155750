import React from 'react';
import { Link } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { WHITE } from '../../../../constants/colors';
import * as ROUTES from '../../../../constants/routes';
import IconButton from '@material-ui/core/IconButton';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import NotificationsIcon from '@material-ui/icons/Notifications';
import StyledBadge from '../../../utils/GenericComponents/styledBadge';
import MemberAvatar from '../../../utils/GenericComponents/memberAvatar';

import { useStyles } from './styles';

const AVATAR_SIZE = 3.5;

const menuId = 'primary-menu';

const MenuDesktop = props => {

    const {isMenuOpen, anchorEl, handleMenuClose, doSignOut, authUser} = props;

    return (
        <Menu
            keepMounted
            id={menuId}
            open={isMenuOpen}
            anchorEl={anchorEl}
            onClose={handleMenuClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
            <MenuItem onClick={handleMenuClose} to={ROUTES.ACCOUNT} component={Link}>
                <IconButton
                    color="inherit"
                    aria-haspopup="true"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                >
                    <MemberAvatar member={authUser} size={AVATAR_SIZE}/>
                </IconButton >
                Account
            </MenuItem>

            <MenuItem onClick={doSignOut}>
                <IconButton aria-label="exit" color="inherit">
                    <ExitToAppIcon />
                </IconButton>
                Signout
            </MenuItem>
            
        </Menu>
    );
}
  

const NavigationAuthSectionDesktop = props => {

    const { notifications, authUser } = props;
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const isMenuOpen = Boolean(anchorEl);

    const handleMenuClose = () => setAnchorEl(null);
    const handleProfileMenuOpen = (event) => setAnchorEl(event.currentTarget);
    
    const getUnreadNotifications = () => {
        let unread = 0;
        notifications.map(notif => !notif.read ? unread++ : unread);
        return unread;
    }

    return (
        <>
            
            <div className={classes.sectionNotif}>

                <div className={classes.grow} />
                
                <IconButton
                    color="inherit"
                    aria-label="show new notifications"
                    onClick={(event) => props.handleNotifClick(event, 1)}
                >
                    <StyledBadge badgeContent={getUnreadNotifications()} color="secondary" overlap="rectangular" bordercolor={WHITE}>
                        <NotificationsIcon />
                    </StyledBadge>
                </IconButton>

                <IconButton
                    edge="end"
                    color="inherit"
                    aria-haspopup="true"
                    aria-controls={menuId}
                    onClick={handleProfileMenuOpen}
                    aria-label="account-of-current-user"
                >
                    <MemberAvatar member={authUser} size={AVATAR_SIZE}/>
                </IconButton>

            </div>

            <MenuDesktop
                anchorEl={anchorEl}
                authUser={authUser}
                isMenuOpen={isMenuOpen}
                doSignOut={props.doSignOut}
                handleMenuClose={handleMenuClose}
            />

        </>
    );
}

export default NavigationAuthSectionDesktop;