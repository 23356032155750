import moment from 'moment';
import { stringToDate } from '../../../utils';
import { PROFILE } from '../../../../constants/infos';
import IconButton from '@material-ui/core/IconButton';
import { Grid, Typography } from '@material-ui/core';
import React, { useRef, useLayoutEffect, useState } from "react";
import MemberAvatar from '../../../utils/GenericComponents/memberAvatar';
import FloatingCard from '../../../utils/GenericComponents/floatingCard';
import FileUploader from '../../../utils/GenericComponents/fileUploader';

import { useStyles } from './styles';

const Duration = ({employee}) => {

    if (employee?.contract?.end?.length > 0) return (<>no more in company</>);

    const duration = moment.duration(moment() - moment(stringToDate(employee?.contract?.start || '01/01/2020')));

    let message = duration.asDays() < 0 ? " - " : "";
    if (duration.years() > 0)
        message = `${duration.years()} year${duration.years() > 1 ? "s" : ""} `;
    if (duration.months() > 0)
        message += `${duration.months()} month${duration.months() > 1 ? "s" : ""} `;
    if (duration.days() > 0)
        message += `${duration.days()} day${duration.days() > 1 ? "s" : ""}`;

    return(<>{message}</>);
}

const EmployeeProfile = props => {

    const { employee, firebase, reload, editable } = props;

    const classesProfile = useStyles();

    let movement_timer = null;
    const RESET_TIMEOUT = 100;
    const targetRef = useRef();
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  
    const test_dimensions = () => {
      if (targetRef.current) {
        setDimensions({
          width: targetRef.current.offsetWidth,
          height: targetRef.current.offsetHeight
        });
      }
    };
  
    useLayoutEffect(() => { test_dimensions(); }, []);
  
    window.addEventListener("resize", () => {
      clearInterval(movement_timer);
      movement_timer = setTimeout(test_dimensions, RESET_TIMEOUT);
    });
      
    const handleUploadSuccess = (filename) => reload();

    const buildFileName = (file, emp) => {
        let count = 0;
        const storageRef = firebase.storage.ref(`members/${emp.uid}/employee/profile`);
        firebase.getProfileImage(`members/${emp.uid}/employee/profile`)
        .then(fileInfos => {
            const desertRef = storageRef.child(fileInfos.name);
            if (count < 1) {
                count++;
                desertRef.delete()
                .then(() => console.log(`file ${fileInfos.name} deleted successfully`))
                .catch(error => console.log("ERROR: " + error));
            }
        })
        if (file.name.toLowerCase().includes(emp.trigram.toLowerCase()))
            return `${PROFILE}_${file.name}`;
        return `${PROFILE}_${file.name.split('.')[0]}_${emp.trigram}.${file.name.split('.')[1]}`  
    }

    return (
        <div ref={targetRef} style={{height: '100%'}}>
            <FloatingCard >
                <Grid container spacing={1} direction="column" justifyContent="center" alignItems="stretch">
                    <div style={{paddingTop: 10}}></div>
                    <Grid item align="center">
                        <FileUploader
                            multiple={false}
                            accept="image/*"
                            firebase={firebase}
                            disabled={!editable}
                            onUploadSuccess={handleUploadSuccess}
                            path={`members/${employee.uid}/employee/profile`}
                            filename={file => buildFileName(file, employee)}
                        >
                            <IconButton aria-label="upload-profile" component="span" disabled={!editable} onClick={e => !editable ? e.preventDefault() : ""}>
                                <MemberAvatar member={employee} size={dimensions.width / 20} beautify={true} />
                            </IconButton>
                        </FileUploader>
                    </Grid>
                    <Grid item align="center">
                        <div style={{paddingTop: 20}}></div>
                        <Typography variant='h6' color="textPrimary" className={classesProfile.name}>
                            <strong>{employee.civil.firstName} {employee.civil.lastName}</strong>
                        </Typography>
                    </Grid>
                    <Grid item align="center">
                        <Typography variant='h6' color="textSecondary" className={classesProfile.role}>
                            {employee.contract.role}
                        </Typography>
                    </Grid>
                    <Grid item align="center">
                        <div className={classesProfile.atWorkPanel}>
                            <Typography variant="caption" color="textPrimary">
                                <strong>At work for: </strong><Duration employee={employee}/>
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </FloatingCard>
        </div>
    );
};

export default EmployeeProfile;