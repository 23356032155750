import React, { Component } from 'react';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { CircularProgress } from '@material-ui/core';
import { VERITAS } from '../../../../constants/realtimeDB';

import { useStyles } from './styles';

const WaitUntilConfirmationModal = (props) => {
  const classes = useStyles();
  return <WaitUntilConfirmationModalComponent {...props} classes={classes} />;
};

class WaitUntilConfirmationModalComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: 'none',
      status: 'none',
    };
    this.type = props.type;
    this.classes = props.classes;
    this.firebase = props.firebase;
    this.callBack = props.callBack;
  }

  componentDidMount() {
    this.firebase.db.ref(`${VERITAS}/${this.type}/status`).on('value', snapshot => {
      const status = snapshot.val();
      this.setState({ status });
    });
    this.firebase.db.ref(`${VERITAS}/${this.type}/error`).on('value', snapshot => {
      const error = snapshot.val();
      this.setState({ error });
    });
  }

  componentWillUnmount() {
    this.firebase.db.ref(`${VERITAS}/${this.type}/error`).off();
    this.firebase.db.ref(`${VERITAS}/${this.type}/status`).off();
  };

  render() {
  
    const { error, status } = this.state;
    const { title, authUserUID, message } = this.props;
    const open = (status === authUserUID) && (error === 'none');

    if (error !== 'none') {
      this.callBack(error);
    } else {
      if (status === 'done') {
        this.callBack(status);
      }
    }

    return (
      <Modal
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        className={this.classes.modal}
        BackdropProps={{ timeout: 500 }}
        aria-labelledby="wait-until-confirmation-modal"
        aria-describedby="wait-until-confirmation-modal-closing-when-finished"
      >
        <Fade in={open}>
          <div className={this.classes.paper}>
            <h2 id="wait-until-confirmation-modal-title">{title}</h2>
            <p><i>{message}</i></p>
            <CircularProgress color="inherit" />
          </div>
        </Fade>
      </Modal>
    );
  }
}

export default WaitUntilConfirmationModal;