import React from 'react';
import RecapPanel from './reacapPanel';
import Step from '@material-ui/core/Step';
import { Button } from '@material-ui/core';
import { DAY_OPTIONS } from './dayOptions';
import CalendarPanel from './calendarPanel';
import Stepper from '@material-ui/core/Stepper';
import CloseIcon from '@material-ui/icons/Close';
import StepLabel from '@material-ui/core/StepLabel';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import StepContent from '@material-ui/core/StepContent';
import { YELLOW } from '../../../../../constants/colors';
import { PENDING, UNPAID } from '../../../holidays_constants';
import { OFFICE_MANAGER } from '../../../../../constants/infos';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

import { useStyles } from './styles';

const getSteps = () => ['Select days in the calendar', 'Provides details', 'Validate'];

const getValueToAdd = (option, dayOption) => {
    if (dayOption === DAY_OPTIONS.HALF_DAY.option
        || dayOption === DAY_OPTIONS.MORNING.option
        || dayOption === DAY_OPTIONS.AFTERNOON.option) {
        if (option === DAY_OPTIONS.FULL_DAY.option) {
            return 0.5;
        }
    } else if (dayOption === DAY_OPTIONS.FULL_DAY.option) {
        if (option === DAY_OPTIONS.HALF_DAY.option
            || option === DAY_OPTIONS.MORNING.option
            || option === DAY_OPTIONS.AFTERNOON.option) {
            return -0.5;
        }
    }
    return 0;
}

const createSlackMessage = (eventToStore, result, firebase, employee, daysOffToTake) => {

    const msgEndTime = new Date(eventToStore.end.dateTime).toLocaleTimeString("fr-FR", { hour: 'numeric', minute: 'numeric' });
    const msgStartTime = new Date(eventToStore.start.dateTime).toLocaleTimeString("fr-FR", { hour: 'numeric', minute: 'numeric' });
    const msgEndDate = new Date(eventToStore.end.dateTime).toLocaleDateString("fr-FR", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
    const msgStartDate = new Date(eventToStore.start.dateTime).toLocaleDateString("fr-FR", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
    const msg = `${employee.civil.firstName} ${employee.civil.lastName} has requested *${daysOffToTake} day${daysOffToTake > 1 ? "s" : ""}* off.`;
    const optionMsg = (result) ? `\nThe slot has been added in the <${result.htmlLink}|Google Calendar>.` : "";
    const infosMsg = (sep) => `reason: ${eventToStore.description}${sep} from: ${msgStartDate} ${msgStartTime}${sep}to: ${msgEndDate} ${msgEndTime}${optionMsg}`;
    const slackPayload = {
      attachments: [ {
            pretext: msg,
            fallback: msg,
            color: YELLOW,
            footer: infosMsg('\n'),
      } ]
    };
    firebase.webHooks.sendHrFeedbackNotification(slackPayload);
}

const createEvent = (props) => {
    const { endDate, employee, eventName, startDate, endDayOption, startDayOption } = props;

    const CROWDSEC_MIDDLE_DAY_OFF = 14;
    const CROWDSEC_END_DAY_OFF_HOURS = 20;
    const CROWDSEC_START_DAY_OFF_HOURS = 8;

    const endDateTime = new Date(endDate);
    const startDateTime = new Date(startDate);

    let endHour = CROWDSEC_END_DAY_OFF_HOURS;
    let startHour = CROWDSEC_START_DAY_OFF_HOURS;
    if (startDayOption === DAY_OPTIONS.HALF_DAY.option || startDayOption === DAY_OPTIONS.AFTERNOON.option)
        startHour = CROWDSEC_MIDDLE_DAY_OFF;
    if (endDayOption !== null) {
        if (endDayOption === DAY_OPTIONS.HALF_DAY.option)
            endHour = CROWDSEC_MIDDLE_DAY_OFF;
    } else {
        if (startDayOption === DAY_OPTIONS.AFTERNOON.option) {
            startHour = CROWDSEC_MIDDLE_DAY_OFF;
            endHour = CROWDSEC_END_DAY_OFF_HOURS;
        } else if (startDayOption === DAY_OPTIONS.MORNING.option) {
            startHour = CROWDSEC_START_DAY_OFF_HOURS;
            endHour = CROWDSEC_MIDDLE_DAY_OFF;
        } else {
            startHour = CROWDSEC_START_DAY_OFF_HOURS;
            endHour = CROWDSEC_END_DAY_OFF_HOURS;
        }
    }

    endDateTime.setHours(endHour, 0, 0, 0);
    startDateTime.setHours(startHour, 0, 0, 0);
    
    return {
        summary: `${employee.trigram}: OOO`,
        description: `${eventName || 'holidays'} for ${employee.civil.firstName} ${employee.civil.lastName}`,
        start: {
            dateTime: startDateTime.toISOString(),
            timeZone: "Europe/Paris"
        },
        end: {
            dateTime: endDateTime.toISOString(),
            timeZone: "Europe/Paris"
        },
    }
}

const AskForHolidaysDialog = (props) => {
    
    const classes = useStyles();

    const [endDate, setEndDate] = React.useState(null);
    const [activeStep, setActiveStep] = React.useState(0);
    const [startDate, setStartDate] = React.useState(null);
    const [daysOffToTake, setDaysOffToTake] = React.useState(0);
    const [eventName, setEventName] = React.useState('holidays');
    const [addInGoogleCalendar, setAddInGoogleCalendar] = React.useState(false);
    const [endDayOption, setEndDayOption] = React.useState(DAY_OPTIONS.FULL_DAY.option);
    const [startDayOption, setStartDayOption] = React.useState(DAY_OPTIONS.FULL_DAY.option);

    const steps = getSteps();
    const handleNext = () => setActiveStep(activeStep + 1);
    const handleBack = () => setActiveStep(activeStep - 1);

    const setDates = (startDate, endDate) => {
        setEndDate(endDate);
        setStartDate(startDate);
        setEndDayOption(DAY_OPTIONS.FULL_DAY.option);
        setStartDayOption(DAY_OPTIONS.FULL_DAY.option);
    }

    const saveRequest = (eventToStore, result = null) => {
        const uniqueID = "request_" + Date.now();
        const employee = {...props.employee};
        const payload = {
            status: PENDING,
            to: endDate.toISOString(),
            daysOffToTake: daysOffToTake,
            name: eventName || "holidays",
            calendarID: result?.id || "",
            from: startDate.toISOString(),
        }
        if (employee.contract.daysOff?.holidays)
            employee.contract.daysOff.holidays[uniqueID] = payload;
        else
            employee.contract.daysOff["holidays"] = { [uniqueID]: payload };
      
        props.saveUserInfos(employee);
        setDates(null, null);
        props.setOpenHolidayDialog(false);
        createSlackMessage(eventToStore, result, props.firebase, employee, daysOffToTake);
    }

    const handleValidate = (event) => {
        event.stopPropagation();
        const employee = {...props.employee};
        const eventToStore = createEvent({ endDate, employee, eventName, startDate, endDayOption, startDayOption });
        if (addInGoogleCalendar && props.apiCalendar) {
            props.apiCalendar.createEvent(eventToStore, employee.email)
            .then(value => saveRequest(eventToStore, value.result))
            .catch(reason => alert("Error: " + reason));
        } else {
            saveRequest(eventToStore);
        }        
    }

    const setAddInGoogleCalendarWrapper = (value) => {

        const signUpdate = (sign) => sign && setAddInGoogleCalendar(true);

        if (value && !props.apiCalendar.sign) {
            props.apiCalendar.listenSign(signUpdate);
            props.apiCalendar.handleAuthClick();
        }
        else if (value && props.apiCalendar.sign)
            setAddInGoogleCalendar(value);
    }

    const setStartDayOptionWrapper = (event) => {
        const option = event.target.value;
        let valueToAdd = getValueToAdd(option, startDayOption);
        setDaysOffToTake(daysOffToTake + valueToAdd);
        setStartDayOption(option);
    }

    const setEndDayOptionWrapper = (event) => {
        if (event) {
            const option = event.target.value;
            let valueToAdd = getValueToAdd(option, endDayOption);
            setDaysOffToTake(daysOffToTake + valueToAdd);
            setEndDayOption(option);
        } else {
            setEndDayOption(null);
        }
    }

    const getStepContent = (step) => {

        let realDaysOffToTake = daysOffToTake;
        if (eventName.toLowerCase().indexOf(UNPAID) !== -1)
            realDaysOffToTake = 0;
        const remainingDays = Math.round((props.availableDays - realDaysOffToTake) * 10) / 10;
        const remainingDaysText = `You will have ${remainingDays} day${remainingDays > 1 ? "s" : ""} left.`;

        switch (step) {
            case 0:
                return <CalendarPanel
                            endDate={endDate}
                            width={props.width}
                            setDates={setDates}
                            startDate={startDate}
                            employee={props.employee}
                            daysOffToTake={daysOffToTake}
                            availableDays={props.availableDays}
                            setDaysOffToTake={setDaysOffToTake}
                        />;
            case 1:
                return <RecapPanel
                            step={step}
                            endDate={endDate}
                            eventName={eventName}
                            startDate={startDate}
                            setEventName={setEventName}
                            endDayOption={endDayOption}
                            message={remainingDaysText}
                            daysOffToTake={daysOffToTake}
                            startDayOption={startDayOption}
                            availableDays={props.availableDays}
                            setEndDayOption={setEndDayOptionWrapper}
                            setStartDayOption={setStartDayOptionWrapper}
                        />;
            case 2:
                return <RecapPanel
                            step={step}
                            endDate={endDate}
                            startDate={startDate}
                            endDayOption={endDayOption}
                            daysOffToTake={daysOffToTake}
                            startDayOption={startDayOption}
                            availableDays={props.availableDays}
                            message={`One last look... (${remainingDaysText})`}
                            setAddInGoogleCalendar={setAddInGoogleCalendarWrapper}
                        />;
            default:
                return <Typography>Unknown step</Typography>
        }
    }

    return (
        <center>
            <Dialog
                fullScreen
                className={classes.dialog}
                open={props.openHolidayDialog}
                onClose={() => props.setOpenHolidayDialog(false)}
            >
                <DialogTitle>
                    Ask for new holidays <i>Beta</i>
                    <IconButton aria-label="close" onClick={() => props.setOpenHolidayDialog(false)} className={classes.closeButton}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <div className={classes.root}>
                        <Stepper activeStep={activeStep} orientation="vertical" style={{ marginLeft: -20 }}>
                            {steps && steps.map((label, index) => (
                                <Step key={label} >
                                    <StepLabel><strong>{label}</strong></StepLabel>
                                    <StepContent>
                                        <div style={{ overflowY: 'auto', maxHeight: '500px' }}>
                                            {getStepContent(index)}
                                        </div>
                                        <div className={classes.actionsContainer}>
                                            <div>
                                                <Button
                                                    onClick={handleBack}
                                                    disabled={activeStep === 0}
                                                    className={classes.button}
                                                >
                                                    Back
                                                </Button>
                                                <Button
                                                    color="primary"
                                                    variant="contained"
                                                    className={classes.button}
                                                    disabled={startDate === null || endDate === null}
                                                    onClick={activeStep === steps.length - 1 ? handleValidate : handleNext}
                                                >
                                                    {activeStep === steps.length - 1 ? `Finish and send to ${OFFICE_MANAGER}` : 'Next'}
                                                </Button>
                                            </div>
                                        </div>
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => props.setOpenHolidayDialog(false)} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </center>
    );

}



export default AskForHolidaysDialog;