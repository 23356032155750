import { PURPLE } from '../../../constants/colors';
import { makeStyles } from '@material-ui/core/styles';
import { divRoot, listPanel, rightPanel } from '../../utils';

export const useStyles = makeStyles((theme) => ({

  listPanel: listPanel({theme}),

  divRoot: {
    ...divRoot({theme, bar: false}),
  },

  marketingPanel: {
    marginTop: theme.spacing(1),
  },
  
  passwordDialogText : {
    fontSize: 21,
    textJustify: 'center'
  },

  rightPanel: {
    ...rightPanel({theme}),
    zIndex: -1,
    backgroundColor: 'transparent',
  },
  
  backdrop: {
    color: PURPLE,
    backgroundColor: 'transparent',
    zIndex: theme.zIndex.drawer + 2,
  },
      
  passwordDialogPanel : {
    width: 200,
    marginLeft: 80,
    marginRight: 80,
    marginBottom: 20,
  },
  
}));
  