import React from 'react';
import LangTags from './langTags';
import NewsRating from './rating';
import Box from '@material-ui/core/Box';
import * as UTILS from '../../../../utils';
import Table from '@material-ui/core/Table';
import DateFnsUtils from '@date-io/date-fns';
import { TextField } from '@material-ui/core';
import HistoryNewsRow from './historyNewsRow';
import Avatar from '@material-ui/core/Avatar';
import TableRow from '@material-ui/core/TableRow';
import Collapse from '@material-ui/core/Collapse';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import YouTubeIcon from '@material-ui/icons/YouTube';
import IconButton from '@material-ui/core/IconButton';
import { VIDEO, IMAGE, TITLE, TIMESTAMP } from '../../constants';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { useIntersectionObserver } from '../../../clickableMediaCard/interactionObserver';

import { useRowStyles } from './styles';

const DEFAULT_IMG = 'https://via.placeholder.com/50';

const extractDate = (currentNews) => new Date(currentNews.timestamp);

const ImagePreview = ({currentNews, firebase, classes, open}) => {

  const target = React.useRef();
  const [source, setSource] = React.useState(DEFAULT_IMG);
  let controller = new AbortController();

  const onIntersect = async ([{ isIntersecting }], observerElement) => {
    if (isIntersecting) {
      if (currentNews.type === IMAGE && currentNews?.image && source === DEFAULT_IMG) {
        firebase.getImageAsync(currentNews.image, controller.signal)
        .then(url =>  setSource(url))
        .catch(err => {});
      }
      observerElement.unobserve(target.current);
    }
  }

  React.useEffect(() => {
    return () => controller && controller.abort();
  }, [controller]);

  useIntersectionObserver({ target, onIntersect });

    return (
        <div ref={target} >
            {currentNews.type === IMAGE && <Avatar variant="rounded" src={source} className={open ? classes.xtralarge : classes.large}/>}
            {currentNews.type === VIDEO && <Avatar variant="rounded" className={open ? classes.xtralarge : classes.large}> <YouTubeIcon/> </Avatar>}
        </div>
    );
};

const NewsTableRow = (props) => {

    const { opened, firebase, currentNews, onSaveCurrentNews, onChangeCurrentNews, onDeleteCurrentNews } = props;

    const classes = useRowStyles();
    const [open, setOpen] = React.useState(false);

    const handleDateChange = (date) => {
      const fakeEvent = {
        target: {
          name: TIMESTAMP,
          value: date.getTime(),
        },
        preventDefault: () => {}
      }
      onChangeCurrentNews(fakeEvent, currentNews);
    };

    React.useEffect(() => {
      setOpen(opened);
    }, [opened]);

    return (
      <>
        <TableRow hover className={open ? classes.rootOpen : classes.root}>

          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          
          <TableCell align="left">
            <ImagePreview currentNews={currentNews} firebase={firebase} classes={classes} open={open}/>
          </TableCell>
          
          <TableCell align="left">
            {open
              ? <TextField
                name={TITLE}
                align="left"
                label="Title"
                margin="dense"
                fullWidth={true}
                value={currentNews.title}
                InputProps={{ classes: { input: classes.titleTextField } }}
                onChange={event => onChangeCurrentNews(event, currentNews)}
              />
              : <><strong>{currentNews.title}</strong></>}
          </TableCell>
          
          <TableCell align="left">
            {open
              ? <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    margin="dense"
                    variant="inline"
                    format="dd/MM/yyyy"
                    label="Date picker"
                    id="date-picker-inline"
                    onChange={handleDateChange}
                    KeyboardButtonProps={{ 'aria-label': 'change date' }}
                    value={new Date(currentNews?.timestamp || Date.now())}
                  />
                  </MuiPickersUtilsProvider>
              : <>{UTILS.dateToPrettyString(extractDate(currentNews))}</>}
            
          </TableCell>
          
          <TableCell align="left">
            <NewsRating currentNews={currentNews} onChangeCurrentNews={onChangeCurrentNews} onSaveCurrentNews={onSaveCurrentNews}/>
          </TableCell>

          <TableCell align="left">
            <LangTags currentNews={currentNews} />
          </TableCell>
          
        </TableRow>
          
        <TableRow className={classes.historyRow}>
          <TableCell className={classes.historyRowTable} colSpan={8}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={0} className={classes.historyRowTableBox}>
                <Table size="small" aria-label="news-details" rowkey="id">
                  <TableBody>
                    <HistoryNewsRow
                      firebase={firebase}
                      width={props.width}
                      currentNews={currentNews}
                      onSaveCurrentNews={onSaveCurrentNews}
                      onDeleteCurrentNews={onDeleteCurrentNews}
                      onChangeCurrentNews={onChangeCurrentNews}
                    />
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
  
      </>
    );
}

export default NewsTableRow;
  