import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

const AskForGoogleCalendarAuthDialog = (props) => {

    const { open, onClose, onConfirm } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="confirm-calendar-dialog-title"
        aria-describedby="confirm-calendar-dialog-description"
      >
        <DialogTitle id="confirm-calendar-dialog-title">{"Ask for permission"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-calendar-dialog-description">
            It seems that some items have been set in your Google Calendar.<br/>
            I need your authorization to delete them.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Disagree
          </Button>
          <Button onClick={onConfirm} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AskForGoogleCalendarAuthDialog;