import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({

    list: props => ({
        width: '100%',
        paddingLeft: theme.spacing(0),
        paddingBottom: theme.spacing(1),
        //height: props.length > 6 ? 'calc(100% - 10px)' : 'auto',
        height: 'calc(100% - 10px)'
    }),
    
}));
