import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({

    container: {
      width: '1000',
      maxHeight: '70vh',
    },

    visuallyHidden: {
      top: 20,
      width: 1,
      border: 0,
      height: 1,
      margin: -1,
      padding: 0,
      overflow: 'hidden',
      position: 'absolute',
      clip: 'rect(0 0 0 0)',
    },   

});
