import _ from 'lodash';  // Import lodash for deep object comparison
import Chart from "react-google-charts";
import { Grid } from '@material-ui/core';
import { dateToString } from '../../../utils';
import { Typography } from '@material-ui/core';
import EuroIcon from '@material-ui/icons/Euro';
import React, { useState, useEffect, useCallback } from 'react';
import FloatingCard from "../../../utils/GenericComponents/floatingCard";

import { useStyles, chartOptions } from './styles';

const DisplayChart = props => {
    return (
        <Chart
            data={props.wages}
            options={chartOptions}
            chartType="ColumnChart"
            rootProps={{ 'data-testid': '1' }}
            loader={<div>Loading Chart...</div>}
        />
    )
}

const DisplayMessage = props => {
    return (
        <div className={props.classes.message}>
            <Typography variant="h5" color="textSecondary" component="p" align="center">No data yet</Typography>
        </div>
    )
}

const WagesChart = ({ employee: initialEmployee }) => {
    
    const classes = useStyles();
    const [wages, setWages] = useState([]);
    const [employee, setEmployee] = useState({ ...initialEmployee });

    const computeWages = useCallback((employee) => {
        const wages = [['Date', 'Amount']];
        const lastYear = Date.now() - (1000 * 60 * 60 * 24 * 365);
        if (employee?.contract?.wages) {
            Object.keys(employee.contract.wages).forEach(date => {
                if (parseInt(date) >= lastYear) {
                    const chartDate = dateToString(new Date(parseInt(date)));
                    const chartValue = parseFloat(employee.contract.wages[date]);
                    wages.push( [chartDate, chartValue] );
                }
            });
        }
        setWages(wages);
    }, []);

    useEffect(() => computeWages(initialEmployee), [initialEmployee, computeWages]);

    useEffect(() => {
        if (!_.isEqual(employee, initialEmployee)) {
            setEmployee(initialEmployee);
            computeWages(initialEmployee);
        }
    }, [initialEmployee, employee, computeWages]);

    return (
        <FloatingCard
            noflex={true}
            avatar={<EuroIcon />}
            title="Wages history"
            subheader={<i>Salaries paid over the last 12 months</i>}
        >
            <Grid container spacing={1} justifyContent="center" alignItems="center" className={classes.grid}>
                <Grid item xs={12} >
                    {wages.length > 1 && <DisplayChart wages={wages} />}
                    {wages.length <= 1 && <DisplayMessage classes={classes}/>}
                </Grid>
            </Grid>                      
        </FloatingCard>
    );
};

export default WagesChart;
