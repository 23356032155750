import React from 'react';
import * as constants from './constants';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import RoundDetailsPanel from './roundDetailsPanel';
import Accordion from '@material-ui/core/Accordion';
import RoundSummaryPanel from './roundSummaryPanel';
import IconButton from '@material-ui/core/IconButton';
import CaptablePartnerList from './captablePartnerList';
import { STEERING } from '../../../constants/realtimeDB';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionActions from '@material-ui/core/AccordionActions';

const CaptableRoundDetails = (props) => {

    const { round, rounds, partners, firebase, steeringObj, onSaveUserWithPartnerInfos } = props;

    const [error, setError] = React.useState(false);
    const [expanded, setExpanded] = React.useState(false);
    const [unChanged, setUnchanged] = React.useState(true);
    const [cloneRound, setCloneRound] = React.useState( {...round} );
    const [snackBarOpened, setSnackBarOpened] = React.useState( false );
    const [lock, setLock] = React.useState(('locked' in round) ? !(round.locked) : true);
    const [funded, setFunded] = React.useState(('funded' in round) ? (round.funded) : false);
    const [started, setStarted] = React.useState(('started' in round) ? round.started : false);
    const [cloneEsopRound, setCloneEsopRound] = React.useState( (round.round in steeringObj.ESOP) ? {...steeringObj.ESOP[round.round]} : null );

    const handleChange = (value) => setExpanded(!expanded);

    const getEsopQuantity = () => {
        if (cloneEsopRound && cloneEsopRound.quantity.toString().length > 0) {
            return parseInt(cloneEsopRound.quantity);
        }
        return 0;
    };

    const handleSwitch = (event) => {
        if (event.target.name === constants.SWITCH_LOCK) {
            setLock( event.target.checked );
            cloneRound.locked = !event.target.checked;    
        } else if (event.target.name === constants.SWITCH_START) {
            setStarted( event.target.checked );
            cloneRound.started = event.target.checked;
        } else if (event.target.name === constants.SWITCH_FUND) {
            setFunded( event.target.checked );
            cloneRound.funded = event.target.checked;
        }
        setUnchanged(false);
    };

    const onChange = (event) => {
        if (event.target.name === constants.ESOP_QUANTITY) {
            if (cloneEsopRound) {
                if (event.target.value.toString().length === 0) {
                    event.target.value = 0;
                    event.target.select();
                }
                cloneEsopRound.quantity = parseInt(event.target.value);    
                setCloneEsopRound( {...cloneEsopRound} );
            }
            setError(event.target.value < 0)
        } else {
            if (event.target.name === 'multiplier') {
                if (event.target.value.toString().length === 0) {
                    event.target.value = 1;
                    event.target.select();
                }
            }
            setError(event.target.value.toString().length === 0 || event.target.value < 0)
            cloneRound[event.target.name] = parseFloat(event.target.value);
            setCloneRound( {...cloneRound} );
        }
        setUnchanged(false);
    };

    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway')
          return;
        setSnackBarOpened(false);
    };
  
    const onSubmit = () => {

        if (cloneEsopRound) {
            const esopRoundToSave = { ...cloneEsopRound };
            esopRoundToSave.strike = cloneRound.price;
            firebase.db.ref(`${STEERING}/ESOP/${round.round}`).update( esopRoundToSave );
        }

        const roundToSave = { ...cloneRound };
        delete roundToSave.round;
        firebase.db.ref(`${STEERING}/SHARES/${round.round}`).update( roundToSave );
  
        setSnackBarOpened(true);
        setUnchanged(true);
    };

    return(
        <>
            <Grid container spacing={1}>

                <Grid item xs={12}>

                    <Accordion
                        expanded={expanded}
                        onChange={handleChange}
                    >
                        <AccordionSummary
                            id="panel1bh-header"
                            expandIcon={ <ExpandMoreIcon /> }
                            aria-controls="panel1bh-content"
                        >
                            <RoundSummaryPanel {...props} />
            
                        </AccordionSummary>

                        <div >
                            <AccordionDetails>

                                <Grid container spacing={1}>

                                    <Grid item xs={12}>
                                        <RoundDetailsPanel
                                            lock={lock}
                                            funded={funded}
                                            rounds={rounds}
                                            started={started}
                                            round={cloneRound}
                                            partners={partners}
                                            onChange={onChange}
                                            steeringObj={steeringObj}
                                            handleSwitch={handleSwitch}
                                            esopQuantity={getEsopQuantity()}
                                        />
                                    </Grid>

                                </Grid>

                            </AccordionDetails>
                        </div>

                        <Divider />
                        
                        <AccordionActions>
                            <Button size="small" color="primary" variant="contained" onClick={onSubmit} disabled={error || unChanged}>
                                Save
                            </Button>
                        </AccordionActions>

                    </Accordion>


                </Grid>

                <Grid item xs={12}>
                    <CaptablePartnerList 
                        round={round}
                        partners={partners}
                        onSaveUserWithPartnerInfos={onSaveUserWithPartnerInfos}
                    />
                </Grid>

            </Grid>

            <Snackbar
                open={snackBarOpened}
                message="Infos saved"
                autoHideDuration={3000}
                onClose={handleCloseSnack}
                anchorOrigin={ {vertical: 'top', horizontal: 'center'} }
                action={
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
          />   

        </>
    );
}

export default CaptableRoundDetails;