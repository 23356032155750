import { makeStyles } from '@material-ui/core/styles';
import { YELLOW } from '../../../../../constants/colors';

export const useStyles = makeStyles((theme) => ({
  
  indicator: {
    backgroundColor: YELLOW,
  },

  tab : {
    '&:hover': {
      borderBottom: `3px solid ${YELLOW}`,
    },
    [theme.breakpoints.up('md')]: { paddingBottom: theme.spacing(1.75), fontSize: theme.spacing(1.7) },
  }
  
}));
  