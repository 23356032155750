import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { Tooltip } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useStyles, BorderLinearProgress } from './styles';

const LinearProgressWithLabel = (props) => {
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1} minWidth={75}>
                <BorderLinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">
                    {`${Math.round(props.value,)}%`}
                </Typography>
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
};


export const CircularProgressWithLabel = (props) => { 
    const classes = useStyles(props);

    const value = Math.max(0, Math.min(100, props.value));
    //const value = Math.max(0, props.value);
    return (
        <Tooltip title={props?.title || ''} arrow>
            <Box position="relative" display="inline-flex">
                <div className={classes.box}>
                    <Typography variant="caption" component="div" color="textSecondary" className={classes.text}>{`${Math.round(props.value,)}%`}</Typography>
                </div>
                <div className={classes.box}>
                    <CircularProgress variant="determinate" size={props.size} value={100} className={classes.circularProgressGray}/>
                </div>
                <CircularProgress variant="determinate" thickness={5} size={props.size} value={value} className={classes.circularProgress}/>
            </Box>
        </Tooltip>
    );
}

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};


export default LinearProgressWithLabel;