import React from 'react';
import { parseISO } from 'date-fns';
import Warning from '@material-ui/icons/Warning';
import AskForHolidaysDialog from './askForHolidays';
import PlannedHolidayDialog from './plannedHolidays';
import BeachAccess from '@material-ui/icons/BeachAccess';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { CONTRACT_TYPE } from '../../contract_type_constants';
import { UNPAID, VALIDATED } from '../../holidays_constants';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import StyledBadge from '../../../utils/GenericComponents/styledBadge';
import FloatingCard from '../../../utils/GenericComponents/floatingCard';
import { capitalize, Grid, Typography, Button, Divider } from '@material-ui/core';
import { CircularProgressWithLabel } from '../../../utils/GenericComponents/progressBar';

import { useStyles } from './styles';

const getNUmberOfPlannedHolidays = (employee) => {
    const requests = Object.values(employee.contract.daysOff?.holidays || {});
    const now = new Date();
    now.setDate(1);
    let nbrDays = 0;
    requests.forEach(request => {
        const reason = request.name.toLowerCase();
        if (reason.indexOf(UNPAID) === -1) {
            const to = parseISO(request.to);
            (to >= now) && (nbrDays += request.daysOffToTake);
        }
    });
    return nbrDays;
}

const YearDaysOff = (props) => {
    return (
        <Grid container spacing={1} direction="column" justifyContent="flex-start" className={props.classes.yearDayOff}>
            <Grid item className={props.classes.yearDayOffHeader}>
                <Typography variant="h6" className={props.classes.year}>
                    <strong>{props.year}</strong>
                </Typography>
            </Grid>
            <Grid item className={props.classes.yearDayOff}>
                <DaysOffTypography {...props} mode='taken'/>
            </Grid>
            <Grid item className={props.classes.yearDayOff}>
                <DaysOffTypography {...props} mode='available'/>
            </Grid>
        </Grid>
    );
}

const DaysOffTypography = (props) => {
    const { width, employee, classes, year, mode, numberOfPlannedHolidays } = props;

    const days = Math.round((employee?.contract?.daysOff?.[year]?.[mode] || 0) * 100) / 100;
    const classMode = days < 0 ? "overtaken" : days > 0 ? mode : "none";

    let daysTxt = (width === 'xs') ? `${days} d` : `${days} ${Math.abs(days) > 1 ? 'days' : 'day'}`;
    const modeTxt = (width === 'xs') ? capitalize(mode).replace('vailable', 'vlb') : capitalize(mode);
    if (days === 0) {
        daysTxt = 'none';
    }
    if (numberOfPlannedHolidays > 0)
        daysTxt += ` (${numberOfPlannedHolidays} ${Math.abs(numberOfPlannedHolidays) > 1 ? 'days' : 'day'} planned)`;

    return (
        <Typography variant="caption" className={classes.year}>
            <span className={classes[classMode]}>
                <strong>{modeTxt}: </strong>
            </span>
            {/* <span className={classes[classMode]}>{days === 0 ? 'none' : daysTxt}</span> */}
            <span className={classes[classMode]}>{daysTxt}</span>
        </Typography>
    );
}

const TotalDaysOffTypography = (props) => {

    const { employee, classes, lastYear, currentYear } = props;

    const daysLastYear = employee?.contract?.daysOff?.[lastYear]?.available || 0;
    const daysCurrentYear = employee?.contract?.daysOff?.[currentYear]?.available || 0;
    const daysPlanned = getNUmberOfPlannedHolidays(employee);
    const days = Math.round((daysLastYear + daysCurrentYear - daysPlanned) * 100) / 100;
    const classMode = days < 0 ? "overtaken" : days > 0 ? "available" : "none";

    return (
        <center>
            <Grid container direction="row" justifyContent="center" alignItems="center" className={classes.totalDaysOffPanel}>
                {(days < 0) && (
                    <Grid item >
                        <Warning className={classes.warning}/>
                    </Grid>
                )}
                <Grid item >
                    <Typography className={classes.remaining} gutterBottom>
                        <strong>Available: </strong><span className={classes[classMode]}>{days === 0 ? 'none' : `${days} ${Math.abs(days) > 1 ? 'days' : 'day'}`}</span>
                    </Typography>
                </Grid>
            </Grid>
        </center>
    );
}

const getAccountantYears = () => {
    const now = new Date();
    const lastYear = (now.getMonth() < 5) ? now.getFullYear() - 1 : now.getFullYear();
    return [lastYear, lastYear + 1];
}

const EmployeeHolidays = props => {
    const { width, firebase, employee, isManager, apiCalendar, saveUserInfos } = props;

    const [lastYear, currentYear] = getAccountantYears();
    const employeeDaysOff = employee?.contract?.daysOff || {}; 
    const [openHolidayDialog, setOpenHolidayDialog] = React.useState(false);
    const [openPlannedHolidayDialog, setOpenPlannedHolidayDialog] = React.useState(false);

    const numberOfPlannedHolidays = getNUmberOfPlannedHolidays(employee);

    let maxDaysAvailable = employeeDaysOff?.[currentYear]?.available || 0;
    maxDaysAvailable += employeeDaysOff?.[lastYear]?.available || 0;
    maxDaysAvailable -= numberOfPlannedHolidays;

    let maxDaysTaken = employeeDaysOff?.[currentYear]?.taken || 0;
    maxDaysTaken += employeeDaysOff?.[lastYear]?.taken || 0;

    const total = maxDaysAvailable + maxDaysTaken;
    const value = total > 0 ? Math.round((maxDaysTaken / total) * 100) : 100;
    const employeeHolidaysClasses = useStyles({value});

    const onAskForNewHoliday = () => setOpenHolidayDialog(true);
    const onSeePlannedHolidays = () => setOpenPlannedHolidayDialog(true);

    const currentMonth = new Date();
    currentMonth.setDate(1);
    const plannedHolidays = Object.values(employeeDaysOff?.holidays || {}).filter(holiday => new Date(holiday.to) >= currentMonth);
    const hasHolidaysPending = plannedHolidays.reduce((acc, holiday) => acc + (holiday.status !== VALIDATED ? 1 : 0), 0);

    if (employee.contract?.type === CONTRACT_TYPE.OYSTER) {
        return (
            <>
                <Grid container spacing={2} direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item xs={12} style={{paddingTop : '3vh'}} align="center">
                        <Typography variant="h6">
                            {employee.civil.firstName} is on Oyster contract
                        </Typography>
                    </Grid>
                    
                </Grid>
            </>
        );        
    }

    return (
        <>
        <FloatingCard 
            title="Days off"
            avatar={<BeachAccess />}
            subheader={<i>summary and management</i>}
            buttonAction={ <CircularProgressWithLabel value={value} size={90} normal={false}/> }
        >
            <Grid container spacing={2} direction="row" justifyContent="space-between" alignItems="center">
                <Grid item xs={12} style={{paddingTop : '3vh'}}>
                    <TotalDaysOffTypography classes={employeeHolidaysClasses} employee={employee} lastYear={lastYear} currentYear={currentYear}/>
                </Grid>
                <Grid item xs={12} align="center" style={{paddingTop : '3vh'}}>
                    <Grid container spacing={2} direction="row" justifyContent="center" alignItems="stretch" className={employeeHolidaysClasses.gridDaysOff}>
                        <Grid item xs={6} >
                            <YearDaysOff width={width} classes={employeeHolidaysClasses} employee={employee} year={lastYear} />
                        </Grid>
                        <Grid item xs={6} >
                            <YearDaysOff width={width} classes={employeeHolidaysClasses} employee={employee} year={currentYear} numberOfPlannedHolidays={numberOfPlannedHolidays} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} >
                    <Divider className={employeeHolidaysClasses.divider}/>
                </Grid>
                <Grid item xs={12} sm={6} align="center">
                    <StyledBadge overlap="circular" top={5} right={5} badgeContent={ hasHolidaysPending } >
                        {isManager
                        ? (<Button
                                variant="contained"
                                onClick={onSeePlannedHolidays}
                                startIcon={<EventAvailableIcon />}
                                className={employeeHolidaysClasses.button}
                            >
                                Planned holidays
                            </Button>)
                        : (<Button
                                variant="contained"
                                onClick={onSeePlannedHolidays}
                                startIcon={<EventAvailableIcon />}
                                disabled={plannedHolidays.length === 0}
                                className={employeeHolidaysClasses.button}
                            >
                                Planned holidays
                            </Button>)
                        }
                    </StyledBadge>
                </Grid>
                <Grid item xs={12} sm={6}  align='center'>
                    <Button
                        variant="contained"
                        onClick={onAskForNewHoliday}
                        startIcon={<DateRangeIcon />}
                        className={employeeHolidaysClasses.button}
                    >
                        Ask for holidays
                    </Button>
                </Grid>
            </Grid>
        </FloatingCard>
        
        {openHolidayDialog && (
            <AskForHolidaysDialog
                width={width}
                employee={employee}
                firebase={firebase}
                apiCalendar={apiCalendar}
                saveUserInfos={saveUserInfos}
                availableDays={maxDaysAvailable}
                openHolidayDialog={openHolidayDialog}
                setOpenHolidayDialog={setOpenHolidayDialog}
            />
        )}

        {openPlannedHolidayDialog && (
            <PlannedHolidayDialog
                width={width}
                employee={employee}
                apiCalendar={apiCalendar}
                saveUserInfos={saveUserInfos}
                availableDays={maxDaysAvailable}
                openPlannedHolidayDialog={openPlannedHolidayDialog}
                setOpenPlannedHolidayDialog={setOpenPlannedHolidayDialog}
            />
        )}
        </>
    );
}

export default EmployeeHolidays;