import { makeStyles } from '@material-ui/core/styles';
import { PURPLE } from '../../../constants/colors';

const PATH = "https://firebasestorage.googleapis.com/v0/b/react-compta-automation.appspot.com/o/images";

const GENERAL_INFOS_BG = `url(${PATH}%2Faccount_infos.png?alt=media&token=${process.env.GENERAL_IMAGE_BG_TOKEN})`;
const OTHER_PANELS_BG = `url(${PATH}%2Fback.png?alt=media&token=${process.env.OTHER_IMAGE_BG_TOKEN})`;

export const useStyles = makeStyles( (theme) => ({
  
  pos: {
    marginBottom: theme.spacing(1),
  },

  title: {
    fontSize: theme.typography.pxToRem(15),
  },

  avatar: {
    backgroundColor: PURPLE,
    marginRight: theme.spacing(1),
  }, 

  secondaryHeading: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(15),
  },  

  content: {
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
  },

  form_root: {
    '& > *': {
      width: '35ch',
      margin: theme.spacing(1),
    },
  },

  heading: {
    flexShrink: 0,
    flexBasis: '33.33%',
    marginTop: theme.spacing(1),
    fontSize: theme.typography.pxToRem(15),
  },

  headerGeneralInfos: props => ({
    backgroundSize: 'contain',
    backgroundPosition: 'right',
    backgroundRepeat: 'no-repeat',
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      backgroundImage: GENERAL_INFOS_BG,
    },
  }),

  headerPassword: props => ({
    backgroundSize: 'contain',
    backgroundPosition: 'right',
    backgroundRepeat: 'no-repeat',
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      backgroundImage: OTHER_PANELS_BG,
    },
  }),

}));
