import React from 'react';
import PropTypes from 'prop-types';
import Rating from '@material-ui/lab/Rating';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  ratingContainer: {
    paddingTop: 13,
    paddingLeft: 13,
  },
});

const NewsRating = ({value}) => {
    const classes = useStyles();

    return (
        <div className={classes.ratingContainer}>
            <Rating name="read-only" value={value} readOnly />
        </div>
    );
}

NewsRating.propTypes = {
  value: PropTypes.number.isRequired,
};

export default NewsRating;
