import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 150,
        marginRight: theme.spacing(1),
    },

    listItemText: {
        marginBottom: 0,
        fontSize: '1.3vh',
        marginLeft: theme.spacing(1),
    },
    
    typoText: {
        fontSize: '1.3vh',
        marginTop: theme.spacing(-0.5),
    },
    
    listItemIcon: {
        marginBottom: 0,
        marginLeft: theme.spacing(1),
    },
    
}));
