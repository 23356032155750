import { boxShadow } from '../../..';
import { makeStyles } from '@material-ui/core/styles';
import { PURPLE } from '../../../../../constants/colors';

export const useStyles = makeStyles((theme) => ({
  
  avatar: {
    backgroundColor: PURPLE,
    marginRight: theme.spacing(1),
  }, 

  indicatorCard: {
    maxWidth: '100%',
    boxShadow: boxShadow,
  },
  
  dashboardContainer: props => ({
    overflow: 'hidden',
    position: 'relative',
    paddingTop: `${props.ratio * 100}%`,
  }),

  dashboardIframe: {
    top: 0,
    left: 0,
    border: 0,
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
  
  title: {
    [theme.breakpoints.down('sm')]: { fontSize: '1rem'},
    [theme.breakpoints.up('md')]: { fontSize: '1.5vw' },
  },

  subheader: {
    [theme.breakpoints.up('md')]: { fontSize: '1vw' },
    [theme.breakpoints.down('sm')]: { fontSize: '1rem' },
  },
  
}));
  