import Round from './round';
import * as UTILS from '../utils';
import TabPanel from '../TabPanel';
import ListFooter from '../ListFooter';
import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import List from '@material-ui/core/List';
import MyInvestments from './myInvestments';
import * as ROLES from '../../constants/roles';
import LockIcon from '@material-ui/icons/Lock';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import Collapse from '@material-ui/core/Collapse';
import Backdrop from '@material-ui/core/Backdrop';
import { RED, GREEN } from '../../constants/colors';
import IconButton from '@material-ui/core/IconButton';
import { STEERING } from '../../constants/realtimeDB';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ImpersonationPanel } from './impersonationPanel';
import CircularProgress from '@material-ui/core/CircularProgress';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import CustomListItem from '../utils/GenericComponents/customListItem';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';

class PartnerPageBase extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      rounds: [],
      reports: [],
      partners: [],
      partnerID: '',
      loading: false,
      isAdmin: false,
      lastUpdate: '',
      selectedIndex: 0,
      reportsNumber: 0,
      expanded : false,
      balanceSheets: [],
      steeringObj : null,
      balanceSheetsNumber: 0,
      openSnackFeedback: false,
      firebase: props.firebase,
      partner: { ...props.partner },
    };
    this.unMounted = false;
    this.classes = props.classes;
    this.authUser = props.partner;
  }

  componentDidMount() {
    this.setState({ loading: true });

    this.props.firebase.db.ref(`${STEERING}/SHARES`).on('value', snapshot => {
      const roundsData = snapshot.val();
      delete roundsData.FOUNDERS;
      const rounds = Object.keys(roundsData).map(key => ({
        round: key,
        locked: roundsData[key].locked,
        started: roundsData[key].started,
        order: roundsData[key].order - 1,
      }));
  
      rounds.sort( (a, b) => (a.order > b.order) ? 1 : -1);
      this.setState({ rounds });
    });

    this.props.firebase.isAdminAsync(this.authUser).then(isAdmin => {
      if (isAdmin) {    

        this.props.firebase.steering().on('value', snapshot => {
          const steeringObj = snapshot.val();
          this.setState({ steeringObj, lastUpdate: steeringObj.lastUpdate });
        });

        // if authUser is admin, get all partners
        this.props.firebase.users().on('value', snapshot => {
          const usersObjectFromDB = snapshot.val();
          const usersList = Object.keys(usersObjectFromDB)
          .filter(key => usersObjectFromDB[key]?.roles?.[ROLES.PARTNER] || false)
          .map(key => ({ uid: key, ...usersObjectFromDB[key] }));

          usersList.map(user => delete user.notifications);

          this.setState({ partners: usersList, isAdmin });
          this.setState({ loading: false });
        });
      } else {
        // authUser is not admin, get only steering info needed to compute partner's dashboard
        const steeringObj = {};
        this.props.firebase.db.ref(`${STEERING}/SHARES`).on('value', snapshot => steeringObj.SHARES = snapshot.val());
        this.props.firebase.db.ref(`${STEERING}/metrics`).on('value', snapshot => steeringObj.metrics = snapshot.val());
        this.props.firebase.db.ref(`${STEERING}/currentSharePrice`).on('value', snapshot => steeringObj.currentSharePrice = snapshot.val());
        this.props.firebase.db.ref(`${STEERING}/sharePriceEvolution`).on('value', snapshot => steeringObj.sharePriceEvolution = snapshot.val());
        this.props.firebase.db.ref(`${STEERING}/ESOP`).on('value', snapshot => {
          steeringObj.ESOP = snapshot.val();
          this.setState({ loading: false, steeringObj, lastUpdate: UTILS.dateToString(new Date()) });
        });
      }
    });

    this.props.firebase.getFilesNumber(`reporting`, this.setReportingFilesNumber);
    this.props.firebase.getFilesNumber(`balance`, this.setBalanceSheetsFilesNumber);

    this.listenToPartnerChanges(this.state.partner.uid);
  }

  listenToPartnerChanges = (partnerID) => {
    this.props.firebase.user(this.state.partner.uid).off();
    this.props.firebase.user(partnerID).on('value', snapshot => {
      (!this.unMounted) && this.setState( { partnerID, partner: { ...snapshot.val(), uid: snapshot.key } });
    });
  }

  componentWillUnmount() {
    this.unMounted = true;
    this.props.firebase.users().off();
    this.props.firebase.steering().off();
    this.props.firebase.db.ref(`${STEERING}/ESOP`).off();
    this.props.firebase.db.ref(`${STEERING}/SHARES`).off();
    this.props.firebase.user(this.state.partner.uid).off();
    this.props.firebase.db.ref(`${STEERING}/metrics`).off();
    this.props.firebase.db.ref(`${STEERING}/currentSharePrice`).off();
    this.props.firebase.db.ref(`${STEERING}/sharePriceEvolution`).off();
  }
  
  updateUser = (user) => this.setState({ user });
  getUrlDataStudio = (id) => this.props.firebase.urlDataStudio(id);
  handleClick = () => this.setState({expanded: !this.state.expanded});
  handleListItemClick = (event, index) => this.setState({ selectedIndex: index });
  handleSelectPartner = (event) => this.listenToPartnerChanges(event.target.value);

  setReportingFilesNumber = (number) => {
    this.setState({ reportsNumber: number });
    this.props.firebase.getFiles(`reporting`, this.setReportingFiles);
  }

  setBalanceSheetsFilesNumber = (number) => {
    this.setState({ balanceSheetsNumber: number });
    this.props.firebase.getFiles(`balance`, this.setBalanceSheetsFiles);
  }

  setReportingFiles = (fileInfos) => {
    if (!this.unMounted) {
      this.state.reports.push(fileInfos)
      if (this.state.reports.length === this.state.reportsNumber)
        this.setState({ reports: this.state.reports });
    }
  }

  setBalanceSheetsFiles = (fileInfos) => {
    if (!this.unMounted) {
      this.state.balanceSheets.push(fileInfos)
      if (this.state.balanceSheets.length === this.state.balanceSheetsNumber)
        this.setState( {balanceSheets: this.state.balanceSheets} );      
    }
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    this.setState({ openSnackFeedback: false });
  };
        
  sendFeedbackInvest = (round) => {
    const { partner } = this.state;
    //console.log(`got invest intent of ${partner.partnerInfo.SHARES[round].investmentIntent} for round ${round}`);
    this.props.firebase.webHooks.sendFeedbackInvestNotification(partner, round);
  }

  saveInvestIntent = (round) => {
    const { partner } = this.state;
    const investmentIntent = partner.partnerInfo.SHARES[round].investmentIntent;
    this.props.firebase.getUserValue(partner.uid, `partnerInfo/SHARES/${round}`).update({
      investmentIntent : investmentIntent,
      stage: { P1: true, P2: true, P3: true },
      date: UTILS.dateToString(new Date(Date.now())),
    })
    .then(r => {
      this.props.firebase.webHooks.fillGoogleSheet(partner, round);
      //console.log("updating google sheet");
      this.props.firebase.webHooks.sendSlackNotification(partner, round);
      this.setState( { openSnackFeedback: true });  
    })
    .catch(error => {
      alert("Error : " + error);
    });  
  }

  render() {

    const {
      rounds,
      isAdmin,
      reports,
      loading,
      partner,
      expanded,
      firebase,
      partners,
      partnerID,
      lastUpdate,
      steeringObj,
      balanceSheets,
      selectedIndex,
      openSnackFeedback,
    } = this.state;

    //rounds.sort( (a, b) => (a.order > b.order) ? 1 : -1);

    const sm = (this.props.width === 'sm' || this.props.width === 'xs');

    return(
      <>
        <Backdrop className={this.classes.backdrop} open={loading} >
          <CircularProgress color="inherit" />
        </Backdrop> 

        {isAdmin && (
          <ImpersonationPanel
            partners={partners}
            firebase={firebase}
            partnerID={partnerID}
            classes={this.classes}
            width={this.props.width}
            handleSelectPartner={this.handleSelectPartner}
          />
        )}

      <div className={this.classes.divRoot} style={{marginTop: isAdmin ? 60 : 0}}>

        <CssBaseline />


        <Grid container spacing={0} alignItems="stretch">

          <Grid item xs={1} sm={sm ? 1 : 2}>
            <List
              dense={sm}
              component="nav"
              aria-label="partner-info-list"
              className={this.classes.listPanel}
            >
              <CustomListItem
                index={0}
                title="My Investment"
                selectedIndex={selectedIndex}
                headerIcon={<AccountBalanceWalletIcon />}
                handleListItemClick={this.handleListItemClick}
              />

              <CustomListItem
                index={1}
                nestable={expanded}
                title="Funding Rounds"
                headerIcon={<MonetizationOnIcon />}
                handleListItemClick={this.handleClick}
              />

              <Collapse in={expanded} timeout="auto" unmountOnExit>  
                {rounds.map( round => (
                  <CustomListItem
                    nested={true}
                    key={round.round}
                    index={round.order}
                    title={round.round}
                    selectedIndex={selectedIndex}
                    handleListItemClick={this.handleListItemClick}
                    headerIcon={(round.locked) ? <LockIcon style={{color: RED}}/> : <LockOpenIcon style={{color: GREEN}}/>}
                  />
                ))}
              </Collapse>

              <ListFooter sm={sm} firebase={firebase} />
            </List>
          </Grid>

            <Grid item xs={11} sm={sm ? 11 : 10}>

              <div className={this.classes.rightPanel}>

                {selectedIndex === 0 && 
                  <TabPanel value={selectedIndex} index={0}>
                    {steeringObj && (
                      <MyInvestments
                        partner={partner}
                        reports={reports}
                        firebase={firebase}
                        classes={this.classes}
                        lastUpdate={lastUpdate}
                        width={this.props.width}
                        steeringObj={steeringObj}
                        balanceSheets={balanceSheets}
                        getUrlDataStudio={this.getUrlDataStudio}
                      />
                    )}
                  </TabPanel>
                }
  
                {rounds.map( round => ( selectedIndex === round.order &&
                  <TabPanel key={round.round} value={selectedIndex} index={round.order}>
                  {steeringObj && (
                    <Round
                      {...this.props}
                      partner={partner}
                      firebase={firebase}
                      round={round.round}
                      steeringObj={steeringObj}
                      updateUser={this.updateUser}
                      saveInvestIntent={this.saveInvestIntent}
                      sendFeedbackInvest={this.sendFeedbackInvest}
                      />
                  )}
                  </TabPanel>
                ))}

              </div>
            </Grid>

          </Grid>

          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            autoHideDuration={3000}
            open={openSnackFeedback}
            onClose={this.handleClose}
            message="Investment amount submitted"
            action={
              <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          />

        </div>
        </>
    );
  }
}

export default PartnerPageBase;
  