import { card } from '../../..';
import { makeStyles } from '@material-ui/core/styles';
import * as COLORS from '../../../../../constants/colors';

export const useStyles = makeStyles((theme) => ({

  card: card,
  
  avatar: {
    backgroundColor: COLORS.PURPLE,
  },
  
  listItemText: {
    marginLeft: theme.spacing(1),
    marginBottom: 0,
  },

  listItemIcon: {
    marginLeft: theme.spacing(1),
    marginBottom: 0,
  },

  menuItem: {
    '&:hover': {
      borderRadius: '0.5rem',
    },
  },

  backdrop: {
    color: COLORS.PURPLE,
    backgroundColor: COLORS.WHITE,
    zIndex: theme.zIndex.drawer + 1,
  },
  
  dragAndDropDiv: {
    width: '100%',
    height: '100%',
    minHeight: 100,
    margin: 'auto',
    marginBottom: 10,
    overflowY: 'auto',
  },

  title: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.2vw'
    },  
  },
  
  subheader: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7rem'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '0.8vw'
    },  
  },
  
}));
  