import * as COLORS from '../../../constants/colors';
import { makeStyles } from '@material-ui/core/styles';
import { divRoot, listPanel, rightPanel } from '../../utils';

export const useStyles = makeStyles((theme) => ({

    listPanel: listPanel({theme}),
    rightPanel: rightPanel({theme}),
    divRoot: divRoot({ theme, bar: false }),
      
    formControl: {
        minWidth: 200,
        margin: theme.spacing(1),
    },

    impersonationPanel:{
        margin: 5,
        background: COLORS.PASTEL_RED,
        border: '1px solid transparent',
        borderRadius: theme.shape.borderRadius,
    },

    impersonationGrid:{
        margin: -10,
        border: '1px solid transparent'
    },

    impersonationTypo:{
        margin: 0,
        border: '1px solid transparent'
    },

    listItemText: {
        marginBottom: 0,
        marginLeft: theme.spacing(1),
      },
  
    listItemIcon: {
        marginLeft: theme.spacing(1),
        marginBottom: 0,
    },
  
    menuItem: {
        '&:hover': {
          borderRadius: '0.5rem',
        },
    }  
  
}));
  